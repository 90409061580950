/*************************
 *
 * MAVENICK CONFIDENTIAL
 * ______
 *
 *  [2020] - Mavenick Consulting OPC Pvt Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mavenick Consulting OPC Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Mavenick Consulting OPC Pvt Ltd
 * and its suppliers and may be covered by India, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mavenick Consulting OPC Pvt Ltd.
 */


import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'KlearStack';
  login;

  constructor(private route: Router) {}
  ngOnInit() {
  //  this.route.navigate(['/login']);
    this.login = parseInt(sessionStorage.getItem('homepage'), 10);
  }
  logginPressed(event) {
    this.login = event;
    alert('Reloding on page 20');
    if (event) {
      this.route.navigate(['/home']).then( res => {
         location.reload();
      });
    }
  }
}
