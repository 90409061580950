/*************************
 *
 * MAVENICK CONFIDENTIAL
 * ______
 *
 *  [2020] - Mavenick Consulting OPC Pvt Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mavenick Consulting OPC Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Mavenick Consulting OPC Pvt Ltd
 * and its suppliers and may be covered by India, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mavenick Consulting OPC Pvt Ltd.
 */


import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetpostService } from './getpost.service';
import { DataService } from './shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  ip = '';

  // URLS
  getLabelsURL = this.ip + '/klearstack/getmappingdata';

  private content = new BehaviorSubject<any>({"name": 'Ajinkya', "age": 25});
  public data = this.content.asObservable();
  static totalInvoicePages;
  static totalInvoices;
  static checkedRecord: any[];
  static checkedCount: number;
  static file: File;
  static labelJson = {
    notification: {
      saveChanges: {
        visibility: true,
        value: 'Yes'
      },
      downloadLocal: {
        visibility: true,
        value: 'Yes'
      },
      exportQuickbook: {
        visibility: true,
        value: 'Yes'
      },
      deleteInvoice: {
        visibility: true,
        value: 'Yes'
      },
      addTableRow: {
        visibility: true,
        value: 'Yes'
      },
      approveInvoice: {
        visibility: true,
        value: 'Yes'
      },
      deleteTableRow: {
        visibility: true,
        value: 'Yes'
      },
      deleteAllRows: {
        visibility: true,
        value: 'Yes'
      },
      saveCoOrdinates: {
        visibility: true,
        value: 'Yes'
      },
      markApproved: {
        visibility: true,
        value: 'Yes'
      },
      continuousHeaders: {
        visibility: true,
        value: 'Yes'
      },
      invoiceType: {
        visibility: true,
        value: 'b2b'
      }
    },
    invoices: {
      invoiceNumber: {
        label: 'Invoice #',
        visibility: true,
        required: false
      },
      supplierName: {
        label: 'Supplier Name',
        visibility: true,
        required: false
      },
      invoiceDate: {
        label: 'Invoice Date',
        visibility: true,
        required: false
      },
      gstnNumber: {
        label: 'Supplier GSTN #',
        visibility: true,
        required: false
      },
      poNumber: {
        label: 'PO #',
        visibility: true,
        required: false
      },
      vatNumber: {
        label: 'Supplier VAT #',
        visibility: true,
        required: false
      },
      total: {
        label: 'Total',
        visibility: true,
        required: false
      },
      table: {
        label: '',
        visibility: true,
        required: false
      },
      dataItemTable: {
        label: '',
        visibility: true,
        required: false
      },
      fcv: {
        label: 'Currency',
        visibility: true,
        required: false
      },
      icv: {
        label: 'Origin',
        visibility: true,
        required: false
      },
      creditSupplier: {
        label: 'Credit Supplier',
        visibility: true,
        required: false
      },
      debitPurchase: {
        label: 'Debit Purchase',
        visibility: true,
        required: false
      },
      mdv: {
        label: 'Due Date',
        visibility: true,
        required: false
      },
      creation_date: {
        label: 'Creation Date',
        visibility: true,
        required: false
      },
      file_name: {
        label: 'File Name',
        visibility: true,
        required: false
      },
      application_no: {
        label: 'Application #',
        visibility: true,
        required: false
      },
      error_fields: {
        label: 'Error Fileds',
        visibility: true,
        required: false
      },
      customerAddress: {
        label: 'Customer Address',
        visibility: true,
        required: false
      },
      supplierAddress: {
        label: 'Supplier Address',
        visibility: true,
        required: false
      },
      txav: {
        label: 'Total Tax',
        visibility: true,
        required: false
      },
      bdv: {
        label: 'Balance Due',
        visibility: true,
        required: false
      },
      cgv: {
        label: 'Customer GSTN',
        visibility: true,
        required: false
      },
      csnv: {
        label: 'Customer State Name',
        visibility: true,
        required: false
      },
      ssnv: {
        label: 'Supplier State Name',
        visibility: true,
        required: false
      },
      ilv: {
        label: 'Loan #',
        visibility: true,
        required: false
      },
      ptv: {
        label: 'Payment Type',
        visibility: true,
        required: false
      },
      cnv: {
        label: 'Customer Name',
        visibility: true,
        required: false
      },
      gstp: {
        label: '',
        visibility: true,
        required: false
      },
      gstv: {
        label: '',
        visibility: true,
        required: false
      },
      iitv : {
        label: 'Invoice Type',
        visibility: true,
        required: false
      },
      spnv : {
        label: 'Supplier Pincode',
        visibility: true,
        required: false
      },
      cpnv : {
        label: 'Customer Pincode',
        visibility: true,
        required: false
      },
      icnv : {
        label: 'CIN #',
        visibility: true,
        required: false
      },
      chnv : {
        label: 'Chassis #',
        visibility: true,
        required: false
      },
      env : {
        label: 'Engin #',
        visibility: true,
        required: false
      },
      mnv : {
        label: 'Model Name',
        visibility: true,
        required: false
      },
      cvv : {
        label: 'Customer VAT #',
        visibility: true,
        required: false
      },
      dnv : {
        label: 'Delivery #',
        visibility: true,
        required: false
      },
      gnv : {
        label: 'GRN #',
        visibility: true,
        required: false
      },
      sesv : {
        label: 'SES #',
        visibility: true,
        required: false
      },
      vatp : {
        label: 'VAT Percentage',
        visibility: true,
        required: false
      },
      ihv : {
        label: 'Hypothecation',
        visibility: true,
        required: false
      },
      islv : {
        label: 'Lessor Name',
        visibility: false,
        required: false
      },
      iclv : {
        label: 'Lessee Name',
        visibility: false,
        required: false
      },
      scrn : {
        label: 'Supplier Contact Refernece Name',
        visibility: false,
        required: false
      },
      lecrn : {
        label: 'Lessee Contact Reference Name',
        visibility: false,
        required: false
      },
      lrcrn : {
        label: 'Lessor Contact Reference Name',
        visibility: false,
        required: false
      },
      islav : {
        label: 'Lessor Address Value',
        visibility: false,
        required: false
      },
      iclav : {
        label: 'Lessee Address Value',
        visibility: false,
        required: false
      },
      cessv : {
        label: 'CESS Amount',
        visibility: false,
        required: false
      },
      tcsv : {
        label: 'TCS Amount',
        visibility: false,
        required: false
      },
      discv : {
        label: 'Discount Amount',
        visibility: false,
        required: false
      },
      vrnv : {
        label: 'Vehicle Reg. #',
        visibility: false,
        required: false
      },
      hsnv : {
        label: 'HSN',
        visibility: false,
        required: false
      },
      lrgv : {
        label: 'Lessor GSTIN',
        visibility: false,
        required: false
      },
      legv : {
        label: 'Lessee GSTIN',
        visibility: false,
        required: false
      },
      smnv : {
        label: 'Supplier Contact #',
        visibility: false,
        required: false
      },
      iclmnv : {
        label: 'Lessee Contact Person Phone',
        visibility: false,
        required: false
      },
      icleiv : {
        label: 'Lessee Contact Person Email',
        visibility: false,
        required: false
      },
      insv : {
        label: 'Insurance Value',
        visibility: false,
        required: false
      },
      regv : {
        label: 'Registration Value',
        visibility: false,
        required: false
      },
      accuracy: {
        label: 'Confidence Score',
        visibility: true,
        required: false
      },
      cmnv: {
        label: 'Customer Mobile #',
        visibility: true,
        required: false
      },
      emv: {
        label: 'Supplier Email ID',
        visibility: true,
        required: false
      },
      ipef: {
        label: 'Image Quality Error',
        visibility: true,
        required: false
      },
      exceptionType: {
        label: 'Exception Type',
        visibility: true,
        required: false
      },
      subExceptionType: {
        label: 'Exception Details',
        visibility: true,
        required: false
      },
      cmnl: {
        label: 'Cust. Mob. No. List',
        visibility: true,
        required: false
      },
      stav: {
        label: 'Ship To Party Address',
        visibility: true,
        required: false
      },
      bmv: {
        label: "Buyer's  eMail Address",
        visibility: true,
        required: false
      },
      downloadFormat: {
        format: 'excel'
      },
      quickBookPref: {
        status: 'on'
      },
      taxSystem: 'indian',
      show: true
    },
    receipts: {
      receiptNumber: {
        label: 'Receipt #',
        visibility: true,
        required: false
      },
      supplierName: {
        label: 'Supplier Name',
        visibility: true,
        required: false
      },
      receiptDate: {
        label: 'Receipt Date',
        visibility: true,
        required: false
      },
      gstnNumber: {
        label: 'Supplier GSTN #',
        visibility: true,
        required: false
      },
      poNumber: {
        label: 'PO #',
        visibility: true,
        required: false
      },
      vatNumber: {
        label: 'Supplier VAT #',
        visibility: true,
        required: false
      },
      total: {
        label: 'Total',
        visibility: true,
        required: false
      },
      table: {
        label: '',
        visibility: true,
        required: false
      },
      dataItemTable: {
        label: '',
        visibility: true,
        required: false
      },
      fcv: {
        label: 'Currency',
        visibility: true,
        required: false
      },
      icv: {
        label: 'Origin',
        visibility: true,
        required: false
      },
      creditSupplier: {
        label: 'Credit Supplier',
        visibility: true,
        required: false
      },
      debitPurchase: {
        label: 'Debit Purchase',
        visibility: true,
        required: false
      },
      mdv: {
        label: 'Due Date',
        visibility: true,
        required: false
      },
      creation_date: {
        label: 'Creation Date',
        visibility: true,
        required: false
      },
      file_name: {
        label: 'File Name',
        visibility: true,
        required: false
      },
      application_no: {
        label: 'Application No.',
        visibility: true,
        required: false
      },
      error_fields: {
        label: 'Error Fields',
        visibility: true,
        required: false
      },
      customerAddress: {
        label: 'Customer Address',
        visibility: true,
        required: false
      },
      supplierAddress: {
        label: 'Supplier Address',
        visibility: true,
        required: false
      },
      txav: {
        label: 'Total Tax',
        visibility: true,
        required: false
      },
      bdv: {
        label: 'Balance Due',
        visibility: true,
        required: false
      },
      cgv: {
        label: 'Customer GSTN',
        visibility: true,
        required: false
      },
      csnv: {
        label: 'Customer State Name',
        visibility: true,
        required: false
      },
      ssnv: {
        label: 'Supplier State Name',
        visibility: true,
        required: false
      },
      scnv: {
        label: 'Supplier City Name',
        visibility: true,
        required: false
      },
      ccnv: {
        label: 'Customer City Name',
        visibility: true,
        required: false
      },
      ilv: {
        label: 'Loan #',
        visibility: true,
        required: false
      },
      ptv: {
        label: 'Payment Type',
        visibility: true,
        required: false
      },
      cnv: {
        label: 'Customer Name',
        visibility: true,
        required: false
      },
      gstp: {
        label: '',
        visibility: true,
        required: false
      },
      gstv: {
        label: '',
        visibility: true,
        required: false
      },
      iitv : {
        label: 'Invoice Type',
        visibility: true,
        required: false
      },
      spnv : {
        label: 'Supplier Pincode',
        visibility: true,
        required: false
      },
      cpnv : {
        label: 'Customer Pincode',
        visibility: true,
        required: false
      },
      accuracy: {
        label: 'Confidence Score',
        visibility: true,
        required: false
      },
      cmnv: {
        label: 'Customer Mobile #',
        visibility: true,
        required: false
      },
      emv: {
        label: 'Supplier Email ID',
        visibility: true,
        required: false
      },
      ipef: {
        label: 'Image Quality Error',
        visibility: true,
        required: false
      },
      exceptionType: {
        label: 'Exception Type',
        visibility: true,
        required: false
      },
      subExceptionType: {
        label: 'Exception Details',
        visibility: true,
        required: false
      },
      cmnl: {
        label: 'Customer Mobile # List',
        visibility: true,
        required: false
      },
      downloadFormat: {
        format: 'excel'
      },
      quickBookPref: {
        status: 'on'
      },
      taxSystem: 'indian',
      show: false
    },
    purchaseOrder: {
      supplierName: {
        label: 'Supplier Name',
        visibility: true,
        required: false
      },
      puchaseorderDate: {
        label: 'Puchase Order Date',
        visibility: true,
        required: false
      },
      gstnNumber: {
        label: 'Customer GSTN #',
        visibility: true,
        required: false
      },
      poNumber: {
        label: 'PO #',
        visibility: true,
        required: false
      },
      vatNumber: {
        label: 'Supplier VAT #',
        visibility: true,
        required: false
      },
      total: {
        label: 'Total',
        visibility: true,
        required: false
      },
      table: {
        label: '',
        visibility: true,
        required: false
      },
      dataItemTable: {
        label: '',
        visibility: true,
        required: false
      },
      fcv: {
        label: 'Currency',
        visibility: true,
        required: false
      },
      icv: {
        label: 'Origin',
        visibility: true,
        required: false
      },
      creditSupplier: {
        label: 'Credit Supplier',
        visibility: true,
        required: false
      },
      debitPurchase: {
        label: 'Debit Purchase',
        visibility: true,
        required: false
      },
      mdv: {
        label: 'Due Date',
        visibility: true,
        required: false
      },
      creation_date: {
        label: 'Creation date',
        visibility: true,
        required: false
      },
      file_name: {
        label: 'File Name',
        visibility: true,
        required: false
      },
      application_no: {
        label: 'Application No.',
        visibility: true,
        required: false
      },
      error_fields: {
        label: 'Image Quality Error',
        visibility: true,
        required: false
      },
      customerAddress: {
        label: 'Customer Address',
        visibility: true,
        required: false
      },
      supplierAddress: {
        label: 'Supplier Address',
        visibility: true,
        required: false
      },
      txav: {
        label: 'Total Tax',
        visibility: true,
        required: false
      },
      bdv: {
        label: 'Balance Due',
        visibility: true,
        required: false
      },
      cgv: {
        label: 'Customer GSTN',
        visibility: true,
        required: false
      },
      csnv: {
        label: 'Customer State Name',
        visibility: true,
        required: false
      },
      ssnv: {
        label: 'Supplier State name',
        visibility: true,
        required: false
      },
      scnv: {
        label: 'Supplier City Name',
        visibility: true,
        required: false
      },
      ccnv: {
        label: 'Customer City name',
        visibility: true,
        required: false
      },
      ilv: {
        label: 'Loan #',
        visibility: true,
        required: false
      },
      ptv: {
        label: 'Payment Type',
        visibility: true,
        required: false
      },
      cnv: {
        label: 'Customer Name',
        visibility: true,
        required: false
      },
      gstp: {
        label: '',
        visibility: true,
        required: false
      },
      gstv: {
        label: '',
        visibility: true,
        required: false
      },
      iitv : {
        label: 'Invoice Type',
        visibility: true,
        required: false
      },
      spnv : {
        label: 'Supplier Pincode',
        visibility: true,
        required: false
      },
      cpnv : {
        label: 'Customer Pincode',
        visibility: true,
        required: false
      },
      accuracy: {
        label: 'Confidence Score',
        visibility: true,
        required: false
      },
      cmnv: {
        label: 'Customer Mobile #',
        visibility: true,
        required: false
      },
      emv: {
        label: 'Supplier Email ID',
        visibility: true,
        required: false
      },
      pfv: {
        label: 'Freight',
        visibility: true,
        required: false
      },
      stav: {
        label: 'Ship To Party Address',
        visibility: true,
        required: false
      },
      ipef: {
        label: 'Image Quality Error',
        visibility: true,
        required: false
      },
      exceptionType: {
        label: 'Exception Type',
        visibility: true,
        required: false
      },
      subExceptionType: {
        label: 'Exception Details',
        visibility: true,
        required: false
      },
      cmnl: {
        label: 'Customer Mobile # List',
        visibility: true,
        required: false
      },
      downloadFormat: {
        format: 'excel'
      },
      quickBookPref: {
        status: 'on'
      },
      taxSystem: 'indian',
      show: false
    },
    autorejected: {
      application_no: {
        label: 'Application No.',
        visibility: true,
        required: false
      },
      file_name: {
        label: 'File Name',
        visibility: true,
        required: false
      },
      date: {
        label: 'Date',
        visibility: true,
        required: false
      },
      reject_reason: {
        label: 'Reject Reason',
        visibility: true,
        required: false
      },
      downloadFormat: {
        format: 'excel'
      },
      show: false
    },
    tabs: {
      general: {
        label: 'General',
        visibility: true,
        required: false
      },
      supplier: {
        label: 'Supplier',
        visibility: true,
        required: false
      },
      customer: {
        label: 'Customer',
        visibility: true,
        required: false
      },
      lessor: {
        label: 'Lessor',
        visibility: true,
        required: false
      },
      lessee: {
        label: 'Lessee',
        visibility: true,
        required: false
      },
      tax: {
        label: 'Tax',
        visibility: true,
        required: false
      },
      charges: {
        label: 'Charges',
        visibility: true,
        required: false
      },
      two_wheeler: {
        label: 'Two Wheeler',
        visibility: true,
        required: false
      },
      push_api: {
        label: 'Push API',
        visibility: true,
        required: false
      },
      accounts: {
        label: 'Accounts',
        visibility: true,
        required: false
      },
      tables: {
        label: 'Tables',
        visibility: true,
        required: false
      },
      misc: {
        label: 'Misc',
        visibility: true,
        required: false
      },
    },
    rfq: {
      qiv: {
        label: 'Quote Id',
        visibility: true,
        required: false
      },
      cnv: {
        label: 'Customer Name',
        visibility: true,
        required: false
      },
      pnv: {
        label: 'Product Name',
        visibility: true,
        required: false
      },
      sidv: {
        label: 'Start Date',
        visibility: true,
        required: false
      },
      eidv: {
        label: 'End Date',
        visibility: true,
        required: false
      },
      pdv: {
        label: 'Payment Date',
        visibility: true,
        required: false
      },
      pmv: {
        label: 'Payment Method',
        visibility: true,
        required: false
      },
      ctv: {
        label: 'Consumption Threshold',
        visibility: true,
        required: false
      },
      vtv: {
        label: 'Volume Tolerance',
        visibility: true,
        required: false
      },
      mav: {
        label: 'Material Amount',
        visibility: true,
        required: false
      },
      crv: {
        label: 'Contract Reference #',
        visibility: true,
        required: false
      },
      tecv: {
        label: 'Total Estimation Consumption',
        visibility: true,
        required: false
      },
      downloadFormat: {
        format: 'excel'
      },
      show: false
    },
    nach: {
      niq: {
        label: 'Nach Image Quality',
        visibility: true,
        required: false
      },
      nan: {
        label: 'Nach A/C No.',
        visibility: true,
        required: false
      },
      ntype: {
        label: 'Nach Type',
        visibility: true,
        required: false
      },
      nrn1: {
        label: 'Nach Ref Number1',
        visibility: true,
        required: false
      },
      nsef: {
        label: 'Nach Signature Exist',
        visibility: true,
        required: false
      },
      nbnef: {
        label: 'Nach Bank Name Exist',
        visibility: true,
        required: false
      },
      downloadFormat: {
        format: 'excel'
      },
      show: false
    },
    insurances: {
      cnv: {
        label: 'Customer Name',
        visibility: false,
        required: false
      },
      customerAddress: {
        label: 'Customer Address',
        visibility: false,
        required: false
      },
      invoiceDate: {
        label: 'Policy start date',
        visibility: false,
        required: false
      },
      mnv: {
        label: 'Model',
        visibility: false,
        required: false
      },
      env: {
        label: 'Engine Number',
        visibility: false,
        required: false
      },
      chnv: {
        label: 'Chassis Number',
        visibility: false,
        required: false
      },
      total: {
        label: 'Insured Declared Value',
        visibility: false,
        required: false
      },
      ihv: {
        label: 'Hypothecation',
        visibility: false,
        required: false
      },
      table: {
        label: '',
        visibility: false,
        required: false
      },
      dataItemTable: {
        label: '',
        visibility: false,
        required: false
      },
      downloadFormat: {
        format: 'excel'
      },
      show: false
    },
    accuracyLevels: {
      high: 85,
      low: 40,
    },
    push_api_status: false
  }
  defaultDocValues = ['invoices', 'receipts', 'purchaseOrder', 'autorejected', 'tabs', 'rfq', 'nach', 'insurances'];
  public isFilterOn: boolean;
  constructor(
    private getpost: GetpostService,
    private dataService: DataService
    ) {}

  setData(value) {
    this.content.next(value);
  }

  onIsFilterOn(isFilterOn) {
    this.isFilterOn = isFilterOn;
  }

  getIsFilterOn() {
    return this.isFilterOn;
  }

  setMappingData(mappingJson, parent? ) {
    let changed = false;
    let docTypes = Object.keys(CommonService.labelJson);
    docTypes.forEach(docType => {
      if (this.defaultDocValues.includes(docType)) {
        let fields = Object.keys(CommonService.labelJson[docType]);

        try {
          if (!mappingJson[docType].downloadFormat || !mappingJson[docType].downloadFormat.format) {
            mappingJson[docType].downloadFormat.format = CommonService.labelJson[docType].downloadFormat.format;
            changed = true;
          }
        } catch (e) {}

        // remove unwanted keys
        try {
          if (Object.keys(mappingJson[docType]).includes('visibility')) {
            delete(mappingJson[docType]['visibility']);
            delete(mappingJson[docType]['required']);
            changed = true;
          }
        } catch (e) {}

        fields.forEach(field => {
          try {
            if (!mappingJson[docType]) {
              mappingJson[docType] = CommonService.labelJson[docType];
              changed = true;
            } else if (
              (!mappingJson[docType][field] || (
                mappingJson[docType][field] &&
                !mappingJson[docType][field]['label'] &&
                CommonService.labelJson[docType][field]['label'])
                ) && field != 'show'
              ) {
                  // set defaulty values for fields
                  mappingJson[docType][field] = CommonService.labelJson[docType][field];
                  changed = true;
            }
          } catch (e) {
          }
        });
      }
    });

    // to be removed after some time
    try {
      if (mappingJson['invoices']['pfv']) {
        let res = delete(mappingJson['invoices']['pfv']);
        changed = true;
      }
    } catch (e) {
      console.log(e)
    }

    // update to server
    if (changed) {
      this.getpost.updateLabels(mappingJson, 'Invoices').subscribe(
        res => {
          if (res === 'Successfully') {
          }
        },
        err => {
          console.log(err);
        },
        () => {
        }
      );
    }
    CommonService.labelJson = mappingJson;
    return mappingJson;
  }

  getVisibleFieldsForGrid(labelJson) {
    let selectedDocType = this.dataService._selectedDocType;
    let gridJson;
    if (selectedDocType.labelKey == 'invoices') {
      gridJson = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '1px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'number',
          fieldName: 'overall_confidence',
          text: 'Overall confidence',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'application_no',
          text: labelJson[selectedDocType.labelKey].application_no.label,
          minWidth: '115px',
          visibility: labelJson[selectedDocType.labelKey].application_no.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: labelJson[selectedDocType.labelKey].file_name.label,
          minWidth: '270px',
          visibility: labelJson[selectedDocType.labelKey].file_name.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'inv',
          text: labelJson[selectedDocType.labelKey].invoiceNumber.label,
          minWidth: '120px',
          visibility: labelJson[selectedDocType.labelKey].invoiceNumber.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'isv',
          text: labelJson[selectedDocType.labelKey].supplierName.label,
          minWidth: '260px',
          visibility: labelJson[selectedDocType.labelKey].supplierName.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'idv',
          text: labelJson[selectedDocType.labelKey].invoiceDate.label,
          minWidth: '100px',
          visibility: labelJson[selectedDocType.labelKey].invoiceDate.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'itv',
          text: labelJson[selectedDocType.labelKey].total.label,
          minWidth: '75px',
          visibility: labelJson[selectedDocType.labelKey].total.visibility
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: labelJson[selectedDocType.labelKey].creation_date.label,
          minWidth: '100px',
          visibility: labelJson[selectedDocType.labelKey].creation_date.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: 0,
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: 0,
          visibility: true
        }
      ];
    } else if (selectedDocType.labelKey == 'receipts') {
      gridJson = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'number',
          fieldName: 'overall_confidence',
          text: 'Overall confidence',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'File name',
          minWidth: '270px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'inv',
          text: 'Invoice number',
          minWidth: '110px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'isv',
          text: 'Supplier name',
          minWidth: '260px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'idv',
          text: 'Invoice date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'itv',
          text: 'Total',
          minWidth: '75px',
          visibility: true
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: 'Creation date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: '0',
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (selectedDocType.labelKey == 'purchaseOrder') {
      gridJson = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'number',
          fieldName: 'overall_confidence',
          text: 'Overall confidence',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: labelJson[selectedDocType.labelKey].file_name.label,
          minWidth: '270px',
          visibility: labelJson[selectedDocType.labelKey].file_name.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'ipv',
          text: labelJson[selectedDocType.labelKey].poNumber.label,
          minWidth: '110px',
          visibility: labelJson[selectedDocType.labelKey].poNumber.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'cnv',
          text: labelJson[selectedDocType.labelKey].cnv.label,
          minWidth: '260px',
          visibility: labelJson[selectedDocType.labelKey].cnv.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'idv',
          text: 'PO date',
          minWidth: '100px',
          visibility: true,
        },
        {
          fieldType: 'string',
          fieldName: 'itv',
          text: labelJson[selectedDocType.labelKey].total.label,
          minWidth: '75px',
          visibility: labelJson[selectedDocType.labelKey].total.visibility,
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: labelJson[selectedDocType.labelKey].creation_date.label,
          minWidth: '100px',
          visibility: labelJson[selectedDocType.labelKey].creation_date.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: '0',
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (selectedDocType.labelKey == 'autorejected') {
      gridJson = [
        {
          fieldType: 'string',
          fieldName: 'application_no',
          text: labelJson[selectedDocType.labelKey].application_no.label,
          minWidth: '0',
          visibility: labelJson[selectedDocType.labelKey].application_no.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: labelJson[selectedDocType.labelKey].file_name.label,
          minWidth: '0',
          visibility: labelJson[selectedDocType.labelKey].file_name.visibility,
        },
        {
          fieldType: 'date',
          fieldName: 'date',
          text: labelJson[selectedDocType.labelKey].date.label,
          minWidth: '0',
          visibility: labelJson[selectedDocType.labelKey].date.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'reject_reson',
          text: labelJson[selectedDocType.labelKey].reject_reason.label,
          minWidth: '0',
          visibility: labelJson[selectedDocType.labelKey].reject_reason.visibility
        }
      ];
    } else if (selectedDocType.labelKey == 'rfq') {
      gridJson = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'number',
          fieldName: 'overall_confidence',
          text: 'Overall confidence',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'File name',
          minWidth: '270px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'qiv',
          text: 'Quote Id',
          minWidth: '110px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'pnv',
          text: 'Product Name',
          minWidth: '260px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'sidv',
          text: 'Start Date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'eidv',
          text: 'End Date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: 'Creation date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: 0,
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (selectedDocType.labelKey == 'nach') {
      gridJson = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'File name',
          minWidth: '230px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'ntype',
          text: 'Type',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'nrn1',
          text: 'Ref Number1',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'nsef',
          text: 'Signature Exist',
          minWidth: '105px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'nbnef',
          text: 'Bank Name Exist',
          minWidth: '110px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'nan',
          text: 'A/C No.',
          minWidth: '140px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'niq',
          text: 'Image Quality',
          minWidth: '260px',
          visibility: true
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: 'Creation date',
          minWidth: '160px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: '80px',
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (selectedDocType.labelKey == 'insurances') {
      gridJson = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'File name',
          minWidth: '230px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'cnv',
          text: labelJson[selectedDocType.labelKey].cnv.label,
          minWidth: '269px',
          visibility: labelJson[selectedDocType.labelKey].cnv.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'idv',
          text: labelJson[selectedDocType.labelKey].invoiceDate.label,
          minWidth: '100px',
          visibility: labelJson[selectedDocType.labelKey].invoiceDate.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'mnv',
          text: labelJson[selectedDocType.labelKey].mnv.label,
          minWidth: '200px',
          visibility: labelJson[selectedDocType.labelKey].mnv.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'itv',
          text: labelJson[selectedDocType.labelKey].total.label,
          minWidth: '100px',
          visibility: labelJson[selectedDocType.labelKey].total.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'ihv',
          text: labelJson[selectedDocType.labelKey].ihv.label,
          minWidth: '100px',
          visibility: labelJson[selectedDocType.labelKey].ihv.visibility
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: 'Creation date',
          minWidth: '160px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: '80px',
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (selectedDocType.labelKey == 'uploadStatus') {
      gridJson = [
        {
          fieldType: 'string',
          fieldName: 'dotMarker',
          text: 'BATCH NAME',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'selectInvoice',
          text: 'UPLOAD TIME',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'overall_confidence',
          text: 'COMPLETION TIME',
          minWidth: '1px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'STATUS',
          minWidth: '269px',
          visibility: true
        }
      ];
    }
    try {
      for (let i = 0; i < gridJson.length; i++) {
        if (!gridJson[i].visibility) {
          gridJson.splice(i--, 1)
        }
      }
    } catch (e) {
      console.log('selected doctype array:', gridJson)
    }
    return gridJson;
  }

}
