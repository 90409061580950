import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string, headers: string[], sheetNames?, downloadFormat?): void {
    // console.log(json)
    let sheets = {};
    let tempSheetNames = [];
    if (sheetNames
      && sheetNames.length > 0
      && downloadFormat
      && downloadFormat === 'excel') {
      for(let i = 0; i < json.length; i++) {
        tempSheetNames.push('sheet' + (i + 1));
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[i]);
        sheets[sheetNames[i]] = worksheet;
      }
      const workbook: XLSX.WorkBook = { Sheets: sheets, SheetNames: sheetNames };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    } else {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    if (!fileName) {
      // FileSaver.saveAs(data, '_' + (new Date().getTime()) + EXCEL_EXTENSION);
      FileSaver.saveAs(data, '_' + EXCEL_EXTENSION);

    } else {
      // FileSaver.saveAs(data, fileName + '_' + (new Date().getTime()) + EXCEL_EXTENSION);
      // FileSaver.saveAs(data, fileName + '_' + (new Date().getTime()) + EXCEL_EXTENSION);
      FileSaver.saveAs(data, fileName + '_' + EXCEL_EXTENSION);
    }
  }
  // public saveAsCSV (json: any[], csvFilename: string, options): void {
  //   console.log(json);
  //   const file =  new Angular5Csv(json, 'My Report');
  // }
}

