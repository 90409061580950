import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GetpostService } from './getpost.service';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule,
  MatListModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatSelectModule,
  MatCheckboxModule,
  MatBadgeModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatSnackBarModule,
  MatChipsModule,
  MatStepperModule,
  MatTabsModule,
  MatToolbarModule,
  MatDialogModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataStructureService } from './data-structure.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { DisconnectComponent } from './disconnect/disconnect.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SnackBarComponent } from './homepage2/homepage2.component';
import { FilterDialog } from './tables/tables.component';
import { PopupComponent } from './shared/popup/popup.component';

@NgModule({
  declarations: [
    AppComponent,
    DisconnectComponent,
    SnackBarComponent,
    FilterDialog,
    PopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSnackBarModule,
    MatChipsModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatDialogModule,

    HttpClientModule,
    FormsModule,

    NgxPaginationModule,
    NgxSpinnerModule,
    ScrollingModule,

    FlexLayoutModule,
    ReactiveFormsModule,
    PdfViewerModule,
    NgxImageZoomModule.forRoot()
  ],
  entryComponents: [FilterDialog],
  providers: [GetpostService, DataStructureService],
  bootstrap: [AppComponent]
})
export class AppModule { }
