/*************************
 *
 * MAVENICK CONFIDENTIAL
 * ______
 *
 *  [2020] - Mavenick Consulting OPC Pvt Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mavenick Consulting OPC Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Mavenick Consulting OPC Pvt Ltd
 * and its suppliers and may be covered by India, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mavenick Consulting OPC Pvt Ltd.
 */


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetpostService {
  company_name = sessionStorage.getItem('company_name');
  ip = '';
  // ip = '';
  tableDataURL = this.ip + '/klearstack/getresult';
  tableDataPaginationURL = this.ip + '/klearstack/getinvoices';
  getUserInvoicesUrl = this.ip + '/klearstack/getuserinvoices';
  requestImageUrl = this.ip + '/klearstack/getimage';
  requestSymlinkImageUrl = this.ip + '/klearstack/get_image_string';
  uploadImageUrl = this.ip + '/klearstack/invoiceprocessingui';
  sendProcessedDataUrl = this.ip + '/klearstack/saveresult';
  saveChangedData = this.ip + '/klearstack/saveresult';
  sendToDeleteURL = this.ip + '/klearstack/deleteresult';
  getRegisteredDetails = this.ip + '/klearstack/getregisterdetails';
  sendUserDetailsURl = this.ip + '/klearstack/insertuserdetails';
  getUserDetailsURL = this.ip + '/klearstack/getuserdetails';
  updateUserDetailsURL = this.ip + '/klearstack/updateuserdetails';
  reviewAllURL = this.ip + '/klearstack/updatereview';
  loginURL = this.ip + '/klearstack/login';
  batchProcessURL = this.ip + '/klearstack/batchprocess';
  importUrl = this.ip + '/klearstack/importsupplieraccounts';
  appendUrl = this.ip + '/klearstack/appendexceldata';
  getSupplierListUrl = this.ip + '/klearstack/getsupplierlist';
  getAccountListUrl = this.ip + '/klearstack/getaccountlist';
  getQuickBookListUrl = this.ip + '/klearstack/getaccountlist';
  updateLabelsUrl = this.ip + '/klearstack/insertmappingdata';
  getLabelsURL = this.ip + '/klearstack/getmappingdata';
  sendDateForFilterUrl = this.ip + '/klearstack/getdatefilter';
  sendConatesUrl = this.ip + '/klearstack/gettext';
  sendTableConatesUrl = this.ip + '/klearstack/gettable';
  getMeteringDetailsUrl = this.ip + '/klearstack/getmeteringdetails';
  getUploadingStatusUrl = '/klearstack/getstatusdocuments';
  deleteSingleStatusUrl = '/klearstack/deletestatusdocuments';
  clearStatusUrl = this.ip + '/klearstack/deletestatusdocuments';
  retryUploadInvoiceUrl = this.ip + '/klearstack/retryinvoice';
  pdfSplittingUrl = this.ip + '/pdf_splitter/batch_upload';
  totalsInformationUrl = this.ip + '/klearstack/dailytotalforanalytics';
  getDocumentUrl = this.ip + '/klearstack/getdocument';
  getRecordsCountUrl = this.ip + '/klearstack/getcountdocument';
  getPageResultUrl = this.ip + '/klearstack/getpageresult';
  getapprovedocumentURL = this.ip + '/klearstack/getapprovedocument';
  getfilterdocumentsURL = this.ip +  '/klearstack/getfilterdocuments';
  getApplicationFilterURL = this.ip +  '/klearstack/getapplicationfilter';
  getprogressdocumentsUrl = this.ip + '/klearstack/getprogressdocuments';
  getErroredRecordsURL = this.ip + '/klearstack/getsfdcerrorfilter';
  getAutorejectPassURL = this.ip + '/klearstack/getautorejectpreviouscondtion';
  getAutoRejectedRecordsURL = this.ip + '/klearstack/getautorejectinvoices';
  getOriginalImageURL = this.ip + '/klearstack/uploaded_file_download';
  getqbestatusdocumentsUrl = this.ip + 'klearstack/getqbestatusdocuments';
  getuserinvoicesfilterUrl = this.ip +  '/klearstack/getuserinvoicesfilter';
  pushAndApproveUrl = this.ip + '/klearstack/push_document';
  multipleSearchFilterUrl = this.ip + '/klearstack/getmultiresult';

  quickBookUrl = 'https://try.klearstack.com/qbo_publish';
  quickBookAuthURL = 'https://try.klearstack.com/qbo_auth';
  quickBookDisconnect = 'https://try.klearstack.com/disconnect';

  revieweidlist = [];
  newInvoiceList = [];
  erroredInvoices = [];
  fd: FormData = new FormData();
  postData: any;
  image_id: number;
  obj_id: any;
  data: any;
  arrayLength: number;
  finalResult = [];
  httpHeader = new HttpHeaders();
  constructor(private http: HttpClient) {}

  getqbestatusdocuments(collection, pagenumber, status) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('page_index', pagenumber);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('role', sessionStorage.getItem('rolename'));
    fd.append('status', status);
    return this.http.post(this.getqbestatusdocumentsUrl, fd);
  }

  getDocument(doc_id, company_name, user_id, collection_name ) {
    const fd = new FormData();
    fd.append('company_name', company_name);
    fd.append('collection_name', collection_name);
    fd.append('user_id', user_id);
    fd.append('document_id', doc_id);
    return this.http.post(this.getDocumentUrl, fd);
  }

  getAutorejectedRecords(pagenumber) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('page_index', pagenumber);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('role', sessionStorage.getItem('rolename'));
    return this.http.post(this.getAutoRejectedRecordsURL, fd);
  }

  getErroredRecords(collection, pagenumber) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('page_index', pagenumber);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('role', sessionStorage.getItem('rolename'));
    return this.http.post(this.getErroredRecordsURL, fd);
  }

  getAutorejectPass(collection, pagenumber) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('page_index', pagenumber);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('role', sessionStorage.getItem('rolename'));
    return this.http.post(this.getAutorejectPassURL, fd);
  }

  // get table data page by page
  getPageResult(collection, pagenumber, parentfunction?) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('page_index', pagenumber);
    fd.append('user_id', sessionStorage.getItem('_id'));
    if (sessionStorage.getItem('rolename') === 'Annotator') {
      fd.append('role', 'Data_annotator');// replace Approver with Data_annotator
    } else {
      fd.append('role', sessionStorage.getItem('rolename'));
    }
    return this.http.post(this.getPageResultUrl, fd);
  }

  // get count of total records
  getRecordsCount(collection) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('role', sessionStorage.getItem('rolename'));
    return this.http.post(this.getRecordsCountUrl, fd);
  }

  // total af all invoices to show in dashboard
  getTotalsInformation(collection) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    return this.http.post(this.totalsInformationUrl, fd);
  }

  splitPdf(data, data2, file, location, collection, isSkipped) {
    console.log(data, data2, isSkipped);
    const fd = new FormData();
    if (isSkipped) {
      fd.append('list_of_pageno', JSON.stringify([]));
      fd.append('skip_page_no', JSON.stringify(data2));
    } else {
      fd.append('list_of_pageno', JSON.stringify(data));
      fd.append('skip_page_no', JSON.stringify([]));
    }
    fd.append('file', file);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('location', location);
    console.log(this.pdfSplittingUrl);
    return this.http.post(this.pdfSplittingUrl, fd, {responseType: 'arraybuffer'});
  }

  // date filter function
  sendDate(data, collection) {
    const fd = new FormData();
    fd.append('start_date', data.start_date);
    fd.append('end_date', data.end_date);
    fd.append('collection_name', collection);
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('page_index', '1');
    fd.append('role', sessionStorage.getItem('rolename'));
    return this.http.post(this.sendDateForFilterUrl, fd);
  }

  // suppliername and filename filter
  getfilterdocuments(collection, supplierName, fileName, applicationNumber, pageIndex) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('collection_name', collection);
    fd.append('role', sessionStorage.getItem('rolename'));
    if (supplierName) {
      fd.append('supplier_name', supplierName);
    } else if (fileName) {
      fd.append('file_name', fileName);
    } else {
      fd.append('application_number', applicationNumber);
    }
    if (pageIndex) {
      fd.append('page_index', pageIndex);
    } else {
      fd.append('page_index', '1');
    }
    if (applicationNumber) {
      return this.http.post(this.getApplicationFilterURL, fd);
    } else {
      return this.http.post(this.getfilterdocumentsURL, fd);
    }

  }

  // suppliername and filename filter
  getuserinvoicesfilter(collection, userList: string[], pageIndex) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('collection_name', collection);
    fd.append('user_list', JSON.stringify(userList));
    if (pageIndex) {
      fd.append('page_index', pageIndex);
    } else {
      fd.append('page_index', '1');
    }
    return this.http.post(this.getuserinvoicesfilterUrl, fd);
  }

  // suppliername and filename filter
  getmultipleFilters(date:string[], dateType:string, userList: string[], supplierList:string[], recordsPerPage: number, pageIndex) {
    const fd = new FormData();
    fd.append('date', JSON.stringify(date));
    fd.append('user_name_list', JSON.stringify(userList));
    fd.append('date_condition', dateType);
    fd.append('supplier_name_list', JSON.stringify(supplierList));
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('records', recordsPerPage.toString());
    if (pageIndex) {
      fd.append('page_index', pageIndex);
    } else {
      fd.append('page_index', '1');
    }
    return this.http.post(this.multipleSearchFilterUrl, fd);
  }

  // get approver and/or saved records from server
  getApprovedSavedRecords(collection, pageIndex, approvedFlag, savedFlag) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('page_index', pageIndex);
    fd.append('role', sessionStorage.getItem('rolename'));
    fd.append('approve_flag', approvedFlag);
    fd.append('save_flag', savedFlag);
    return this.http.post(this.getapprovedocumentURL, fd);
  }

  deleteSingleStatus (docId) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('document_id', docId);
    return this.http.post(this.deleteSingleStatusUrl, fd);
  }

  // clear uploading status
  clearStatus(collection) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    return this.http.post(this.clearStatusUrl, fd);
  }

  // clear uploading status
  retryUpload(data, collection) {
    const fd = new FormData();
    fd.append('failed_files', JSON.stringify(data));
    fd.append('collection_name', collection);
    fd.append('company_name', sessionStorage.getItem('company_name'));
    // this.httpHeader.set('Content-Type': 'application/json');
    return this.http.post(this.retryUploadInvoiceUrl, fd).pipe(timeout(2000), retry(0));
  }

  // quickBookAuth() {
  //   const fd = new FormData();
  //   fd.append('company_name', sessionStorage.getItem('company_name'));
  //   fd.append('user_id', sessionStorage.getItem('_id'));
  //   return this.http.get(this.quickBookAuthURL + '?company_name=' + sessionStorage.getItem('company_name'));
  // }

  sendTableHeaders(data, collection, company_name?) {
    const fd = new FormData();
    if (!company_name) {
      company_name = sessionStorage.getItem('company_name');
    }
    fd.append('data', JSON.stringify(data));
    fd.append('company_name', company_name);
    fd.append('collection_name', collection);
    return this.http.post(this.sendTableConatesUrl, fd);
  }

  // metering details
  getMeteringDetails(collection) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    return this.http.post(this.getMeteringDetailsUrl, fd);
  }

  // get uploading status
  getUploadingStatus(collection, pageIndex) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('page_index', pageIndex);
    return this.http.post(this.getUploadingStatusUrl, fd);
  }

  // check if any upload is processing
  getprogressdocuments(collection) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('user_id', sessionStorage.getItem('_id'));
    return this.http.post(this.getprogressdocumentsUrl, fd);
  }

  // quickbook
  quickBookExport(data, collection) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('json_data', JSON.stringify(data));
    fd.append('collection_name', collection);
    return this.http.post(this.quickBookUrl, fd);
  }

  // send coordinates for retraining
  sendDrewConates(data, collection, company_name?) {
    const fd = new FormData();
    const co = data.coordinates;
    if (!company_name) {
      company_name = sessionStorage.getItem('company_name');
    }
    fd.append('company_name', company_name);
    fd.append('collection_name', collection);
    fd.append('ocr_text_id', data.ocr_text);
    fd.append('coordinates', '[' + co.toString() + ']');
    return this.http.post(this.sendConatesUrl, fd);
  }

  // for pagination instance... get limited invoices per request
  getInvoicesOnRequest(data, collection) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('page_index', data.pageNumber);
    fd.append('role', sessionStorage.getItem('rolename'));
    return this.http.post(this.tableDataPaginationURL, fd);
  }

  // login
  loginCheck(data) {
    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fd.append('username', data.username);
    fd.append('password', data.password);
    fd.append('company_name', data.company_name);
    return this.http.post(this.loginURL, fd, {
      responseType: 'text'
    });
  }

  // get all users invoice data from server
  getFromServer(collection) {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('collection_name', collection);
    return this.http.post(this.tableDataURL, fd);
  }

  // get current logged in users invoice data from server
  getUserInvoices(collection) {
    const formData = new FormData();
    formData.append('user_id', sessionStorage.getItem('_id'));
    formData.append('company_name', sessionStorage.getItem('company_name'));
    formData.append('collection_name', collection);
    return this.http.post(this.getUserInvoicesUrl, formData);
  }

  // get current logged in users invoice data from server
  pushAndApprove(collection, batchId) {
    const formData = new FormData();
    formData.append('user_id', sessionStorage.getItem('_id'));
    formData.append('company_name', sessionStorage.getItem('company_name'));
    formData.append('collection_name', collection);
    formData.append('batch_id', batchId);
    return this.http.post(this.pushAndApproveUrl, formData);
  }

  getReviewedInvoices() {
    return this.revieweidlist;
  }

  getNewInvoiceList() {
    return this.newInvoiceList;
  }

  // request for selected image from table
  requestImage(docId, collection, company?, docType?) {
    const formData = new FormData();
    if (!company) {
      company = sessionStorage.getItem('company_name');
    }
    formData.append('id', docId);
    formData.append('collection_name', collection);
    formData.append('company_name', company);
    return this.http.post(this.requestImageUrl, formData, {
      responseType: 'text'
    });
  }

  // request for selected image from table
  requestSymlinkImage(docId, collection, company?, docType?) {
    const formData = new FormData();
    if (!company) {
      company = sessionStorage.getItem('company_name');
    }
    formData.append('file_name', docId);
    formData.append('collection_name', collection);
    formData.append('company_name', company);
    return this.http.post(this.requestSymlinkImageUrl, formData, {
      responseType: 'text'
    });
  }

  // request for selected image from table
  getOriginalImage(image_name, collection) {
    const formData = new FormData();
    formData.append('file_name ', image_name);
    formData.append('collection_name', collection);
    formData.append('company_name', sessionStorage.getItem('company_name'));
    formData.append('username', sessionStorage.getItem('username'));
    formData.append('user_id', sessionStorage.getItem('_id'));
    return this.http.post(this.getOriginalImageURL, formData, {
      responseType: 'blob'
    });
  }

  // get rolename list and company name list
  requestRegistered(collection): any {
    const fd = new FormData();
    fd.append('collection_name', collection);
    fd.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.getRegisteredDetails, fd);
  }

  // get users list
  getUserDetails(): any {
    const fd = new FormData();
    fd.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.getUserDetailsURL, fd);
  }

  // create new user
  sendUserDetails(data): any {
    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fd.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.sendUserDetailsURl, fd, {
      responseType: 'text'
    });
  }

  // update existing user information
  updateUserDetails(data) {
    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fd.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.updateUserDetailsURL, fd, {
      responseType: 'text'
    });
  }

  // update multiple users at a time
  reviewAll(data, collection) {
    const fd = new FormData();
    fd.append('data', data);
    fd.append('collection_name', collection);
    fd.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.reviewAllURL, fd, {
      responseType: 'text'
    });
  }

  // change labels
  updateLabels(data, collection): any {
    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fd.append('collection_name', 'CompanyMetadata');
    fd.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.updateLabelsUrl, fd, {
      responseType: 'text'
    });
  }

  // get labels
  getLabels(company_name): any {
    const fd = new FormData();
    // fd.append('collection_name', collection);
    if (!company_name) {
      company_name = sessionStorage.getItem('company_name');
    }
    fd.append('company_name', company_name);
    return this.http.post(this.getLabelsURL, fd);
  }

  // delete invoice
  sendToDelete(data, collection) {
    const fd = new FormData();
    fd.append('user_id', sessionStorage.getItem('_id'));
    fd.append('data', data);
    fd.append('collection_name', collection);
    fd.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.sendToDeleteURL, fd, {
      responseType: 'text'
    });
  }

  // upload image
  // postDatatoURL(data) {
  //   return this.http.post(this.uploadImageUrl, data, {
  //     reportProgress: true,
  //     observe: 'events',
  //   });
  // }

  // upload image
  postDatatoURL(data) {
    return this.http.post(this.uploadImageUrl, data, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text'
    }).pipe(timeout(110000), retry(0));
  }

  // upload bulk
  postBulkDatatoURL(data) {
    return this.http.post(this.batchProcessURL, data, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text'
    }).pipe(timeout(100000), retry(0));
  }

  getErroredInvoices() {
    return this.erroredInvoices;
  }
  getFinalResult() {
    const dummyresult = JSON.parse(sessionStorage.getItem('oldValue'));
    return dummyresult;
  }

  sendSupplierAc(file, quickBook?) {
    const fd = new FormData();
    if (quickBook) {
      fd.append('excel_name', 'SupplierMasterQBE');
    } else {
      fd.append('excel_name', 'SupplierMaster');
    }
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('file', file);
    return this.http.post(this.importUrl, fd, {
      responseType: 'text'
    });
  }

  sendChartOfAc(file, quickBook?) {
    const fd = new FormData();
    if (quickBook) {
      fd.append('excel_name', 'ChartOfAccountQBE');
    } else {
      fd.append('excel_name', 'ChartOfAccount');
    }
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('file', file);
    return this.http.post(this.importUrl, fd, {
      responseType: 'text'
    });
  }

  appendSupplierAC (file, quickBook?) {
    const fd = new FormData();
    if (quickBook) {
      fd.append('excel_name', 'SupplierMasterQBE');
    } else {
      fd.append('excel_name', 'SupplierMaster');
    }
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('file', file);
    return this.http.post(this.appendUrl, fd, {
      responseType: 'text'
    });
  }

  AppendChartOfAc(file, quickBook?) {
    const fd = new FormData();
    if (quickBook) {
      fd.append('excel_name', 'ChartOfAccountQBE');
    } else {
      fd.append('excel_name', 'ChartOfAccount');
    }
    fd.append('company_name', sessionStorage.getItem('company_name'));
    fd.append('file', file);
    return this.http.post(this.appendUrl, fd, {
      responseType: 'text'
    });
  }

  getSupplierAcInfo(company_name?, quickBook? ) {
    const fd = new FormData();
    if (!company_name) {
      company_name = sessionStorage.getItem('company_name');
    }
    fd.append('company_name', company_name);
    if (quickBook) {
      fd.append('excel_name', 'SupplierMasterQBE');
    } else {
      fd.append('excel_name', 'SupplierMaster');
    }
    return this.http.post(this.getSupplierListUrl, fd);
  }

  getAccountInfo(company_name?, quickBook ?) {
    const fd = new FormData();
    if (!company_name) {
      company_name = sessionStorage.getItem('company_name');
    }
    fd.append('company_name', company_name);
    if (quickBook) {
      fd.append('excel_name', 'ChartOfAccountQBE');
    } else {
      fd.append('excel_name', 'ChartOfAccount');
    }
    return this.http.post(this.getAccountListUrl, fd);
  }

}
