import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  pageChange = new EventEmitter<number>();

  constructor() {}

  onPageChange(direction: number) {
    this.pageChange.emit(direction);
  }
}
