/*************************
 *
 * MAVENICK CONFIDENTIAL
 * ______
 *
 *  [2020] - Mavenick Consulting OPC Pvt Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mavenick Consulting OPC Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Mavenick Consulting OPC Pvt Ltd
 * and its suppliers and may be covered by India, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mavenick Consulting OPC Pvt Ltd.
 */


import { HttpEventType } from '@angular/common/http';
import { Component, HostListener, OnInit, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../common.service';
import { DataStructureService } from '../data-structure.service';
import { ExcelService } from '../excel.service';
import { GetpostService } from '../getpost.service';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventsService } from '../shared/events.service';
import { FormControl } from '@angular/forms';
import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-homepage2',
  templateUrl: './homepage2.component.html',
  styleUrls: ['./homepage2.component.css'],
})
export class Homepage2Component implements OnInit, OnChanges {

  color = 'primary';
  mode = 'indeterminate';
  diameter = '18';
  stroke = '3';

  tabNumber: any;
  tableViewIndex: any;
  subscriber: Subscription;
  invoiceList = [];
  totalRecordsCount: any;
  pageNumber: any;
  currentInvoiceIndex: any;
  config: any;
  selectedDocType = {text: '', param: '', labelKey: ''};
  labelJson: any;
  searchByFileNameString: any;
  searchByIsvString: any;
  searchByApplicationNo: any;
  refreshComplete: boolean;
  popup: boolean;
  reader = new FileReader();
  file: File;
  filename: string;
  messageText: string;
  imagePath: any;
  uploadComplete: any;
  fileSizeLimit: number;
  clickEvent: any;
  scrHeight: number;
  scrWidth: number;
  doNotShowAgainFlag: any;
  operation: any;
  checkedRecord: any[];
  checkedInvoices: any[];
  areAllChecked: boolean;
  checkedCount: number;
  dataToUpload: any[];
  showAllCount: number;
  errorOnlyCount: number;
  approvedCount: number;
  autoPassCount: number;
  toBeReveived: number;
  operationComplete: boolean;
  selectedInvoiceIds: any[];
  doc_id: any;
  destroyed: any;
  docIsOpened: boolean;
  myRecordsCount: any;
  okForDetaileidview: boolean;
  rawData: any;
  stopAutoLoad: boolean;
  statusPageExpanded: boolean;
  expanded: boolean;
  retryList: any;
  selectedHistoriRecords: {};
  heightOfFilesContainer: any;
  uploadStatus: any[];
  statusPageNumber: any;
  totalHistoryCount: any[] = [];
  recentUploadedFiles: number;
  totalFilesInRecentBatch: number;
  progress: any;
  completedFiles: any[];
  progresBarAnimationFlag: boolean;
  recentFileStatus: any;
  userrole: string;
  username: string;
  rotation: number;
  dataChanged: boolean;
  tableMaxed: string;
  companyName: string;
  ocrIsOnFlag: any;
  originalImage: Blob;
  imageIndex: any;
  searchByUserName: any;
  fileNames: any[];

  data = [10, 20, 30, 40];
  multiParamSearch: any;
  selectedRawData: any[];

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
      this.clickEvent = event;
      try {
        const offsetHeight = event.path[0].pageYOffset;
        document.getElementById('popUpBackground').style.top = offsetHeight + 'px';
      } catch(e){}
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
  }

  @HostListener('document:mouseover', ['$event'])
    mouseovering(event?) {
      try {
        this.clickEvent = event;
        const offsetHeight = event.path[2].pageYOffset;
        document.getElementById('popUpBackground').style.top = offsetHeight + 'px';
      } catch(e){}
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
  }

  @HostListener('document:scroll', ['$event'])
    scrolleding(event?) {
      try {
        this.clickEvent = event;
        const offsetHeight = event.path[1].pageYOffset;
        document.getElementById('downloadPopup').style.top = 0 + 'px';
        document.getElementById('popUpBackground').style.top = offsetHeight + 'px';
       } catch(e){}
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
  }

  @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
      const target = {localName: []};
      target['target'] = event.target;
      if (event.shiftKey && !event.ctrlKey) {
        switch (event.key.toString()) {
          case 'ArrowLeft':
            this.changePage(-1)
            event.preventDefault();
            break;
          case 'ArrowRight':
            this.changePage(1)
            event.preventDefault();
        }
      } else if (event.ctrlKey && !event.shiftKey) {
        switch (event.key.toString()) {
          case 's': case 'S':
            // your function here
            event.preventDefault();
            break;
          case 'r': case 'R':
            this.refresh();
            event.preventDefault();
        }
      } else if (event.ctrlKey && event.shiftKey) {
      } else {
        if (target['target'].localName === 'input' || target['target'].isContentEditable) {
        } else {
          switch (event.key) {
            case 'ArrowLeft':
              // if (this.popup) {

              // } else {
              //   this.checkChangesForPrev(event);
              // }
              event.preventDefault();
            break;
            case 'ArrowRight':
              // if (this.popup) {

              // } else {
              //   this.checkChangesForNext(event);
              // }
              event.preventDefault();
            break;
            case 'Escape':
              // if (this.expanded) {
              //   this.hideInvoiceInfo({});
              // }
              if(
                  (
                    !document.getElementById('popUpBackground').style.display
                    || document.getElementById('popUpBackground').style.display === 'none'
                    )
                  && this.tableMaxed != 'true' && this.ocrIsOnFlag != 'true'
                ) {
                  this.exitDetaileidview();
              }
            break;
          }
        }
      }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private getpost: GetpostService,
    private ds: DataStructureService,
    private excelService: ExcelService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private route: Router,
    private eventService: EventsService,
    private dataService: DataService
  ) {
    this.initializeValues();
  }

  test(data) {
    console.log(data)
  }

  ngOnInit() {
    
    this.stopSpinner('show');
    if (!sessionStorage.getItem('loggedin') || sessionStorage.getItem('loggedin') != 'true') {
      this.route.navigate(['/login']);
    } else {
      this.companyName = sessionStorage.getItem('company_name');

      this.progress = [];
      this.heightOfFilesContainer = [];

      this.userrole = sessionStorage.getItem('rolename');
      this.username = sessionStorage.getItem('username');
      sessionStorage.setItem('searchByFileName', 'false');
      sessionStorage.setItem('searchByMultiParam', 'false');
      sessionStorage.setItem('searchByUserName', 'false');
      sessionStorage.setItem('searchByISVFlag', 'false');
      sessionStorage.setItem('searchByApplicationNo', 'false');

      let tempJson;
      this.getpost.getLabels(sessionStorage.getItem('company_name')).subscribe(res => {
        if (res === null) {} else {
          tempJson = res[0];
        }
      },
      (err) => {console.log('error getting mapping dict');},
      () => {
        this.labelJson = this.commonService.setMappingData(tempJson, 2);
        this.dataService._labelJson = this.labelJson;
        try {
          if (this.labelJson.invoices.show) {
            this.selectedDocType['text'] = 'Invoices';
            this.selectedDocType['param'] = 'Invoices';
            this.selectedDocType['labelKey'] = 'invoices';
          } else if (this.labelJson.receipts.show) {
            this.selectedDocType['text'] = 'Receipt';
            this.selectedDocType['param'] = 'Receipts';
            this.selectedDocType['labelKey'] = 'invoices';
          } else if (this.labelJson.purchaseOrder.show) {
            this.selectedDocType['text'] = 'Purchase Orders';
            this.selectedDocType['param'] = 'PurchaseOrders';
            this.selectedDocType['labelKey'] = 'purchaseOrder';
          } else if (this.labelJson.rfq.show) {
            this.selectedDocType['text'] = 'RFQ';
            this.selectedDocType['param'] = 'RFQ';
            this.selectedDocType['labelKey'] = 'rfq';
          } else if (this.labelJson.nach.show) {
            this.selectedDocType['text'] = 'Nach';
            this.selectedDocType['param'] = 'NACH';
            this.selectedDocType['labelKey'] = 'nach';
          } else if (this.labelJson.insurances.show) {
            this.selectedDocType['text'] = 'Insurance';
            this.selectedDocType['param'] = 'Insurances';
            this.selectedDocType['labelKey'] = 'insurances';
          }
        } catch (e) {
          this.selectedDocType['text'] = 'Invoices';
          this.selectedDocType['param'] = 'Invoices';
          this.selectedDocType['labelKey'] = 'invoices';
        } finally{
          this.dataService._selectedDocType = this.selectedDocType;
        }
        this.complete();
        this.selectTabs(1);
        this.stopSpinner('hide');
      });
    }

  }

  ngOnChanges() {
    this.refreshing();
  }

  setMappingJson(tempJson) {
    try {
      this.labelJson = {};
      this.labelJson = tempJson;
      this.dataService._labelJson = this.labelJson;
    } catch (e) {}
  }

  refreshing() {
    this.rotation = 0;
    while(!this.refreshComplete) {
      this.rotation += 45;
      if (this.rotation == 360) {
        this.rotation = 0;
      }
    }
  }

  docTypeSelected(text, param, key, tabNumber) {
    this.tabNumber = 1;
    this.pageNumber = 1;
    sessionStorage.setItem('page-number', '1');
    sessionStorage.setItem('tab-number', this.tabNumber.toString());
    this.selectedDocType['text'] = text;
    this.selectedDocType['param'] = param;
    this.selectedDocType['labelKey'] = key;

    this.dataService._selectedDocType = this.selectedDocType

    let selectedDocTypeString = JSON.stringify(this.selectedDocType);
    sessionStorage.setItem('selectedDocType', selectedDocTypeString);

    this.initializeValues();
    this.complete();
  }

  initializeValues() {

    this.retryList = {};

    this.currentInvoiceIndex = sessionStorage.getItem('invoiceNumber');
    if (!this.currentInvoiceIndex) {
      this.currentInvoiceIndex = 1;
    }

    this.statusPageNumber = 1;
    this.completedFiles = [];
    this.progresBarAnimationFlag = true;

    if (location.hostname.toLowerCase().includes('idfc')) {
      this.fileSizeLimit = 10;
    } else {
      this.fileSizeLimit = 15;
    }
    this.pageNumber = sessionStorage.getItem('page-number');
    if(!this.pageNumber){
      this.pageNumber = 1;
      sessionStorage.setItem('page-number', '1');
    }
    this.tableViewIndex = sessionStorage.getItem('table-view-number')
    if(!this.tableViewIndex){
      this.tableViewIndex = 1;
      sessionStorage.setItem('table-view-number', '1');
    }
    // this.resetCheckList();
  }

  refreshBeforeopen(event) {
    console.log('calling complete()')
    this.complete(event);
  }

  openDocument(event) {
    try {
      this.invoiceList = this.dataService._invoiceList;
      this.totalRecordsCount = new Array(this.dataService._totalListCount);
      this.selectedDocType = this.dataService._selectedDocType;
      this.subscriber.unsubscribe();
      if (event == this.dataService._recordsPerPage || event == -1) {
        if (event == this.dataService._recordsPerPage) {
          this.pageNumber++;
          event = 0;
        } else {
          this.pageNumber--;
          event = this.dataService._recordsPerPage - 1;
        }
        this.currentInvoiceIndex = event;

        sessionStorage.setItem('page-number', this.currentInvoiceIndex.toString());
        sessionStorage.setItem('invoiceNumber', this.pageNumber.toString());

        this.pageChanged(this.pageNumber).then(res => {
          this.docIsOpened = true;
          if (this.invoiceList[this.currentInvoiceIndex].is_opened == 0) {
            this.ds.openedInvoice(this.invoiceList[this.currentInvoiceIndex],
              this.invoiceList[this.currentInvoiceIndex].is_modified,
              this.invoiceList[this.currentInvoiceIndex].is_approved,
              this.selectedDocType.param)
            .subscribe(
              res => {
              },
              (err) => {
              },
              () => {}
            );
          }
          this.filename = this.invoiceList[this.currentInvoiceIndex].file_name;
          this.doc_id = this.invoiceList[this.currentInvoiceIndex].obj_id;
          sessionStorage.setItem('invoiceNumber', this.currentInvoiceIndex);
        });
      } else {
        this.docIsOpened = true;
        this.currentInvoiceIndex = event;
        if (this.invoiceList[this.currentInvoiceIndex].is_opened == 0) {
          this.ds.openedInvoice(this.invoiceList[this.currentInvoiceIndex],
            this.invoiceList[this.currentInvoiceIndex].is_modified,
            this.invoiceList[this.currentInvoiceIndex].is_approved,
            this.selectedDocType.param)
          .subscribe(
            res => {},
            (err) => {},
            () => {}
          );
        }
        this.filename = this.invoiceList[this.currentInvoiceIndex].file_name;
        this.doc_id = this.invoiceList[this.currentInvoiceIndex].obj_id;
        sessionStorage.setItem('invoiceNumber', this.currentInvoiceIndex);
        if (this.tabNumber == 5) {
        } else {
          sessionStorage.setItem('previous-tab', this.tabNumber);
          this.selectTabs(5);
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  // operations to perform when data is fetched from server successfully
  complete(invoiceNumber?) {
    let tempJson;
    this.getpost.getLabels(sessionStorage.getItem('company_name')).subscribe(res => {
      if (res === null) {} else {
        tempJson = res[0];
      }
    },
    (err) => {console.log('error getting mapping dict');},
    () => {
      // const blob = new Blob([JSON.stringify(tempJson)], {type : 'application/json'});
      // saveAs(blob, 'mappingDict' + '.json');
      this.uploadComplete = true;
      if (invoiceNumber >= 0) {
        this.openDocument(invoiceNumber);
      } else {
        if (tempJson) {
          this.labelJson = this.commonService.setMappingData(tempJson, 1);
          this.dataService._labelJson = this.labelJson;
        }
        if (sessionStorage.getItem('searchByFileName') && sessionStorage.getItem('searchByFileName') != 'false') {
          sessionStorage.setItem('searchByApplicationNo', 'false');
          sessionStorage.setItem('searchByISVFlag', 'false');
          sessionStorage.setItem('searchByUserName', 'false');
          sessionStorage.setItem('searchByMultiParam', 'false');
          this.filterFiles(sessionStorage.getItem('searchByFileName'), this.pageNumber);

        } else if (sessionStorage.getItem('searchByISVFlag') && sessionStorage.getItem('searchByISVFlag') != 'false') {
          sessionStorage.setItem('searchByApplicationNo', 'false');
          sessionStorage.setItem('searchByFileName', 'false');
          sessionStorage.setItem('searchByUserName', 'false');
          sessionStorage.setItem('searchByMultiParam', 'false');
          this.filterSupplier(sessionStorage.getItem('searchByISVFlag'), this.pageNumber);

        } else if (sessionStorage.getItem('searchByMultiParam') && sessionStorage.getItem('searchByMultiParam') != 'false') {
          sessionStorage.setItem('searchByApplicationNo', 'false');
          sessionStorage.setItem('searchByFileName', 'false');
          sessionStorage.setItem('searchByUserName', 'false');
          sessionStorage.setItem('searchByMultiParam', 'true');
          this.filterMultiParam(this.multiParamSearch, this.pageNumber);

        } else if (sessionStorage.getItem('searchByApplicationNo') && sessionStorage.getItem('searchByApplicationNo') != 'false') {
          sessionStorage.setItem('searchByISVFlag', 'false');
          sessionStorage.setItem('searchByFileName', 'false');
          sessionStorage.setItem('searchByUserName', 'false');
          sessionStorage.setItem('searchByMultiParam', 'false');
          this.filterApplication(sessionStorage.getItem('searchByApplicationNo'), this.pageNumber);

        } else if (sessionStorage.getItem('searchByUserName') && sessionStorage.getItem('searchByUserName') != 'false') {
          sessionStorage.setItem('searchByApplicationNo', 'false');
          sessionStorage.setItem('searchByISVFlag', 'false');
          sessionStorage.setItem('searchByFileName', 'false');
          sessionStorage.setItem('searchByMultiParam', 'false');
          this.filterUser(sessionStorage.getItem('searchByUserName'), this.pageNumber);

        } else {
          sessionStorage.setItem('searchByFileName', 'false');
          sessionStorage.setItem('searchByISVFlag', 'false');
          sessionStorage.setItem('searchByApplicationNo', 'false');
          sessionStorage.setItem('searchByUserName', 'false');
          sessionStorage.setItem('searchByMultiParam', 'false');

          if (this.tableViewIndex == 2) {
            this.getErroredRecords(this.pageNumber);
          } else if (this.tableViewIndex == 1) {
            this.subscriberFun();
          } else if (this.tableViewIndex == 3) {
            this.getSavedOrApprovedData(true, this.pageNumber);
          } else if (this.tableViewIndex == 5) {
            this.getSavedOrApprovedData(false, this.pageNumber);
          } else if (this.tableViewIndex == 4) {
            this.getAutorejectPass(this.pageNumber);
          } else if (this.tableViewIndex == 6) {
            this.getqbestatusdocuments(this.pageNumber);
          }
        }
      }
    });
  }

  periodicSubscribe() {
    // function delays(ms: number) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // }
    // if (this.tabNumber == 5 || this.stopAutoLoad) {
    //   return;
    // }
    this.complete();
    // delays(30000).then(res => {
    //   this.periodicSubscribe();
    // });
  }

  stopAutoReload(flag) {
    if (flag == 'true') {
      this.stopAutoLoad = true;
    } else {
      this.stopAutoLoad = false;
      this.periodicSubscribe();
    }
  }

  refresh() {
    // this.refreshing();
    sessionStorage.setItem('searchByFileName', 'false');
    sessionStorage.setItem('searchByUserName', 'false');
    sessionStorage.setItem('searchByISVFlag', 'false');
    sessionStorage.setItem('searchByMultiParam', 'false');
    sessionStorage.setItem('searchByApplicationNo', 'false');
    this.complete();
    this.checkForUploadStatus();
    this.updateRecentUploadedCount();
    // this.periodicSubscribe();
    // this.stopAutoReload('false');
  }

  hideInvoiceInfo(event) {
    try {
      if (document.getElementById('full-page').style.width === '100%') {
        document.getElementById('full-page').style.width = '2%';
        this.statusPageExpanded = false;
        this.expanded = false;
        this.retryList['batch_id'] = '';
        this.retryList['data'] = [];
        this.selectedHistoriRecords = {};
      } else {
        this.statusPageExpanded = true;
        document.getElementById('full-page').style.width = '100%';
        this.expanded = true;
        this.checkForUploadStatus();
      }
    } catch (e) {}
  }

  expandFileInfo(batchNumebr: number) {
    try {
      if (batchNumebr !== -1) {
        const diamensions = document.getElementById('files-container' + batchNumebr).getBoundingClientRect();
        const checkHeight = document.getElementById('files-container-wrapper' + batchNumebr).getBoundingClientRect();
        if (checkHeight.height < 2) {
          this.heightOfFilesContainer[batchNumebr] = diamensions.height + 'px';
        //  document.getElementById('files-container-wrapper' + batchNumebr).style.height = diamensions.height + 'px';
        } else {
          this.heightOfFilesContainer[batchNumebr] = '0px';
        //  document.getElementById('files-container-wrapper' + batchNumebr).style.height = '0px';
        }
      } else {
        this.heightOfFilesContainer[0] = '0px';
        for (let i = 0; i < this.heightOfFilesContainer.length - 1; i++) {
          const hei = document.getElementById('files-container-wrapper' + i).getBoundingClientRect();
          this.heightOfFilesContainer[i + 1] = hei.height + 'px';
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  checkImiidiatelyForUploadStatus() {
    let count;
    this.getpost.getUploadingStatus(this.selectedDocType.param, this.statusPageNumber).subscribe(res => {
      this.recentFileStatus = res['all_result'][0];
      count = res['count'];
    },
    (err) => {
      this.checkImiidiatelyForUploadStatus();
    },
    () => {
      for (let i = 0; i < count; i++) {
        this.totalHistoryCount.push(1);
      }
      this.recentUploadedFiles = 0;
      this.totalFilesInRecentBatch = 0;
      try {
        if (this.recentFileStatus) {
          this.totalFilesInRecentBatch = this.recentFileStatus.files_info.length;

          for (let i = 0; i < this.recentFileStatus.files_info.length; i++) {
            try {
              if (this.recentFileStatus.files_info[i]['status'] === 'Completed') {
                this.recentUploadedFiles++;
                if (this.recentUploadedFiles === this.totalFilesInRecentBatch) {
                  break;
                }
              }
            } catch (e) {
            }
          }
        }
      } catch (e) {
        console.log('error occured', e);
      }
    });
  }

  // check for uploading status every 10 seconds
  checkForUploadStatus() {
    // function delays(ms: number) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // }
    // if (this.tabNumber == 5 || this.stopAutoLoad) {
    //   return;
    // }
    // if (!this.expanded || this.destroyed) {
    //   return;
    // }
    let count = 0;
    let tempData;
    this.getpost.getUploadingStatus(this.selectedDocType.param, this.statusPageNumber).subscribe(res => {
      tempData = res['all_result'];
      count = res['count'];
      // this.uploadStatus = res['all_result'];
    },
    (err) => {
      this.totalHistoryCount = [];
      for (let i = 0; i < count; i++) {
        this.totalHistoryCount.push(1);
      }
      this.checkForUploadStatusLogic(tempData);
      // delays(10000).then(res => {
      //   this.checkForUploadStatus();
      // });
    },
    () => {
      this.totalHistoryCount = [];
      for (let i = 0; i < count; i++) {
        this.totalHistoryCount.push(1);
      }
      this.checkForUploadStatusLogic(tempData);
      // delays(10000).then(res => {
      //   this.checkForUploadStatus();
      // });
    });
  }

  // change info in ui when changed
  checkForUploadStatusLogic(tempData) {
    try {
      if (!tempData || tempData.length === 0) {
        this.uploadStatus = [];
        this.recentUploadedFiles = 0;
        this.totalFilesInRecentBatch = 0;
        return;
      } else {
        this.uploadStatus = tempData;
      }
    } catch (e) {
      console.log(e);
    }
    if (tempData) {
      for (let i = 0; i < tempData.length; i++) {
        try {
          let completedFiles = 0;
          const totalFiles = tempData[i].files_info.length;
          // if new invoice is uploaded
          if (tempData[i].batch_id === this.uploadStatus[i].batch_id) {
            if (!this.uploadStatus[i]) {
              this.uploadStatus[i] = tempData[i];
            }
            this.uploadStatus[i]._id = tempData[i]._id;
            this.uploadStatus[i].batch_id = tempData[i].batch_id;
            this.uploadStatus[i].user_id = tempData[i].user_id;
            this.uploadStatus[i].batch_completion_time = tempData[i].batch_completion_time;
            this.uploadStatus[i].batch_status = tempData[i].batch_status;
            this.uploadStatus[i].batch_name = tempData[i].batch_name;
            this.uploadStatus[i].batch_upload_time = tempData[i].batch_upload_time;
            for (let j = 0; j < tempData[i].files_info.length; j++) {
              try {
                // this.uploadStatus[i].files_info[j] = tempData[i].files_info[j];
                this.uploadStatus[i].files_info[j] = tempData[i].files_info[j];
                if (tempData[i].files_info[j]['status'] === 'Completed') {
                  completedFiles++;
                }
              } catch (e) {
                console.log(e);
                continue;
              }
            }
          } else if (tempData.length > this.uploadStatus.length) {
            this.uploadStatus.splice(0, 0, tempData[i]);
            this.expandFileInfo(-1);
          }
          this.progress[i] = completedFiles * 100 / totalFiles;
          this.completedFiles[i] = completedFiles;
          this.progresBarAnimationFlag = true;
        } catch (e) {
          console.log(e);
          continue;
        }
      }
    }
  }

  // update count of recently uploaded record periodically
  updateRecentUploadedCount() {
    // function delays(ms: number) {
    //   return new Promise(resolve => setTimeout(resolve, ms));
    // }
    // if (this.tabNumber == 5 || this.stopAutoLoad) {
    //   return;
    // }

    // if (this.destroyed) {
    //   return;
    // }
    let count;
    this.getpost.getUploadingStatus(this.selectedDocType.param, this.statusPageNumber)
    .subscribe(res => {
      this.recentFileStatus = res['all_result'][0];
      count = res['count'];
    },
    (err) => {
      // delays(5000).then(res => {
      //   this.updateRecentUploadedCount();
      // });
    },
    () => {
      for (let i = 0; i < count; i++) {
        this.totalHistoryCount.push(1);
      }
      this.recentUploadedFiles = 0;
      this.totalFilesInRecentBatch = 0;
      try {
        if (this.recentFileStatus) {
          this.totalFilesInRecentBatch = this.recentFileStatus.files_info.length;

          for (let i = 0; i < this.recentFileStatus.files_info.length; i++) {
            try {
              if (this.recentFileStatus.files_info[i]['status'] === 'Completed') {
                this.recentUploadedFiles++;
                if (this.recentUploadedFiles === this.totalFilesInRecentBatch) {
                  break;
                }
              }
            } catch (e) {
            }
          }
        }
      } catch (e) {
        console.log('error occured', e);
      }
      // delays(5000).then(res => {
      //   this.updateRecentUploadedCount();
      // });
    });
  }

  statusPageNumberChanged(event) {
    function delays(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    if (this.tabNumber == 5 || this.stopAutoLoad) {
      return;
    }
    let tempData, count = 0;
    this.getpost.getUploadingStatus(this.selectedDocType.param, event).subscribe(res => {
      tempData = res['all_result'];
      count = res['count'];
      // this.uploadStatus = res['all_result'];
    },
    (err) => {
      this.totalHistoryCount = [];
      for (let i = 0; i < count; i++) {
        this.totalHistoryCount.push(1);
      }
      this.checkForUploadStatusLogic(tempData);
    },
    () => {
      this.totalHistoryCount = [];
      for (let i = 0; i < count; i++) {
        this.totalHistoryCount.push(1);
      }
      this.checkForUploadStatusLogic(tempData);
      this.progresBarAnimationFlag = false;
      this.statusPageNumber = event;
      delays(500).then(res => {
        this.progresBarAnimationFlag = true;
      });
    });
  }

  // clear uploaded histori
  clearStatus() {
    this.getpost.clearStatus(this.selectedDocType.param)
    .subscribe(res => {},
    (err) => {},
    () => {this.uploadStatus = []; });
  }

  // select failed records for retry
  selectForRetry(event, file_name, invoiceId) {
    const singleBatch = {};
    singleBatch['processing_pref'] = sessionStorage.getItem('processing_pref');
    singleBatch['company_name'] = sessionStorage.getItem('company_name');
    singleBatch['user_id'] = event.user_id;
    singleBatch['batch_id'] = event.batch_id;
    singleBatch['batch_name'] = event.batch_name;
    singleBatch['file_name'] = file_name;
    singleBatch['invoice_id'] = invoiceId;
    let recordFoundFlag = false;
    if (!this.retryList || !this.retryList['data'] ||
    this.retryList['data'].length === 0 || this.retryList['batch_id'] !== event.batch_id) {
      this.retryList['batch_id'] = event.batch_id;
      this.retryList['data'] = [];
      this.retryList['data'].push(singleBatch);
      this.selectedHistoriRecords = {};
      this.selectedHistoriRecords[this.retryList['batch_id'] + '-' + file_name] = true;
    } else {
      for (let file = 0; file < this.retryList['data'].length; file++) {
        if (this.retryList['data'][file]['batch_id'] === event.batch_id
          && this.retryList['data'][file]['file_name'] === file_name) {
          recordFoundFlag = true;
          this.retryList['data'].splice(file--, 1);
          if (this.retryList['data'].length === 0) {
            delete this.retryList['batch_id'];
          }
          delete this.selectedHistoriRecords[event.batch_id + '-' + file_name];
          break;
        }
      }
      if (!recordFoundFlag) {
        this.retryList['data'].push(singleBatch);
        this.selectedHistoriRecords[event.batch_id + '-' + file_name] = true;
      }
    }
  }

  // send selected failed invoices to retry
  retryFailed() {
    if (!this.uploadComplete) {
      alert('File is being processed. Please wait.');
      return;
    }

    this.getpost.retryUpload(this.retryList, this.selectedDocType.param).subscribe(res => {}, (err) => {
    }, () => {
    });
    this.checkImiidiatelyForUploadStatus();
    function delays(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    delays(500).then(res => {
      this.uploadComplete = false;
      this.retryList['batch_id'] = '';
      this.retryList['data'] = [];
      this.selectedHistoriRecords = {};
    });
  }

  scrollFileName(event, direction) {
    try {
      const element = document.querySelector('.filename-content')
      const currentScroll = element.scrollLeft;
      element.scroll(currentScroll + direction, 0);
    } catch (e) {
      console.log(e);
    }
  }

  operationChanged(event){
    if (event == 'false') {
      this.operationComplete = false;
    } else {
      this.operationComplete = true;
    }
  }

  operationSuccessfull() {
    this.okForDetaileidview = false;
  }

  // fetch data from server
  subscriberFun(refresh?) {
    try {
      // getFromServer / getPageResult
      if (this.subscriber) {
        this.subscriber.unsubscribe();
      }
      if (this.selectedDocType.text == 'Autorejected') {
        this.subscriber = this.getpost.getAutorejectedRecords(this.pageNumber).subscribe(
          (res) => {
            try {
              if (this.tabNumber != 5 || refresh) {
                this.rawData = res['all_result'];
              }
              this.UpdateRecordsCount(res['count'], '955');
            } catch (e) {
              console.log(e);
            }
          },
          (err) => {
            console.log(err);
          },
          () => {
            try {
              this.invoiceList = this.rawData;
              this.dataService._invoiceList = this.invoiceList;
              this.commonService.onIsFilterOn(false);
            } catch (e) {
              console.log(e);
            }
          }
        );
      } else {
        this.subscriber = this.getpost.getPageResult(this.selectedDocType.param, this.pageNumber).subscribe(
          (res) => {
            try {
              if (this.tabNumber != 5 || refresh) {
                this.rawData = res['all_result'];
                this.UpdateRecordsCount(res['count'], '978');
              }
            } catch (e) {}
          },
          (err) => {
            console.log(err);
            // const blob = new Blob([err.error.text], {type : 'text'});
            // saveAs(blob, this.filename + '.txt');
          },
          () => {
            try {
              if (this.tabNumber != 5 || refresh) {
                this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
                this.dataService._invoiceList = this.invoiceList;
                this.commonService.onIsFilterOn(false);
              }
            } catch (e) {
              console.log(e);
            }
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  UpdateRecordsCount(recordsCount, callingFunction?) {
    this.totalRecordsCount = [];
    this.myRecordsCount = recordsCount;
    this.totalRecordsCount = new Array(recordsCount);
    this.dataService._totalListCount = recordsCount;
    CommonService.totalInvoices = recordsCount;
    CommonService.totalInvoicePages = this.returnLastPage();
  }

  returnLastPage() {
    return Math.ceil(this.totalRecordsCount.length/this.dataService._recordsPerPage);
  }

  singleOrbatch(event) {
    this.file = CommonService.file;
    if (event == 'file') {
      this.submitSingle();
    } else {
      this.submitBatch();
    }
  }

  submitSingle(invoiceType?: string) {
    if (!invoiceType) {
      invoiceType = this.labelJson.notification.invoiceType.value;
    }
    let count;
    this.getpost.getprogressdocuments(this.selectedDocType.param).subscribe(res => {
      count = res['count'];
    }, (e) => {},
    () => {
        this.uploadComplete = true;
        if (this.file) {
          const startTime = Date.now();
          const fd = new FormData();
          fd.append('file', this.file);
          fd.append('company_name', sessionStorage.getItem('company_name'));
          fd.append('user_id', sessionStorage.getItem('_id'));
          fd.append('collection_name', this.selectedDocType.param);
          fd.append('invoice_type', invoiceType.toUpperCase());
          fd.append('processing_pref', sessionStorage.getItem('processing_pref'));
          this.getpost.postDatatoURL(fd).subscribe(
            response => {
              if (response.type === HttpEventType.UploadProgress) {
                if (response['loaded'] === response['total']) {

                  if (this.tabNumber == 5 || this.stopAutoLoad) {
                    return;
                  }


                }
              } else if (response.type === HttpEventType.Response) {
              }
            },
            error => {
            },
            () => {
              this.uploadComplete = true;
            }
          );
        } else {
          alert('please select a file.');
        }
    });
  }

  submitBatch(invoiceType?: string) {
    if (!invoiceType) {
      invoiceType = this.labelJson.notification.invoiceType.value;
    }
    let count;
    this.getpost.getprogressdocuments(this.selectedDocType.param).subscribe(res => {
      count = res['count'];
    }, (e) => {},
    () => {
      if (count === 0) {
        const fd = new FormData();
        fd.append('file', this.file);
        fd.append('company_name', sessionStorage.getItem('company_name'));
        fd.append('user_id', sessionStorage.getItem('_id'));
        fd.append('invoice_type', invoiceType.toUpperCase());
        fd.append('processing_pref', sessionStorage.getItem('processing_pref'));
        this.getpost.postBulkDatatoURL(fd).subscribe(
          response => {
            if (response.type === HttpEventType.UploadProgress) {
              if (response['loaded'] === response['total']) {
                this.uploadComplete = false;
              }
            } else if (response.type === HttpEventType.Response) {
              console.log(response.body);
            }
            const element = <HTMLInputElement>(
              document.getElementById('batchUploadInput')
            );
            element.value = '';
          },
          (err) => {
            const element = <HTMLInputElement>(
              document.getElementById('batchUploadInput')
            );
            element.value = '';
          },
          () => {
            this.uploadComplete = true;
          }
        );
      } else {
        this.uploadComplete = false;
        alert('File is being processed. Please wait.');
        return;
      }
    });
  }

  singleupload(files: FileList) {
    //  this.downloadFileExample(files);
    this.file = <File>files.item(0);
    this.filename = this.file.name;

    // check file size limit
    if (this.file.size / (1024 * 1024) > this.fileSizeLimit) {
      alert('File size limit ('+ this.fileSizeLimit +' MB) exceeds');
      const element = <HTMLInputElement>document.getElementById('singleUpload');
      element.value = '';
      return;
    }

    const supportedFileTypes = [
      'pdf',
      'tiff',
      'bmp',
      'jpeg',
      'png',
      'jpg',
      'PDF',
      'TIFF',
      'PNG',
      'BMP',
      'JPEG',
      'JPG'
    ];
    try {
      if (
        supportedFileTypes.indexOf(
          this.filename.split('.')[this.filename.split('.').length - 1]
        ) === -1
      ) {
        alert('Please upload pdf, jpg, tiff or bmp only');
        const element = <HTMLInputElement>document.getElementById('singleUpload');
        element.value = '';
        return;
      }
    } catch (e) {
      console.log(e);
    }
    if (!this.labelJson.notification.invoiceType.visibility) {
      this.messageText = 'Select type of Invoice';
      this.b2bOrB2c();
    } else {
      this.reader.onload = (event: any) => {
        this.imagePath = event.target.result;
      };
      this.reader.readAsDataURL(this.file);
      // alert('uploading single file directly as ' + this.labelJson.notification.invoiceType.value);
      this.submitSingle();
      const element = <HTMLInputElement>document.getElementById('singleUpload');
      element.value = '';
    }
  }

  batchupload(files: FileList) {
    this.file = <File>files.item(0);
    this.filename = this.file.name;

    // check file size limit
    if (this.file.size / (1024 * 1024) > this.fileSizeLimit) {
      alert('File size limit ('+ this.fileSizeLimit +' MB) exceeds');
      return;
    }

    try {
      if (
        this.filename.split('.')[this.filename.split('.').length - 1] !==
          'zip' &&
        this.filename.split('.')[this.filename.split('.').length - 1] !== 'ZIP'
      ) {
        alert('Please upload zip file only.');
        return;
      }
    } catch (e) {
      console.log(e);
    }
    if (this.labelJson.notification.invoiceType.visibility) {
      this.messageText = 'Select type of Invoices in batch';
      this.b2bOrB2c();
    } else {
      this.reader.onload = (event: any) => {
        this.imagePath = event.target.result;
      };
      this.reader.readAsDataURL(this.file);
      // alert('uploading single file directly as ' + this.labelJson.notification.invoiceType.value);
      this.submitBatch();
    }
  }

  uploadFileSelected(files: FileList) {
    this.file = <File>files.item(0);
    this.filename = this.file.name;

    const supportedFileTypes = [
      'pdf',
      'tiff',
      'bmp',
      'jpeg',
      'png',
      'jpg',
      'PDF',
      'TIFF',
      'PNG',
      'BMP',
      'JPEG',
      'JPG',
      'zip',
      'ZIP'
    ];

    if (
      supportedFileTypes.indexOf(
        this.filename.split('.')[this.filename.split('.').length - 1]
      ) === -1
    ) {
      alert('File format not supported');
      const element = <HTMLInputElement>document.getElementById('singleUpload');
      element.value = '';
      return;
    } else {
      if (this.filename.split('.')[this.filename.split('.').length - 1].toLowerCase() == 'zip') {
        this.batchupload(files);
      } else {
        this.singleupload(files);
      }
    }
  }

  stopSpinner(event) {
    if (event == 'show') {
      this.spinner.show();
    } else if (event == 'hide') {
      this.spinner.hide();
    }
  }

  saveClickEvent(event) {
    // this.clickEvent = event;
    // console.log(this.clickEvent);
  }

  b2bOrB2c() {
    let offsetHeight;
    try {
      offsetHeight = this.clickEvent.pageY - this.clickEvent.clientY;
      // const screenHeight = (document.body.clientHeight) * 40 / 100;
    } catch (e) {
      offsetHeight = 0;
    }
    this.scrHeight = document.getElementById('row').offsetHeight; //
    document.getElementById('popUpBackground').style.height = + this.scrHeight.toString() + 'px';
    document.getElementById('downloadPopup').style.display = 'flex';
    document.getElementById('downloadPopup').style.flexDirection = 'column';
    document.getElementById('downloadPopup').style.alignItems = 'center';
    document.getElementById('downloadPopup').style.justifyContent = 'center';
    // document.getElementById('downloadPopup').style.top = offsetHeight + 'px';
    document.getElementById('popUpBackground').style.display = 'block';
    document.getElementById('popUpBackground').style.top = offsetHeight + 'px';
    try {
      document.getElementById('b2b-btn').focus();
    } catch (e) {
      // console.log(document.getElementById('b2b-btn'));
    }
  }

  b2b() {
    this.hidePopup();
    if (this.doNotShowAgainFlag) {
      this.labelJson.notification.invoiceType.visibility = false;
      this.labelJson.notification.invoiceType.value = 'B2B';
      this.getpost.updateLabels(this.labelJson, 'Invoices').subscribe(
        res => {
          if (res === 'Successfully') {
          }
        },
        err => {
          console.log(err);
        },
        () => {
        }
      );
    }
    if (this.messageText === 'Select type of Invoice') {
      this.reader.onload = (event: any) => {
        this.imagePath = event.target.result;
      };
      this.reader.readAsDataURL(this.file);
      // alert('uploading single file as b2b');
      this.submitSingle('B2B');
      const element = <HTMLInputElement>document.getElementById('singleUpload');
      element.value = '';
    } else {
      this.reader.onload = (event: any) => {
        this.imagePath = event.target.result;
      };
      this.reader.readAsDataURL(this.file);
      // alert('uploading single batch as b2b');
      this.submitBatch('B2B');
      const element = <HTMLInputElement>document.getElementById('batchUploadInput');
      element.value = '';
    }
  }

  b2c() {
    this.hidePopup();
    if (this.doNotShowAgainFlag) {
      // sessionStorage.setItem('doNotShowInvoiceType', 'b2c');
      this.labelJson.notification.invoiceType.visibility = false;
      this.labelJson.notification.invoiceType.value = 'B2C';
      this.getpost.updateLabels(this.labelJson, 'Invoices').subscribe(
        res => {
          if (res === 'Successfully') {
          }
        },
        err => {
          console.log(err);
        },
        () => {}
      );
    }
    if (this.messageText === 'Select type of Invoice') {
      this.reader.onload = (event: any) => {
        this.imagePath = event.target.result;
      };
      this.reader.readAsDataURL(this.file);
      this.submitSingle('B2C');
      // alert('uploading invoice as b2c');
      const element = <HTMLInputElement>document.getElementById('singleUpload');
      element.value = '';
    } else {
      this.reader.onload = (event: any) => {
        this.imagePath = event.target.result;
      };
      this.reader.readAsDataURL(this.file);
      this.submitBatch('B2C');
      // alert('uploading batch as b2c');
      const element = <HTMLInputElement>document.getElementById('batchUploadInput');
      element.value = '';
    }
  }

  // display message of confirmation
  displayPopup(operation?) {
    this.popup = true;
    this.operation = operation;
    this.checkedRecord = CommonService.checkedRecord;
    switch (operation) {
      case 'local':
        this.export('local');
        return; // download without popup
        break;
      case 'b2bOrB2cInvoice':
        this.file = CommonService.file;
        this.messageText = 'Select type of Invoice';
        this.b2bOrB2c();
      break;
      case 'b2bOrB2cBatch':
        this.file = CommonService.file;
        this.messageText = 'Select type of Invoices in batch';
        this.b2bOrB2c();
      break;
      case 'download-local':
        this.messageText = 'Only approved invoices will be downloaded. Do you want to proceed ?';
        if (sessionStorage.getItem('doNotShowLocalDownload') === 'Yes') {
          this.action();
          this.doNotShowPopup();
          return;
        } else if (sessionStorage.getItem('doNotShowLocalDownload') === 'No') {
          this.cancelAction();
          return;
        }
      break;
      case 'download-cloud':
        if (sessionStorage.getItem('doNotShowCloudDownload') === 'Yes') {
          this.action();
          this.doNotShowPopup();
          return;
        } else if (sessionStorage.getItem('doNotShowCloudDownload') === 'No') {
          this.cancelAction();
          return;
        }
        this.messageText = 'Only approved invoices will be downloaded. Do you want to proceed ?';
      break;
      case 'delete':
        if (sessionStorage.getItem('doNotShowDeleteInvoice') === 'Yes') {
          this.action();
          this.doNotShowPopup();
          return;
        } else if (sessionStorage.getItem('doNotShowDeleteInvoice') === 'No') {
          this.cancelAction();
          return;
        }
        this.messageText = 'Do you really want to delete selected invoices ?';
      break;
      case 'downloadReport':
        this.exportReport();
         // download without popup
         return;
        this.messageText = 'Only approved records will be downloaded. Do you want to proceed ?';
      break;
      case 'downloadReportAnyway':
        this.messageText = 'Only approved invoices will be downloaded. Do you want to proceed ?';
        if (sessionStorage.getItem('doNotShowDownloadReport') === 'Yes') {
          this.action();
          this.doNotShowPopup();
          return;
        } else if (sessionStorage.getItem('doNotShowDownloadReport') === 'No') {
          this.cancelAction();
          return;
        }
      break;
      case 'markApproved':
        if (sessionStorage.getItem('doNotShowMarkApproved') === 'Yes') {
          this.action();
          this.doNotShowPopup();
          return;
        } else if (sessionStorage.getItem('doNotShowMarkApproved') === 'No') {
          this.cancelAction();
          return;
        }
        this.messageText = 'Do you want to approve selected invoices ?';
      break;
      case 'backToRecords': case 'prevInvoice': case 'nextInvoice':
        if (sessionStorage.getItem('doNotShowBackToRecords') === 'No') {
          alert('Save changes please');
          return;
        } else if (sessionStorage.getItem('doNotShowBackToRecords') === 'Yes') {
          this.dataChanged = false;
          if (operation === 'backToRecords') {
            this.exitDetaileidview();
          } else if (operation === 'prevInvoice') {
            // code to previous invoice
          } else if (operation === 'nextInvoice') {
            // code to next invoice
          }
          return;
        }
        this.messageText = 'Changes are not saved, do you want continue ?';
      break;
      case 'addItem':
        this.messageText = 'Do you want to add new row ?';
      break;
      case 'approve':
        this.messageText = 'You cannot change data once approved. Continue ?';
      break;
      case 'sendToScientist':
        this.messageText = 'Are you sure want to send for review ?';
      break;
      case 'deleteItem':
        this.messageText = 'Do you really want to delete row ?';
      break;
      case 'deleteDataItem':
        this.messageText = 'Do you really want to delete row ?';
      break;
      case 'deleteAllItems':
        this.messageText = 'Do you want to delete all rows ?';
      break;
      case 'trainIsv':
        this.messageText = 'You cannot retrain table after training Supplier name. Do you want to proceed ?';
      break;
      case 'tableSelectionMode':
        this.messageText = 'Do you want to select all headers consecutevely';
      break;
      case 'requiredFieldToSave': case 'requiredFieldToApprove': case 'requiredFieldToApproveAndPush':
        this.messageText = 'Reuqired fields are empty. Do you want to save ?';
        break;
    }
    let offsetHeight;
    try {
      offsetHeight = this.clickEvent.pageY - this.clickEvent.clientY;
    } catch(e){
      offsetHeight = 0;
    }
    // const screenHeight = (document.body.clientHeight) * 40 / 100;
    this.scrHeight = document.getElementById('row').offsetHeight;
    document.getElementById('popUpBackground').style.height = this.scrHeight.toString() + 'px';
    document.getElementById('downloadPopup').style.display = 'flex';
    document.getElementById('downloadPopup').style.flexDirection = 'column';
    document.getElementById('downloadPopup').style.alignItems = 'center';
    document.getElementById('downloadPopup').style.justifyContent = 'center';
    // document.getElementById('downloadPopup').style.top = offsetHeight + 'px';
    document.getElementById('popUpBackground').style.display = 'block';
    document.getElementById('popUpBackground').style.top = offsetHeight + 'px';
    // document.getElementById('cancel-btn').focus();
  }

  doNotShowPopup() {
    this.popup = false;
    this.doNotShowAgainFlag = false;
  }

  action(event?) {
    this.okForDetaileidview = false;
    switch (this.operation) {
      case 'download-local':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowLocalDownload', 'Yes');
        }
        this.proceedDownload();
        this.hidePopup();
        break;
      case 'download-cloud':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowCloudDownload', 'Yes');
        }
        // this.exportToQuickbook();
        this.hidePopup();
        break;
      case 'delete':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteInvoice', 'Yes');
        }
        this.delete();
        this.hidePopup();
        break;
      case 'downloadReportAnyway':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDownloadReport', 'Yes');
        }
        this.downloadReport();
        this.hidePopup();
        break;
      case 'markApproved':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowMarkApproved', 'Yes');
        }
        this.multiReview();
        this.hidePopup();
      break;
      case 'backToRecords':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowBackToRecords', 'Yes')
        }
        this.tabNumber = sessionStorage.getItem('previous-tab');
        if (!this.tabNumber){
          this.tabNumber = 1;
        }
        this.docIsOpened = false;
        this.filename = null;
        this.selectTabs(this.tabNumber);
        // this.exitDetaileidview();
        // this.okForDetaileidview = true;
      break;
      case 'prevInvoice': case 'nextInvoice':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowBackToRecords', 'Yes')
        }
        this.okForDetaileidview = true;
      break;
      case 'addItem':
        this.okForDetaileidview = true;
      break;
      case 'approve':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowApprove', 'Yes')
        }
        this.okForDetaileidview = true;
      break;
      case 'sendToScientist':
        this.okForDetaileidview = true;
      break;
      case 'deleteItem':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteItem', 'Yes')
        }
        this.okForDetaileidview = true;
      break;
      case 'deleteDataItem':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteItem', 'Yes')
        }
        this.okForDetaileidview = true;
      break;
      case 'deleteAllItems':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteAll', 'Yes')
        }
        this.okForDetaileidview = true;
      break;
      case 'deleteAllDataItems':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteAll', 'Yes')
        }
        this.okForDetaileidview = true;
      break;
      case 'trainIsv':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowTrainIsv', 'Yes')
        }
        this.okForDetaileidview = true;
      break;
      case 'tableSelectionMode':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowTrainIsv', 'Yes')
        }
        this.okForDetaileidview = true;
      break;
      case 'requiredFieldToSave': case 'requiredFieldToApprove':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowRequiredField', 'Yes')
        }
        this.okForDetaileidview = true;
        this.okForDetaileidview = true;
      break;
    }
    this.hidePopup();
  }
  downloadReport() {
    if (this.checkedInvoices.length > 1) {
      this.reportStructure();
        this.excelService.exportAsExcelFile(
          this.dataToUpload,
          'InvoiceRecords',
          null,
          this.fileNames,
          'single'
        );
    } else if (this.checkedInvoices.length !== 0) {
      this.reportStructure();
        this.excelService.exportAsExcelFile(
          this.dataToUpload,
          this.filename,
          null,
          this.fileNames,
          'single'
        );
    }
    this.dataToUpload = [];
    this.cancelDownload();
  }

  cancelAction() {
    switch (this.operation) {
      case 'download-local':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowLocalDownload', 'No');
        }
        this.cancelDownload();
        this.hidePopup();
        break;
      case 'download-cloud':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowCloudDownload', 'No');
        }
        this.cancelDownload();
        this.hidePopup();
        break;
      case 'downloadReport':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDownloadReport', 'No');
        }
        this.cancelDownload();
        this.hidePopup();
        break;
      case 'delete':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteInvoice', 'No');
        }
        this.hidePopup();
        break;
      case 'markApproved':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowMarkApproved', 'No');
        }
        this.hidePopup();
      break;
      case 'backToRecords': case 'prevInvoice': case 'nextInvoice':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowBackToRecords', 'No')
        }
        this.okForDetaileidview = false;
      break;
      case 'addItem':
        this.okForDetaileidview = false;
      break;
      case 'approve':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowApprove', 'No')
        }
        this.okForDetaileidview = false;
      break;
      case 'sendToScientist':
        this.okForDetaileidview = false;
      break;
      case 'deleteItem':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteItem', 'No')
        }
        this.okForDetaileidview = false;
      break;
      case 'deleteDataItem':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteItem', 'No')
        }
        this.okForDetaileidview = false;
      break;
      case 'deleteAllItems':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowDeleteAll', 'No')
        }
        this.okForDetaileidview = false;
      break;
      case 'trainIsv':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowTrainIsv', 'No')
        }
        this.okForDetaileidview = false;
      break;
      case 'tableSelectionMode':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowTrainIsv', 'No')
        }
        this.okForDetaileidview = false;
      break;
      case 'requiredFieldToSave': case 'requiredFieldToApprove':
        if (this.doNotShowAgainFlag) {
          sessionStorage.setItem('doNotShowRequiredField', 'No')
        }
        this.okForDetaileidview = false;
      break;
    }
    this.hidePopup();
    CommonService.checkedCount = 0;
  }

  // hide confirmation message when 'cancel' button is pressed
  hidePopup() {
    try {
      this.popup = false;
      document.getElementById('popUpBackground').style.height = 'initial';
      document.getElementById('downloadPopup').style.display = 'none';
      document.getElementById('downloadPopup').style.top = 'initial';
      document.getElementById('popUpBackground').style.display = 'none';
    } catch (e) {
      console.log(e);
    }
  }

  resetCheckList() {
    this.checkedCount = 0;
    this.checkedRecord = [];
    this.operationComplete = true;
    CommonService.checkedCount = 0;
    CommonService.checkedRecord = [];
    this.areAllChecked = false;
    this.checkedInvoices = [];
    this.stopAutoReload('false');
  }

  // ******************************************* delete ******************************************
  delete() {
    let isApprovedIsChecked;
    this.checkedInvoices = [];
    this.operationComplete = true;
    this.checkedRecord = CommonService.checkedRecord;
    for (let i = 0; i < this.invoiceList.length; i++) {
      if (this.checkedRecord[i]) {
        if (
          this.invoiceList[i].is_approved === 0 ||
          sessionStorage.getItem('rolename') === 'Admin'
        ) {
          this.checkedInvoices.push(this.invoiceList[i]['obj_id']);
        } else {
          isApprovedIsChecked = true;
        }
      }
    }
    if (this.checkedInvoices.length > 0) {
      let res;
      this.getpost.sendToDelete(this.checkedInvoices, this.selectedDocType.param).subscribe(
        resp => {
          res = resp;
        },
        err => {
          alert('The records cannot be deleted. Please try again');
        },
        () => {
          this.complete();
          if (this.checkedInvoices.length === 1) {
            alert('1 record is deleted successfully.');
          } else if (this.checkedInvoices.length > 1) {
            alert(
              this.checkedInvoices.length + ' records are deleted succesfully.'
            );
          }
          this.resetCheckList();
        }
      );
    } else {
      if (isApprovedIsChecked) {
        alert('Approved records cannot be deleted.');
      } else {
        alert('No records are selected to delete.');
      }
    }
  }

  deleteCurrent() {
    this.getpost.sendToDelete([this.invoiceList[this.currentInvoiceIndex]['obj_id']], this.selectedDocType.param).subscribe(
      resp => {},
      err => {
        alert('The records cannot be deleted. Please try again');
      },
      () => {
        this.complete();
        alert('Record is deleted successfully.');
      }
    );
  }

  downloadCurrent() {
    if (this.invoiceList[this.currentInvoiceIndex].is_approved) {
      this.checkedInvoices = [];
      this.checkedInvoices.push(this.invoiceList[this.currentInvoiceIndex]);
      if (this.labelJson[this.selectedDocType.labelKey].downloadFormat && this.labelJson[this.selectedDocType.labelKey].downloadFormat.format === 'json') {
        this.downloadJSONFormat();
      } else {
        this.downloadStructure();
        this.excelService.exportAsExcelFile(
          this.dataToUpload,
          this.filename,
          null,
          this.fileNames,
          this.labelJson[this.selectedDocType.labelKey].downloadFormat.format
        );
      }
    } else {
      alert('Invoice is not approved');
    }
  }

  approveCurrent() {
    this.getpost.reviewAll([this.invoiceList[this.currentInvoiceIndex]['obj_id']], this.selectedDocType.param).subscribe(
      res => {
      },
      err => {},
      () => {
        this.resetCheckList();
        alert('Record is approved')
      }
    );
  }

  // ******************************* Review multiple invoices at a time **************************
  multiReview() {
    let isApprovedIsChecked;
    this.checkedInvoices = [];
    this.checkedRecord = CommonService.checkedRecord;
    for (let i = 0; i < this.invoiceList.length; i++) {
      if (this.checkedRecord[i]) {
        if (this.invoiceList[i].is_approved === 0) {
          this.checkedInvoices.push(this.invoiceList[i]['obj_id']);
        } else {
          isApprovedIsChecked = true;
        }
      }
    }
    if (this.checkedInvoices.length === 0 && isApprovedIsChecked) {
      alert('Already marked as reviewed');
    } else if (this.checkedInvoices.length === 0) {
      alert('Please select at least one invoice to mark as reviewed.');
    } else {
      this.getpost.reviewAll(this.checkedInvoices, this.selectedDocType.param).subscribe(
        res => {
        },
        err => {},
        () => {
          this.resetCheckList();
        }
      );
    }
  }

  // ************************************ list the files to be downloaded ************************
  export(to) {
    let nonApprovedSelected = false;
    this.checkedInvoices = [];
    this.selectedInvoiceIds = [];
    this.checkedRecord = CommonService.checkedRecord;
    for (let i = 0; i < this.invoiceList.length; i++) {
      if (this.checkedRecord[i] && this.invoiceList[i].is_approved === 1) {
        this.checkedInvoices.push(this.invoiceList[i]);
        this.selectedInvoiceIds.push(this.invoiceList[i].obj_id);
      } else if (
        this.checkedRecord[i] &&
        this.invoiceList[i].is_approved === 0
      ) {
        nonApprovedSelected = true;
      }
    }
    if (this.checkedRecord.length === 0) {
      alert('Please select invoices to download');
    } else if (nonApprovedSelected) {
      this.displayPopup('download-' + to);
    } else {
      if (to === 'local') {
        this.proceedDownload();
      } else {
        // this.exportToQuickbook();
      }
    }
  }

  // ******************************** Download selected invoices in local folder *****************
  proceedDownload() {
    if (this.checkedInvoices.length > 1) {
      if (this.labelJson[this.selectedDocType.labelKey].downloadFormat && this.labelJson[this.selectedDocType.labelKey].downloadFormat.format === 'json') {
        this.downloadJSONFormat();
      } else {
        this.downloadStructure();
        this.excelService.exportAsExcelFile(
          this.dataToUpload,
          'InvoiceRecords',
          null,
          this.fileNames,
          this.labelJson[this.selectedDocType.labelKey].downloadFormat.format
        );
      }
    } else if (this.checkedInvoices.length !== 0) {
      if (this.labelJson[this.selectedDocType.labelKey].downloadFormat && this.labelJson[this.selectedDocType.labelKey].downloadFormat.format === 'json') {
        this.downloadJSONFormat();
      } else {
        this.downloadStructure();

        this.excelService.exportAsExcelFile(
          this.dataToUpload,
          this.filename,
          null,
          this.fileNames,
          this.labelJson[this.selectedDocType.labelKey].downloadFormat.format
        );
      }
    }
    this.dataToUpload = [];
    this.cancelDownload();
  }

  // ******************************** Cancel downloading invoices ********************************
  cancelDownload() {
    this.resetCheckList();
  }

  // ************************************ function to download excel file ************************
  downloadStructure() {
    let rowNumber = 0;
    this.dataToUpload = [];
    this.fileNames = [];
    let count = 1;
    // iterate invoices
    for (let invoice = 0; invoice < this.checkedInvoices.length; invoice++) {
      let singleEntry = {};
      let itemNumber = 0;
      let singleInvoice = [];
      try {
        if (this.checkedInvoices[invoice].file_name.length < 27) {
          this.filename = this.checkedInvoices[invoice].file_name;
        } else {
          this.filename = this.checkedInvoices[invoice].file_name.substr(0, 27);
        }
        this.fileNames.push(this.returnUniqueFileName(this.filename, 0));
      } catch (e) {}
      try {
        // iterate pages in single invoice
        for (
          let imageIndex = 1; imageIndex <= this.checkedInvoices[invoice].image_id.length; imageIndex++
        ) {
          try {
            let item = 0;
            // iterate all lineitems in invoice
            do {
              rowNumber++;
              itemNumber++;

              if (this.labelJson[this.selectedDocType.labelKey].downloadFormat.format == 'single') {
                singleEntry['Sl.No'] = rowNumber;
                singleEntry['File Name'] = this.filename;
              }

              try {

                if (this.selectedDocType.labelKey == 'rfq') {
                  // qiv
                  if (this.labelJson[this.selectedDocType.labelKey].qiv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['qiv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].qiv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['qiv'][0];
                      } else if (this.checkedInvoices[invoice]['qiv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].qiv.label] = this.checkedInvoices[invoice]['qiv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].qiv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].qiv.label] = '';
                    }
                  }

                  // pnv
                  if (this.labelJson[this.selectedDocType.labelKey].pnv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['pnv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['pnv'][0];
                      } else if (this.checkedInvoices[invoice]['pnv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pnv.label] = this.checkedInvoices[invoice]['pnv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pnv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].pnv.label] = '';
                    }
                  }

                  // sidv
                  if (this.labelJson[this.selectedDocType.labelKey].sidv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['sidv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].sidv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['sidv'][0];
                      } else if (this.checkedInvoices[invoice]['sidv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].sidv.label] = this.checkedInvoices[invoice]['sidv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].sidv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].sidv.label] = '';
                    }
                  }

                  // eidv
                  if (this.labelJson[this.selectedDocType.labelKey].eidv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['eidv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].eidv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['eidv'][0];
                      } else if (this.checkedInvoices[invoice]['eidv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].eidv.label] = this.checkedInvoices[invoice]['eidv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].eidv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].eidv.label] = '';
                    }
                  }

                  // pdv
                  if (this.labelJson[this.selectedDocType.labelKey].pdv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['pdv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pdv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['pdv'][0];
                      } else if (this.checkedInvoices[invoice]['pdv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pdv.label] = this.checkedInvoices[invoice]['pdv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pdv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].pdv.label] = '';
                    }
                  }

                  // pmv
                  if (this.labelJson[this.selectedDocType.labelKey].pmv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['pmv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pmv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['pmv'][0];
                      } else if (this.checkedInvoices[invoice]['pmv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pmv.label] = this.checkedInvoices[invoice]['pmv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pmv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].pmv.label] = '';
                    }
                  }

                  // ctv
                  if (this.labelJson[this.selectedDocType.labelKey].ctv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['ctv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ctv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ctv'][0];
                      } else if (this.checkedInvoices[invoice]['ctv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ctv.label] = this.checkedInvoices[invoice]['ctv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ctv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].ctv.label] = '';
                    }
                  }

                  // vtv
                  if (this.labelJson[this.selectedDocType.labelKey].vtv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['vtv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].vtv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['vtv'][0];
                      } else if (this.checkedInvoices[invoice]['vtv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].vtv.label] = this.checkedInvoices[invoice]['vtv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].vtv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].vtv.label] = '';
                    }
                  }

                  // mav
                  if (this.labelJson[this.selectedDocType.labelKey].mav.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['mav']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].mav.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['mav'][0];
                      } else if (this.checkedInvoices[invoice]['mav']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].mav.label] = this.checkedInvoices[invoice]['mav'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].mav.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].mav.label] = '';
                    }
                  }

                  // crv
                  if (this.labelJson[this.selectedDocType.labelKey].crv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['crv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].crv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['crv'][0];
                      } else if (this.checkedInvoices[invoice]['crv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].crv.label] = this.checkedInvoices[invoice]['crv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].crv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].crv.label] = '';
                    }
                  }

                  // tecv
                  if (this.labelJson[this.selectedDocType.labelKey].tecv.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['tecv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].tecv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['tecv'][0];
                      } else if (this.checkedInvoices[invoice]['tecv']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].tecv.label] = this.checkedInvoices[invoice]['tecv'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].tecv.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].tecv.label] = '';
                    }
                  }

                  if (this.labelJson[this.selectedDocType.labelKey].table.visibility) {
                    singleEntry['Inv.Sl.No'] = itemNumber;
                    try {
                      for (let j = 0; j < this.checkedInvoices[invoice]['page_' + imageIndex]['colNames'].length; j++) {
                        singleEntry[this.checkedInvoices[invoice]['page_' + imageIndex]['colNames'][j]] =
                        this.checkedInvoices[invoice]['page_' + imageIndex]['lineItems'][item]
                        [this.checkedInvoices[invoice]['page_' + imageIndex]['colNames'][j]];
                      }
                    } catch (e) {}
                  }

                  if (this.labelJson[this.selectedDocType.labelKey].dataItemTable.visibility) {
                    singleEntry['Inv.Sl.No'] = itemNumber;
                    try {
                      for (let j = 0; j < this.checkedInvoices[invoice]['page_' + imageIndex]['dataColNames'].length; j++) {
                        singleEntry[this.checkedInvoices[invoice]['page_' + imageIndex]['dataColNames'][j]] =
                        this.checkedInvoices[invoice]['page_' + imageIndex]['dataItems'][item]
                        [this.checkedInvoices[invoice]['page_' + imageIndex]['dataColNames'][j]];
                      }
                    } catch (e) {}
                  }

                } else if (this.selectedDocType.labelKey == 'nach') {
                  // niq
                  if (this.labelJson[this.selectedDocType.labelKey].niq.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['niq']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].niq.label]
                        = this.checkedInvoices[invoice]['page_' + imageIndex]['niq'][0];
                      } else if (this.checkedInvoices[invoice]['niq']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].niq.label]
                        = this.checkedInvoices[invoice]['niq'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].niq.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].niq.label] = '';
                    }
                  }

                  // nan
                  if (this.labelJson[this.selectedDocType.labelKey].nan.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['nan']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nan.label]
                        = this.checkedInvoices[invoice]['page_' + imageIndex]['nan'][0];
                      } else if (this.checkedInvoices[invoice]['nan']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nan.label]
                        = this.checkedInvoices[invoice]['nan'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nan.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nan.label] = '';
                    }
                  }

                  // ntype
                  if (this.labelJson[this.selectedDocType.labelKey].ntype.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['ntype']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ntype.label]
                        = this.checkedInvoices[invoice]['page_' + imageIndex]['ntype'][0];
                      } else if (this.checkedInvoices[invoice]['ntype']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ntype.label]
                        = this.checkedInvoices[invoice]['ntype'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ntype.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].ntype.label] = '';
                    }
                  }

                  // nrn1
                  if (this.labelJson[this.selectedDocType.labelKey].nrn1.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['nrn1']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nrn1.label]
                        = this.checkedInvoices[invoice]['page_' + imageIndex]['nrn1'][0];
                      } else if (this.checkedInvoices[invoice]['nrn1']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nrn1.label]
                        = this.checkedInvoices[invoice]['nrn1'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nrn1.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nrn1.label] = '';
                    }
                  }

                  // nsef
                  if (this.labelJson[this.selectedDocType.labelKey].nsef.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['nsef']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nsef.label]
                        = this.checkedInvoices[invoice]['page_' + imageIndex]['nsef'][0];
                      } else if (this.checkedInvoices[invoice]['nsef']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nsef.label]
                        = this.checkedInvoices[invoice]['nsef'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nsef.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nsef.label] = '';
                    }
                  }

                  // nbnef
                  if (this.labelJson[this.selectedDocType.labelKey].nbnef.visibility) {
                    try {
                      if (this.checkedInvoices[invoice]['page_' + imageIndex]['nbnef']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nbnef.label]
                        = this.checkedInvoices[invoice]['page_' + imageIndex]['nbnef'][0];
                      } else if (this.checkedInvoices[invoice]['nbnef']) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nbnef.label]
                        = this.checkedInvoices[invoice]['nbnef'][0];
                      } else {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].nbnef.label] = '';
                      }
                    } catch (e) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nbnef.label] = '';
                    }
                  }
                } else {

                  // isv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].supplierName.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['isv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].supplierName.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['isv'][0];
                        } else if (this.checkedInvoices[invoice]['isv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].supplierName.label] = this.checkedInvoices[invoice]['isv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].supplierName.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].supplierName.label] = '';
                      }
                    }
                  } catch (e) {}

                  // cnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey]['cnv'].visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['cnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['cnv'].label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cnv'][0];
                        } else if (this.checkedInvoices[invoice]['cnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['cnv'].label] = this.checkedInvoices[invoice]['cnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['cnv'].label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey]['cnv'].label] = '';
                      }
                    }
                  }catch (e) {}

                  // customerAddress
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey]['customerAddress'].visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['cav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['customerAddress'].label] =
                          this.checkedInvoices[invoice]['page_' + imageIndex]['cav'][0];
                        } else if (this.checkedInvoices[invoice]['cav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['customerAddress'].label] =
                          this.checkedInvoices[invoice]['cav'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['customerAddress'].label] = '';
                        }
                      } catch (e) {
                        console.log(e)
                        singleEntry[this.labelJson[this.selectedDocType.labelKey]['customerAddress'].label] = '';
                      }
                    }
                  } catch (e) {}

                  // supplierAddress
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['sav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].label] =
                          this.checkedInvoices[invoice]['page_' + imageIndex]['sav'][0];
                        } else if (this.checkedInvoices[invoice]['isv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].label] = this.checkedInvoices[invoice]['sav'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].label] = '';
                      }
                    }
                  } catch (e) {}

                  // csnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].csnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['csnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].csnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['csnv'][0];
                        } else if (this.checkedInvoices[invoice]['csnv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].csnv.label] = this.checkedInvoices[invoice]['csnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].csnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].csnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // ssnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].ssnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['ssnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ssnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ssnv'][0];
                        } else if (this.checkedInvoices[invoice]['ssnv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ssnv.label] = this.checkedInvoices[invoice]['ssnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ssnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ssnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // icnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].icnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['icnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['icnv'][0];
                        } else if (this.checkedInvoices[invoice]['icnv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icnv.label] = this.checkedInvoices[invoice]['icnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].icnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // inv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].invoiceNumber.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['inv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label] =
                            this.checkedInvoices[invoice]['page_' + imageIndex]['inv'][0];
                        } else if (this.checkedInvoices[invoice]['inv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label] = this.checkedInvoices[invoice]['inv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label] = '';
                      }
                    }
                  } catch (e) {}

                  // chnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].chnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['chnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].chnv.label] =
                            this.checkedInvoices[invoice]['page_' + imageIndex]['chnv'][0];
                        } else if (this.checkedInvoices[invoice]['chnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].chnv.label] = this.checkedInvoices[invoice]['chnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].chnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].chnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // env
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].env.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['env']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].env.label] =
                            this.checkedInvoices[invoice]['page_' + imageIndex]['env'][0];
                        } else if (this.checkedInvoices[invoice]['env']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].env.label] = this.checkedInvoices[invoice]['env'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].env.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].env.label] = '';
                      }
                    }
                  } catch (e) {}

                  // idv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].invoiceDate.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['idv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceDate.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['idv'][0];
                        } else if (this.checkedInvoices[invoice]['idv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceDate.label] = this.checkedInvoices[invoice]['idv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceDate.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceDate.label] = '';
                      }
                    }
                  } catch (e) {}

                  // igv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].gstnNumber.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['igv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['igv'][0];
                        } else if (this.checkedInvoices[invoice]['igv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = this.checkedInvoices[invoice]['igv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = '';
                      }
                    }
                  } catch (e) {}

                  // cmnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].cmnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['cmnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cmnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cmnv'][0];
                        } else if (this.checkedInvoices[invoice]['cmnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cmnv.label] = this.checkedInvoices[invoice]['cmnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cmnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].cmnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // cgv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].cgv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['cgv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cgv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cgv'][0];
                        } else if (this.checkedInvoices[invoice]['cgv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cgv.label] = this.checkedInvoices[invoice]['cgv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cgv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].cgv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // ipv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].poNumber.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['ipv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].poNumber.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ipv'][0];
                        } else if (this.checkedInvoices[invoice]['ipv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].poNumber.label] = this.checkedInvoices[invoice]['ipv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].poNumber.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].poNumber.label] = '';
                      }
                    }
                  } catch (e) {}

                  // ivv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].vatNumber.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['ivv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] =
                          this.checkedInvoices[invoice]['page_' + imageIndex]['vv'][0];
                        } else if (this.checkedInvoices[invoice]['ivv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = this.checkedInvoices[invoice]['ivv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = '';
                      }
                    }
                  } catch (e) {}

                  // cvv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].vatNumber.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['cvv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] =
                          this.checkedInvoices[invoice]['page_' + imageIndex]['vv'][0];
                        } else if (this.checkedInvoices[invoice]['cvv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = this.checkedInvoices[invoice]['cvv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = '';
                      }
                    }
                  } catch (e) {}

                  // icv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].icv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['icv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['icv'][0];
                        } else if (this.checkedInvoices[invoice]['icv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icv.label] = this.checkedInvoices[invoice]['icv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].icv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // fcv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].fcv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['fcv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].fcv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['fcv'][0];
                        } else if (this.checkedInvoices[invoice]['fcv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].fcv.label] = this.checkedInvoices[invoice]['fcv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].fcv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].fcv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // pfv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].pfv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['pfv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].pfv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['pfv'][0];
                        } else if (this.checkedInvoices[invoice]['pfv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].pfv.label] = this.checkedInvoices[invoice]['pfv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].pfv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].pfv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // stav
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].stav.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['stav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].stav.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['stav'][0];
                        } else if (this.checkedInvoices[invoice]['stav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].stav.label] = this.checkedInvoices[invoice]['stav'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].stav.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].stav.label] = '';
                      }
                    }
                  } catch (e) {}

                  // bmv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].bmv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['bmv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].bmv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['bmv'][0];
                        } else if (this.checkedInvoices[invoice]['bmv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].bmv.label] = this.checkedInvoices[invoice]['bmv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].bmv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].bmv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // mdv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].mdv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['mdv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].mdv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['mdv'][0];
                        } else if (this.checkedInvoices[invoice]['mdv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].mdv.label] = this.checkedInvoices[invoice]['mdv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].mdv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].mdv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // iitv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].iitv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['iitv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iitv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['iitv'][0];
                        } else if (this.checkedInvoices[invoice]['iitv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iitv.label] = this.checkedInvoices[invoice]['iitv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iitv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].iitv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // itv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].total.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['itv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['itv'][0];
                        } else if (this.checkedInvoices[invoice]['itv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['itv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                      }
                    }
                  } catch (e) {}

                  // bdv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].bdv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['bdv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].bdv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['bdv'][0];
                        } else if (this.checkedInvoices[invoice]['bdv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].bdv.label] = this.checkedInvoices[invoice]['bdv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].bdv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].bdv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // ilv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].ilv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['ilv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ilv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ilv'][0];
                        } else if (this.checkedInvoices[invoice]['ilv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ilv.label] = this.checkedInvoices[invoice]['ilv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ilv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ilv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // ptv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].ptv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['ptv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ptv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ptv'][0];
                        } else if (this.checkedInvoices[invoice]['ptv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ptv.label] = this.checkedInvoices[invoice]['ptv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].ptv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].ptv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // islv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].islv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['islv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].islv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['islv'][0];
                        } else if (this.checkedInvoices[invoice]['islv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].islv.label] = this.checkedInvoices[invoice]['islv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].islv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].islv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // iclv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].iclv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['iclv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['iclv'][0];
                        } else if (this.checkedInvoices[invoice]['iclv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclv.label] = this.checkedInvoices[invoice]['iclv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].iclv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // scrn
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].scrn.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['scrn']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].scrn.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['scrn'][0];
                        } else if (this.checkedInvoices[invoice]['scrn'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].scrn.label] = this.checkedInvoices[invoice]['scrn'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].scrn.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].scrn.label] = '';
                      }
                    }
                  } catch (e) {}

                  // lecrn
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].lecrn.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['lecrn']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lecrn.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['lecrn'][0];
                        } else if (this.checkedInvoices[invoice]['lecrn'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lecrn.label] = this.checkedInvoices[invoice]['lecrn'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lecrn.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].lecrn.label] = '';
                      }
                    }
                  } catch (e) {}

                  // lrcrn
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].lrcrn.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['lrcrn']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lrcrn.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['lrcrn'][0];
                        } else if (this.checkedInvoices[invoice]['lrcrn'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lrcrn.label] = this.checkedInvoices[invoice]['lrcrn'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lrcrn.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].lrcrn.label] = '';
                      }
                    }
                  } catch (e) {}

                  // islav
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].islav.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['islav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].islav.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['islav'][0];
                        } else if (this.checkedInvoices[invoice]['islav'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].islav.label] = this.checkedInvoices[invoice]['islav'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].islav.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].islav.label] = '';
                      }
                    }
                  } catch (e) {}

                  // iclav
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].iclav.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['iclav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclav.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['iclav'][0];
                        } else if (this.checkedInvoices[invoice]['iclav'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclav.label] = this.checkedInvoices[invoice]['iclav'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclav.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].iclav.label] = '';
                      }
                    }
                  } catch (e) {}

                  // cessv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].cessv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['cessv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cessv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cessv'][0];
                        } else if (this.checkedInvoices[invoice]['cessv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cessv.label] = this.checkedInvoices[invoice]['cessv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].cessv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].cessv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // tcsv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].tcsv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['tcsv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].tcsv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['tcsv'][0];
                        } else if (this.checkedInvoices[invoice]['tcsv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].tcsv.label] = this.checkedInvoices[invoice]['tcsv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].tcsv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].tcsv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // discv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].discv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['discv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].discv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['discv'][0];
                        } else if (this.checkedInvoices[invoice]['discv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].discv.label] = this.checkedInvoices[invoice]['discv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].discv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].discv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // vrnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].vrnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['vrnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vrnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['vrnv'][0];
                        } else if (this.checkedInvoices[invoice]['vrnv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vrnv.label] = this.checkedInvoices[invoice]['vrnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].vrnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].vrnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // hsnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].hsnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['hsnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].hsnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['hsnv'][0];
                        } else if (this.checkedInvoices[invoice]['hsnv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].hsnv.label] = this.checkedInvoices[invoice]['hsnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].hsnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].hsnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // lrgv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].lrgv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['lrgv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lrgv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['lrgv'][0];
                        } else if (this.checkedInvoices[invoice]['lrgv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lrgv.label] = this.checkedInvoices[invoice]['lrgv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].lrgv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].lrgv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // legv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].legv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['legv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].legv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['legv'][0];
                        } else if (this.checkedInvoices[invoice]['legv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].legv.label] = this.checkedInvoices[invoice]['legv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].legv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].legv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // smnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].smnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['smnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].smnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['smnv'][0];
                        } else if (this.checkedInvoices[invoice]['smnv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].smnv.label] = this.checkedInvoices[invoice]['smnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].smnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].smnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // iclmnv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].iclmnv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['iclmnv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclmnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['iclmnv'][0];
                        } else if (this.checkedInvoices[invoice]['iclmnv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclmnv.label] = this.checkedInvoices[invoice]['iclmnv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].iclmnv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].iclmnv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // icleiv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].icleiv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['icleiv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icleiv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['icleiv'][0];
                        } else if (this.checkedInvoices[invoice]['icleiv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icleiv.label] = this.checkedInvoices[invoice]['icleiv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].icleiv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].icleiv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // insv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].insv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['insv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].insv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['insv'][0];
                        } else if (this.checkedInvoices[invoice]['insv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].insv.label] = this.checkedInvoices[invoice]['insv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].insv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].insv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // regv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].regv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['regv']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].regv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['regv'][0];
                        } else if (this.checkedInvoices[invoice]['regv'][0]) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].regv.label] = this.checkedInvoices[invoice]['regv'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey].regv.label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey].regv.label] = '';
                      }
                    }
                  } catch (e) {}

                  // cgstp
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['cgstp']) {
                          singleEntry['CGST %'] = this.checkedInvoices[invoice]['page_' + imageIndex]['cgstp'][0];
                        } else if (this.checkedInvoices[invoice]['cgstp']) {
                          singleEntry['CGST %'] = this.checkedInvoices[invoice]['cgstp'][0];
                        } else {
                          singleEntry['CGST %'] = '';
                        }
                      } catch (e) {
                        singleEntry['CGST %'] = '';
                      }
                    }
                  } catch (e) {}

                  // sgstp
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['sgstp']) {
                          singleEntry['SGST %'] = this.checkedInvoices[invoice]['page_' + imageIndex]['sgstp'][0];
                        } else if (this.checkedInvoices[invoice]['sgstp']) {
                          singleEntry['SGST %'] = this.checkedInvoices[invoice]['sgstp'][0];
                        } else {
                          singleEntry['SGST %'] = '';
                        }
                      } catch (e) {
                        singleEntry['SGST %'] = '';
                      }
                    }
                  } catch (e) {}

                  // igstp
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['igstp']) {
                          singleEntry['IGST %'] = this.checkedInvoices[invoice]['page_' + imageIndex]['igstp'][0];
                        } else if (this.checkedInvoices[invoice]['igstp']) {
                          singleEntry['IGST %'] = this.checkedInvoices[invoice]['igstp'][0];
                        } else {
                          singleEntry['IGST %'] = '';
                        }
                      } catch (e) {
                        singleEntry['IGST %'] = '';
                      }
                    }
                  } catch (e) {}

                  // cgstv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['cgstv']) {
                          singleEntry['CGST Value'] = this.checkedInvoices[invoice]['page_' + imageIndex]['cgstv'][0];
                        } else if (this.checkedInvoices[invoice]['cgstv']) {
                          singleEntry['CGST Value'] = this.checkedInvoices[invoice]['cgstv'][0];
                        } else {
                          singleEntry['CGST Value'] = '';
                        }
                      } catch (e) {
                        singleEntry['CGST Value'] = '';
                      }
                    }
                  } catch (e) {}

                  // sgstv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['sgstv']) {
                          singleEntry['SGST Value'] = this.checkedInvoices[invoice]['page_' + imageIndex]['sgstv'][0];
                        } else if (this.checkedInvoices[invoice]['sgstv']) {
                          singleEntry['SGST Value'] = this.checkedInvoices[invoice]['sgstv'][0];
                        } else {
                          singleEntry['SGST Value'] = '';
                        }
                      } catch (e) {
                        singleEntry['SGST Value'] = '';
                      }
                    }
                  } catch (e) {}

                  // igstv
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['igstv']) {
                          singleEntry['IGST Value'] = this.checkedInvoices[invoice]['page_' + imageIndex]['igstv'][0];
                        } else if (this.checkedInvoices[invoice]['igstv']) {
                          singleEntry['IGST Value'] = this.checkedInvoices[invoice]['igstv'][0];
                        } else {
                          singleEntry['IGST Value'] = '';
                        }
                      } catch (e) {
                        singleEntry['IGST Value'] = '';
                      }
                    }
                  } catch (e) {}

                  // txav (total tax)
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey]['txav'].visibility) {
                      try {
                        if (this.checkedInvoices[invoice]['page_' + imageIndex]['txav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['txav'].label] =
                            this.checkedInvoices[invoice]['page_' + imageIndex]['txav'][0];
                        } else if (this.checkedInvoices[invoice]['txav']) {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['txav'].label] = this.checkedInvoices[invoice]['txav'][0];
                        } else {
                          singleEntry[this.labelJson[this.selectedDocType.labelKey]['txav'].label] = '';
                        }
                      } catch (e) {
                        singleEntry[this.labelJson[this.selectedDocType.labelKey]['txav'].label] = '';
                      }
                    }
                  } catch (e) {}

                  if (this.labelJson.tabs.accounts.visibility) {
                    // cgst account
                    try {
                      if (this.checkedInvoices[invoice]['AccountsData']) {
                        try {
                          if (this.checkedInvoices[invoice]['AccountsData']['CGST']) {
                            singleEntry['CGST A.C. Value'] = this.checkedInvoices[invoice]['AccountsData']['CGST'];
                          } else {
                            singleEntry['CGST A.C. Value'] = '';
                          }
                        } catch (e) {
                          singleEntry['CGST A.C. Value'] = '';
                        }
                      }
                    } catch (e) {}

                    // sgst account
                    try {
                      if (this.checkedInvoices[invoice]['AccountsData']) {
                        try {
                          if (this.checkedInvoices[invoice]['AccountsData']['SGST']) {
                            singleEntry['SGST A.C. Value'] = this.checkedInvoices[invoice]['AccountsData']['SGST'];
                          } else {
                            singleEntry['SGST A.C. Value'] = '';
                          }
                        } catch (e) {
                          singleEntry['SGST A.C. Value'] = '';
                        }
                      }
                    } catch (e) {}

                    // igst account
                    try {
                      if (this.checkedInvoices[invoice]['AccountsData']) {
                        try {
                          if (this.checkedInvoices[invoice]['AccountsData']['IGST']) {
                            singleEntry['IGST A.C. Value'] = this.checkedInvoices[invoice]['AccountsData']['IGST'];
                          } else {
                            singleEntry['IGST A.C. Value'] = '';
                          }
                        } catch (e) {
                          singleEntry['IGST A.C. Value'] = '';
                        }
                      }
                    } catch (e) {}

                    // Purchase AC
                    try {
                      try {
                        if (this.checkedInvoices[invoice]['AccountsData']['LineItemAccounts']) {
                          singleEntry['Purchase A.C.']
                            = this.checkedInvoices[invoice]['AccountsData']['LineItemAccounts'][item];
                        } else {
                          singleEntry['Purchase A.C.'] = '';
                        }
                      } catch (e) {
                        singleEntry['Purchase A.C.'] = '';
                      }
                    } catch (e) {}

                    // QB Item Name
                    // try {
                    //   try {
                    //     if (this.checkedInvoices[invoice]['AccountsData']['individualQuickBookAc']) {
                    //       singleEntry['QB Item Name']
                    //         = this.checkedInvoices[invoice]['AccountsData']['individualQuickBookAc'][item];
                    //     } else {
                    //       singleEntry['QB Item Name'] = '';
                    //     }
                    //   } catch (e) {
                    //     singleEntry['QB Item Name'] = '';
                    //   }
                    // } catch (e) {}

                    // QB Item Type
                    // try {
                    //   try {
                    //     if (this.checkedInvoices[invoice]['AccountsData']['indivisualQbItemType']) {
                    //       singleEntry['QB Item Type']
                    //         = this.checkedInvoices[invoice]['AccountsData']['indivisualQbItemType'][item];
                    //     } else {
                    //       singleEntry['QB Item Type'] = '';
                    //     }
                    //   } catch (e) {
                    //     singleEntry['QB Item Type'] = '';
                    //   }
                    // } catch (e) {}

                  }

                  // lineitem table
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].table.visibility) {
                      singleEntry['Inv.Sl.No'] = itemNumber;
                      try {
                        for (let j = 0; j < this.checkedInvoices[invoice]['page_' + imageIndex]['colNames'].length; j++) {
                          singleEntry[this.checkedInvoices[invoice]['page_' + imageIndex]['colNames'][j]] =
                          this.checkedInvoices[invoice]['page_' + imageIndex]['lineItems'][item]
                          [this.checkedInvoices[invoice]['page_' + imageIndex]['colNames'][j]];
                        }
                      } catch (e) {}
                    }
                  } catch (e) {}

                  // lineitem table
                  try {
                    if (this.labelJson[this.selectedDocType.labelKey].dataItemTable.visibility) {
                      singleEntry['Inv.Sl.No'] = itemNumber;
                      try {
                        for (let j = 0; j < this.checkedInvoices[invoice]['page_' + imageIndex]['dataColNames'].length; j++) {
                          singleEntry[this.checkedInvoices[invoice]['page_' + imageIndex]['dataColNames'][j]] =
                          this.checkedInvoices[invoice]['page_' + imageIndex]['dataItems'][item]
                          [this.checkedInvoices[invoice]['page_' + imageIndex]['dataColNames'][j]];
                        }
                      } catch (e) {}
                    }
                  } catch (e) {}
                }
                if (this.labelJson[this.selectedDocType.labelKey].downloadFormat.format == 'excel') {
                  singleInvoice.push(singleEntry);
                } else {
                  this.dataToUpload.push(singleEntry);
                }
                singleEntry = {};
                item++;
              } catch (e) {
                item++;
                console.log(e)
                continue;
              }

            } while (this.checkedInvoices[invoice]['page_' + imageIndex]['lineItems'][item] ||
              this.checkedInvoices[invoice]['page_' + imageIndex]['dataItems'][item]
            );
          } catch (e) {
            continue;
          }
        }
      } catch (e) {
        console.log(e);
      }
      if (this.labelJson[this.selectedDocType.labelKey].downloadFormat.format == 'excel') {
        this.dataToUpload.push(singleInvoice);
      }
    }
  }

  exportReport() {
    let nonApprovedSelected = false;
    this.checkedInvoices = [];
    this.selectedInvoiceIds = [];
    this.selectedRawData = [];
    this.checkedRecord = CommonService.checkedRecord;
    for (let i = 0; i < this.invoiceList.length; i++) {
      // check if there is any non approved invoice selected
      if (this.checkedRecord[i] && (this.invoiceList[i].is_approved === 1 || true)) {
        this.checkedInvoices.push(this.invoiceList[i]);
        this.selectedRawData.push(this.rawData[i]);
        this.selectedInvoiceIds.push(this.invoiceList[i].obj_id);
      } else if ( this.checkedRecord[i] &&  this.invoiceList[i].is_approved === 0 ) {
        nonApprovedSelected = true;
      }
    }
    if (this.checkedRecord.length === 0) {
      alert('Please select invoices to download');
    } else if (nonApprovedSelected) {
      this.displayPopup('downloadReportAnyway');
    } else {
      this.downloadReport();
    }
  }

  reportStructure() {
    let oldValuesData = this.ds.processOldData(this.selectedRawData);
    this.dataToUpload = [];
    this.fileNames = [];
    // iterate invoices
    for (let invoice = 0; invoice < this.checkedInvoices.length; invoice++) {
      let singleEntry = {};
      let foundFields = {};
      try {
        if (this.checkedInvoices[invoice].file_name.length < 27) {
          this.filename = this.checkedInvoices[invoice].file_name;
        } else {
          this.filename = this.checkedInvoices[invoice].file_name.substr(0, 27);
        }
        this.fileNames.push(this.returnUniqueFileName(this.filename, 0));
      } catch (e) {}
      try {
        // iterate pages in single invoice
        for (
          let imageIndex = 1; imageIndex <= this.checkedInvoices[invoice].image_id.length; imageIndex++
        ) {
          try {
            if (this.labelJson[this.selectedDocType.labelKey].downloadFormat.format == 'single') {
              singleEntry['File Name'] = this.filename;
            }
            singleEntry['File Name'] = this.filename;
            singleEntry['Upload Date'] = this.checkedInvoices[invoice].date;

            try {

              // inv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].invoiceNumber.visibility) {
                  try {
                    if (oldValuesData[invoice]['inv']) {
                      if (!foundFields['inv']) {
                        foundFields['inv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['inv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['inv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['inv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['inv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {}
                }
              } catch (e) {}

              // isv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].supplierName.visibility) {
                  try {
                    if (oldValuesData[invoice]['isv']) {
                      if (!foundFields['isv']) {
                        foundFields['isv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].supplierName.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['isv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['isv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['isv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['isv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {}
                }
              } catch (e) {}

              // sav
              try{
                if (this.labelJson[this.selectedDocType.labelKey].supplierAddress.visibility) {
                  try {
                    if (oldValuesData[invoice]['sav']) {
                      if (!foundFields['sav']) {
                        foundFields['sav'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].supplierAddress.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['sav'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['sav'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['sav'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['sav'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {}
                }
              } catch (e) {}

              // idv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].invoiceDate.visibility) {
                  try {
                    if (oldValuesData[invoice]['idv']) {
                      if (!foundFields['idv']) {
                        foundFields['idv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].invoiceDate.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['idv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['idv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['idv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['idv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // igv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].gstnNumber.visibility) {
                  try {
                    if (oldValuesData[invoice]['igv']) {
                      if (!foundFields['igv']) {
                        foundFields['igv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].gstnNumber.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['igv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['igv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['igv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['igv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // ipv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].poNumber.visibility) {
                  try {
                    if (oldValuesData[invoice]['ipv']) {
                      if (!foundFields['ipv']) {
                        foundFields['ipv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].poNumber.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['ipv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['ipv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['ipv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['ipv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // ivv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].vatNumber.visibility) {
                  try {
                    if (oldValuesData[invoice]['ivv']) {
                      if (!foundFields['ivv']) {
                        foundFields['ivv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].vatNumber.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['ivv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['ivv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['ivv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['ivv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // itv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].total.visibility) {
                  try {
                    if (oldValuesData[invoice]['itv']) {
                      if (!foundFields['itv']) {
                        foundFields['itv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].total.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['itv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['itv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['itv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['itv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // fcv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].fcv.visibility) {
                  try {
                    if (oldValuesData[invoice]['fcv']) {
                      if (!foundFields['fcv']) {
                        foundFields['fcv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].fcv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['fcv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['fcv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['fcv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['fcv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // icv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].icv.visibility) {
                  try {
                    if (oldValuesData[invoice]['icv']) {
                      if (!foundFields['icv']) {
                        foundFields['icv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].icv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['icv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['icv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['icv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['icv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // mdv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].mdv.visibility) {
                  try {
                    if (oldValuesData[invoice]['mdv']) {
                      if (!foundFields['mdv']) {
                        foundFields['mdv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].mdv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['mdv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['mdv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['mdv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['mdv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // txav
              try{
                if (this.labelJson[this.selectedDocType.labelKey].txav.visibility) {
                  try {
                    if (oldValuesData[invoice]['txav']) {
                      if (!foundFields['txav']) {
                        foundFields['txav'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].txav.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['txav'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['txav'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['txav'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['txav'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // bdv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].bdv.visibility) {
                  try {
                    if (oldValuesData[invoice]['bdv']) {
                      if (!foundFields['bdv']) {
                        foundFields['bdv'] = true;singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].bdv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['bdv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['bdv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['bdv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['bdv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cgv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].cgv.visibility) {
                  try {
                    if (oldValuesData[invoice]['cgv']) {
                      if (!foundFields['cgv']) {
                        foundFields['cgv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].cgv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['cgv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cgv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cgv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cgv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // csnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].csnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['csnv']) {
                      if (!foundFields['csnv']) {
                        foundFields['csnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].csnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['csnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['csnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['csnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['csnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // ssnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].ssnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['ssnv']) {
                      if (!foundFields['ssnv']) {
                        foundFields['ssnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].ssnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['ssnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['ssnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['ssnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['ssnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // ilv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].ilv.visibility) {
                  try {
                    if (oldValuesData[invoice]['ilv']) {
                      if (!foundFields['ilv']) {
                        foundFields['ilv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].ilv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['ilv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['ilv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['ilv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['ilv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // ptv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].ptv.visibility) {
                  try {
                    if (oldValuesData[invoice]['ptv']) {
                      if (!foundFields['ptv']) {
                        foundFields['ptv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].ptv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['ptv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['ptv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['ptv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['ptv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].cnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['cnv']) {
                      if (!foundFields['cnv']) {
                        foundFields['cnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].cnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['cnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cav
              try{
                if (this.labelJson[this.selectedDocType.labelKey].customerAddress.visibility) {
                  try {
                    if (oldValuesData[invoice]['cav']) {
                      if (!foundFields['cav']) {
                        foundFields['cav'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].customerAddress.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['cav'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cav'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cav'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cav'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {}
                }
              } catch (e) {}

              // cgstp
              try{
                if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                  try {
                    if (oldValuesData[invoice]['cgstp']) {
                      if (!foundFields['cgstp']) {
                        foundFields['cgstp'] = true;
                        singleEntry['Field Name'] = 'CGST %';
                        singleEntry['Old Value'] = oldValuesData[invoice]['cgstp'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cgstp'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cgstp'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cgstp'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // sgstp
              try{
                if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                  try {
                    if (oldValuesData[invoice]['sgstp']) {
                      if (!foundFields['sgstp']) {
                        foundFields['sgstp'] = true;
                        singleEntry['Field Name'] = "SGST %";
                        singleEntry['Old Value'] = oldValuesData[invoice]['sgstp'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['sgstp'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['sgstp'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['sgstp'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // igstp
              try{
                if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                  try {
                    if (oldValuesData[invoice]['igstp']) {
                      if (!foundFields['igstp']) {
                        foundFields['igstp'] = true;
                        singleEntry['Field Name'] = "IGST %";
                        singleEntry['Old Value'] = oldValuesData[invoice]['igstp'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['igstp'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['igstp'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['igstp'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cgstv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                  try {
                    if (oldValuesData[invoice]['cgstv']) {
                      if (!foundFields['cgstv']) {
                        foundFields['cgstv'] = true;
                        singleEntry['Field Name'] = "CGST Value";
                        singleEntry['Old Value'] = oldValuesData[invoice]['cgstv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cgstv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cgstv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cgstv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // sgstv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                  try {
                    if (oldValuesData[invoice]['sgstv']) {
                      if (!foundFields['sgstv']) {
                        foundFields['sgstv'] = true;
                        singleEntry['Field Name'] = "SGST Value";
                        singleEntry['Old Value'] = oldValuesData[invoice]['sgstv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['sgstv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['sgstv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['sgstv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // igstv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                  try {
                    if (oldValuesData[invoice]['igstv']) {
                      if (!foundFields['igstv']) {
                        foundFields['igstv'] = true;
                        singleEntry['Field Name'] = "IGST Value";
                        singleEntry['Old Value'] = oldValuesData[invoice]['igstv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['igstv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['igstv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['igstv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // iitv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].iitv.visibility) {
                  try {
                    if (oldValuesData[invoice]['iitv']) {
                      if (!foundFields['iitv']) {
                        foundFields['iitv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].iitv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['iitv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['iitv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['iitv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['iitv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // spnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].spnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['spnv']) {
                      if (!foundFields['spnv']) {
                        foundFields['spnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].spnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['spnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['spnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['spnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['spnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cpnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].cpnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['cpnv']) {
                      if (!foundFields['cpnv']) {
                        foundFields['cpnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].cpnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['cpnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cpnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cpnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cpnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // icnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].icnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['icnv']) {
                      if (!foundFields['icnv']) {
                        foundFields['icnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].icnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['icnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['icnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['icnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['icnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // chnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].chnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['chnv']) {
                      if (!foundFields['chnv']) {
                        foundFields['chnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].chnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['chnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['chnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['chnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['chnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // env
              try{
                if (this.labelJson[this.selectedDocType.labelKey].env.visibility) {
                  try {
                    if (oldValuesData[invoice]['env']) {
                      if (!foundFields['env']) {
                        foundFields['env'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].env.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['env'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['env'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['env'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['env'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // mnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].mnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['mnv']) {
                      if (!foundFields['mnv']) {
                        foundFields['mnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].mnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['mnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['mnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['mnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['mnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cvv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].cvv.visibility) {
                  try {
                    if (oldValuesData[invoice]['cvv']) {
                      if (!foundFields['cvv']) {
                        foundFields['cvv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].cvv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['cvv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cvv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cvv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cvv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // dnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].dnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['dnv']) {
                      if (!foundFields['dnv']) {
                        foundFields['dnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].dnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['dnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['dnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['dnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['dnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // gnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].gnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['gnv']) {
                      if (!foundFields['gnv']) {
                        foundFields['gnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].gnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['gnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['gnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['gnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['gnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // sesv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].sesv.visibility) {
                  try {
                    if (oldValuesData[invoice]['sesv']) {
                      if (!foundFields['sesv']) {
                        foundFields['sesv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].sesv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['sesv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['sesv'].join(',');
                        singleEntry['Modified Date'] = oldValuesData[invoice]['sesv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['sesv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // vatp
              try{
                if (this.labelJson[this.selectedDocType.labelKey].vatp.visibility) {
                  try {
                    if (oldValuesData[invoice]['vatp']) {
                      if (!foundFields['vatp']) {
                        foundFields['vatp'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].vatp.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['vatp'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['vatp'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['vatp'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['vatp'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // ihv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].ihv.visibility) {
                  try {
                    if (oldValuesData[invoice]['ihv']) {
                      if (!foundFields['ihv']) {
                        foundFields['ihv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].ihv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['ihv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['ihv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['ihv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['ihv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // islv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].islv.visibility) {
                  try {
                    if (oldValuesData[invoice]['islv']) {
                      if (!foundFields['islv']) {
                        foundFields['islv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].islv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['islv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['islv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['islv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['islv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // iclv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].iclv.visibility) {
                  try {
                    if (oldValuesData[invoice]['iclv']) {
                      if (!foundFields['iclv']) {
                        foundFields['iclv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].iclv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['iclv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['iclv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['iclv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['iclv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // scrn
              try{
                if (this.labelJson[this.selectedDocType.labelKey].scrn.visibility) {
                  try {
                    if (oldValuesData[invoice]['scrn']) {
                      if (!foundFields['scrn']) {
                        foundFields['scrn'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].scrn.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['scrn'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['scrn'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['scrn'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['scrn'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // lecrn
              try{
                if (this.labelJson[this.selectedDocType.labelKey].lecrn.visibility) {
                  try {
                    if (oldValuesData[invoice]['lecrn']) {
                      if (!foundFields['lecrn']) {
                        foundFields['lecrn'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].lecrn.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['lecrn'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['lecrn'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['lecrn'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['lecrn'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // lrcrn
              try{
                if (this.labelJson[this.selectedDocType.labelKey].lrcrn.visibility) {
                  try {
                    if (oldValuesData[invoice]['lrcrn']) {
                      if (!foundFields['lrcrn']) {
                        foundFields['lrcrn'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].lrcrn.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['lrcrn'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['lrcrn'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['lrcrn'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['lrcrn'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // islav
              try{
                if (this.labelJson[this.selectedDocType.labelKey].islav.visibility) {
                  try {
                    if (oldValuesData[invoice]['islav']) {
                      if (!foundFields['islav']) {
                        foundFields['islav'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].islav.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['islav'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['islav'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['islav'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['islav'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // iclav
              try{
                if (this.labelJson[this.selectedDocType.labelKey].iclav.visibility) {
                  try {
                    if (oldValuesData[invoice]['iclav']) {
                      if (!foundFields['iclav']) {
                        foundFields['iclav'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].iclav.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['iclav'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['iclav'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['iclav'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['iclav'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cessv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].cessv.visibility) {
                  try {
                    if (oldValuesData[invoice]['cessv']) {
                      if (!foundFields['cessv']) {
                        foundFields['cessv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].cessv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['cessv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cessv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cessv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cessv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // tcsv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].tcsv.visibility) {
                  try {
                    if (oldValuesData[invoice]['tcsv']) {
                      if (!foundFields['tcsv']) {
                        foundFields['tcsv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].tcsv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['tcsv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['tcsv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['tcsv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['tcsv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // discv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].discv.visibility) {
                  try {
                    if (oldValuesData[invoice]['discv']) {
                      if (!foundFields['discv']) {
                        foundFields['discv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].discv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['discv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['discv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['discv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['discv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // vrnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].vrnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['vrnv']) {
                      if (!foundFields['vrnv']) {
                        foundFields['vrnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].vrnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['vrnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['vrnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['vrnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['vrnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // hsnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].hsnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['hsnv']) {
                      if (!foundFields['hsnv']) {
                        foundFields['hsnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].hsnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['hsnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['hsnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['hsnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['hsnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // lrgv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].lrgv.visibility) {
                  try {
                    if (oldValuesData[invoice]['lrgv']) {
                      if (!foundFields['lrgv']) {
                        foundFields['lrgv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].lrgv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['lrgv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['lrgv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['lrgv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['lrgv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // legv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].legv.visibility) {
                  try {
                    if (oldValuesData[invoice]['legv']) {
                      if (!foundFields['legv']) {
                        foundFields['legv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].legv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['legv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['legv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['legv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['legv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // smnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].smnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['smnv']) {
                      if (!foundFields['smnv']) {
                        foundFields['smnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].smnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['smnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['smnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['smnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['smnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // iclmnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].iclmnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['iclmnv']) {
                      if (!foundFields['iclmnv']) {
                        foundFields['iclmnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].iclmnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['iclmnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['iclmnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['iclmnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['iclmnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // icleiv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].icleiv.visibility) {
                  try {
                    if (oldValuesData[invoice]['icleiv']) {
                      if (!foundFields['icleiv']) {
                        foundFields['icleiv'] = true;singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].icleiv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['icleiv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['icleiv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['icleiv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['icleiv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // insv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].insv.visibility) {
                  try {
                    if (oldValuesData[invoice]['insv']) {
                      if (!foundFields['insv']) {
                        foundFields['insv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].insv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['insv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['insv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['insv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['insv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // regv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].regv.visibility) {
                  try {
                    if (oldValuesData[invoice]['regv']) {
                      if (!foundFields['regv']) {
                        foundFields['regv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].regv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['regv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['regv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['regv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['regv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cmnv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].cmnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['cmnv']) {
                      if (!foundFields['cmnv']) {
                        foundFields['cmnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].cmnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['cmnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cmnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cmnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cmnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // emv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].emv.visibility) {
                  try {
                    if (oldValuesData[invoice]['emv']) {
                      if (!foundFields['emv']) {
                        foundFields['emv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].emv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['emv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['emv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['emv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['emv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // cmnl
              try{
                if (this.labelJson[this.selectedDocType.labelKey].cmnl.visibility) {
                  try {
                    if (oldValuesData[invoice]['cmnl']) {
                      if (!foundFields['cmnl']) {
                        foundFields['cmnl'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].cmnl.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['cmnl'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['cmnl'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['cmnl'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['cmnl'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // stav
              try{
                if (this.labelJson[this.selectedDocType.labelKey].stav.visibility) {
                  try {
                    if (oldValuesData[invoice]['stav']) {
                      if (!foundFields['stav']) {
                        foundFields['stav'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].stav.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['stav'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['stav'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['stav'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['stav'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // bmv
              try{
                if (this.labelJson[this.selectedDocType.labelKey].bmv.visibility) {
                  try {
                    if (oldValuesData[invoice]['bmv']) {
                      if (!foundFields['bmv']) {
                        foundFields['bmv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].bmv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['bmv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['bmv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['bmv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['bmv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              if (this.selectedDocType.labelKey == 'nach') {
                // niq
                try {
                  if (this.labelJson[this.selectedDocType.labelKey].niq.visibility) {
                    try {
                      if (oldValuesData[invoice]['niq']) {
                        if (!foundFields['niq']) {
                          foundFields['niq'] = true;
                          singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].niq.label;
                          singleEntry['Old Value'] = oldValuesData[invoice]['niq'].value;
                          singleEntry['New Value'] = this.checkedInvoices[invoice]['niq'][0];
                          singleEntry['Modified Date'] = oldValuesData[invoice]['niq'].modified_time;
                          singleEntry['Modified By'] = oldValuesData[invoice]['niq'].modified_by;
                          this.dataToUpload.push(singleEntry);
                          singleEntry = {};
                        }
                      }
                    } catch (e) {
                      console.log(e)
                    }
                  }
                } catch (e) {}

                // nan
                try {
                  if (this.labelJson[this.selectedDocType.labelKey].nan.visibility) {
                    try {
                      if (oldValuesData[invoice]['nan']) {
                        if (!foundFields['nan']) {
                          foundFields['nan'] = true;
                          singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].nan.label;
                          singleEntry['Old Value'] = oldValuesData[invoice]['nan'].value;
                          singleEntry['New Value'] = this.checkedInvoices[invoice]['nan'][0];
                          singleEntry['Modified Date'] = oldValuesData[invoice]['nan'].modified_time;
                          singleEntry['Modified By'] = oldValuesData[invoice]['nan'].modified_by;
                          this.dataToUpload.push(singleEntry);
                          singleEntry = {};
                        }
                      }
                    } catch (e) {
                      console.log(e)
                    }
                  }
                } catch (e) {}

                // ntype
                try {
                  if (this.labelJson[this.selectedDocType.labelKey].ntype.visibility) {
                    try {
                      if (oldValuesData[invoice]['ntype']) {
                        if (!foundFields['ntype']) {
                          foundFields['ntype'] = true;
                          singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].ntype.label;
                          singleEntry['Old Value'] = oldValuesData[invoice]['ntype'].value;
                          singleEntry['New Value'] = this.checkedInvoices[invoice]['ntype'][0];
                          singleEntry['Modified Date'] = oldValuesData[invoice]['ntype'].modified_time;
                          singleEntry['Modified By'] = oldValuesData[invoice]['ntype'].modified_by;
                          this.dataToUpload.push(singleEntry);
                          singleEntry = {};
                        }
                      }
                    } catch (e) {
                      console.log(e)
                    }
                  }
                } catch (e) {}

                // nrn1
                try {
                  if (this.labelJson[this.selectedDocType.labelKey].nrn1.visibility) {
                    try {
                      if (oldValuesData[invoice]['nrn1']) {
                        if (!foundFields['nrn1']) {
                          foundFields['nrn1'] = true;
                          singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].nrn1.label;
                          singleEntry['Old Value'] = oldValuesData[invoice]['nrn1'].value;
                          singleEntry['New Value'] = this.checkedInvoices[invoice]['nrn1'][0];
                          singleEntry['Modified Date'] = oldValuesData[invoice]['nrn1'].modified_time;
                          singleEntry['Modified By'] = oldValuesData[invoice]['nrn1'].modified_by;
                          this.dataToUpload.push(singleEntry);
                          singleEntry = {};
                        }
                      }
                    } catch (e) {
                      console.log(e)
                    }
                  }
                } catch (e) {}

                // nsef
                try {
                  if (this.labelJson[this.selectedDocType.labelKey].nsef.visibility) {
                    try {
                      if (oldValuesData[invoice]['nsef']) {
                        if (!foundFields['nsef']) {
                          foundFields['nsef'] = true;
                          singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].nsef.label;
                          singleEntry['Old Value'] = oldValuesData[invoice]['nsef'].value;
                          singleEntry['New Value'] = this.checkedInvoices[invoice]['nsef'][0];
                          singleEntry['Modified Date'] = oldValuesData[invoice]['nsef'].modified_time;
                          singleEntry['Modified By'] = oldValuesData[invoice]['nsef'].modified_by;
                          this.dataToUpload.push(singleEntry);
                          singleEntry = {};
                        }
                      }
                    } catch (e) {
                      console.log(e)
                    }
                  }
                } catch (e) {}

                // nbnef
                try {
                  if (this.labelJson[this.selectedDocType.labelKey].nbnef.visibility) {
                    try {
                      if (oldValuesData[invoice]['nbnef']) {
                        if (!foundFields['nbnef']) {
                          foundFields['nbnef'] = true;
                          singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].nbnef.label;
                          singleEntry['Old Value'] = oldValuesData[invoice]['nbnef'].value;
                          singleEntry['New Value'] = this.checkedInvoices[invoice]['nbnef'][0];
                          singleEntry['Modified Date'] = oldValuesData[invoice]['nbnef'].modified_time;
                          singleEntry['Modified By'] = oldValuesData[invoice]['nbnef'].modified_by;
                          this.dataToUpload.push(singleEntry);
                          singleEntry = {};
                        }
                      }
                    } catch (e) {
                      console.log(e)
                    }
                  }
                } catch (e) {}
              }

              // qiv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].qiv.visibility) {
                  try {
                    if (oldValuesData[invoice]['qiv']) {
                      if (!foundFields['qiv']) {
                        foundFields['qiv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].qiv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['qiv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['qiv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['qiv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['qiv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // pnv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].pnv.visibility) {
                  try {
                    if (oldValuesData[invoice]['pnv']) {
                      if (!foundFields['pnv']) {
                        foundFields['pnv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].pnv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['pnv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['pnv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['pnv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['pnv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // sidv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].sidv.visibility) {
                  try {
                    if (oldValuesData[invoice]['sidv']) {
                      if (!foundFields['sidv']) {
                        foundFields['sidv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].sidv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['sidv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['sidv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['sidv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['sidv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // eidv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].eidv.visibility) {
                  try {
                    if (oldValuesData[invoice]['eidv']) {
                      if (!foundFields['eidv']) {
                        foundFields['eidv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].eidv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['eidv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['eidv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['eidv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['eidv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // pdv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].pdv.visibility) {
                  try {
                    if (oldValuesData[invoice]['pdv']) {
                      if (!foundFields['pdv']) {
                        foundFields['pdv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].pdv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['pdv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['pdv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['pdv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['pdv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // pmv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].pmv.visibility) {
                  try {
                    if (oldValuesData[invoice]['pmv']) {
                      if (!foundFields['pmv']) {
                        foundFields['pmv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].pmv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['pmv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['pmv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['pmv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['pmv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // ctv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].ctv.visibility) {
                  try {
                    if (oldValuesData[invoice]['ctv']) {
                      if (!foundFields['ctv']) {
                        foundFields['ctv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].ctv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['ctv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['ctv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['ctv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['ctv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // vtv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].vtv.visibility) {
                  try {
                    if (oldValuesData[invoice]['vtv']) {
                      if (!foundFields['vtv']) {
                        foundFields['vtv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].vtv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['vtv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['vtv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['vtv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['vtv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // mav
              try {
                if (this.labelJson[this.selectedDocType.labelKey].mav.visibility) {
                  try {
                    if (oldValuesData[invoice]['mav']) {
                      if (!foundFields['mav']) {
                        foundFields['mav'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].mav.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['mav'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['mav'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['mav'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['mav'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // crv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].crv.visibility) {
                  try {
                    if (oldValuesData[invoice]['crv']) {
                      if (!foundFields['crv']) {
                        foundFields['crv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].crv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['crv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['crv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['crv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['crv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

              // tecv
              try {
                if (this.labelJson[this.selectedDocType.labelKey].tecv.visibility) {
                  try {
                    if (oldValuesData[invoice]['tecv']) {
                      if (!foundFields['tecv']) {
                        foundFields['tecv'] = true;
                        singleEntry['Field Name'] = this.labelJson[this.selectedDocType.labelKey].tecv.label;
                        singleEntry['Old Value'] = oldValuesData[invoice]['tecv'].value;
                        singleEntry['New Value'] = this.checkedInvoices[invoice]['tecv'][0];
                        singleEntry['Modified Date'] = oldValuesData[invoice]['tecv'].modified_time;
                        singleEntry['Modified By'] = oldValuesData[invoice]['tecv'].modified_by;
                        this.dataToUpload.push(singleEntry);
                        singleEntry = {};
                      }
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }
              } catch (e) {}

            } catch (e) {
              console.log(e)
              continue;
            }
          } catch (e) {
            console.log(e)
            continue;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  returnUniqueFileName(filename: string, count) {
    if (this.fileNames.includes(filename)) {
      let lastIndex = this.filename.lastIndexOf('.');
      let firstPart = this.filename.substring(0, lastIndex);
      let lastPart = this.filename.substring(lastIndex);
      filename = this.returnUniqueFileName((count+1) + '_' +firstPart + lastPart, count + 1);
    }
    return filename;
  }

  // ************************************ function to download json file ************************
  downloadJSONFormat() {
    this.dataToUpload = [];
    for (let invoice = 0; invoice < this.checkedInvoices.length; invoice++) {
      let singleEntry = {};
      try {
        this.filename = this.checkedInvoices[invoice].file_name;
        singleEntry['File Name'] = this.filename;
        for (
          let imageIndex = 1; imageIndex <= this.checkedInvoices[invoice].image_id.length; imageIndex++
        ) {
          try {

            // isv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].supplierName.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['isv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].supplierName.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['isv'][0];
                  } else if (this.checkedInvoices[invoice]['isv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].supplierName.label] = this.checkedInvoices[invoice]['isv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].supplierName.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].supplierName.label] = '';
                }
              }
            } catch (e) {}

            // cnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey]['cnv'].visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['cnv'].label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cnv'][0];
                  } else if (this.checkedInvoices[invoice]['cnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['cnv'].label] = this.checkedInvoices[invoice]['cnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['cnv'].label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey]['cnv'].label] = '';
                }
              }
            } catch (e) {}

            // supplierAddress
            try {
              if (this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['sav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].label] =
                    this.checkedInvoices[invoice]['page_' + imageIndex]['sav'][0];
                  } else if (this.checkedInvoices[invoice]['isv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].label] = this.checkedInvoices[invoice]['sav'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey]['supplierAddress'].label] = '';
                }
              }
            } catch (e) {}

            // customerAddress
            try {
              if (this.labelJson[this.selectedDocType.labelKey]['customerAddress'].visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['customerAddress'].label] =
                    this.checkedInvoices[invoice]['page_' + imageIndex]['cav'][0];
                  } else if (this.checkedInvoices[invoice]['customerAddress']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['customerAddress'].label] = this.checkedInvoices[invoice]['cav'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['customerAddress'].label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey]['customerAddress'].label] = '';
                }
              }
            } catch (e) {}

            // csnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].csnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['csnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].csnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['csnv'][0];
                  } else if (this.checkedInvoices[invoice]['csnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].csnv.label] = this.checkedInvoices[invoice]['csnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].csnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].csnv.label] = '';
                }
              }
            } catch (e) {}

            // ssnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].ssnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['ssnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ssnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ssnv'][0];
                  } else if (this.checkedInvoices[invoice]['ssnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ssnv.label] = this.checkedInvoices[invoice]['ssnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ssnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].ssnv.label] = '';
                }
              }
            } catch (e) {}

            // spnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].spnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['spnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].spnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['spnv'][0];
                  } else if (this.checkedInvoices[invoice]['spnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].spnv.label] = this.checkedInvoices[invoice]['spnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].spnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].spnv.label] = '';
                }
              }
            } catch (e) {}

            // cpnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].cpnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cpnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cpnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cpnv'][0];
                  } else if (this.checkedInvoices[invoice]['cpnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cpnv.label] = this.checkedInvoices[invoice]['cpnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cpnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].cpnv.label] = '';
                }
              }
            } catch (e) {}

            // icnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].icnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['icnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['icnv'][0];
                  } else if (this.checkedInvoices[invoice]['icnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icnv.label] = this.checkedInvoices[invoice]['icnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].icnv.label] = '';
                }
              }
            } catch (e) {}

            // inv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].invoiceNumber.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['inv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['inv'][0];
                  } else if (this.checkedInvoices[invoice]['inv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label] = this.checkedInvoices[invoice]['inv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label] = '';
                }
              }
            } catch (e) {}

            // chnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].chnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['chnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].chnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['chnv'][0];
                  } else if (this.checkedInvoices[invoice]['chnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].chnv.label] = this.checkedInvoices[invoice]['chnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].chnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].chnv.label] = '';
                }
              }
            } catch (e) {}

            // env
            try {
              if (this.labelJson[this.selectedDocType.labelKey].env.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['env']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].env.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['env'][0];
                  } else if (this.checkedInvoices[invoice]['env']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].env.label] = this.checkedInvoices[invoice]['env'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].env.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].env.label] = '';
                }
              }
            } catch (e) {}

            // mnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].mnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['mnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['mnv'][0];
                  } else if (this.checkedInvoices[invoice]['mnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mnv.label] = this.checkedInvoices[invoice]['mnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].mnv.label] = '';
                }
              }
            } catch (e) {}

            // dnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].dnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['dnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].dnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['dnv'][0];
                  } else if (this.checkedInvoices[invoice]['dnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].dnv.label] = this.checkedInvoices[invoice]['dnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].dnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].dnv.label] = '';
                }
              }
            } catch (e) {}

            // gnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['gnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['gnv'][0];
                  } else if (this.checkedInvoices[invoice]['gnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gnv.label] = this.checkedInvoices[invoice]['gnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].gnv.label] = '';
                }
              }
            } catch (e) {}

            // idv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].invoiceDate.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['idv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceDate.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['idv'][0];
                  } else if (this.checkedInvoices[invoice]['idv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceDate.label] = this.checkedInvoices[invoice]['idv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceDate.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].invoiceDate.label] = '';
                }
              }
            } catch (e) {}

            // igv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gstnNumber.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['igv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['igv'][0];
                  } else if (this.checkedInvoices[invoice]['igv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = this.checkedInvoices[invoice]['igv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = '';
                }
              }
            } catch (e) {}

            // sesv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gstnNumber.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['sesv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['sesv'][0];
                  } else if (this.checkedInvoices[invoice]['sesv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = this.checkedInvoices[invoice]['sesv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].gstnNumber.label] = '';
                }
              }
            } catch (e) {}

            // cmnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].cmnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cmnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cmnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cmnv'][0];
                  } else if (this.checkedInvoices[invoice]['cmnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cmnv.label] = this.checkedInvoices[invoice]['cmnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cmnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].cmnv.label] = '';
                }
              }
            } catch (e) {}

            // cgv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].cgv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cgv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cgv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cgv'][0];
                  } else if (this.checkedInvoices[invoice]['cgv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cgv.label] = this.checkedInvoices[invoice]['cgv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cgv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].cgv.label] = '';
                }
              }
            } catch (e) {}

            // ipv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].poNumber.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['ipv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].poNumber.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ipv'][0];
                  } else if (this.checkedInvoices[invoice]['ipv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].poNumber.label] = this.checkedInvoices[invoice]['ipv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].poNumber.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].poNumber.label] = '';
                }
              }
            } catch (e) {}

            // ivv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].vatNumber.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['ivv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] =
                    this.checkedInvoices[invoice]['page_' + imageIndex]['vv'][0];
                  } else if (this.checkedInvoices[invoice]['ivv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = this.checkedInvoices[invoice]['ivv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = '';
                }
              }
            } catch (e) {}

            // cvv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].vatNumber.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cvv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] =
                    this.checkedInvoices[invoice]['page_' + imageIndex]['vv'][0];
                  } else if (this.checkedInvoices[invoice]['cvv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = this.checkedInvoices[invoice]['cvv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].vatNumber.label] = '';
                }
              }
            } catch (e) {}

            // icv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].icv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['icv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['icv'][0];
                  } else if (this.checkedInvoices[invoice]['icv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icv.label] = this.checkedInvoices[invoice]['icv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].icv.label] = '';
                }
              }
            } catch (e) {}

            // qiv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].qiv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['qiv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].qiv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['qiv'][0];
                  } else if (this.checkedInvoices[invoice]['qiv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].qiv.label] = this.checkedInvoices[invoice]['qiv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].qiv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].qiv.label] = '';
                }
              }
            } catch (e) {}

            // pnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].pnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['pnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['pnv'][0];
                  } else if (this.checkedInvoices[invoice]['pnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pnv.label] = this.checkedInvoices[invoice]['pnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].pnv.label] = '';
                }
              }
            } catch (e) {}

            // sidv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].sidv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['sidv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].sidv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['sidv'][0];
                  } else if (this.checkedInvoices[invoice]['sidv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].sidv.label] = this.checkedInvoices[invoice]['sidv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].sidv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].sidv.label] = '';
                }
              }
            } catch (e) {}

            // eidv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].eidv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['eidv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].eidv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['eidv'][0];
                  } else if (this.checkedInvoices[invoice]['eidv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].eidv.label] = this.checkedInvoices[invoice]['eidv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].eidv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].eidv.label] = '';
                }
              }
            } catch (e) {}

            // pdv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].pdv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['pdv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pdv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['pdv'][0];
                  } else if (this.checkedInvoices[invoice]['pdv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pdv.label] = this.checkedInvoices[invoice]['pdv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pdv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].pdv.label] = '';
                }
              }
            } catch (e) {}

            if (this.selectedDocType.labelKey == 'nach') {
              // niq
              try {
                if (this.labelJson[this.selectedDocType.labelKey].niq.visibility) {
                  try {
                    if (this.checkedInvoices[invoice]['page_' + imageIndex]['niq']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].niq.label]
                      = this.checkedInvoices[invoice]['page_' + imageIndex]['niq'][0];
                    } else if (this.checkedInvoices[invoice]['niq']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].niq.label]
                      = this.checkedInvoices[invoice]['niq'][0];
                    } else {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].niq.label] = '';
                    }
                  } catch (e) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].niq.label] = '';
                  }
                }
              } catch (e) {}

              // nan
              try {
                if (this.labelJson[this.selectedDocType.labelKey].nan.visibility) {
                  try {
                    if (this.checkedInvoices[invoice]['page_' + imageIndex]['nan']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nan.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['nan'][0];
                    } else if (this.checkedInvoices[invoice]['nan']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nan.label] = this.checkedInvoices[invoice]['nan'][0];
                    } else {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nan.label] = '';
                    }
                  } catch (e) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].nan.label] = '';
                  }
                }
              } catch (e) {}

              // ntype
              try {
                if (this.labelJson[this.selectedDocType.labelKey].ntype.visibility) {
                  try {
                    if (this.checkedInvoices[invoice]['page_' + imageIndex]['ntype']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].ntype.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ntype'][0];
                    } else if (this.checkedInvoices[invoice]['ntype']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].ntype.label] = this.checkedInvoices[invoice]['ntype'][0];
                    } else {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].ntype.label] = '';
                    }
                  } catch (e) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ntype.label] = '';
                  }
                }
              } catch (e) {}

              // nrn1
              try {
                if (this.labelJson[this.selectedDocType.labelKey].nrn1.visibility) {
                  try {
                    if (this.checkedInvoices[invoice]['page_' + imageIndex]['nrn1']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nrn1.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['nrn1'][0];
                    } else if (this.checkedInvoices[invoice]['nrn1']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nrn1.label] = this.checkedInvoices[invoice]['nrn1'][0];
                    } else {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nrn1.label] = '';
                    }
                  } catch (e) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].nrn1.label] = '';
                  }
                }
              } catch (e) {}

              // nsef
              try {
                if (this.labelJson[this.selectedDocType.labelKey].nsef.visibility) {
                  try {
                    if (this.checkedInvoices[invoice]['page_' + imageIndex]['nsef']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nsef.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['nsef'][0];
                    } else if (this.checkedInvoices[invoice]['nsef']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nsef.label] = this.checkedInvoices[invoice]['nsef'][0];
                    } else {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nsef.label] = '';
                    }
                  } catch (e) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].nsef.label] = '';
                  }
                }
              } catch (e) {}

              // nbnef
              try {
                if (this.labelJson[this.selectedDocType.labelKey].nbnef.visibility) {
                  try {
                    if (this.checkedInvoices[invoice]['page_' + imageIndex]['nbnef']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nbnef.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['nbnef'][0];
                    } else if (this.checkedInvoices[invoice]['nbnef']) {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nbnef.label] = this.checkedInvoices[invoice]['nbnef'][0];
                    } else {
                      singleEntry[this.labelJson[this.selectedDocType.labelKey].nbnef.label] = '';
                    }
                  } catch (e) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].nbnef.label] = '';
                  }
                }
              } catch (e) {}
            }

            // pmv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].pmv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['pmv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pmv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['pmv'][0];
                  } else if (this.checkedInvoices[invoice]['pmv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pmv.label] = this.checkedInvoices[invoice]['pmv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pmv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].pmv.label] = '';
                }
              }
            } catch (e) {}

            // ctv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].ctv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['ctv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ctv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ctv'][0];
                  } else if (this.checkedInvoices[invoice]['ctv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ctv.label] = this.checkedInvoices[invoice]['ctv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ctv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].ctv.label] = '';
                }
              }
            } catch (e) {}

            // vtv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].vtv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['vtv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vtv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['vtv'][0];
                  } else if (this.checkedInvoices[invoice]['vtv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vtv.label] = this.checkedInvoices[invoice]['vtv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vtv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].vtv.label] = '';
                }
              }
            } catch (e) {}

            // mav
            try {
              if (this.labelJson[this.selectedDocType.labelKey].mav.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['mav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mav.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['mav'][0];
                  } else if (this.checkedInvoices[invoice]['mav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mav.label] = this.checkedInvoices[invoice]['mav'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mav.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].mav.label] = '';
                }
              }
            } catch (e) {}

            // crv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].crv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['crv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].crv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['crv'][0];
                  } else if (this.checkedInvoices[invoice]['crv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].crv.label] = this.checkedInvoices[invoice]['crv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].crv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].crv.label] = '';
                }
              }
            } catch (e) {}

            // tecv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].tecv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['tecv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].tecv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['tecv'][0];
                  } else if (this.checkedInvoices[invoice]['tecv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].tecv.label] = this.checkedInvoices[invoice]['tecv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].tecv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].tecv.label] = '';
                }
              }
            } catch (e) {}

            // fcv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].fcv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['fcv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].fcv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['fcv'][0];
                  } else if (this.checkedInvoices[invoice]['fcv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].fcv.label] = this.checkedInvoices[invoice]['fcv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].fcv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].fcv.label] = '';
                }
              }
            } catch (e) {}

            // pfv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].pfv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['pfv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pfv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['pfv'][0];
                  } else if (this.checkedInvoices[invoice]['pfv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pfv.label] = this.checkedInvoices[invoice]['pfv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].pfv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].pfv.label] = '';
                }
              }
            } catch (e) {}

            // stav
            try {
              if (this.labelJson[this.selectedDocType.labelKey].stav.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['stav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].stav.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['stav'][0];
                  } else if (this.checkedInvoices[invoice]['stav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].stav.label] = this.checkedInvoices[invoice]['stav'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].stav.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].stav.label] = '';
                }
              }
            } catch (e) {}

            // bmv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].bmv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['bmv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].bmv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['bmv'][0];
                  } else if (this.checkedInvoices[invoice]['bmv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].bmv.label] = this.checkedInvoices[invoice]['bmv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].bmv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].bmv.label] = '';
                }
              }
            } catch (e) {}

            // mdv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].mdv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['mdv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mdv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['mdv'][0];
                  } else if (this.checkedInvoices[invoice]['mdv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mdv.label] = this.checkedInvoices[invoice]['mdv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].mdv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].mdv.label] = '';
                }
              }
            } catch (e) {}

            // iitv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].iitv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['iitv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iitv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['iitv'][0];
                  } else if (this.checkedInvoices[invoice]['iitv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iitv.label] = this.checkedInvoices[invoice]['iitv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iitv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].iitv.label] = '';
                }
              }
            } catch (e) {}

            // itv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].total.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['itv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['itv'][0];
                  } else if (this.checkedInvoices[invoice]['itv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['itv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                }
              }
            } catch (e) {}

            // vatp
            try {
              if (this.labelJson[this.selectedDocType.labelKey].total.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['vatp']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['vatp'][0];
                  } else if (this.checkedInvoices[invoice]['vatp'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['vatp'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                }
              }
            } catch (e) {}

            // ihv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].total.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['ihv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ihv'][0];
                  } else if (this.checkedInvoices[invoice]['ihv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['ihv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                }
              }
            } catch (e) {}

            // emv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].total.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['emv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['emv'][0];
                  } else if (this.checkedInvoices[invoice]['emv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = this.checkedInvoices[invoice]['emv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].total.label] = '';
                }
              }
            } catch (e) {}

            // bdv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].bdv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['bdv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].bdv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['bdv'][0];
                  } else if (this.checkedInvoices[invoice]['bdv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].bdv.label] = this.checkedInvoices[invoice]['bdv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].bdv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].bdv.label] = '';
                }
              }
            } catch (e) {}

            // ilv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].ilv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['ilv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ilv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ilv'][0];
                  } else if (this.checkedInvoices[invoice]['ilv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ilv.label] = this.checkedInvoices[invoice]['ilv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ilv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].ilv.label] = '';
                }
              }
            } catch (e) {}

            // ptv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].ptv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['ptv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ptv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['ptv'][0];
                  } else if (this.checkedInvoices[invoice]['ptv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ptv.label] = this.checkedInvoices[invoice]['ptv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].ptv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].ptv.label] = '';
                }
              }
            } catch (e) {}

            // islv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].islv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['islv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].islv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['islv'][0];
                  } else if (this.checkedInvoices[invoice]['islv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].islv.label] = this.checkedInvoices[invoice]['islv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].islv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].islv.label] = '';
                }
              }
            } catch (e) {}

            // iclv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].iclv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['iclv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['iclv'][0];
                  } else if (this.checkedInvoices[invoice]['iclv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclv.label] = this.checkedInvoices[invoice]['iclv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].iclv.label] = '';
                }
              }
            } catch (e) {}

            // scrn
            try {
              if (this.labelJson[this.selectedDocType.labelKey].scrn.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['scrn']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].scrn.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['scrn'][0];
                  } else if (this.checkedInvoices[invoice]['scrn'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].scrn.label] = this.checkedInvoices[invoice]['scrn'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].scrn.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].scrn.label] = '';
                }
              }
            } catch (e) {}

            // lecrn
            try {
              if (this.labelJson[this.selectedDocType.labelKey].lecrn.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['lecrn']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lecrn.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['lecrn'][0];
                  } else if (this.checkedInvoices[invoice]['lecrn'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lecrn.label] = this.checkedInvoices[invoice]['lecrn'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lecrn.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].lecrn.label] = '';
                }
              }
            } catch (e) {}

            // lrcrn
            try {
              if (this.labelJson[this.selectedDocType.labelKey].lrcrn.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['lrcrn']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lrcrn.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['lrcrn'][0];
                  } else if (this.checkedInvoices[invoice]['lrcrn'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lrcrn.label] = this.checkedInvoices[invoice]['lrcrn'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lrcrn.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].lrcrn.label] = '';
                }
              }
            } catch (e) {}

            // islav
            try {
              if (this.labelJson[this.selectedDocType.labelKey].islav.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['islav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].islav.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['islav'][0];
                  } else if (this.checkedInvoices[invoice]['islav'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].islav.label] = this.checkedInvoices[invoice]['islav'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].islav.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].islav.label] = '';
                }
              }
            } catch (e) {}

            // iclav
            try {
              if (this.labelJson[this.selectedDocType.labelKey].iclav.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['iclav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclav.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['iclav'][0];
                  } else if (this.checkedInvoices[invoice]['iclav'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclav.label] = this.checkedInvoices[invoice]['iclav'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclav.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].iclav.label] = '';
                }
              }
            } catch (e) {}

            // cessv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].cessv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cessv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cessv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['cessv'][0];
                  } else if (this.checkedInvoices[invoice]['cessv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cessv.label] = this.checkedInvoices[invoice]['cessv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].cessv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].cessv.label] = '';
                }
              }
            } catch (e) {}

            // tcsv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].tcsv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['tcsv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].tcsv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['tcsv'][0];
                  } else if (this.checkedInvoices[invoice]['tcsv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].tcsv.label] = this.checkedInvoices[invoice]['tcsv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].tcsv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].tcsv.label] = '';
                }
              }
            } catch (e) {}

            // discv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].discv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['discv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].discv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['discv'][0];
                  } else if (this.checkedInvoices[invoice]['discv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].discv.label] = this.checkedInvoices[invoice]['discv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].discv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].discv.label] = '';
                }
              }
            } catch (e) {}

            // vrnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].vrnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['vrnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vrnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['vrnv'][0];
                  } else if (this.checkedInvoices[invoice]['vrnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vrnv.label] = this.checkedInvoices[invoice]['vrnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].vrnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].vrnv.label] = '';
                }
              }
            } catch (e) {}

            // hsnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].hsnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['hsnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].hsnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['hsnv'][0];
                  } else if (this.checkedInvoices[invoice]['hsnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].hsnv.label] = this.checkedInvoices[invoice]['hsnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].hsnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].hsnv.label] = '';
                }
              }
            } catch (e) {}

            // lrgv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].lrgv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['lrgv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lrgv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['lrgv'][0];
                  } else if (this.checkedInvoices[invoice]['lrgv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lrgv.label] = this.checkedInvoices[invoice]['lrgv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].lrgv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].lrgv.label] = '';
                }
              }
            } catch (e) {}

            // legv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].legv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['legv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].legv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['legv'][0];
                  } else if (this.checkedInvoices[invoice]['legv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].legv.label] = this.checkedInvoices[invoice]['legv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].legv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].legv.label] = '';
                }
              }
            } catch (e) {}

            // smnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].smnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['smnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].smnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['smnv'][0];
                  } else if (this.checkedInvoices[invoice]['smnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].smnv.label] = this.checkedInvoices[invoice]['smnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].smnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].smnv.label] = '';
                }
              }
            } catch (e) {}

            // iclmnv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].iclmnv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['iclmnv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclmnv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['iclmnv'][0];
                  } else if (this.checkedInvoices[invoice]['iclmnv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclmnv.label] = this.checkedInvoices[invoice]['iclmnv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].iclmnv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].iclmnv.label] = '';
                }
              }
            } catch (e) {}

            // icleiv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].icleiv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['icleiv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icleiv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['icleiv'][0];
                  } else if (this.checkedInvoices[invoice]['icleiv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icleiv.label] = this.checkedInvoices[invoice]['icleiv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].icleiv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].icleiv.label] = '';
                }
              }
            } catch (e) {}

            // insv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].insv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['insv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].insv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['insv'][0];
                  } else if (this.checkedInvoices[invoice]['insv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].insv.label] = this.checkedInvoices[invoice]['insv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].insv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].insv.label] = '';
                }
              }
            } catch (e) {}

            // regv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].regv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['regv']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].regv.label] = this.checkedInvoices[invoice]['page_' + imageIndex]['regv'][0];
                  } else if (this.checkedInvoices[invoice]['regv'][0]) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].regv.label] = this.checkedInvoices[invoice]['regv'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey].regv.label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey].regv.label] = '';
                }
              }
            } catch (e) {}

            // cgstp
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cgstp']) {
                    singleEntry['CGST %'] = this.checkedInvoices[invoice]['page_' + imageIndex]['cgstp'][0];
                  } else if (this.checkedInvoices[invoice]['cgstp']) {
                    singleEntry['CGST %'] = this.checkedInvoices[invoice]['cgstp'][0];
                  } else {
                    singleEntry['CGST %'] = '';
                  }
                } catch (e) {
                  singleEntry['CGST %'] = '';
                }
              }
            } catch (e) {}

            // sgstp
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['sgstp']) {
                    singleEntry['SGST %'] = this.checkedInvoices[invoice]['page_' + imageIndex]['sgstp'][0];
                  } else if (this.checkedInvoices[invoice]['sgstp']) {
                    singleEntry['SGST %'] = this.checkedInvoices[invoice]['sgstp'][0];
                  } else {
                    singleEntry['SGST %'] = '';
                  }
                } catch (e) {
                  singleEntry['SGST %'] = '';
                }
              }
            } catch (e) {}

            // igstp
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gstp.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['igstp']) {
                    singleEntry['IGST %'] = this.checkedInvoices[invoice]['page_' + imageIndex]['igstp'][0];
                  } else if (this.checkedInvoices[invoice]['igstp']) {
                    singleEntry['IGST %'] = this.checkedInvoices[invoice]['igstp'][0];
                  } else {
                    singleEntry['IGST %'] = '';
                  }
                } catch (e) {
                  singleEntry['IGST %'] = '';
                }
              }
            } catch (e) {}

            // cgstv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['cgstv']) {
                    singleEntry['CGST Value'] = this.checkedInvoices[invoice]['page_' + imageIndex]['cgstv'][0];
                  } else if (this.checkedInvoices[invoice]['cgstv']) {
                    singleEntry['CGST Value'] = this.checkedInvoices[invoice]['cgstv'][0];
                  } else {
                    singleEntry['CGST Value'] = '';
                  }
                } catch (e) {
                  singleEntry['CGST Value'] = '';
                }
              }
            } catch (e) {}

            // sgstv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['sgstv']) {
                    singleEntry['SGST Value'] = this.checkedInvoices[invoice]['page_' + imageIndex]['sgstv'][0];
                  } else if (this.checkedInvoices[invoice]['sgstv']) {
                    singleEntry['SGST Value'] = this.checkedInvoices[invoice]['sgstv'][0];
                  } else {
                    singleEntry['SGST Value'] = '';
                  }
                } catch (e) {
                  singleEntry['SGST Value'] = '';
                }
              }
            } catch (e) {}

            // igstv
            try {
              if (this.labelJson[this.selectedDocType.labelKey].gstv.visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['igstv']) {
                    singleEntry['IGST Value'] = this.checkedInvoices[invoice]['page_' + imageIndex]['igstv'][0];
                  } else if (this.checkedInvoices[invoice]['igstv']) {
                    singleEntry['IGST Value'] = this.checkedInvoices[invoice]['igstv'][0];
                  } else {
                    singleEntry['IGST Value'] = '';
                  }
                } catch (e) {
                  singleEntry['IGST Value'] = '';
                }
              }
            } catch (e) {}

            // txav (total tax)
            try {
              if (this.labelJson[this.selectedDocType.labelKey]['txav'].visibility) {
                try {
                  if (this.checkedInvoices[invoice]['page_' + imageIndex]['txav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['txav'].label] =
                      this.checkedInvoices[invoice]['page_' + imageIndex]['txav'][0];
                  } else if (this.checkedInvoices[invoice]['txav']) {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['txav'].label] = this.checkedInvoices[invoice]['txav'][0];
                  } else {
                    singleEntry[this.labelJson[this.selectedDocType.labelKey]['txav'].label] = '';
                  }
                } catch (e) {
                  singleEntry[this.labelJson[this.selectedDocType.labelKey]['txav'].label] = '';
                }
              }
            } catch (e) {}

            // cgst account
            try {
              if (this.checkedInvoices[invoice]['AccountsData']) {
                try {
                  if (this.checkedInvoices[invoice]['AccountsData']['CGST']) {
                    singleEntry['CGST A.C. Value'] = this.checkedInvoices[invoice]['AccountsData']['CGST'];
                  } else {
                    singleEntry['CGST A.C. Value'] = '';
                  }
                } catch (e) {
                  singleEntry['CGST A.C. Value'] = '';
                }
              }
            } catch (e) {}

            // sgst account
            try {
              if (this.checkedInvoices[invoice]['AccountsData']) {
                try {
                  if (this.checkedInvoices[invoice]['AccountsData']['SGST']) {
                    singleEntry['SGST A.C. Value'] = this.checkedInvoices[invoice]['AccountsData']['SGST'];
                  } else {
                    singleEntry['SGST A.C. Value'] = '';
                  }
                } catch (e) {
                  singleEntry['SGST A.C. Value'] = '';
                }
              }
            } catch (e) {}

            // igst account
            try {
              if (this.checkedInvoices[invoice]['AccountsData']) {
                try {
                  if (this.checkedInvoices[invoice]['AccountsData']['IGST']) {
                    singleEntry['IGST A.C. Value'] = this.checkedInvoices[invoice]['AccountsData']['IGST'];
                  } else {
                    singleEntry['IGST A.C. Value'] = '';
                  }
                } catch (e) {
                  singleEntry['IGST A.C. Value'] = '';
                }
              }
            } catch (e) {}

            // lineitems and dataitems
            try {
              for (
                let lineitem = 0; lineitem
                < this.checkedInvoices[invoice]['page_' + imageIndex]['lineItems'].length; lineitem++) {
                  try {

                    // purchase account for each line item
                    // if (this.checkedInvoices[invoice]['AccountsData']['LineItemAccounts'][lineitem]) {
                    //   this.checkedInvoices[invoice]['page_' + imageIndex]['lineItems'][lineitem]['Purchase A.C.']
                    //   = this.checkedInvoices[invoice]['AccountsData']['LineItemAccounts'][lineitem];
                    // } else {
                    //   this.checkedInvoices[invoice]['page_' + imageIndex]['lineItems'][lineitem]['Purchase A.C.'] = '';
                    // }

                    singleEntry['lineitem_' + (lineitem + 1)] =
                    this.checkedInvoices[invoice]['page_' + imageIndex]['lineItems'][lineitem];
                  } catch (e) {
                    continue;
                  }
                }
            } catch (e) {
              console.log(this.checkedInvoices[invoice])
            }

            // const file = {};
            // file[this.filename] = singleEntry;

            this.dataToUpload.push(singleEntry);
            singleEntry = {};
          } catch (e) {
            console.log('error ', e, this.checkedInvoices[invoice]['page_' + imageIndex]);
            continue;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (this.checkedInvoices.length > 0) {
      this.filename = 'records';
    }
    const blob = new Blob([JSON.stringify(this.dataToUpload)], {type : 'application/json'});
    saveAs(blob, this.filename + '.json');
  }

  // select table data (All, Errored, Approved, Autoreject pass, To be reviewed)
  selectView(tableViewIndex) {
    this.tableViewIndex = tableViewIndex;
    this.pageNumber = 1;
    sessionStorage.setItem('table-view-number', tableViewIndex);
    this.complete(); // get data here
  }

  selectTabs(tabNumber) {

    if (this.selectedDocType.text == 'Invoices') {
      this.selectedDocType['text'] = 'Invoices';
      this.selectedDocType['param'] = 'Invoices';
      this.selectedDocType['labelKey'] = 'invoices';
      this.dataService._selectedDocType = this.selectedDocType
      // this.docTypeSelected("Invoices", "Invoices", "invoices", tabNumber);
    } else if (this.selectedDocType.text == 'Receipts') {
      this.selectedDocType['text'] = 'Receipts';
      this.selectedDocType['param'] = 'Receipts';
      this.selectedDocType['labelKey'] = 'receipts';
      // this.docTypeSelected("Receipts", "Receipts", "receipts", tabNumber);
    } else if (this.selectedDocType.text == 'Purchase Orders') {
      this.selectedDocType['text'] = 'Purchase Orders';
      this.selectedDocType['param'] = 'PurchaseOrders';
      this.selectedDocType['labelKey'] = 'purchaseOrder';
      // this.docTypeSelected("Purchase Orders", "PurchaseOrders", "purchaseOrder", tabNumber);
    } else if (this.selectedDocType.text == 'RFQ') {
      this.selectedDocType['text'] = 'RFQ';
      this.selectedDocType['param'] = 'RFQ';
      this.selectedDocType['labelKey'] = 'rfq';
      // this.docTypeSelected("RFQ", "RFQ", "rfq", tabNumber);
    } else if (this.selectedDocType.text == 'Nach') {
      this.selectedDocType['text'] = 'Nach';
      this.selectedDocType['param'] = 'NACH';
      this.selectedDocType['labelKey'] = 'nach';
      // this.docTypeSelected("Nach", "NACH", "nach", tabNumber);
    } else if (this.selectedDocType.text == 'Insurance') {
      this.selectedDocType['text'] = 'Insurance';
      this.selectedDocType['param'] = 'Insurances';
      this.selectedDocType['labelKey'] = 'insurances';
      // this.docTypeSelected("Insurance", "Insurances", "insurances", tabNumber);
    }

    this.tabNumber = tabNumber;

    this.statusPageNumber = 1;
    this.progress = [];
    this.completedFiles = [];
    this.tableMaxed = 'false';

    this.complete();

    sessionStorage.setItem('tab-number', tabNumber.toString());
  }

  pageChanged(event) {
    return new Promise<any>((resolve, reject) => {
      sessionStorage.setItem('page-number', event);
      if (sessionStorage.getItem('searchByFileName') && sessionStorage.getItem('searchByFileName') !== 'false') {

        sessionStorage.setItem('searchByApplicationNo', 'false');
        sessionStorage.setItem('searchByISVFlag', 'false');
        sessionStorage.setItem('searchByUserName', 'false');
        sessionStorage.setItem('searchByMultiParam', 'false');
        this.filterFiles(sessionStorage.getItem('searchByFileName'), event, true);

      } else if (sessionStorage.getItem('searchByISVFlag') && sessionStorage.getItem('searchByISVFlag') !== 'false') {

        sessionStorage.setItem('searchByApplicationNo', 'false');
        sessionStorage.setItem('searchByFileName', 'false');
        sessionStorage.setItem('searchByUserName', 'false');
        sessionStorage.setItem('searchByMultiParam', 'false');
        this.filterSupplier(sessionStorage.getItem('searchByISVFlag'), event, true);

      } else if (sessionStorage.getItem('searchByMultiParam') && sessionStorage.getItem('searchByMultiParam') !== 'false') {

        sessionStorage.setItem('searchByApplicationNo', 'false');
        sessionStorage.setItem('searchByFileName', 'false');
        sessionStorage.setItem('searchByUserName', 'false');
        sessionStorage.setItem('searchByMultiParam', 'true');
        this.filterMultiParam(this.multiParamSearch, event, true);

      } else if (sessionStorage.getItem('searchByUserName') && sessionStorage.getItem('searchByUserName') !== 'false') {

        sessionStorage.setItem('searchByApplicationNo', 'false');
        sessionStorage.setItem('searchByISVFlag', 'false');
        sessionStorage.setItem('searchByFileName', 'false');
        sessionStorage.setItem('searchByMultiParam', 'false');
        this.filterUser(sessionStorage.getItem('searchByUserName'), event, true);

      } else if (sessionStorage.getItem('searchByApplicationNo') && sessionStorage.getItem('searchByApplicationNo') !== 'false') {
        this.filterApplication(sessionStorage.getItem('searchByApplicationNo'), event, true);
      } else {
        sessionStorage.setItem('searchByFileName', 'false');
        sessionStorage.setItem('searchByISVFlag', 'false');
        sessionStorage.setItem('searchByApplicationNo', 'false');
        sessionStorage.setItem('searchByUserName', 'false');
        sessionStorage.setItem('searchByMultiParam', 'false');

        // sessionStorage.getItem('radioBtn') === '1'
        if (this.tableViewIndex == 1) {
          if (this.subscriber) {
            this.subscriber.unsubscribe();
          }
          this.subscriber = this.getpost.getPageResult(this.selectedDocType.param, event).subscribe(
            (res) => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.rawData = res['all_result'];
                  this.UpdateRecordsCount(res['count'], '6472');
                }
              } catch (e) {
                console.log(e);
              }
            },
            (err) => {
              console.log(err);
              reject();
            },
            () => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
                  this.dataService._invoiceList = this.invoiceList;
                  this.commonService.onIsFilterOn(false);
                }
                this.pageNumber = event;
                sessionStorage.setItem('page-number', this.pageNumber.toString());
              } catch (e) {
                console.log(e);
              } finally{
                resolve(true);
              }
            }
          );
        } else if (this.tableViewIndex == 2) {
          if (this.subscriber) {
            this.subscriber.unsubscribe();
          }
          this.subscriber = this.getpost.getErroredRecords(this.selectedDocType.param, event).subscribe(
            (res) => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.rawData = res['all_result'];
                  this.UpdateRecordsCount(res['count'], '6506');
                }
              } catch (e) {
                console.log(e);
              }
            },
            (err) => {
              console.log(err);
              // this.spinner.hide();
            },
            () => {
              try {
                // this.spinner.hide();
                if (this.tabNumber != 5 || true) {
                  this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
                  this.dataService._invoiceList = this.invoiceList;
                  this.commonService.onIsFilterOn(false);
                }
                this.pageNumber = event;
                sessionStorage.setItem('page-number', this.pageNumber.toString());
              } catch (e) {
                console.log(e);
              }
            }
          );
        } else if (this.tableViewIndex == 3) {
          if (this.subscriber) {
            this.subscriber.unsubscribe();
          }
          this.subscriber = this.getpost.getApprovedSavedRecords(this.selectedDocType.param, event, 1, 0).subscribe(
            (res) => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.rawData = res['all_result'];
                  this.UpdateRecordsCount(res['count'], '6539');
                }
              } catch (e) {
                console.log(e);
              }
            },
            (err) => {
              console.log(err);
              // this.spinner.hide();
            },
            () => {
              try {
                // this.spinner.hide();
                if (this.tabNumber != 5 || true) {
                  this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
                  this.dataService._invoiceList = this.invoiceList;
                  this.commonService.onIsFilterOn(false);
                }
                this.pageNumber = event;
                sessionStorage.setItem('page-number', this.pageNumber.toString());
              } catch (e) {
                console.log(e);
              }
            }
          );
        } else if (this.tableViewIndex == 5) {
          if (this.subscriber) {
            this.subscriber.unsubscribe();
          }
          this.subscriber = this.getpost.getApprovedSavedRecords(this.selectedDocType.param, event, 0, 1).subscribe(
            (res) => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.rawData = res['all_result'];
                  this.UpdateRecordsCount(res['count'], '6572');
                }
              } catch (e) {
                console.log(e);
              }
            },
            (err) => {
              console.log(err);
              // this.spinner.hide();
            },
            () => {
              try {
                // this.spinner.hide();
                if (this.tabNumber != 5 || true) {
                  this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
                  this.dataService._invoiceList = this.invoiceList;
                  this.commonService.onIsFilterOn(false);
                }
                this.pageNumber = event;
                sessionStorage.setItem('page-number', this.pageNumber.toString());
              } catch (e) {
                console.log(e);
              }
            }
          );
        } else if (this.tableViewIndex == 4) {
          if (this.subscriber) {
            this.subscriber.unsubscribe();
          }
          this.subscriber = this.getpost.getAutorejectPass(this.selectedDocType.param, event).subscribe(
            (res) => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.rawData = res['all_result'];
                  this.UpdateRecordsCount(res['count'], '6606');
                }
              } catch (e) {
                console.log(e);
              }
            },
            (err) => {
              console.log(err);
            },
            () => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
                  this.dataService._invoiceList = this.invoiceList;
                  this.commonService.onIsFilterOn(false);
                }
                this.pageNumber = event;
                sessionStorage.setItem('page-number', this.pageNumber.toString());
              } catch (e) {
                console.log(e);
              }
            }
          );
        } else if (this.tableViewIndex == 5) {
          if (this.subscriber) {
            this.subscriber.unsubscribe();
          }
          this.subscriber = this.getpost.getAutorejectedRecords(event).subscribe(
            (res) => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.rawData = res['all_result'];
                  this.UpdateRecordsCount(res['count'], '6636');
                }
              } catch (e) {
                console.log(e);
              }
            },
            (err) => {
              console.log(err);
            },
            () => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
                  this.dataService._invoiceList = this.invoiceList;
                  this.commonService.onIsFilterOn(false);
                }
                this.pageNumber = event;
                sessionStorage.setItem('page-number', this.pageNumber.toString());
              } catch (e) {
                console.log(e);
              }
            }
          );
        } else if (this.tableViewIndex == 6) {
          if (this.subscriber) {
            this.subscriber.unsubscribe();
          }
          this.subscriber = this.getpost.getqbestatusdocuments(this.selectedDocType.param, event, 'failed').subscribe(
            (res) => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.rawData = res['all_result'];
                  this.UpdateRecordsCount(res['count'], '6667');
                }
              } catch (e) {
                console.log(e);
              }
            },
            (err) => {
              console.log(err);
            },
            () => {
              try {
                if (this.tabNumber != 5 || true) {
                  this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
                  this.dataService._invoiceList = this.invoiceList;
                  this.commonService.onIsFilterOn(false);
                }
                this.pageNumber = event;
                sessionStorage.setItem('page-number', this.pageNumber.toString());
              } catch (e) {
                console.log(e);
              }
            }
          );
        }
      }
    });
  }

  clearFilters() {
    this.searchByApplicationNo = '';
    this.searchByFileNameString = '';
    this.searchByIsvString = '';
  }

  // fetch saved or approved data from server depending on flag
  // savedOrApprovedFlag is true for approved && false for saved
  getSavedOrApprovedData(savedOrApprovedFlag, pageIndex, refresh?) {
    let count = 0;
    if (savedOrApprovedFlag) {
      if (this.subscriber) {
        this.subscriber.unsubscribe();
      }
      this.subscriber = this.getpost.getApprovedSavedRecords(this.selectedDocType.param, pageIndex, 1, 0).subscribe(
        res => {
          if (this.tabNumber != 5 || refresh) {
                this.rawData = res['all_result'];
              }
          this.refreshComplete = true;
          count = res['count'];
        },
        (err) => {
          this.refreshComplete = true;
          console.log('error fetching data');
        },
        () => {
          if (this.tabNumber != 5 || refresh) {
            this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
            this.dataService._invoiceList = this.invoiceList;
            this.commonService.onIsFilterOn(false);
            this.UpdateRecordsCount(count, '6724');
          }
        }
      );
    } else {
      if (this.subscriber) {
        this.subscriber.unsubscribe();
      }
      this.subscriber = this.getpost.getApprovedSavedRecords(this.selectedDocType.param, pageIndex, 0, 1).subscribe(
        res => {
          if (this.tabNumber != 5 || refresh) {
            this.rawData = res['all_result'];
          }
          count = res['count'];
        },
        (err) => {
          console.log('error fetching data');
        },
        () => {
          if (this.tabNumber != 5 || refresh) {
            this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
            this.dataService._invoiceList = this.invoiceList;
            this.commonService.onIsFilterOn(false);
            this.UpdateRecordsCount(count, '6746');
          }
        }
      );
    }
  }

  downloadOriginalImage() {
    this.getpost.getOriginalImage(this.invoiceList[this.currentInvoiceIndex].file_name, 'Invoices')
      .subscribe(res => {
        this.originalImage = res;
      }, (e) => {},
      () => {
        const blob = new Blob([this.originalImage], {type: 'application/force-download'});
        saveAs(blob, this.invoiceList[this.currentInvoiceIndex].file_name);
      });
  }

  getErroredRecords(page, refresh?) {
    let count = 0;
    if (this.subscriber) {
        this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost.getErroredRecords(this.selectedDocType.param, page).subscribe(res => {
      if (this.tabNumber != 5 || refresh) {
        this.rawData = res['all_result'];
      }
      count = res['count'];
    },
    (err) => {
      this.refreshComplete = true;
    },
    () => {
      this.refreshComplete = true;
      if (this.tabNumber != 5 || refresh) {
        this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
        this.dataService._invoiceList = this.invoiceList;
        this.commonService.onIsFilterOn(false);
        this.UpdateRecordsCount(count, '6783');
      }
    });
  }

  getAutorejectPass(page, refresh?) {
    let count = 0;
    if (this.subscriber) {
        this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost.getAutorejectPass(this.selectedDocType.param, page).subscribe(res => {
      if (this.tabNumber != 5 || refresh) {
        this.rawData = res['all_result'];
      }
        count = res['count'];
    },
    (err) => {
      this.refreshComplete = true;
    },
    () => {
      this.refreshComplete = true;
      if (this.tabNumber != 5 || refresh) {
        this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
        this.dataService._invoiceList = this.invoiceList;
        this.commonService.onIsFilterOn(false);
        this.UpdateRecordsCount(count, '6807');
      }
    });
  }

  getqbestatusdocuments(page, refresh?) {
    let count = 0;
    if (this.subscriber) {
        this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost.getqbestatusdocuments(this.selectedDocType.param, page, 'failed').subscribe(res => {
      if (this.tabNumber != 5 || refresh) {
        this.rawData = res['all_result'];
      }
        count = res['count'];
    },
    (err) => {
      this.refreshComplete = true;
    },
    () => {
      this.refreshComplete = true;
      if (this.tabNumber != 5 || refresh) {
        this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
        this.dataService._invoiceList = this.invoiceList;
        this.commonService.onIsFilterOn(false);
        this.UpdateRecordsCount(count, '6831');
      }
    });
  }

  getAutorejected(page, refresh?) {
    let count = 0;
    if (this.subscriber) {
        this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost.getAutorejectedRecords(page).subscribe(res => {
      if (this.tabNumber != 5 || refresh) {
        this.rawData = res['all_result'];
      }
      count = res['count'];
    },
    (err) => {
      this.refreshComplete = true;
    },
    () => {
      this.refreshComplete = true;
      if (this.tabNumber != 5 || refresh) {
        this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
        this.dataService._invoiceList = this.invoiceList;
        this.commonService.onIsFilterOn(false);
        this.UpdateRecordsCount(count, '6855');
      }
    });
  }

  filterFiles(inputString, pageIndex?, refresh?) {
    this.subscriber.unsubscribe();
    this.stopAutoLoad = true;
    if (!pageIndex) {
      pageIndex = '1';
    }
    this.searchByFileNameString = inputString.split('\\').join('\\\\');
    sessionStorage.setItem('searchByFileName', this.searchByFileNameString);
    if (!this.searchByIsvString) {
      this.searchByIsvString = '';
    }
    if(!this.searchByApplicationNo) {
      this.searchByApplicationNo = '';
    }
    if (!this.searchByFileNameString) {
      this.complete();
      return;
    }
    let count;
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost
    .getfilterdocuments(this.selectedDocType.param, this.searchByIsvString, this.searchByFileNameString, this.searchByApplicationNo, pageIndex)
    .subscribe(
      res => {
        if (this.tabNumber != 5 || refresh) {
          this.rawData = res['all_result'];
        }
        count = res['count'];
      },
      (err) => {

      },
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        if (this.tabNumber != 5 || refresh) {
          this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
          this.dataService._invoiceList = this.invoiceList;
          this.commonService.onIsFilterOn(true);
          this.UpdateRecordsCount(count, '6900');
        }
      }
    );
  }

  filterSupplier(inputString, pageIndex?, refresh?) {
    this.subscriber.unsubscribe();
    this.stopAutoLoad = true;
    this.searchByIsvString = inputString;
    sessionStorage.setItem('searchByISVFlag', this.searchByIsvString);
    if (!this.searchByFileNameString) {
      this.searchByFileNameString = '';
    }
    if (!this.searchByApplicationNo) {
      this.searchByApplicationNo = '';
    }
    if (!pageIndex) {
      pageIndex = '1';
    }
    let count;
    if (this.subscriber) {
        this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost
    .getfilterdocuments(this.selectedDocType.param, this.searchByIsvString, this.searchByFileNameString, this.searchByApplicationNo, pageIndex)
    .subscribe(
      res => {
        if (this.tabNumber != 5 || refresh) {
          this.rawData = res['all_result'];
        }
        count = res['count'];
      },
      (err) => {},
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        if (this.tabNumber != 5 || refresh) {
          this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
          this.dataService._invoiceList = this.invoiceList;
          this.commonService.onIsFilterOn(true);
          this.UpdateRecordsCount(count, '6940');
        }
      }
    );
  }

  filterMultiParam(event, pageIndex?, refresh?) {
    this.subscriber.unsubscribe();
    this.multiParamSearch = event;
    this.stopAutoLoad = true;
    this.searchByIsvString = '';
    sessionStorage.setItem('searchByMultiParam', 'true');
    if (!this.searchByFileNameString) {
      this.searchByFileNameString = '';
    }
    if (!this.searchByApplicationNo) {
      this.searchByApplicationNo = '';
    }
    if (!pageIndex) {
      pageIndex = '1';
    }
    let count;
    if (this.subscriber) {
        this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost
    .getmultipleFilters(
      this.multiParamSearch.date,
      this.multiParamSearch.dateType,
      this.multiParamSearch.username,
      this.multiParamSearch.isv,
      this.multiParamSearch.recordsPerPage,
      pageIndex
      )
    .subscribe(
      res => {
        if (this.tabNumber != 5 || refresh) {
          this.rawData = res['all_result'];
        }
        count = res['count'];
      },
      (err) => {},
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        if (this.tabNumber != 5 || refresh) {
          this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
          this.dataService._invoiceList = this.invoiceList;
          this.commonService.onIsFilterOn(true);
          this.UpdateRecordsCount(count, '6991');
        }
      }
    );
  }

  filterApplication(event, pageIndex?, refresh?) {

    if (!pageIndex) {
      pageIndex = '1';
    }

    if (event && event.charAt(0) !==  '0') {
      event = '0' + event;
    }

    this.searchByApplicationNo = event;
    sessionStorage.setItem('searchByApplicationNo', this.searchByApplicationNo);
    if (!this.searchByIsvString) {
      this.searchByIsvString = '';
    }
    if (!this.searchByFileNameString) {
      this.searchByFileNameString = '';
    }
    let count;
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost
    .getfilterdocuments(this.selectedDocType.param, this.searchByIsvString, this.searchByFileNameString, this.searchByApplicationNo, pageIndex)
    .subscribe(
      res => {
        try {
          if (this.tabNumber != 5 || refresh) {
            this.rawData = res['all_result'];
          }
          count = res['count'];
        } catch (e) {}
      },
      (err) => {},
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        if (this.tabNumber != 5 || refresh) {
          this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
          this.dataService._invoiceList = this.invoiceList;
          this.commonService.onIsFilterOn(true);
          this.UpdateRecordsCount(count, '7038');
        }
      }
    );
  }

  // filter by user names
  filterUser(inputString, pageIndex?, refresh?) {
    try {
      this.subscriber.unsubscribe();
    } catch (e) {}
    this.stopAutoLoad = true;
    if (!pageIndex) {
      pageIndex = '1';
    }
    this.searchByUserName = inputString.split('\\').join('\\\\');
    sessionStorage.setItem('searchByUserName', this.searchByUserName);
    if (!this.searchByFileNameString) {
      this.searchByFileNameString = '';
    }
    if (!this.searchByIsvString) {
      this.searchByIsvString = '';
    }
    if(!this.searchByApplicationNo) {
      this.searchByApplicationNo = '';
    }
    if (!this.searchByUserName) {
      this.complete();
      return;
    }
    let count;
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost
    .getuserinvoicesfilter(this.selectedDocType.param, [this.searchByUserName], pageIndex)
    .subscribe(
      res => {
        if (this.tabNumber != 5 || refresh) {
          this.rawData = res['all_result'];
        }
        count = res['count'];
      },
      (err) => {},
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        if (this.tabNumber != 5 || refresh) {
          this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 112);
          this.dataService._invoiceList = this.invoiceList;
          this.commonService.onIsFilterOn(true);
          this.UpdateRecordsCount(count, '7088');
        }
      }
    );
  }

  //============================================================================================================================
  // detailed view functions
  exitDetaileidview() {
    if(this.dataChanged) {
      this.stopSpinner('hide');
      this.displayPopup('backToRecords');
    } else {
      this.stopSpinner('hide');
      this.tabNumber = sessionStorage.getItem('previous-tab');
      if (!this.tabNumber){
        this.tabNumber = 1;
      }
      this.docIsOpened = false;
      this.filename = null;
      this.imageIndex = 0;
      this.totalRecordsCount = new Array(this.dataService._totalListCount);
      this.selectTabs(this.tabNumber);
    }
  }

  currentPage(event) {
    this.imageIndex = event;
  }

  changePage (direction: number) {
    this.eventService.onPageChange(direction);
  }

  // check if any changes in data
  isDataChanged(event) {
    if (event === 'true') {
      this.dataChanged = true;
    } else {
      this.dataChanged = false;
    }
  }

  // toggle on table size changes
  tableMaxedChanged(event) {
    this.tableMaxed = event;
  }

  ocrIsOn(event) {
    this.ocrIsOnFlag = event;
  }

  openSnackBar(message, value) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (value));
      if (e.target['id'] && ['copy-filename', 'copy-application-no'].includes(e.target['id'])) {
        e.preventDefault();
      }
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this._snackBar.open(message, '', {
      duration: 2000,
    });
  }
}

@Component({
  selector: 'snack-bar',
  templateUrl: 'snack-bar-component.html',
  styles: [`
    .example-pizza-party {
      color: hotpink;
    }
  `],
})
export class SnackBarComponent {}
