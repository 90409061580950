/*************************
 *
 * MAVENICK CONFIDENTIAL
 * ______
 *
 *  [2020] - Mavenick Consulting OPC Pvt Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mavenick Consulting OPC Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Mavenick Consulting OPC Pvt Ltd
 * and its suppliers and may be covered by India, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mavenick Consulting OPC Pvt Ltd.
 */


import { Component, Input, OnInit, EventEmitter, Output, HostListener, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../common.service';
import { DataStructureService } from '../data-structure.service';
import { FileHandle } from '../dragDrop.directive';
import { GetpostService } from '../getpost.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class TablesComponent implements OnInit, OnChanges {

  @Input() labelJson: any;
  @Input() pageNumber: any;
  @Input() currentInvoiceIndex: any;
  @Input() refreshComplete;
  @Input() rotation = 0;
  @Input() selectedDocType = {text: '', param: '', labelKey: ''};
  @Input() totalRecordsCount = [];
  @Input() invoiceList = [];
  @Input() operationComplete;

  @Output() pageChangedEvent = new EventEmitter();
  @Output() tableViewChanged = new EventEmitter();
  @Output() clearFilters = new EventEmitter();
  @Output() refreshBecon = new EventEmitter();
  @Output() notification = new EventEmitter();
  @Output() uploadbecon = new EventEmitter();
  @Output() operationChanged = new EventEmitter();
  @Output() openDocumentBecon = new EventEmitter();
  @Output() searchByFileBecon = new EventEmitter<object>();
  @Output() searchByMultiParamBecon = new EventEmitter();
  @Output() searchByApplicationNoBecon = new EventEmitter();
  @Output() searchBySupplierBecon = new EventEmitter();
  @Output() stopAutoReloding = new EventEmitter();


  checkedRecord = [];
  recordsPerPage = this.dataService._recordsPerPage;
  checkedCount: number;
  areAllChecked: boolean;
  tabNumber: any;
  tableViewIndex: any;
  subscriber: Subscription;
  config: any;
  partialListSelected = false;
  messageText: string;
  doNotShowAgainFlag: boolean = false;
  popup: boolean;
  scrHeight: number;
  scrWidth: number;
  operation: any;
  checkedInvoices: any[];
  clickEvent: any;
  uploadComplete: boolean;
  file: File;
  file_name: string;
  fileSizeLimit: number;
  reader = new FileReader();
  imagePath: any;
  selectedInvoiceIds: any[];
  dataToUpload: any = [];
  showAllCount: number;
  errorOnlyCount: number;
  approvedCount: number;
  autoPassCount: number;
  toBeReveived: number;
  autoRejecedCount: number;
  qbeStatusCount: number;
  searchField: string;
  searchByApplicationNo: string;
  searchString: any = {applicationNo: [], fileName: [], username: [], isv: [], date: [], dateType: 'lessThan'};
  rawData: any;
  userRole: string;
  selectedRow: any;

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
  }

  @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
      const target = {localName: []};
      target['target'] = event.target;
      if (!event.shiftKey && !event.ctrlKey) {
        switch (event.key.toString()) {
          case 'ArrowLeft':
            if (this.pageNumber > 1) {
              this.pageChanged(parseInt(this.pageNumber, 10) - 1)
            }
            event.preventDefault();
            break;
          case 'ArrowRight':
            if (this.pageNumber < this.returnLastPage()) {
              this.pageChanged(parseInt(this.pageNumber, 10) + 1)
            }
            event.preventDefault();
        }
      } else if (event.ctrlKey && !event.shiftKey) {
        switch (event.key.toString()) {
          case 's': case 'S':
            // your function here
            event.preventDefault();
            break;
          case 'r': case 'R':
            this.refresh();
            event.preventDefault();
        }
      } else if (event.ctrlKey && event.shiftKey) {
      } else {
        if (target['target'].localName === 'input' || target['target'].isContentEditable) {
        } else {
          switch (event.key) {
            case 'ArrowLeft':
              // if (this.popup) {

              // } else {
              //   this.checkChangesForPrev(event);
              // }
              event.preventDefault();
            break;
            case 'ArrowRight':
              // if (this.popup) {

              // } else {
              //   this.checkChangesForNext(event);
              // }
              event.preventDefault();
            break;
            case 'Escape':
            break;
          }
        }
      }
  }

  constructor(
    public dialog: MatDialog,
    private getpost: GetpostService,
    private ds: DataStructureService,
    private commonService: CommonService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.userRole = sessionStorage.getItem('rolename');
    this.recordsPerPage = this.dataService._recordsPerPage;
    this.initializeValues();
    this.getAllViewCounts();
  }

  //replace function here
  replaceFunction() {
    let selectedDoctype;
    if (this.selectedDocType.labelKey == 'invoices') {
      selectedDoctype = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '1px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'number',
          fieldName: 'overall_confidence',
          text: 'Overall confidence',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'application_no',
          text: this.labelJson[this.selectedDocType.labelKey].application_no.label,
          minWidth: '115px',
          visibility: this.labelJson[this.selectedDocType.labelKey].application_no.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: this.labelJson[this.selectedDocType.labelKey].file_name.label,
          minWidth: '270px',
          visibility: this.labelJson[this.selectedDocType.labelKey].file_name.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'inv',
          text: this.labelJson[this.selectedDocType.labelKey].invoiceNumber.label,
          minWidth: '120px',
          visibility: this.labelJson[this.selectedDocType.labelKey].invoiceNumber.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'isv',
          text: this.labelJson[this.selectedDocType.labelKey].supplierName.label,
          minWidth: '260px',
          visibility: this.labelJson[this.selectedDocType.labelKey].supplierName.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'idv',
          text: this.labelJson[this.selectedDocType.labelKey].invoiceDate.label,
          minWidth: '100px',
          visibility: this.labelJson[this.selectedDocType.labelKey].invoiceDate.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'itv',
          text: this.labelJson[this.selectedDocType.labelKey].total.label,
          minWidth: '75px',
          visibility: this.labelJson[this.selectedDocType.labelKey].total.visibility
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: this.labelJson[this.selectedDocType.labelKey].creation_date.label,
          minWidth: '100px',
          visibility: this.labelJson[this.selectedDocType.labelKey].creation_date.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: 0,
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: 0,
          visibility: true
        }
      ];
    } else if (this.selectedDocType.labelKey == 'receipts') {
      selectedDoctype = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'number',
          fieldName: 'overall_confidence',
          text: 'Overall confidence',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'File name',
          minWidth: '270px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'inv',
          text: 'Invoice number',
          minWidth: '110px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'isv',
          text: 'Supplier name',
          minWidth: '260px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'idv',
          text: 'Invoice date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'itv',
          text: 'Total',
          minWidth: '75px',
          visibility: true
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: 'Creation date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: '0',
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (this.selectedDocType.labelKey == 'purchaseOrder') {
      selectedDoctype = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'number',
          fieldName: 'overall_confidence',
          text: 'Overall confidence',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: this.labelJson[this.selectedDocType.labelKey].file_name.label,
          minWidth: '270px',
          visibility: this.labelJson[this.selectedDocType.labelKey].file_name.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'ipv',
          text: this.labelJson[this.selectedDocType.labelKey].poNumber.label,
          minWidth: '110px',
          visibility: this.labelJson[this.selectedDocType.labelKey].poNumber.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'cnv',
          text: this.labelJson[this.selectedDocType.labelKey].cnv.label,
          minWidth: '260px',
          visibility: this.labelJson[this.selectedDocType.labelKey].cnv.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'idv',
          text: 'PO date',
          minWidth: '100px',
          visibility: true,
        },
        {
          fieldType: 'string',
          fieldName: 'itv',
          text: this.labelJson[this.selectedDocType.labelKey].total.label,
          minWidth: '75px',
          visibility: this.labelJson[this.selectedDocType.labelKey].total.visibility,
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: this.labelJson[this.selectedDocType.labelKey].creation_date.label,
          minWidth: '100px',
          visibility: this.labelJson[this.selectedDocType.labelKey].creation_date.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: '0',
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (this.selectedDocType.labelKey == 'autorejected') {
      selectedDoctype = [
        {
          fieldType: 'string',
          fieldName: 'application_no',
          text: this.labelJson[this.selectedDocType.labelKey].application_no.label,
          minWidth: '0',
          visibility: this.labelJson[this.selectedDocType.labelKey].application_no.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: this.labelJson[this.selectedDocType.labelKey].file_name.label,
          minWidth: '0',
          visibility: this.labelJson[this.selectedDocType.labelKey].file_name.visibility,
        },
        {
          fieldType: 'date',
          fieldName: 'date',
          text: this.labelJson[this.selectedDocType.labelKey].date.label,
          minWidth: '0',
          visibility: this.labelJson[this.selectedDocType.labelKey].date.visibility,
        },
        {
          fieldType: 'string',
          fieldName: 'reject_reson',
          text: this.labelJson[this.selectedDocType.labelKey].reject_reason.label,
          minWidth: '0',
          visibility: this.labelJson[this.selectedDocType.labelKey].reject_reason.visibility
        }
      ];
    } else if (this.selectedDocType.labelKey == 'rfq') {
      selectedDoctype = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'number',
          fieldName: 'overall_confidence',
          text: 'Overall confidence',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'File name',
          minWidth: '270px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'qiv',
          text: 'Quote Id',
          minWidth: '110px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'pnv',
          text: 'Product Name',
          minWidth: '260px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'sidv',
          text: 'Start Date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'eidv',
          text: 'End Date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: 'Creation date',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: 0,
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (this.selectedDocType.labelKey == 'nach') {
      selectedDoctype = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'File name',
          minWidth: '230px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'ntype',
          text: 'Type',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'nrn1',
          text: 'Ref Number1',
          minWidth: '100px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'nsef',
          text: 'Signature Exist',
          minWidth: '105px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'nbnef',
          text: 'Bank Name Exist',
          minWidth: '110px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'nan',
          text: 'A/C No.',
          minWidth: '140px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'niq',
          text: 'Image Quality',
          minWidth: '260px',
          visibility: true
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: 'Creation date',
          minWidth: '160px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: '80px',
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (this.selectedDocType.labelKey == 'insurances') {
      selectedDoctype = [
        {
          fieldType: 'indicator',
          fieldName: 'dotMarker',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'checkBox',
          fieldName: 'selectInvoice',
          text: '',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'File name',
          minWidth: '230px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'cnv',
          text: this.labelJson[this.selectedDocType.labelKey].cnv.label,
          minWidth: '269px',
          visibility: this.labelJson[this.selectedDocType.labelKey].cnv.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'idv',
          text: this.labelJson[this.selectedDocType.labelKey].idv.label,
          minWidth: '100px',
          visibility: this.labelJson[this.selectedDocType.labelKey].idv.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'mnv',
          text: this.labelJson[this.selectedDocType.labelKey].mnv.label,
          minWidth: '200px',
          visibility: this.labelJson[this.selectedDocType.labelKey].mnv.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'itv',
          text: this.labelJson[this.selectedDocType.labelKey].itv.label,
          minWidth: '100px',
          visibility: this.labelJson[this.selectedDocType.labelKey].itv.visibility
        },
        {
          fieldType: 'string',
          fieldName: 'ihv',
          text: this.labelJson[this.selectedDocType.labelKey].ihv.label,
          minWidth: '100px',
          visibility: this.labelJson[this.selectedDocType.labelKey].ihv.visibility
        },
        {
          fieldType: 'date',
          fieldName: 'formated_date',
          text: 'Creation date',
          minWidth: '160px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'username',
          text: 'Owner',
          minWidth: '80px',
          visibility: true
        },
        {
          fieldType: 'icon',
          fieldName: 'options',
          text: '',
          minWidth: '0',
          visibility: true
        }
      ];
    } else if (this.selectedDocType.labelKey == 'uploadStatus') {
      selectedDoctype = [
        {
          fieldType: 'string',
          fieldName: 'dotMarker',
          text: 'BATCH NAME',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'selectInvoice',
          text: 'UPLOAD TIME',
          minWidth: '20px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'overall_confidence',
          text: 'COMPLETION TIME',
          minWidth: '1px',
          visibility: true
        },
        {
          fieldType: 'string',
          fieldName: 'file_name',
          text: 'STATUS',
          minWidth: '269px',
          visibility: true
        }
      ];
    }
    try {
      for (let i = 0; i < selectedDoctype.length; i++) {
        if (!selectedDoctype[i].visibility) {
          selectedDoctype.splice(i--, 1)
        }
      }
    } catch (e) {
      console.log('selected doctype array:', selectedDoctype)
    }

    this.config = {};
    this.config[this.selectedDocType.labelKey] = selectedDoctype;
  }

  ngOnChanges(event) {
    this.getAllViewCounts();
    this.config = {};
    this.config[this.selectedDocType.labelKey] = this.commonService.getVisibleFieldsForGrid(this.labelJson);

    if (this.operationComplete) {

      CommonService.checkedCount = 0;
      CommonService.checkedRecord = [];
      this.checkedCount = 0;
      this.checkedInvoices = [];
      this.checkedRecord = [];
      this.areAllChecked = false;

      this.initializeValues();
      this.operationChanged.emit('false');

    }

  }

  setMappingJson(tempJson) {
    let keys;
    this.labelJson = tempJson[this.selectedDocType.labelKey];
    keys = Object.keys(this.labelJson);
  }

  initializeValues() {
    if (location.hostname.toLowerCase().includes('idfc')) {
      this.fileSizeLimit = 10;
    } else {
      this.fileSizeLimit = 15;
    }
    this.tableViewIndex = sessionStorage.getItem('table-view-number')
    if(!this.tableViewIndex){
      this.tableViewIndex = 1;
      sessionStorage.setItem('table-view-number', '1');
    }
    this.tabNumber = sessionStorage.getItem('tab-number')
    if(!this.tabNumber){
      this.tabNumber = 1;
      sessionStorage.setItem('tab-number', '1');
    }
  }

  openDocument(docIndex, type) {
    if (type == 'checkBox' || type == 'icon') {}
    else {
      this.openDocumentBecon.emit(docIndex)
    }
  }

  searchBy(fieldNameToSearch: string) {
    this.searchField = fieldNameToSearch;
    // this.searchByFileBecon.emit('true');
  }

  changeSearchField(field) {
    switch(field) {
      case 'isv' :
        if (sessionStorage.getItem('searchByISVFlag') && sessionStorage.getItem('searchByISVFlag') != 'false') {
          this.searchString.isv[0] = sessionStorage.getItem('searchByISVFlag');
        } else {
          this.searchString.isv[0] = '';
        }
      break;
      case 'file_name' :
        if (sessionStorage.getItem('searchByFileName') && sessionStorage.getItem('searchByFileName') != 'false') {
          this.searchString.fileName[0] = sessionStorage.getItem('searchByFileName');
        } else {
          this.searchString.fileName[0] = '';
        }
      break;
      case 'application_no' :
        if (sessionStorage.getItem('searchByApplicationNo') && sessionStorage.getItem('searchByApplicationNo') != 'false') {
          this.searchString.applicationNo[0] = sessionStorage.getItem('searchByApplicationNo');
        } else {
          this.searchString.applicationNo[0] = '';
        }
      break;
      case 'username' :
        if (sessionStorage.getItem('searchByUserName') && sessionStorage.getItem('searchByUserName') != 'false') {
          this.searchString.username[0] = sessionStorage.getItem('searchByUserName');
        } else {
          this.searchString.username[0] = '';
        }
      break;
    }
  }

  searchDocument(event) {
    switch(this.searchField) {
      case 'file_name':
        if (event.target.value == ''){
          this.refresh();
          return;
        }
        this.searchString.fileName[0] = event.target.value;
        this.filterFiles(this.searchField);
        // this.searchByFileBecon.emit(event.target.value)
        break;
      case 'isv':
        if (event.target.value == ''){
          this.refresh();
          return;
        }
        this.searchString.isv[0] = event.target.value;
        this.filterSupplier(this.searchField);
        // this.searchBySupplierBecon.emit(event.target.value)
        break;
      case 'application_no':
        const inputField = document.getElementById('application_no-search') as HTMLInputElement;
        let numArray = [null, '0','1','2','3','4','5','6','7','8','9'];
        let inputArray = inputField.value.split('');

        if (event.inputType === 'insertText') {
          if (!numArray.includes(event.data)) {
            inputField.value = this.searchByApplicationNo;
            return;
          }
        } else if (event.inputType === 'insertFromPaste') {
          inputArray.forEach(item => {
            if (!numArray.includes(item)) {
              inputField.value = '';
              return;
            }
          })
        }
        this.searchString.applicationNo[0] = inputField.value;
        sessionStorage.setItem('searchByApplicationNo', inputField.value)
        if (inputField.value == ''){
          this.refresh();
          return;
        }
        this.filterApplication(event)
        // this.searchByApplicationNoBecon.emit(this.searchString.applicationNo[0])
      break;
      case 'username':
        if (event.target.value == ''){
          this.refresh();
          return;
        }
        this.searchString.username[0] = event.target.value;
        this.filterUser(this.searchField);
      break;
    }
  }

  filterFiles(inputString, pageIndex?) {
    this.rawData;
    if (!pageIndex) {
      pageIndex = '1';
    }
    let count;

    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }

    sessionStorage.setItem('searchByFileName', this.searchString.fileName[0]);
    sessionStorage.setItem('searchByISVFlag', 'false');
    sessionStorage.setItem('searchByApplicationNo', 'false');
    sessionStorage.setItem('searchByUserName', 'false');

    this.searchString.username = [];
    this.searchString.isv = [];
    this.searchString.applicationNo = [];
    this.searchString.date = [];

    this.subscriber = this.getpost
    .getfilterdocuments(this.selectedDocType.param, '', this.searchString.fileName[0], '', pageIndex)
    .subscribe(
      res => {
        this.rawData = res['all_result'];
        count = res['count'];
      },
      (err) => {

      },
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 2709);
        this.dataService._invoiceList = this.invoiceList;
        this.commonService.onIsFilterOn(true);
        this.UpdateRecordsCount(count);
      }
    );
  }

  filterSupplier(inputString, pageIndex?) {
    this.rawData;
    if (!pageIndex) {
      pageIndex = '1';
    }
    let count;
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }

    sessionStorage.setItem('searchByFileName', 'false');
    sessionStorage.setItem('searchByISVFlag', this.searchString.isv[0]);
    sessionStorage.setItem('searchByApplicationNo', 'false');
    sessionStorage.setItem('searchByUserName', 'false');

    this.searchString.username = [];
    this.searchString.fileName = [];
    this.searchString.applicationNo = [];
    this.searchString.date = [];

    this.subscriber = this.getpost
    .getfilterdocuments(this.selectedDocType.param, this.searchString.isv[0], '', '', pageIndex)
    .subscribe(
      res => {
        this.rawData = res['all_result'];
        count = res['count'];
      },
      (err) => {},
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 2709);
        this.dataService._invoiceList = this.invoiceList;
        this.commonService.onIsFilterOn(true);
        this.UpdateRecordsCount(count);
      }
    );
  }

  filterApplication(event, pageIndex?) {

    this.rawData;

    if (!pageIndex) {
      pageIndex = '1';
    }

    if (event && event.toString().charAt(0) !==  '0') {
      event = '0' + event;
    }

    this.searchByApplicationNo = event;

    sessionStorage.setItem('searchByFileName', 'false');
    sessionStorage.setItem('searchByISVFlag', 'false');
    sessionStorage.setItem('searchByApplicationNo', this.searchString.applicationNo[0]);
    sessionStorage.setItem('searchByUserName', 'false');

    this.searchString.username = [];
    this.searchString.fileName = [];
    this.searchString.isv = [];
    this.searchString.date = [];

    let count;
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
    this.subscriber = this.getpost
    .getfilterdocuments(this.selectedDocType.param, '', '', this.searchString.applicationNo[0], pageIndex)
    .subscribe(
      res => {
        try {
          this.rawData = res['all_result'];
          count = res['count'];
        } catch (e) {}
      },
      (err) => {},
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 2709);
        this.dataService._invoiceList = this.invoiceList;
        this.commonService.onIsFilterOn(true);
        this.UpdateRecordsCount(count);
      }
    );
  }

  filterUser(inputString, pageIndex?) {
    this.rawData;
    if (!pageIndex) {
      pageIndex = '1';
    }

    let count;
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }

    sessionStorage.setItem('searchByFileName', 'false');
    sessionStorage.setItem('searchByISVFlag', 'false');
    sessionStorage.setItem('searchByApplicationNo', 'false');
    sessionStorage.setItem('searchByUserName', this.searchString.username[0]);

    this.searchString.isv = [];
    this.searchString.fileName = [];
    this.searchString.applicationNo = [];
    this.searchString.date = [];

    this.subscriber = this.getpost
    .getuserinvoicesfilter(this.selectedDocType.param, [this.searchString.username[0]], pageIndex)
    .subscribe(
      res => {
        this.rawData = res['all_result'];
        count = res['count'];
      },
      (err) => {},
      () => {
        this.pageNumber = pageIndex;
        sessionStorage.setItem('page-number', this.pageNumber.toString());
        this.invoiceList = this.ds.processData(this.rawData, this.selectedDocType.param, 2709);
        this.dataService._invoiceList = this.invoiceList;
        this.commonService.onIsFilterOn(true);
        this.UpdateRecordsCount(count);
      }
    );
  }

  isFilterOn() {
    return this.commonService.isFilterOn;
  }

  // ******************************** Animation for table when file is dropped *******************
  droped(files: FileHandle[]) {
    this.dragLeave();
    if (sessionStorage.getItem('processing_pref') === 'unset') {
      alert('Select media type');
      return;
    }
    const supportedFileTypes =
    ['pdf', 'tiff', 'jpeg', 'jpg', 'png', 'bmp', 'PDF', 'TIFF', 'PNG', 'JPEG', 'JPG', 'BMP'];

    try {
      if (files.length > 1) {
        alert('Multiple files detected.');
        return;
      } else {
        const extension = files[0].file.name.split('.')[
          files[0].file.name.split('.').length - 1
        ];
        this.file = files[0].file;

        // check file size limit
        if (this.file.size / (1024 * 1024) > this.fileSizeLimit) {
          alert('File size limit ('+ this.fileSizeLimit +' MB) exceeds');
          this.file = null;
          return;
        }

        CommonService.file = files[0].file;

        if (extension === 'zip') {
          if (this.labelJson.notification.invoiceType.visibility) {
            this.notification.emit('b2bOrB2cBatch')
          } else {
            // alert('uploading zip file directly as ' + this.labelJson.notification.invoiceType.value);
            this.uploadbecon.emit('batch');
          }
        } else if (supportedFileTypes.indexOf(extension) === -1) {
          alert('File type not supported');
        } else {
          if (this.labelJson.notification.invoiceType.visibility) {
            this.messageText = 'Select type of Invoice';
            this.notification.emit('b2bOrB2cInvoice');
          } else {
            // alert('uploading doc file directly as ' + this.labelJson.notification.invoiceType.value);
            this.uploadbecon.emit('file');
          }
        }
      }
    } catch (e) { console.log(e); }
  }

  // ******************************** Animation for table when file is dragged over it ***********
  dragged() {
    const height = document.getElementById('recordTable').clientHeight;
    const width = document.getElementById('recordTable').clientWidth;
    document.getElementById('dragDropContainer').style.visibility = 'visible';
    document.getElementById('dragDropContainer').style.height = height.toString() + 'px';
    document.getElementById('dragDropContainer').style.width = width.toString() + 'px';
    document.getElementById('dragDropContainer').style.visibility = 'visible';
  }

  // ******************************** Animation for table when file is dragged away **************
  dragLeave() {
    document.getElementById('dragDropContainer').style.visibility = 'hidden';
  }

  trackInvoice(index: number) {
    return index;
  }

  // display message of confirmation
  displayPopup(operation, extraParam?) {
    this.popup = true;
    this.operation = operation;
    switch (operation) {
      case 'local':
        if (extraParam >= 0) {
          this.checkedRecord[extraParam] = true;
          this.checkedCount++;
          CommonService.checkedRecord = this.checkedRecord;
        }
        this.notification.emit(operation);
        this.messageText = 'Only approved invoices will be downloaded. Do you want to proceed ?';
      break;
      case 'download-cloud':
        if (extraParam >= 0) {
          this.checkedRecord[extraParam] = true;
          this.checkedCount++;
          CommonService.checkedRecord = this.checkedRecord;
        }
        this.notification.emit(operation);
        this.messageText = 'Only approved invoices will be downloaded. Do you want to proceed ?';
      break;
      case 'delete':
        if (extraParam >= 0) {
          this.checkedRecord[extraParam] = true;
          this.checkedCount++;
          CommonService.checkedRecord = this.checkedRecord;
        }
        this.notification.emit(operation);
        this.messageText = 'Do you really want to delete selected invoices ?';
      break;
      case 'downloadReport':
        if (extraParam >= 0) {
          this.checkedRecord[extraParam] = true;
          this.checkedCount++;
          CommonService.checkedRecord = this.checkedRecord;
        }
        this.notification.emit(operation);
        this.messageText = 'Do you really want to delete selected invoices ?';
      break;
      case 'markApproved':
        if (extraParam >= 0) {
          this.checkedRecord[extraParam] = true;
          this.checkedCount++;
          CommonService.checkedRecord = this.checkedRecord;
        }
        this.notification.emit(operation);
        this.messageText = 'Do you want to approve selected invoices ?';
      break;
    }
  }

  saveClickEvent(event) {
    this.clickEvent = event;
  }

  refresh(recordsPerPage?, event?: MouseEvent) {
    this.searchString.username = [];
    this.searchString.isv = [];
    this.searchString.applicationNo = [];
    this.searchString.date = [];
    this.searchString.fileName = [];
    this.searchField = '';
    this.stopAutoReloding.emit('false')
    if (recordsPerPage) {
      this.recordsPerPage = recordsPerPage;
    }
    this.recordsPerPage = this.dataService._recordsPerPage;
    this.clearFilters.emit();
    this.refreshBecon.emit('');
    this.initializeValues();
    this.getAllViewCounts();
    if (event) {
      event.stopPropagation()
    }
  }

  // ******************************* Review multiple invoices at a time **************************
  multiReview() {
    let isApprovedIsChecked;
    this.checkedInvoices = [];
    for (let i = 0; i < this.invoiceList.length; i++) {
      if (this.checkedRecord[i]) {
        if (this.invoiceList[i].is_approved === 0) {
          this.checkedInvoices.push(this.invoiceList[i]['obj_id']);
        } else {
          isApprovedIsChecked = true;
        }
      }
    }
    if (this.checkedInvoices.length === 0 && isApprovedIsChecked) {
      alert('Already marked as reviewed');
    } else if (this.checkedInvoices.length === 0) {
      alert('Please select at least one invoice to mark as reviewed.');
    } else {
      this.getpost.reviewAll(this.checkedInvoices, this.selectedDocType.param).subscribe(
        res => {
        },
        err => {},
        () => {
          for(let i = 0; i < this.recordsPerPage; i++) {
            this.checkedRecord.push(false);
          }
          this.areAllChecked = false;
          this.checkedCount = 0;
        }
      );
    }
  }

  checkBoxClicked(event, index) {
    this.checkedRecord[index] = event.checked;
    this.checkedCount = 0;
    this.checkedRecord.forEach(item=>{
      if(item) {
        this.checkedCount++;
      }
    });
    CommonService.checkedRecord = this.checkedRecord;
    CommonService.checkedCount = this.checkedCount;
    if (this.checkedCount > 0) {
      this.stopAutoReloding.emit('true');
    } else {
      this.stopAutoReloding.emit('false');
    }
  }

  selectAll(event) {
    for (let i = 0; i < this.invoiceList.length; i++) {
      this.checkedRecord[i] = event.checked;
    }
    if (event.checked) {
      this.checkedCount = this.invoiceList.length;
    } else {
      this.checkedCount = 0;
    }
    CommonService.checkedRecord = this.checkedRecord;
    CommonService.checkedCount = this.checkedCount;
    if (this.checkedCount > 0) {
      this.stopAutoReloding.emit('true');
    } else {
      this.stopAutoReloding.emit('false');
    }
  }

  selectView(tableViewIndex) {
    // this.searchString.username = [];
    // this.searchString.isv = [];
    // this.searchString.applicationNo = [];
    // this.searchString.date = [];
    // this.searchString.fileName = [];
    // this.searchField = '';
    this.dataService._recordsPerPage = 10;
    this.refresh();
    this.clearFilters.emit();
    this.tableViewChanged.emit(tableViewIndex);
    this.tableViewIndex = tableViewIndex;
  }

  getAllViewCounts() {
    this.getpost.getPageResult(this.selectedDocType.param, this.pageNumber, '').subscribe(
      res => {
        this.showAllCount = res['count'];
      },
      (err) => {},
      () => {
        this.getpost.getErroredRecords(this.selectedDocType.param, this.pageNumber).subscribe(
          res => {
            this.errorOnlyCount = res['count'];
          },
          (err) => {},
          () => {
            this.getpost.getApprovedSavedRecords(this.selectedDocType.param, this.pageNumber, 1, 0).subscribe(
              res => {
                this.approvedCount = res['count'];
              },
              (err) => {},
              () => {
                this.getpost.getApprovedSavedRecords(this.selectedDocType.param, this.pageNumber, 0, 1).subscribe(
                  res => {
                    this.toBeReveived = res['count'];
                  },
                  (err) => {},
                  () => {
                    this.getpost.getAutorejectPass(this.selectedDocType.param, this.pageNumber).subscribe(
                      res => {
                        this.autoPassCount = res['count'];
                      },
                      (err) => {},
                      () => {
                        this.getpost.getAutorejectedRecords(this.pageNumber).subscribe(
                          res => {
                            this.autoRejecedCount = res['count'];
                          },
                          (err) => {},
                          () => {
                            this.getpost.getqbestatusdocuments(this.selectedDocType.param, this.pageNumber, 'failed').subscribe(
                              res => {
                                this.qbeStatusCount = res['count'];
                              },
                              (err) => {},
                              () => {
                              }
                            );
                          }
                        );
                      }
                    );
                  }
                );
              }
            );
          }
        );
      }
    );
  }

  UpdateRecordsCount(recordsCount) {
    this.totalRecordsCount = [];
    this.totalRecordsCount = new Array<number>(recordsCount);
    this.dataService._totalListCount = recordsCount;
    CommonService.totalInvoices = recordsCount;
    CommonService.totalInvoicePages = this.returnLastPage();
  }

  returnLastPage() {
    return Math.ceil(this.totalRecordsCount.length/this.dataService._recordsPerPage);
  }

  selectTabs(tabNumber) {
    this.tabNumber = tabNumber;
  }

  // ************************************ Page is changed from pagination ************************
  pageChanged(event) {
    if (event < 1) {
      return
    }
    const recordsCount = document.getElementById('records').childElementCount;
    const start = (this.pageNumber - 1) * this.recordsPerPage + 0;
    const end = (this.pageNumber - 1) * this.recordsPerPage + recordsCount - 1;
    if ((end === this.totalRecordsCount.length - 1 && this.pageNumber < event)
      || (this.pageNumber === 1 && event < this.pageNumber)) {
      return;
    }
    for (let i = start; i < end; i++) {
      this.checkedRecord[i] = false;
      this.checkedCount = 0;
    }
    this.areAllChecked = false;
    this.pageChangedEvent.emit(event);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FilterDialog, {
      width: '50%', maxHeight: '85vh',
      data: {
        username: this.searchString.username,
        supplierName: this.searchString.isv,
        date: this.searchString.date,
        recordsPerPage: this.recordsPerPage,
        dateType: this.searchString.dateType ? this.searchString.dateType : 'lessThan'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      try {
        this.searchString['username'] = result.username;
        this.searchString['isv'] = result.supplierName;
        this.searchString['date'] = result.date;
        this.searchString['dateType'] = result.dateType;
        this.recordsPerPage = result.recordsPerPage;
        this.dataService._recordsPerPage = this.recordsPerPage;
      } catch (e) {}
      finally{
        if (result) {
          console.log(result)
          // this.getpost.getmultipleFilters(
          //   this.searchString['date'],
          //   this.searchString['dateType'],
          //   this.searchString['username'],
          //   this.searchString['isv'],
          //   this.recordsPerPage,
          //   this.pageNumber
          // ).subscribe(res => {
          //   console.log(res);
          // })
          let searchJson = {
            "username": this.searchString['username'],
            "isv": this.searchString['isv'],
            "date": this.searchString['date'],
            "dateType": this.searchString['dateType'],
            "recordsPerPage": this.recordsPerPage,
          }
          this.searchByMultiParamBecon.emit(searchJson)
        }
      }
    });
  }

}

@Component({
  selector: 'filter-dialog',
  templateUrl: 'filter-dialog.html',
  styleUrls: ['./tables.component.css']
})
export class FilterDialog {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA];

  constructor(
    public dialogRef: MatDialogRef<FilterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
    this.data.username = [];
    this.data.isv = [];
    this.data.date = [];
    this.data.dateType = [];
    this.data.recordsPerPage = this.dataService._recordsPerPage;
  }

  // add chips for username or supplier name
  add(event: MatChipInputEvent, field): void {
    const value = (event.value || '').trim();
    if (value.includes(',')) {
      let valueArr = value.split(',');
      valueArr.forEach(val=> {
        if (field == 'username') {
          // Add our fruit
          if (value) {
            this.data['username'].push(val.trim());
          }
        } else if (field == 'supplierName') {
          // Add our fruit
          if (value) {
            this.data['supplierName'].push(val.trim());
          }
        } else if (field == 'date') {
          // Add our fruit
          if (value) {
            this.data['date'].push(val.trim());
          }
        }
      })
    } else {
      if (field == 'username') {
        // Add our fruit
        if (value) {
          this.data['username'].push(value);
        }
      } else if (field == 'supplierName') {
        // Add our fruit
        if (value) {
          this.data['supplierName'].push(value);
        }
      } else if (field == 'date') {
        // Add our fruit
        if (value) {
          this.data['date'].push(value);
        }
      }
    }
    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }
  }

  // remove chips for username or supplier name
  remove(value, field): void {

    if (field == 'username') {
      const index = this.data['username'].indexOf(value);
      if (index >= 0) {
        this.data['username'].splice(index, 1);
      }
    } else if (field == 'supplierName') {
      const index = this.data['supplierName'].indexOf(value);
      if (index >= 0) {
        this.data['supplierName'].splice(index, 1);
      }
    } else if (field == 'date') {
      const index = this.data['date'].indexOf(value);
      if (index >= 0) {
        this.data['date'].splice(index, 1);
      }
    }

  }

  // set records per page to 200 if user enter greater value
  checkForRecordsPerPageLimit() {
    if (this.data.recordsPerPage > 200) {
      this.data.recordsPerPage = 200;
    }
  }

  // set date type values on radio button selected
  searchDateType(event) {
    this.data.dateType = event.value;
    try {
      if (this.data.date.length == 2 && this.data.dateType != 'inBetween') {
        this.data.date.splice(1, 1);
      }
    } catch (e) {}
  }
}
