/*************************
 *
 * MAVENICK CONFIDENTIAL
 * ______
 *
 *  [2020] - Mavenick Consulting OPC Pvt Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mavenick Consulting OPC Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Mavenick Consulting OPC Pvt Ltd
 * and its suppliers and may be covered by India, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mavenick Consulting OPC Pvt Ltd.
 */



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { CommonService } from './common.service';
import { DataService } from './shared/data.service';
@Injectable({
  providedIn: 'root'
})
export class DataStructureService {
  revieweidlist = [];
  reviewedReceiptsList = [];
  reviewedPoList = [];
  newInvoiceList = [];
  newReceiptList = [];
  newPoList = [];
  finalResult = [];
  finalReceipt = [];
  finalPos = [];
  savedData = [];
  savedReceipts = [];
  savedPos = [];
  cols = [];
  dataCols = [];
  fd: FormData = new FormData();
  rawData: any;
  image_id: number;
  obj_id: any;
  date: string;
  data: any;
  arrayLength: number;
  is_approved;
  is_modified;

  inv = [];
  invfound = false;
  invChanged = [];
  invCo = [];
  chnv = [];
  chnvfound = false;
  chnvChanged = [];
  chnvCo = [];
  env = [];
  envfound = false;
  envChanged = [];
  envCo = [];
  mnv = [];
  mnvfound = false;
  mnvChanged = [];
  mnvCo = [];
  cmnv = [];
  cmnvfound = false;
  cmnvChanged = [];
  cmnvCo = [];
  icnv = [];
  icnvfound = false;
  icnvChanged = [];
  icnvCo = [];
  dnv = [];
  dnvfound = false;
  dnvChanged = [];
  dnvCo = [];
  qiv = [];
  qivfound = false;
  qivChanged = [];
  qivCo = [];
  niq = [];
  niqfound = false;
  niqChanged = [];
  niqCo = [];
  nan = [];
  nanfound = false;
  nanChanged = [];
  nanCo = [];
  ntype = [];
  ntypefound = false;
  ntypeChanged = [];
  ntypeCo = [];
  nrn1 = [];
  nrn1found = false;
  nrn1Changed = [];
  nrn1Co = [];
  nsef = [];
  nseffound = false;
  nsefChanged = [];
  nsefCo = [];
  nbnef = [];
  nbneffound = false;
  nbnefChanged = [];
  nbnefCo = [];
  pnv = [];
  pnvfound = false;
  pnvChanged = [];
  pnvCo = [];
  sidv = [];
  sidvfound = false;
  sidvChanged = [];
  sidvCo = [];
  eidv = [];
  eidvfound = false;
  eidvChanged = [];
  eidvCo = [];
  pdv = [];
  pdvfound = false;
  pdvChanged = [];
  pdvCo = [];
  pmv = [];
  pmvfound = false;
  pmvChanged = [];
  pmvCo = [];
  ctv = [];
  ctvfound = false;
  ctvChanged = [];
  ctvCo = [];
  vtv = [];
  vtvfound = false;
  vtvChanged = [];
  vtvCo = [];
  crv = [];
  crvfound = false;
  crvChanged = [];
  crvCo = [];
  tecv = [];
  tecvfound = false;
  tecvChanged = [];
  tecvCo = [];
  bmv = [];
  bmvfound = false;
  bmvChanged = [];
  bmvCo = [];
  mav = [];
  mavfound = false;
  mavChanged = [];
  mavCo = [];
  pfv = [];
  pfvfound = false;
  pfvChanged = [];
  pfvCo = [];
  stav = [];
  stavfound = false;
  stavChanged = [];
  stavCo = [];
  vatp = [];
  vatpfound = false;
  vatpChanged = [];
  vatpCo = [];
  islv = [];
  islvfound = false;
  islvChanged = [];
  islvCo = [];
  iclv = [];
  iclvfound = false;
  iclvChanged = [];
  iclvCo = [];
  scrn = [];
  scrnfound = false;
  scrnChanged = [];
  scrnCo = [];
  lecrn = [];
  lecrnfound = false;
  lecrnChanged = [];
  lecrnCo = [];
  lrcrn = [];
  lrcrnfound = false;
  lrcrnChanged = [];
  lrcrnCo = [];
  islav = [];
  islavfound = false;
  islavChanged = [];
  islavCo = [];
  iclav = [];
  iclavfound = false;
  iclavChanged = [];
  iclavCo = [];
  cessv = [];
  cessvfound = false;
  cessvChanged = [];
  cessvCo = [];
  tcsv = [];
  tcsvfound = false;
  tcsvChanged = [];
  tcsvCo = [];
  discv = [];
  discvfound = false;
  discvChanged = [];
  discvCo = [];
  vrnv = [];
  vrnvfound = false;
  vrnvChanged = [];
  vrnvCo = [];
  hsnv = [];
  hsnvfound = false;
  hsnvChanged = [];
  hsnvCo = [];
  lrgv = [];
  lrgvfound = false;
  lrgvChanged = [];
  lrgvCo = [];
  legv = [];
  legvfound = false;
  legvChanged = [];
  legvCo = [];
  smnv = [];
  smnvfound = false;
  smnvChanged = [];
  smnvCo = [];
  iclmnv = [];
  iclmnvfound = false;
  iclmnvChanged = [];
  iclmnvCo = [];
  icleiv = [];
  icleivfound = false;
  icleivChanged = [];
  icleivCo = [];
  insv = [];
  insvfound = false;
  insvChanged = [];
  insvCo = [];
  regv = [];
  regvfound = false;
  regvChanged = [];
  regvCo = [];
  cmnl = [];
  cmnlfound = false;
  cmnlChanged = [];
  cmnlCo = [];
  sesv = [];
  sesvfound = false;
  sesvChanged = [];
  sesvCo = [];
  emv = [];
  emvfound = false;
  emvChanged = [];
  emvCo = [];
  idv = [];
  idvfound = false;
  idvChanged = [];
  idvCo = [];
  isv = [];
  isvfound = false;
  isvChanged = [];
  isvCo = [];
  igv = [];
  igvfound = false;
  igvChanged = [];
  igvCo = [];
  cgv = [];
  cgvfound = false;
  cgvChanged = [];
  cgvCo = [];
  ivv = [];
  ivvfound = false;
  ivvChanged = [];
  ivvCo = [];
  cvv = [];
  cvvfound = false;
  cvvChanged = [];
  cvvCo = [];
  itv = [];
  itvfound = false;
  itvChanged = [];
  itvCo = [];
  ipv = [];
  ipvfound = false;
  ipvChanged = [];
  ipvCo = [];
  icv = [];
  icvfound = false;
  icvChanged = [];
  icvCo = [];
  fcv = [];
  fcvfound = false;
  fcvChanged = [];
  fcvCo = [];
  mdv = [];
  mdvfound = false;
  mdvChanged = [];
  mdvCo = [];
  invAccuracy = [];
  chnvAccuracy = [];
  envAccuracy = [];
  mnvAccuracy = [];
  cmnvAccuracy = [];
  icnvAccuracy = [];
  dnvAccuracy = [];
  qivAccuracy = [];
  niqAccuracy = [];
  nanAccuracy = [];
  ntypeAccuracy = [];
  nrn1Accuracy = [];
  nsefAccuracy = [];
  nbnefAccuracy = [];
  pnvAccuracy = [];
  sidvAccuracy = [];
  eidvAccuracy = [];
  pdvAccuracy = [];
  pmvAccuracy = [];
  ctvAccuracy = [];
  vtvAccuracy = [];
  crvAccuracy = [];
  tecvAccuracy = [];
  bmvAccuracy = [];
  mavAccuracy = [];
  pfvAccuracy = [];
  stavAccuracy = [];
  vatpAccuracy = [];
  islvAccuracy = [];
  iclvAccuracy = [];
  scrnAccuracy = [];
  lecrnAccuracy = [];
  lrcrnAccuracy = [];
  islavAccuracy = [];
  iclavAccuracy = [];
  cessvAccuracy = [];
  tcsvAccuracy = [];
  discvAccuracy = [];
  vrnvAccuracy = [];
  hsnvAccuracy = [];
  lrgvAccuracy = [];
  legvAccuracy = [];
  smnvAccuracy = [];
  iclmnvAccuracy = [];
  icleivAccuracy = [];
  insvAccuracy = [];
  regvAccuracy = [];

  cmnlAccuracy = [];
  sesvAccuracy = [];
  emvAccuracy = [];
  isvAccuracy = [];
  cnvAccuracy = [];
  cavAccuracy = [];
  savAccuracy = [];
  idvAccuracy = [];
  igvAccuracy = [];
  cgvAccuracy = [];
  ivvAccuracy = [];
  cvvAccuracy = [];
  ipvAccuracy = [];
  itvAccuracy = [];
  icvAccuracy = [];
  fcvAccuracy = [];
  mdvAccuracy = [];
  lineItems = [];
  dataItems = [];
  lineItemChanged = [];
  lineCo = [];
  dataItemChanged = [];
  dataItemAccuracy = [];
  dataCo = [];
  colNames = [];
  oldColnames = [];
  dataColNames = [];
  dataOldColnames = [];
  fieldsCount = 0;
  changedCount = 0;
  total_modified_fields = 0;
  modified_fields;
  total_fields;
  itemnumber;
  x1 = [];
  x2 = [];
  y1 = [];
  y2 = [];

  acNoList = [];

  sendProcessedDataUrl = '/klearstack/updateresult'; // retraining enabled

  isOpenedUrl = '/klearstack/updateresult';

  cavfound: boolean;
  cav = [];
  cavCo = [];
  cavChanged = [];
  cgstvfound: boolean;
  cgstv = [];
  cgstvCo = [];
  cgstvChanged = [];
  sgstvfound: boolean;
  sgstv = [];
  sgstvCo = [];
  sgstvChanged = [];
  igstvfound: boolean;
  igstv = [];
  igstvCo = [];
  igstvChanged = [];
  cgstpfound: boolean;
  cgstp = [];
  cgstpCo = [];
  cgstpChanged = [];
  sgstpfound: boolean;
  sgstp = [];
  sgstpCo = [];
  sgstpChanged = [];
  igstpfound: boolean;
  igstp = [];
  txav = [];
  gnv = [];
  ihv = [];
  igstpCo = [];
  igstpChanged = [];
  cgstvAccuracy = [];
  sgstvAccuracy = [];
  igstvAccuracy = [];
  cgstpAccuracy = [];
  sgstpAccuracy = [];
  igstpAccuracy = [];
  txavfound: boolean;
  txavAccuracy = [];
  txavCo = [];
  txavChanged = [];
  gnvfound: boolean;
  gnvAccuracy = [];
  gnvCo = [];
  gnvChanged = [];
  ihvfound: boolean;
  ihvAccuracy = [];
  ihvCo = [];
  ihvChanged = [];
  savfound: boolean;
  sav = [];
  savCo = [];
  savChanged = [];
  cnvfound: boolean;
  cnv = [];
  cnvCo = [];
  cnvChanged = [];
  currentUsersInvoiceId: any;
  bdvfound: boolean;
  bdv = [];
  bdvAccuracy = [];
  bdvCo = [];
  bdvChanged = [];
  ilvfound: boolean;
  ilv = [];
  ilvAccuracy = [];
  ilvCo = [];
  ilvChanged = [];
  ptvfound: boolean;
  ptv = [];
  ptvAccuracy = [];
  ptvCo = [];
  ptvChanged = [];
  iitvfound: boolean;
  iitv = [];
  iitvAccuracy = [];
  iitvCo = [];
  iitvChanged = [];
  spnvfound: boolean;
  spnv = [];
  spnvAccuracy = [];
  spnvCo = [];
  spnvChanged = [];
  cpnvfound: boolean;
  cpnv = [];
  cpnvAccuracy = [];
  cpnvCo = [];
  cpnvChanged = [];
  csnvfound: boolean;
  csnv = [];
  csnvAccuracy = [];
  csnvCo = [];
  csnvChanged = [];
  ssnvfound: boolean;
  ssnv = [];
  ssnvAccuracy = [];
  ssnvCo = [];
  ssnvChanged = [];
  scnvfound: boolean;
  scnv = [];
  scnvAccuracy = [];
  scnvCo = [];
  scnvChanged = [];
  ccnvfound: boolean;
  ccnv = [];
  ccnvAccuracy = [];
  ccnvCo = [];
  ccnvChanged = [];
  invl: any;
  invlCo: any;
  invlChanged: any;
  invlfound: any;
  invlAccuracy: any;
  chnvlfound: boolean;
  chnvl: any;
  chnvlAccuracy: any;
  chnvlCo: any;
  chnvlChanged: any;
  envllfound: boolean;
  envl: any;
  envlAccuracy: any;
  envlCo: any;
  envlChanged: any;
  envlfound: any;
  mnvlfound: boolean;
  mnvl: any;
  mnvlAccuracy: any;
  mnvlCo: any;
  mnvlChanged: any;
  cmnvlfound: boolean;
  cmnvl: any[];
  cmnvlCo: any[];
  cmnvlChanged: any[];
  cmnvlAccuracy: any[];
  cmnllfound: boolean;
  cmnll: any;
  cmnllAccuracy: any;
  cmnllCo: any;
  cmnllChanged: any;
  sesvlfound: boolean;
  sesvl: any;
  sesvlAccuracy: any;
  sesvlCo: any;
  sesvlChanged: any;
  emvlfound: boolean;
  emvl: any;
  emvlAccuracy: any;
  emvlCo: any;
  emvlChanged: any;
  isvlfound: boolean;
  isvl: any;
  isvlAccuracy: any;
  isvlCo: any;
  isvlChanged: any;
  cnvlfound: boolean;
  cnvl: any;
  cnvlAccuracy: any;
  cnvlCo: any;
  cnvlChanged: any;
  cavlfound: boolean;
  cavl: any;
  cavlAccuracy: any;
  cavlCo: any;
  cavlChanged: any;
  savlfound: boolean;
  savl: any;
  savlAccuracy: any;
  savlCo: any;
  savlChanged: any;
  idvlfound: boolean;
  idvl: any;
  idvlAccuracy: any;
  idvlCo: any;
  idvlChanged: any;
  igvlfound: boolean;
  igvl: any[];
  igvlCo: any[];
  igvlChanged: any[];
  igvlAccuracy: any[];
  cgvlfound: boolean;
  cgvl: any[];
  cgvlCo: any[];
  cgvlChanged: any[];
  cgvlAccuracy: any[];
  ivvlfound: boolean;
  ivvl: any[];
  ivvlCo: any[];
  ivvlChanged: any[];
  ivvlAccuracy: any[];
  cvvlfound: boolean;
  cvvl: any[];
  cvvlCo: any[];
  cvvlChanged: any[];
  cvvlAccuracy: any[];
  icvlfound: boolean;
  icvl: any;
  icvlAccuracy: any;
  icvlCo: any;
  icvlChanged: any;
  fcvlfound: boolean;
  fcvl: any;
  fcvlAccuracy: any;
  fcvlCo: any;
  fcvlChanged: any;
  mdvlfound: boolean;
  mdvl: any[];
  mdvlAccuracy: any;
  mdvlCo: any;
  mdvlChanged: any;
  ipvlfound: boolean;
  ipvl: any;
  ipvlAccuracy: any;
  ipvlCo: any[];
  ipvlChanged: any;
  itvlfound: boolean;
  itvl: any;
  itvlAccuracy: any;
  itvlCo: any;
  itvlChanged: any;
  cgstvlfound: boolean;
  cgstvl: any;
  cgstvlAccuracy: any;
  cgstvlCo: any;
  cgstvlChanged: any;
  sgstvlfound: boolean;
  sgstvl: any;
  sgstvlAccuracy: any;
  sgstvlCo: any;
  sgstvlChanged: any;
  igstvlfound: boolean;
  igstvl: any;
  igstvlAccuracy: any;
  igstvlCo: any;
  igstvlChanged: any;
  cgstplfound: boolean;
  cgstpl: any;
  cgstplAccuracy: any;
  cgstplCo: any;
  cgstplChanged: any;
  sgstplfound: boolean;
  sgstpl: any;
  sgstplAccuracy: any;
  sgstplCo: any;
  sgstplChanged: any;
  igstplfound: boolean;
  igstpl: any;
  igstplAccuracy: any;
  igstplCo: any;
  igstplChanged: any;
  txavlfound: boolean;
  txavl: any;
  txavlAccuracy: any;
  txavlCo: any;
  txavlChanged: any;
  gnvlfound: boolean;
  gnvl: any;
  gnvlAccuracy: any;
  gnvlCo: any;
  gnvlChanged: any;
  ihvlfound: boolean;
  ihvl: any;
  ihvlAccuracy: any;
  ihvlCo: any;
  ihvlChanged: any;
  bdvlfound: boolean;
  bdvl: any;
  bdvlAccuracy: any;
  bdvlCo: any;
  bdvlChanged: any;
  csnvlfound: boolean;
  csnvl: any;
  csnvlAccuracy: any;
  csnvlCo: any;
  csnvlChanged: any;
  ssnvlfound: boolean;
  ssnvl: any;
  ssnvlAccuracy: any;
  ssnvlCo: any;
  ssnvlChanged: any;
  scnvlfound: boolean;
  scnvl: any;
  scnvlAccuracy: any;
  scnvlCo: any;
  scnvlChanged: any;
  ccnvlfound: boolean;
  ccnvl: any;
  ccnvlAccuracy: any;
  ccnvlCo: any;
  ccnvlChanged: any;
  ilvlfound: boolean;
  ilvl: any;
  ilvlAccuracy: any;
  ilvlCo: any;
  ilvlChanged: any;
  ptvlfound: boolean;
  ptvl: any;
  ptvlAccuracy: any;
  ptvlCo: any;
  ptvlChanged: any;
  iitvlfound: boolean;
  iitvl: any;
  iitvlAccuracy: any;
  iitvlCo: any;
  iitvlChanged: any;
  spnvlfound: boolean;
  spnvl: any;
  spnvlAccuracy: any;
  spnvlCo: any;
  spnvlChanged: any;
  cpnvlfound: boolean;
  cpnvl: any;
  cpnvlAccuracy: any;
  cpnvlCo: any;
  cpnvlChanged: any;
  icnvlfound: boolean;
  icnvl: any;
  icnvlAccuracy: any;
  icnvlCo: any;
  icnvlChanged: any;
  dnvlfound: boolean;
  dnvl: any;
  dnvlAccuracy: any;
  dnvlCo: any;
  dnvlChanged: any;
  qivlfound: boolean;
  qivl: any;
  qivlAccuracy: any;
  qivlCo: any;
  qivlChanged: any;
  pnvlfound: boolean;
  pnvl: any;
  pnvlAccuracy: any;
  pnvlCo: any;
  pnvlChanged: any;
  sidvlfound: boolean;
  sidvl: any;
  sidvlAccuracy: any;
  sidvlCo: any;
  sidvlChanged: any;
  eidvlfound: boolean;
  eidvl: any;
  eidvlAccuracy: any;
  eidvlCo: any;
  eidvlChanged: any;
  pdvlfound: boolean;
  pdvl: any;
  pdvlAccuracy: any;
  pdvlCo: any;
  pdvlChanged: any;
  pmvlfound: boolean;
  pmvl: any;
  pmvlAccuracy: any;
  pmvlCo: any;
  pmvlChanged: any;
  ctvlfound: boolean;
  ctvl: any;
  ctvlAccuracy: any;
  ctvlCo: any;
  ctvlChanged: any;
  vtvlfound: boolean;
  vtvl: any;
  vtvlAccuracy: any;
  vtvlCo: any;
  vtvlChanged: any;
  crvlfound: boolean;
  crvl: any;
  crvlAccuracy: any;
  crvlCo: any;
  crvlChanged: any;
  tecvlfound: boolean;
  tecvl: any;
  tecvlAccuracy: any;
  tecvlCo: any;
  tecvlChanged: any;
  bmvlfound: boolean;
  bmvl: any;
  bmvlAccuracy: any;
  bmvlCo: any;
  bmvlChanged: any;
  mavlfound: boolean;
  mavl: any;
  mavlAccuracy: any;
  mavlCo: any;
  mavlChanged: any;
  pfvlfound: boolean;
  pfvl: any;
  pfvlAccuracy: any;
  pfvlCo: any;
  pfvlChanged: any;
  stavlfound: boolean;
  stavl: any;
  stavlAccuracy: any;
  stavlCo: any;
  stavlChanged: any;
  vatplfound: boolean;
  vatpl: any;
  vatplAccuracy: any;
  vatplCo: any;
  vatplChanged: any;
  islvlfound: boolean;
  islvl: any;
  islvlAccuracy: any;
  islvlCo: any;
  islvlChanged: any;
  iclvlfound: boolean;
  iclvl: any;
  iclvlAccuracy: any;
  iclvlCo: any;
  iclvlChanged: any;
  scrnlfound: boolean;
  scrnl: any;
  scrnlAccuracy: any;
  scrnlCo: any;
  scrnlChanged: any;
  lecrnlfound: boolean;
  lecrnl: any;
  lecrnlAccuracy: any;
  lecrnlCo: any;
  lecrnlChanged: any;
  lrcrnlfound: boolean;
  lrcrnl: any;
  lrcrnlAccuracy: any;
  lrcrnlCo: any;
  lrcrnlChanged: any;
  islavlfound: boolean;
  islavl: any;
  islavlAccuracy: any;
  islavlCo: any;
  islavlChanged: any;
  iclavlfound: boolean;
  iclavl: any;
  iclavlAccuracy: any;
  iclavlCo: any;
  iclavlChanged: any;
  cessvlfound: boolean;
  cessvl: any;
  cessvlAccuracy: any;
  cessvlCo: any;
  cessvlChanged: any;
  tcsvlfound: boolean;
  tcsvl: any;
  tcsvlAccuracy: any;
  tcsvlCo: any;
  tcsvlChanged: any;
  discvlfound: boolean;
  discvl: any;
  discvlAccuracy: any;
  discvlCo: any;
  discvlChanged: any;
  vrnvlfound: boolean;
  vrnvl: any;
  vrnvlAccuracy: any;
  vrnvlCo: any;
  vrnvlChanged: any;
  hsnvlfound: boolean;
  hsnvl: any;
  hsnvlAccuracy: any;
  hsnvlCo: any;
  hsnvlChanged: any;
  lrgvlfound: boolean;
  lrgvl: any;
  lrgvlAccuracy: any;
  lrgvlCo: any;
  lrgvlChanged: any;
  legvlfound: boolean;
  legvl: any;
  legvlAccuracy: any;
  legvlCo: any;
  legvlChanged: any;
  smnvlfound: boolean;
  smnvl: any;
  smnvlAccuracy: any;
  smnvlCo: any;
  smnvlChanged: any;
  iclmnlfound: boolean;
  iclmnl: any;
  iclmnlAccuracy: any;
  iclmnlCo: any;
  iclmnlChanged: any;
  icleilfound: boolean;
  icleil: any;
  icleilAccuracy: any;
  icleilCo: any;
  icleilChanged: any;
  insvlfound: boolean;
  insvl: any;
  insvlAccuracy: any;
  insvlCo: any;
  insvlChanged: any;

  regvlfound: boolean;
  regvl: any;
  regvlAccuracy: any;
  regvlCo: any;
  regvlChanged: any;
  ui_image_list: any;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private dataService: DataService
  ) {}

  processData(rawData, parent: string, linenumber?, ignoreRawData?) {
    this.resetValues();
    // let visibleFields = this.commonService.getVisibleFieldsForGrid(this.dataService._labelJson);
    let json = {};
    try {
      if (!ignoreRawData) {
        this.rawData = rawData;
      }
      this.revieweidlist = [];
      this.reviewedReceiptsList = [];
      this.reviewedPoList = [];
      this.savedData = [];
      this.savedReceipts = [];
      this.savedPos = [];
      this.newInvoiceList = [];
      this.newReceiptList = [];
      this.newPoList = [];
      this.finalResult = [];
      this.finalReceipt = [];
      this.finalPos = [];
      this.currentUsersInvoiceId = [];
      if (!rawData) {
        console.log('returning null');
        return [];
      }
      for (let itrI = 0; itrI < rawData.length; itrI++) {
        this.total_fields = 0;
        this.modified_fields = 0;
        try {
          if (rawData[itrI].result) {
          } else {
            continue;
          }
          json['image_id'] = rawData[itrI].image_id;
          try {
            json['ui_image_list'] = rawData[itrI].ui_image_list;
          } catch (e) {}
          json['is_approved'] = rawData[itrI].is_approved;
          json['AccountsData'] = rawData[itrI].AccountsData;
          json['SplitAccountsJson'] = rawData[itrI].SplitAccountsJson;
          json['Tax Amount'] = rawData[itrI]['Tax Amount'];
          json['CGST'] = rawData[itrI]['CGST'];
          json['SGST'] = rawData[itrI]['SGST'];
          json['IGST'] = rawData[itrI]['IGST'];
          json['PurchaseAc'] = rawData[itrI]['PurchaseAc'];
          json['LineItemAccountsJson'] = rawData[itrI].LineItemAccountsJson;
          json['is_modified'] = rawData[itrI].is_modified;
          json['is_opened'] = rawData[itrI].is_opened;
          json['date'] = rawData[itrI].date;
          json['ocr_id'] = rawData[itrI].ocr_id;
          json['overall_confidence'] = rawData[itrI].overall_confidence;
          json['obj_id'] = rawData[itrI]._id;
          json['batch_id'] = rawData[itrI].batch_id;
          json['user_id'] = rawData[itrI].user_id;
          json['modified_date'] = rawData[itrI].modified_date;
          json['raw_date'] = rawData[itrI].date;
          json['splitBillByValue'] = rawData[itrI].splitBillByValue;
          json['push_api_status'] = rawData[itrI].push_api_status;
          json['formated_date'] = this.dateFormat(rawData[itrI].date);

          if (rawData[itrI].error_fields) {
            json['error_fields'] = rawData[itrI].error_fields;
          }

          if (rawData[itrI].QBE_response) {
            json['QBE_response'] = rawData[itrI].QBE_response;
          }

          try {
            json['application_no'] = rawData[itrI].push_api_status.application_no;
            json['errorField'] = rawData[itrI].push_api_status.sfdc_err_flag
            if (rawData[itrI].push_api_status.response.Description) {
              json['errorDescription'] = rawData[itrI].push_api_status.response.Description;
            } else if (rawData[itrI].push_api_status.response.message) {
              json['errorDescription'] = rawData[itrI].push_api_status.response.message;
            } else if (rawData[itrI].push_api_status.response.Error && rawData[itrI].push_api_status.response.Error.ErrorDescription) {
              json['errorDescription'] = rawData[itrI].push_api_status.response.Error.ErrorDescription;
              // json['errorDescription'] = "Timeout exceeded"
            }
          } catch (e) {}

          if (rawData[itrI].exception_type) {
            json['exceptionType'] = rawData[itrI].exception_type;
          } else {
            // json['exception_type'] = '';
          }

          if (rawData[itrI].exception_image_details) {
            json['subExceptionType'] = rawData[itrI].exception_image_details
          } else {
            // json['exception_image_details'] = '';
          }

          if (rawData[itrI].image_quality_error) {
            json['ipef'] = rawData[itrI].image_quality_error;
          } else {
            json['ipef'] = 'Yes';
          }
          if (sessionStorage.getItem('_id') === rawData[itrI].user_id) {
            this.currentUsersInvoiceId.push(rawData[itrI]._id);
          }
          try {
            json['file_name'] = rawData[itrI].file_name;
          } catch (e) {}
          try {
            json['username'] = rawData[itrI].username;
          } catch (e) {}

          if (rawData[itrI].lineTableTitle) {
            json['lineTableTitle'] = rawData[itrI].lineTableTitle;
          } else {
            json['lineTableTitle'] = {};
          }

          if (rawData[itrI].dataTableTitle) {
            json['dataTableTitle'] = rawData[itrI].dataTableTitle;
          } else {
            json['dataTableTitle'] = {};
          }

          let currentPageIndex = 1;
          let currentPageIndex2 = 1;
          let igvPageIndex = 1;
          let cgvPageIndex = 1;
          let ivvPageIndex = 1;
          let cvvPageIndex = 1;
          let cmnvPageIndex = 1;
          let igvCount = 1;
          let cgvCount = 1;
          let ivvCount = 1;
          let cvvCount = 1;
          let cmnvCount = 1;

          let igvlPageIndex = 1;
          let cgvlPageIndex = 1;
          let ivvlPageIndex = 1;
          let cvvlPageIndex = 1;
          let cmnvlPageIndex = 1;
          let igvlCount = 1;
          let cgvlCount = 1;
          let ivvlCount = 1;
          let cvvlCount = 1;
          let cmnvlCount = 1;
          json['tableModified'] = false;

          for (let itrJ = 0; itrJ < rawData[itrI].result.length; itrJ++) {
            try {
              if (rawData[itrI].result[itrJ] === null || rawData[itrI].result[itrJ].length === 0) {
                const dt = rawData[itrI].result.splice(itrJ--, 1);
                continue;
              }
              let itemIndex;
              let pageIndex;
              switch (rawData[itrI].result[itrJ][0][1]) {
              case 'INV':
                this.total_fields++;
                this.invfound = true;
                this.inv.push(rawData[itrI].result[itrJ][0][0]);
                this.invAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.invCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.invCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.invChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['inv'] = this.inv;
                json['invfound'] = this.invfound;
                json['page_' + pageIndex]['inv'] = this.inv;
                json['invPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['invCo'] = this.invCo;
                json['page_' + pageIndex]['invChanged'] = this.invChanged;
                json['invChanged'] = this.invChanged;
                json['page_' + pageIndex]['invAccuracy'] = this.invAccuracy;
                this.inv = [];
                this.invCo = [];
                this.invChanged = [];
                this.invAccuracy = [];
              break;
              case 'CHNV':
                this.total_fields++;
                this.chnvfound = true;
                this.chnv.push(rawData[itrI].result[itrJ][0][0]);
                this.chnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.chnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.chnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.chnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['chnv'] = this.chnv;
                json['chnvfound'] = this.chnvfound;
                json['page_' + pageIndex]['chnv'] = this.chnv;
                json['chnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['chnvCo'] = this.chnvCo;
                json['page_' + pageIndex]['chnvChanged'] = this.chnvChanged;
                json['chnvChanged'] = this.chnvChanged;
                json['page_' + pageIndex]['chnvAccuracy'] = this.chnvAccuracy;
                try {
                  json['chnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.chnv = [];
                this.chnvCo = [];
                this.chnvChanged = [];
                this.chnvAccuracy = [];
              break;
              case 'ENV':
                this.total_fields++;
                this.envfound = true;
                this.env.push(rawData[itrI].result[itrJ][0][0]);
                this.envAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.envCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.envCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.envChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['env'] = this.env;
                json['envfound'] = this.envfound;
                json['page_' + pageIndex]['env'] = this.env;
                json['envPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['envCo'] = this.envCo;
                json['page_' + pageIndex]['envChanged'] = this.envChanged;
                json['envChanged'] = this.envChanged;
                json['page_' + pageIndex]['envAccuracy'] = this.envAccuracy;
                try {
                  json['env_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.env = [];
                this.envCo = [];
                this.envChanged = [];
                this.envAccuracy = [];
              break;
              case 'MNV':
                this.total_fields++;
                this.mnvfound = true;
                this.mnv.push(rawData[itrI].result[itrJ][0][0]);
                this.mnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.mnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.mnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.mnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['mnv'] = this.mnv;
                json['mnvfound'] = this.mnvfound;
                json['page_' + pageIndex]['mnv'] = this.mnv;
                json['mnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['mnvCo'] = this.mnvCo;
                json['page_' + pageIndex]['mnvChanged'] = this.mnvChanged;
                json['mnvChanged'] = this.mnvChanged;
                json['page_' + pageIndex]['mnvAccuracy'] = this.mnvAccuracy;
                try {
                  json['mnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.mnv = [];
                this.mnvCo = [];
                this.mnvChanged = [];
                this.mnvAccuracy = [];
              break;
              case 'CMNV':
                if (rawData[itrI].is_approved && cmnvCount > 1) {
                  delete rawData[itrI].result[itrJ];
                } else {
                  if (cmnvCount === 1) {
                    this.total_fields++;
                  }
                  cmnvCount++;
                  this.cmnvfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (cmnvPageIndex !== pageIndex) {
                    cmnvCount = 2;
                    this.cmnv = [];
                    this.cmnvCo = [];
                    this.cmnvChanged = [];
                    this.cmnvAccuracy = [];
                    cmnvPageIndex = pageIndex;
                  } else if (cmnvCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.cmnvCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.cmnvCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.cmnv.push(rawData[itrI].result[itrJ][0][0]);
                  this.cmnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.cmnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                  if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                    this.modified_fields++;
                  }
                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['cmnv'] = this.cmnv;
                  json['cmnvfound'] = this.cmnvfound;
                  json['page_' + pageIndex]['cmnv'] = this.cmnv;
                  json['cmnvPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['cmnvCo'] = this.cmnvCo;
                  json['page_' + pageIndex]['cmnvChanged'] = this.cmnvChanged;
                  json['cmnvChanged'] = this.cmnvChanged;
                  json['page_' + pageIndex]['cmnvAccuracy'] = this.cmnvAccuracy;
                  try {
                    json['cmnv_old_log'] = {
                      old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                      modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                      modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                    }
                  } catch (e) {}
                }
              break;
              case 'CMNL':
                this.total_fields++;
                this.cmnlfound = true;
                let cmnlCount = 0;
                  for (let cmnl of rawData[itrI].result[itrJ][0][0]) {
                    if (cmnlCount < 3) {
                      this.cmnl.push(cmnl);
                      cmnlCount++;
                    }
                  }
                this.cmnlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cmnlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cmnlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cmnlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cmnl'] = this.cmnl;
                json['cmnlfound'] = this.cmnlfound;
                json['page_' + pageIndex]['cmnl'] = this.cmnl;
                json['cmnlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cmnlCo'] = this.cmnlCo;
                json['page_' + pageIndex]['cmnlChanged'] = this.cmnlChanged;
                json['cmnlChanged'] = this.cmnlChanged;
                json['page_' + pageIndex]['cmnlAccuracy'] = this.cmnlAccuracy;
                try {
                  json['cmnl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cmnl = [];
                this.cmnlCo = [];
                this.cmnlChanged = [];
                this.cmnlAccuracy = [];
              break;
              case 'SESV':
                this.total_fields++;
                this.sesvfound = true;
                let sesvCount = 0;
                  for (let sesv of rawData[itrI].result[itrJ][0][0]) {
                    if (sesvCount < 3) {
                      this.sesv.push(sesv);
                      sesvCount++;
                    }
                  }
                this.sesvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.sesvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.sesvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.sesvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['sesv'] = this.sesv;
                json['sesvfound'] = this.sesvfound;
                json['page_' + pageIndex]['sesv'] = this.sesv;
                json['sesvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['sesvCo'] = this.sesvCo;
                json['page_' + pageIndex]['sesvChanged'] = this.sesvChanged;
                json['sesvChanged'] = this.sesvChanged;
                json['page_' + pageIndex]['sesvAccuracy'] = this.sesvAccuracy;
                try {
                  json['sesv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.sesv = [];
                this.sesvCo = [];
                this.sesvChanged = [];
                this.sesvAccuracy = [];
              break;
              case 'EMV':
                this.total_fields++;
                this.emvfound = true;
                this.emv.push(rawData[itrI].result[itrJ][0][0]);
                this.emvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.emvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.emvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.emvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['emv'] = this.emv;
                json['emvfound'] = this.emvfound;
                json['page_' + pageIndex]['emv'] = this.emv;
                json['emvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['emvCo'] = this.emvCo;
                json['page_' + pageIndex]['emvChanged'] = this.emvChanged;
                json['emvChanged'] = this.emvChanged;
                json['page_' + pageIndex]['emvAccuracy'] = this.emvAccuracy;
                try {
                  json['emv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.emv = [];
                this.emvCo = [];
                this.emvChanged = [];
                this.emvAccuracy = [];
              break;
              case 'ISV':
                this.total_fields++;
                this.isvfound = true;
                this.isv.push(rawData[itrI].result[itrJ][0][0]);
                this.isvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  if (rawData[itrI].result[itrJ][1][0] &&
                    rawData[itrI].result[itrJ][1][1] &&
                    rawData[itrI].result[itrJ][1][2] &&
                    rawData[itrI].result[itrJ][1][3]) {
                    this.isvCo.push(rawData[itrI].result[itrJ][1]);
                    if (rawData[itrI].result[itrJ][1][4] === -1) {
                      json['isvTrained'] = true;
                    } else {
                      json['isvTrained'] = false;
                    }
                  } else {
                    this.isvCo.push(rawData[itrI].result[itrJ][1]);
                    json['isvTrained'] = false;
                  }
                } else {
                  itemIndex = 0;
                  this.isvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.isvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['isv'] = this.isv;
                json['isvfound'] = this.isvfound;
                json['page_' + pageIndex]['isv'] = this.isv;
                json['isvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['isvCo'] = this.isvCo;
                json['isvCo'] = this.isvCo;
                json['page_' + pageIndex]['isvChanged'] = this.isvChanged;
                json['isvChanged'] = this.isvChanged;
                json['page_' + pageIndex]['isvAccuracy'] = this.isvAccuracy;
                try {
                  json['isv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    new_value: this.isv[0],
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.isv = [];
                this.isvCo = [];
                this.isvChanged = [];
                this.isvAccuracy = [];
              break;
              case 'CNV':
                try {
                  this.total_fields++;
                this.cnvfound = true;
                this.cnv.push(rawData[itrI].result[itrJ][0][0]);
                this.cnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  if (rawData[itrI].result[itrJ][1][0] &&
                    rawData[itrI].result[itrJ][1][1] &&
                    rawData[itrI].result[itrJ][1][2] &&
                    rawData[itrI].result[itrJ][1][3]) {
                    this.cnvCo.push(rawData[itrI].result[itrJ][1]);
                  } else {
                    this.cnvCo.push(rawData[itrI].result[itrJ][1]);
                  }
                } else {
                  itemIndex = 0;
                  this.cnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cnv'] = this.cnv;
                json['cnvfound'] = this.cnvfound;
                json['page_' + pageIndex]['cnv'] = this.cnv;
                json['cnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cnvCo'] = this.cnvCo;
                json['cnvCo'] = this.cnvCo;
                json['page_' + pageIndex]['cnvChanged'] = this.cnvChanged;
                json['cnvChanged'] = this.cnvChanged;
                json['page_' + pageIndex]['cnvAccuracy'] = this.cnvAccuracy;
                try {
                  json['cnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cnv = [];
                this.cnvCo = [];
                this.cnvChanged = [];
                this.cnvAccuracy = [];
                } catch (e) {
                  console.log(e);
                }
              break;
              case 'CAV':
                this.total_fields++;
                this.cavfound = true;
                this.cav.push(rawData[itrI].result[itrJ][0][0]);
                this.cavAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  if (rawData[itrI].result[itrJ][1][0] &&
                    rawData[itrI].result[itrJ][1][1] &&
                    rawData[itrI].result[itrJ][1][2] &&
                    rawData[itrI].result[itrJ][1][3]) {
                      this.cavCo.push(rawData[itrI].result[itrJ][1]);
                  } else {
                    this.cavCo.push(rawData[itrI].result[itrJ][1]);
                  }
                } else {
                  itemIndex = 0;
                  this.cavCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cavChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cav'] = this.cav;
                json['cavfound'] = this.cavfound;
                json['page_' + pageIndex]['cav'] = this.cav;
                json['cavPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cavCo'] = this.cavCo;
                json['cavCo'] = this.cavCo;
                json['page_' + pageIndex]['cavChanged'] = this.cavChanged;
                json['cavChanged'] = this.cavChanged;
                json['page_' + pageIndex]['cavAccuracy'] = this.cavAccuracy;
                try {
                  json['cav_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cav = [];
                this.cavCo = [];
                this.cavChanged = [];
                this.cavAccuracy = [];
              break;
              case 'SAV':
                this.total_fields++;
                this.savfound = true;
                this.sav.push(rawData[itrI].result[itrJ][0][0]);
                this.savAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  if (rawData[itrI].result[itrJ][1][0] &&
                    rawData[itrI].result[itrJ][1][1] &&
                    rawData[itrI].result[itrJ][1][2] &&
                    rawData[itrI].result[itrJ][1][3]) {
                      this.savCo.push(rawData[itrI].result[itrJ][1]);
                  } else {
                    this.savCo.push(rawData[itrI].result[itrJ][1]);
                  }
                } else {
                  itemIndex = 0;
                  this.savCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.savChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['sav'] = this.sav;
                json['savfound'] = this.savfound;
                json['page_' + pageIndex]['sav'] = this.sav;
                json['savPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['savCo'] = this.savCo;
                json['savCo'] = this.savCo;
                json['page_' + pageIndex]['savChanged'] = this.savChanged;
                json['savChanged'] = this.savChanged;
                json['page_' + pageIndex]['savAccuracy'] = this.savAccuracy;
                try {
                  json['sav_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.sav = [];
                this.savCo = [];
                this.savChanged = [];
                this.savAccuracy = [];
              break;
              case 'IDV':
                this.total_fields++;
                this.idvfound = true;
                this.idv.push(rawData[itrI].result[itrJ][0][0]);
                this.idvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.idvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.idvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.idvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['idv'] = this.idv;
                json['idvfound'] = this.idvfound;
                json['page_' + pageIndex]['idv'] = this.idv;
                json['idvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['idvCo'] = this.idvCo;
                json['page_' + pageIndex]['idvChanged'] = this.idvChanged;
                json['idvChanged'] = this.idvChanged;
                json['page_' + pageIndex]['idvAccuracy'] = this.idvAccuracy;
                try {
                  json['idv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.idv = [];
                this.idvCo = [];
                this.idvChanged = [];
                this.idvAccuracy = [];
              break;
              case 'IGV':
                if (rawData[itrI].is_approved && igvCount > 1) {
                  rawData[itrI].result.splice(itrJ--, 1);
                  continue;
                } else {
                  if (igvCount === 1) {
                    this.total_fields++;
                  }
                  igvCount++;
                  this.igvfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (igvPageIndex !== pageIndex) {
                    igvCount = 2;
                    this.igv = [];
                    this.igvCo = [];
                    this.igvChanged = [];
                    this.igvAccuracy = [];
                    igvPageIndex = pageIndex;
                  } else if (igvCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.igvCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.igvCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.igv.push(rawData[itrI].result[itrJ][0][0]);
                  this.igvAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.igvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                  if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                    this.modified_fields++;
                  }
                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['igv'] = this.igv;
                  json['igvfound'] = this.igvfound;
                  json['page_' + pageIndex]['igv'] = this.igv;
                  json['igvPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['igvCo'] = this.igvCo;
                  json['page_' + pageIndex]['igvChanged'] = this.igvChanged;
                  json['igvChanged'] = this.igvChanged;
                  json['page_' + pageIndex]['igvAccuracy'] = this.igvAccuracy;
                }
              break;
              case 'CGV':
                if (rawData[itrI].is_approved && cgvCount > 1) {
                  rawData[itrI].result.splice(itrJ--, 1);
                  continue;
                } else {
                  if (cgvCount === 1) {
                    this.total_fields++;
                  }
                  cgvCount++;
                  this.cgvfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (cgvPageIndex !== pageIndex) {
                    cgvCount = 2;
                    this.cgv = [];
                    this.cgvCo = [];
                    this.cgvChanged = [];
                    this.cgvAccuracy = [];
                    cgvPageIndex = pageIndex;
                  } else if (cgvCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.cgvCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.cgvCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.cgv.push(rawData[itrI].result[itrJ][0][0]);
                  this.cgvAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.cgvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                  if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                    this.modified_fields++;
                  }
                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['cgv'] = this.cgv;
                  json['cgvfound'] = this.cgvfound;
                  json['page_' + pageIndex]['cgv'] = this.cgv;
                  json['cgvPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['cgvCo'] = this.cgvCo;
                  json['page_' + pageIndex]['cgvChanged'] = this.cgvChanged;
                  json['cgvChanged'] = this.cgvChanged;
                  json['page_' + pageIndex]['cgvAccuracy'] = this.cgvAccuracy;
                }
              break;
              case 'IVV':
                if (rawData[itrI].is_approved && ivvCount > 1) {
                  delete rawData[itrI].result[itrJ];
                } else {
                  if (ivvCount === 1) {
                    this.total_fields++;
                  }
                  ivvCount++;
                  this.ivvfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (ivvPageIndex !== pageIndex) {
                    ivvCount = 2;
                    this.ivv = [];
                    this.ivvCo = [];
                    this.ivvChanged = [];
                    this.ivvAccuracy = [];
                    ivvPageIndex = pageIndex;
                  } else if (ivvCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.ivvCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.ivvCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.ivv.push(rawData[itrI].result[itrJ][0][0]);
                  this.ivvAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.ivvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                  if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                    this.modified_fields++;
                  }
                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['ivv'] = this.ivv;
                  json['ivvfound'] = this.ivvfound;
                  json['page_' + pageIndex]['ivv'] = this.ivv;
                  json['ivvPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['ivvCo'] = this.ivvCo;
                  json['page_' + pageIndex]['ivvChanged'] = this.ivvChanged;
                  json['ivvChanged'] = this.ivvChanged;
                  json['page_' + pageIndex]['ivvAccuracy'] = this.ivvAccuracy;
                }
              break;
              case 'CVV':
                if (rawData[itrI].is_approved && cvvCount > 1) {
                  delete rawData[itrI].result[itrJ];
                } else {
                  if (cvvCount === 1) {
                    this.total_fields++;
                  }
                  cvvCount++;
                  this.cvvfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (cvvPageIndex !== pageIndex) {
                    cvvCount = 2;
                    this.cvv = [];
                    this.cvvCo = [];
                    this.cvvChanged = [];
                    this.cvvAccuracy = [];
                    cvvPageIndex = pageIndex;
                  } else if (cvvCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.cvvCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.cvvCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.cvv.push(rawData[itrI].result[itrJ][0][0]);
                  this.cvvAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.cvvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                  if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                    this.modified_fields++;
                  }
                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['cvv'] = this.cvv;
                  json['cvvfound'] = this.cvvfound;
                  json['page_' + pageIndex]['cvv'] = this.cvv;
                  json['cvvPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['cvvCo'] = this.cvvCo;
                  json['page_' + pageIndex]['cvvChanged'] = this.cvvChanged;
                  json['cvvChanged'] = this.cvvChanged;
                  json['page_' + pageIndex]['cvvAccuracy'] = this.cvvAccuracy;
                }
              break;
              case 'ICV':
                this.total_fields++;
                this.icvfound = true;
                this.icv.push(rawData[itrI].result[itrJ][0][0]);
                this.icvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.icvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.icvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                try {
                  this.icvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                  if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                    this.modified_fields++;
                  }
                } catch (e) {}
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['icv'] = this.icv;
                json['icvfound'] = this.icvfound;
                json['page_' + pageIndex]['icv'] = this.icv;
                json['icvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['icvCo'] = this.icvCo;
                json['page_' + pageIndex]['icvChanged'] = this.icvChanged;
                json['icvChanged'] = this.icvChanged;
                json['page_' + pageIndex]['icvAccuracy'] = this.icvAccuracy;
                try {
                  json['icv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.icv = [];
                this.icvCo = [];
                this.icvChanged = [];
                this.icvAccuracy = [];
              break;
              case 'FCV':
                this.total_fields++;
                this.fcvfound = true;
                this.fcv.push(rawData[itrI].result[itrJ][0][0]);
                this.fcvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.fcvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.fcvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                try {
                  this.fcvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                  if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                    this.modified_fields++;
                  }
                } catch (e) {}
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['fcv'] = this.fcv;
                json['fcvfound'] = this.fcvfound;
                json['page_' + pageIndex]['fcv'] = this.fcv;
                json['fcvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['fcvCo'] = this.fcvCo;
                json['page_' + pageIndex]['fcvChanged'] = this.fcvChanged;
                json['fcvChanged'] = this.fcvChanged;
                json['page_' + pageIndex]['fcvAccuracy'] = this.fcvAccuracy;
                try {
                  json['fcv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.fcv = [];
                this.fcvCo = [];
                this.fcvChanged = [];
                this.fcvAccuracy = [];
              break;
              case 'MDV':
                this.total_fields++;
                this.mdvfound = true;
                this.mdv.push(rawData[itrI].result[itrJ][0][0]);
                this.mdvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.mdvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.mdvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.mdvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['mdv'] = this.mdv;
                json['mdvfound'] = this.mdvfound;
                json['page_' + pageIndex]['mdv'] = this.mdv;
                json['mdvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['mdvCo'] = this.mdvCo;
                json['page_' + pageIndex]['mdvChanged'] = this.mdvChanged;
                json['mdvChanged'] = this.mdvChanged;
                json['page_' + pageIndex]['mdvAccuracy'] = this.mdvAccuracy;
                this.mdv = [];
                this.mdvCo = [];
                this.mdvChanged = [];
                this.mdvAccuracy = [];
              break;
              case 'IPV':
                this.total_fields++;
                this.ipvfound = true;
                this.ipv.push(rawData[itrI].result[itrJ][0][0]);
                this.ipvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ipvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ipvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ipvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ipv'] = this.ipv;
                json['ipvfound'] = this.ipvfound;
                json['page_' + pageIndex]['ipv'] = this.ipv;
                json['ipvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ipvCo'] = this.ipvCo;
                json['page_' + pageIndex]['ipvChanged'] = this.ipvChanged;
                json['ipvChanged'] = this.ipvChanged;
                json['page_' + pageIndex]['ipvAccuracy'] = this.ipvAccuracy;
                try {
                  json['ipv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ipv = [];
                this.ipvCo = [];
                this.ipvChanged = [];
                this.ipvAccuracy = [];
              break;
              case 'IDECV' :
                this.total_fields++;
                this.itvfound = true;
                this.itv.push(rawData[itrI].result[itrJ][0][0]);
                this.itvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.itvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.itvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.itvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['itv'] = this.itv;
                json['itvfound'] = this.itvfound;
                json['page_' + pageIndex]['itv'] = this.itv;
                json['itvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['itvCo'] = this.itvCo;
                json['page_' + pageIndex]['itvChanged'] = this.itvChanged;
                json['itvChanged'] = this.itvChanged;
                json['page_' + pageIndex]['itvAccuracy'] = this.itvAccuracy;
                try {
                  json['itv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.itv = [];
                this.itvCo = [];
                this.itvChanged = [];
                this.itvAccuracy = [];
              break;
              case 'TAV':
                this.total_fields++;
                this.itvfound = true;
                this.itv.push(rawData[itrI].result[itrJ][0][0]);
                this.itvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.itvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.itvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.itvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['itv'] = this.itv;
                json['itvfound'] = this.itvfound;
                json['page_' + pageIndex]['itv'] = this.itv;
                json['itvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['itvCo'] = this.itvCo;
                json['page_' + pageIndex]['itvChanged'] = this.itvChanged;
                json['itvChanged'] = this.itvChanged;
                json['page_' + pageIndex]['itvAccuracy'] = this.itvAccuracy;
                try {
                  json['itv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.itv = [];
                this.itvCo = [];
                this.itvChanged = [];
                this.itvAccuracy = [];
              break;
              case 'CGSTV':
                this.total_fields++;
                this.cgstvfound = true;
                this.cgstv.push(rawData[itrI].result[itrJ][0][0]);
                this.cgstvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cgstvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cgstvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cgstvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cgstv'] = this.cgstv;
                json['cgstvfound'] = this.cgstvfound;
                json['page_' + pageIndex]['cgstv'] = this.cgstv;
                json['cgstvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cgstvCo'] = this.cgstvCo;
                json['cgstvCo'] = this.cgstvCo;
                json['page_' + pageIndex]['cgstvChanged'] = this.cgstvChanged;
                json['cgstvChanged'] = this.cgstvChanged;
                json['page_' + pageIndex]['cgstvAccuracy'] = this.cgstvAccuracy;
                try {
                  json['cgstv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cgstv = [];
                this.cgstvCo = [];
                this.cgstvChanged = [];
                this.cgstvAccuracy = [];
              break;
              case 'SGSTV':
                this.total_fields++;
                this.sgstvfound = true;
                this.sgstv.push(rawData[itrI].result[itrJ][0][0]);
                this.sgstvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.sgstvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.sgstvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.sgstvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['sgstv'] = this.sgstv;
                json['sgstvfound'] = this.sgstvfound;
                json['page_' + pageIndex]['sgstv'] = this.sgstv;
                json['sgstvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['sgstvCo'] = this.sgstvCo;
                json['sgstvCo'] = this.sgstvCo;
                json['page_' + pageIndex]['sgstvChanged'] = this.sgstvChanged;
                json['sgstvChanged'] = this.sgstvChanged;
                json['page_' + pageIndex]['sgstvAccuracy'] = this.sgstvAccuracy;
                try {
                  json['sgstv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.sgstv = [];
                this.sgstvCo = [];
                this.sgstvChanged = [];
                this.sgstvAccuracy = [];
              break;
              case 'IGSTV':
                this.total_fields++;
                this.igstvfound = true;
                this.igstv.push(rawData[itrI].result[itrJ][0][0]);
                this.igstvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.igstvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.igstvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.igstvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['igstv'] = this.igstv;
                json['igstvfound'] = this.igstvfound;
                json['page_' + pageIndex]['igstv'] = this.igstv;
                json['igstvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['igstvCo'] = this.igstvCo;
                json['igstvCo'] = this.igstvCo;
                json['page_' + pageIndex]['igstvChanged'] = this.igstvChanged;
                json['igstvChanged'] = this.igstvChanged;
                json['page_' + pageIndex]['igstvAccuracy'] = this.igstvAccuracy;
                try {
                  json['igstv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.igstv = [];
                this.igstvCo = [];
                this.igstvChanged = [];
                this.igstvAccuracy = [];
              break;
              case 'CGSTP':
                this.total_fields++;
                this.cgstpfound = true;
                this.cgstp.push(rawData[itrI].result[itrJ][0][0]);
                this.cgstpAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cgstpCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cgstpCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cgstpChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cgstp'] = this.cgstp;
                json['cgstpfound'] = this.cgstpfound;
                json['page_' + pageIndex]['cgstp'] = this.cgstp;
                json['cgstpPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cgstpCo'] = this.cgstpCo;
                json['cgstpCo'] = this.cgstpCo;
                json['page_' + pageIndex]['cgstpChanged'] = this.cgstpChanged;
                json['cgstpChanged'] = this.cgstpChanged;
                json['page_' + pageIndex]['cgstpAccuracy'] = this.cgstpAccuracy;
                try {
                  json['cgstp_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cgstp = [];
                this.cgstpCo = [];
                this.cgstpChanged = [];
                this.cgstpAccuracy = [];
              break;
              case 'SGSTP':
                this.total_fields++;
                this.sgstpfound = true;
                this.sgstp.push(rawData[itrI].result[itrJ][0][0]);
                this.sgstpAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.sgstpCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.sgstpCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.sgstpChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['sgstp'] = this.sgstp;
                json['sgstpfound'] = this.sgstpfound;
                json['page_' + pageIndex]['sgstp'] = this.sgstp;
                json['sgstpPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['sgstpCo'] = this.sgstpCo;
                json['sgstpCo'] = this.sgstpCo;
                json['page_' + pageIndex]['sgstpChanged'] = this.sgstpChanged;
                json['sgstpChanged'] = this.sgstpChanged;
                json['page_' + pageIndex]['sgstpAccuracy'] = this.sgstpAccuracy;
                try {
                  json['sgstp_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.sgstp = [];
                this.sgstpCo = [];
                this.sgstpChanged = [];
                this.sgstpAccuracy = [];
              break;
              case 'IGSTP':
                this.total_fields++;
                this.igstpfound = true;
                this.igstp.push(rawData[itrI].result[itrJ][0][0]);
                this.igstpAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.igstpCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.igstpCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.igstpChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['igstp'] = this.igstp;
                json['igstpfound'] = this.igstpfound;
                json['page_' + pageIndex]['igstp'] = this.igstp;
                json['igstpPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['igstpCo'] = this.igstpCo;
                json['igstpCo'] = this.igstpCo;
                json['page_' + pageIndex]['igstpChanged'] = this.igstpChanged;
                json['igstpChanged'] = this.igstpChanged;
                json['page_' + pageIndex]['igstpAccuracy'] = this.igstpAccuracy;
                try {
                  json['igstp_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.igstp = [];
                this.igstpCo = [];
                this.igstpChanged = [];
                this.igstpAccuracy = [];
              break;
              case 'TXAV':
                this.total_fields++;
                this.txavfound = true;
                this.txav.push(rawData[itrI].result[itrJ][0][0]);
                this.txavAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.txavCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.txavCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.txavChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['txav'] = this.txav;
                json['txavfound'] = this.txavfound;
                json['page_' + pageIndex]['txav'] = this.txav;
                json['txavPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['txavCo'] = this.txavCo;
                json['txavCo'] = this.txavCo;
                json['page_' + pageIndex]['txavChanged'] = this.txavChanged;
                json['txavChanged'] = this.txavChanged;
                json['page_' + pageIndex]['txavAccuracy'] = this.txavAccuracy;
                try {
                  json['txav_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.txav = [];
                this.txavCo = [];
                this.txavChanged = [];
                this.txavAccuracy = [];
              break;
              case 'GNV':
                this.total_fields++;
                this.gnvfound = true;
                this.gnv.push(rawData[itrI].result[itrJ][0][0]);
                this.gnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.gnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.gnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.gnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['gnv'] = this.gnv;
                json['gnvfound'] = this.gnvfound;
                json['page_' + pageIndex]['gnv'] = this.gnv;
                json['gnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['gnvCo'] = this.gnvCo;
                json['gnvCo'] = this.gnvCo;
                json['page_' + pageIndex]['gnvChanged'] = this.gnvChanged;
                json['gnvChanged'] = this.gnvChanged;
                json['page_' + pageIndex]['gnvAccuracy'] = this.gnvAccuracy;
                try {
                  json['gnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.gnv = [];
                this.gnvCo = [];
                this.gnvChanged = [];
                this.gnvAccuracy = [];
              break;
              case 'IHV':
                this.total_fields++;
                this.ihvfound = true;
                this.ihv.push(rawData[itrI].result[itrJ][0][0]);
                this.ihvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ihvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ihvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ihvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ihv'] = this.ihv;
                json['ihvfound'] = this.ihvfound;
                json['page_' + pageIndex]['ihv'] = this.ihv;
                json['ihvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ihvCo'] = this.ihvCo;
                json['ihvCo'] = this.ihvCo;
                json['page_' + pageIndex]['ihvChanged'] = this.ihvChanged;
                json['ihvChanged'] = this.ihvChanged;
                json['page_' + pageIndex]['ihvAccuracy'] = this.ihvAccuracy;
                try {
                  json['ihv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ihv = [];
                this.ihvCo = [];
                this.ihvChanged = [];
                this.ihvAccuracy = [];
              break;
              case 'BDV':
                this.total_fields++;
                this.bdvfound = true;
                this.bdv.push(rawData[itrI].result[itrJ][0][0]);
                this.bdvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.bdvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.bdvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.bdvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['bdv'] = this.bdv;
                json['bdvfound'] = this.bdvfound;
                json['page_' + pageIndex]['bdv'] = this.bdv;
                json['bdvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['bdvCo'] = this.bdvCo;
                json['bdvCo'] = this.bdvCo;
                json['page_' + pageIndex]['bdvChanged'] = this.bdvChanged;
                json['bdvChanged'] = this.bdvChanged;
                json['page_' + pageIndex]['bdvAccuracy'] = this.bdvAccuracy;
                try {
                  json['bdv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.bdv = [];
                this.bdvCo = [];
                this.bdvChanged = [];
                this.bdvAccuracy = [];
              break;
              case 'CSNV':
                this.total_fields++;
                this.csnvfound = true;
                this.csnv.push(rawData[itrI].result[itrJ][0][0]);
                this.csnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.csnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.csnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.csnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['csnv'] = this.csnv;
                json['csnvfound'] = this.csnvfound;
                json['page_' + pageIndex]['csnv'] = this.csnv;
                json['csnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['csnvCo'] = this.csnvCo;
                json['csnvCo'] = this.csnvCo;
                json['page_' + pageIndex]['csnvChanged'] = this.csnvChanged;
                json['csnvChanged'] = this.csnvChanged;
                json['page_' + pageIndex]['csnvAccuracy'] = this.csnvAccuracy;
                try {
                  json['csnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.csnv = [];
                this.csnvCo = [];
                this.csnvChanged = [];
                this.csnvAccuracy = [];
              break;
              case 'SSNV':
                this.total_fields++;
                this.ssnvfound = true;
                this.ssnv.push(rawData[itrI].result[itrJ][0][0]);
                this.ssnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ssnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ssnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ssnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ssnv'] = this.ssnv;
                json['ssnvfound'] = this.ssnvfound;
                json['page_' + pageIndex]['ssnv'] = this.ssnv;
                json['ssnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ssnvCo'] = this.ssnvCo;
                json['ssnvCo'] = this.ssnvCo;
                json['page_' + pageIndex]['ssnvChanged'] = this.ssnvChanged;
                json['ssnvChanged'] = this.ssnvChanged;
                json['page_' + pageIndex]['ssnvAccuracy'] = this.ssnvAccuracy;
                try {
                  json['ssnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ssnv = [];
                this.ssnvCo = [];
                this.ssnvChanged = [];
                this.ssnvAccuracy = [];
              break;
              case 'SCNV':
                this.total_fields++;
                this.scnvfound = true;
                this.scnv.push(rawData[itrI].result[itrJ][0][0]);
                this.scnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.scnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.scnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.scnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['scnv'] = this.scnv;
                json['scnvfound'] = this.scnvfound;
                json['page_' + pageIndex]['scnv'] = this.scnv;
                json['scnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['scnvCo'] = this.scnvCo;
                json['scnvCo'] = this.scnvCo;
                json['page_' + pageIndex]['scnvChanged'] = this.scnvChanged;
                json['scnvChanged'] = this.scnvChanged;
                json['page_' + pageIndex]['scnvAccuracy'] = this.scnvAccuracy;
                try {
                  json['scnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.scnv = [];
                this.scnvCo = [];
                this.scnvChanged = [];
                this.scnvAccuracy = [];
              break;
              case 'CCNV':
                this.total_fields++;
                this.ccnvfound = true;
                this.ccnv.push(rawData[itrI].result[itrJ][0][0]);
                this.ccnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ccnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ccnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ccnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ccnv'] = this.ccnv;
                json['ccnvfound'] = this.ccnvfound;
                json['page_' + pageIndex]['ccnv'] = this.ccnv;
                json['ccnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ccnvCo'] = this.ccnvCo;
                json['ccnvCo'] = this.ccnvCo;
                json['page_' + pageIndex]['ccnvChanged'] = this.ccnvChanged;
                json['ccnvChanged'] = this.ccnvChanged;
                json['page_' + pageIndex]['ccnvAccuracy'] = this.ccnvAccuracy;
                try {
                  json['ccnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ccnv = [];
                this.ccnvCo = [];
                this.ccnvChanged = [];
                this.ccnvAccuracy = [];
              break;
              case 'ILV':
                this.total_fields++;
                this.ilvfound = true;
                this.ilv.push(rawData[itrI].result[itrJ][0][0]);
                this.ilvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ilvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ilvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ilvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ilv'] = this.ilv;
                json['ilvfound'] = this.ilvfound;
                json['page_' + pageIndex]['ilv'] = this.ilv;
                json['ilvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ilvCo'] = this.ilvCo;
                json['ilvCo'] = this.ilvCo;
                json['page_' + pageIndex]['ilvChanged'] = this.ilvChanged;
                json['ilvChanged'] = this.ilvChanged;
                json['page_' + pageIndex]['ilvAccuracy'] = this.ilvAccuracy;
                try {
                  json['ilv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ilv = [];
                this.ilvCo = [];
                this.ilvChanged = [];
                this.ilvAccuracy = [];
              break;
              case 'PTV':
                this.total_fields++;
                this.ptvfound = true;
                this.ptv.push(rawData[itrI].result[itrJ][0][0]);
                this.ptvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ptvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ptvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ptvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ptv'] = this.ptv;
                json['ptvfound'] = this.ptvfound;
                json['page_' + pageIndex]['ptv'] = this.ptv;
                json['ptvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ptvCo'] = this.ptvCo;
                json['ptvCo'] = this.ptvCo;
                json['page_' + pageIndex]['ptvChanged'] = this.ptvChanged;
                json['ptvChanged'] = this.ptvChanged;
                json['page_' + pageIndex]['ptvAccuracy'] = this.ptvAccuracy;
                try {
                  json['ptv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ptv = [];
                this.ptvCo = [];
                this.ptvChanged = [];
                this.ptvAccuracy = [];
              break;
              case 'IITV':
                this.total_fields++;
                this.iitvfound = true;
                this.iitv.push(rawData[itrI].result[itrJ][0][0]);
                this.iitvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.iitvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.iitvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.iitvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['iitv'] = this.iitv;
                json['iitvfound'] = this.iitvfound;
                json['page_' + pageIndex]['iitv'] = this.iitv;
                json['iitvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['iitvCo'] = this.iitvCo;
                json['iitvCo'] = this.iitvCo;
                json['page_' + pageIndex]['iitvChanged'] = this.iitvChanged;
                json['iitvChanged'] = this.iitvChanged;
                json['page_' + pageIndex]['iitvAccuracy'] = this.iitvAccuracy;
                try {
                  json['iitv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.iitv = [];
                this.iitvCo = [];
                this.iitvChanged = [];
                this.iitvAccuracy = [];
              break;
              case 'SPNV':
                this.total_fields++;
                this.spnvfound = true;
                this.spnv.push(rawData[itrI].result[itrJ][0][0]);
                this.spnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.spnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.spnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.spnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['spnv'] = this.spnv;
                json['spnvfound'] = this.spnvfound;
                json['page_' + pageIndex]['spnv'] = this.spnv;
                json['spnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['spnvCo'] = this.spnvCo;
                json['spnvCo'] = this.spnvCo;
                json['page_' + pageIndex]['spnvChanged'] = this.spnvChanged;
                json['spnvChanged'] = this.spnvChanged;
                json['page_' + pageIndex]['spnvAccuracy'] = this.spnvAccuracy;
                try {
                  json['spnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.spnv = [];
                this.spnvCo = [];
                this.spnvChanged = [];
                this.spnvAccuracy = [];
              break;
              case 'CPNV':
                this.total_fields++;
                this.cpnvfound = true;
                this.cpnv.push(rawData[itrI].result[itrJ][0][0]);
                this.cpnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cpnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cpnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cpnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cpnv'] = this.cpnv;
                json['cpnvfound'] = this.cpnvfound;
                json['page_' + pageIndex]['cpnv'] = this.cpnv;
                json['cpnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cpnvCo'] = this.cpnvCo;
                json['cpnvCo'] = this.cpnvCo;
                json['page_' + pageIndex]['cpnvChanged'] = this.cpnvChanged;
                json['cpnvChanged'] = this.cpnvChanged;
                json['page_' + pageIndex]['cpnvAccuracy'] = this.cpnvAccuracy;
                try {
                  json['cpnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cpnv = [];
                this.cpnvCo = [];
                this.cpnvChanged = [];
                this.cpnvAccuracy = [];
              break;
              case 'ICNV':
                this.total_fields++;
                this.icnvfound = true;
                this.icnv.push(rawData[itrI].result[itrJ][0][0]);
                this.icnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.icnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.icnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.icnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['icnv'] = this.icnv;
                json['icnvfound'] = this.icnvfound;
                json['page_' + pageIndex]['icnv'] = this.icnv;
                json['icnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['icnvCo'] = this.icnvCo;
                json['icnvCo'] = this.icnvCo;
                json['page_' + pageIndex]['icnvChanged'] = this.icnvChanged;
                json['icnvChanged'] = this.icnvChanged;
                json['page_' + pageIndex]['icnvAccuracy'] = this.icnvAccuracy;
                try {
                  json['icnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.icnv = [];
                this.icnvCo = [];
                this.icnvChanged = [];
                this.icnvAccuracy = [];
              break;
              case 'DNV':
                this.total_fields++;
                this.dnvfound = true;
                this.dnv.push(rawData[itrI].result[itrJ][0][0]);
                this.dnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.dnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.dnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.dnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['dnv'] = this.dnv;
                json['dnvfound'] = this.dnvfound;
                json['page_' + pageIndex]['dnv'] = this.dnv;
                json['dnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['dnvCo'] = this.dnvCo;
                json['dnvCo'] = this.dnvCo;
                json['page_' + pageIndex]['dnvChanged'] = this.dnvChanged;
                json['dnvChanged'] = this.dnvChanged;
                json['page_' + pageIndex]['dnvAccuracy'] = this.dnvAccuracy;
                try {
                  json['dnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.dnv = [];
                this.dnvCo = [];
                this.dnvChanged = [];
                this.dnvAccuracy = [];
              break;
              case 'QIV':
                this.total_fields++;
                this.qivfound = true;
                this.qiv.push(rawData[itrI].result[itrJ][0][0]);
                this.qivAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.qivCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.qivCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.qivChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['qiv'] = this.qiv;
                json['qivfound'] = this.qivfound;
                json['page_' + pageIndex]['qiv'] = this.qiv;
                json['qivPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['qivCo'] = this.qivCo;
                json['qivCo'] = this.qivCo;
                json['page_' + pageIndex]['qivChanged'] = this.qivChanged;
                json['qivChanged'] = this.qivChanged;
                json['page_' + pageIndex]['qivAccuracy'] = this.qivAccuracy;
                try {
                  json['qiv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.qiv = [];
                this.qivCo = [];
                this.qivChanged = [];
                this.qivAccuracy = [];
              break;
              case 'NIQ':
                this.total_fields++;
                this.niqfound = true;
                this.niq.push(this.rawData[itrI].result[itrJ][0][0]);
                this.niqAccuracy.push(this.rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.niqCo.push(this.rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.niqCo.push([]);
                }
                pageIndex = this.rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.niqChanged.push(this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['niq'] = this.niq;
                json['niqfound'] = this.niqfound;
                json['page_' + pageIndex]['niq'] = this.niq;
                json['niqPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['niqCo'] = this.niqCo;
                json['niqCo'] = this.niqCo;
                json['page_' + pageIndex]['niqChanged'] = this.niqChanged;
                json['niqChanged'] = this.niqChanged;
                json['page_' + pageIndex]['niqAccuracy'] = this.niqAccuracy;
                try {
                  json['niq_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.niq = [];
                this.niqCo = [];
                this.niqChanged = [];
                this.niqAccuracy = [];
              break;
              case 'NAN':
                this.total_fields++;
                this.nanfound = true;
                this.nan.push(this.rawData[itrI].result[itrJ][0][0]);
                this.nanAccuracy.push(this.rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.nanCo.push(this.rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.nanCo.push([]);
                }
                pageIndex = this.rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.nanChanged.push(this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['nan'] = this.nan;
                json['nanfound'] = this.nanfound;
                json['page_' + pageIndex]['nan'] = this.nan;
                json['nanPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['nanCo'] = this.nanCo;
                json['nanCo'] = this.nanCo;
                json['page_' + pageIndex]['nanChanged'] = this.nanChanged;
                json['nanChanged'] = this.nanChanged;
                json['page_' + pageIndex]['nanAccuracy'] = this.nanAccuracy;
                try {
                  json['nan_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.nan = [];
                this.nanCo = [];
                this.nanChanged = [];
                this.nanAccuracy = [];
              break;
              case 'DOCTYPE':
                this.total_fields++;
                this.ntypefound = true;
                this.ntype.push(this.rawData[itrI].result[itrJ][0][0]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ntypeCo.push(this.rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ntypeCo.push([]);
                }
                pageIndex = this.rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ntypeChanged.push(this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ntype'] = this.ntype;
                json['ntyefound'] = this.ntypefound;
                json['page_' + pageIndex]['ntype'] = this.ntype;
                json['ntypePage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ntypeCo'] = this.ntypeCo;
                json['ntypeCo'] = this.ntypeCo;
                json['page_' + pageIndex]['ntypeChanged'] = this.ntypeChanged;
                json['ntypeChanged'] = this.ntypeChanged;
                json['page_' + pageIndex]['ntypeAccuracy'] = this.ntypeAccuracy;
                try {
                  json['ntype_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ntype = [];
                this.ntypeCo = [];
                this.ntypeChanged = [];
                this.ntypeAccuracy = [];
              break;
              case 'NRN1':
                this.total_fields++;
                this.nrn1found = true;
                this.nrn1.push(this.rawData[itrI].result[itrJ][0][0]);
                this.nrn1Accuracy.push(this.rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.nrn1Co.push(this.rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.nrn1Co.push([]);
                }
                pageIndex = this.rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.nrn1Changed.push(this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['nrn1'] = this.nrn1;
                json['nrn1found'] = this.nrn1found;
                json['page_' + pageIndex]['nrn1'] = this.nrn1;
                json['nrn1Page'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['nrn1Co'] = this.nrn1Co;
                json['nrn1Co'] = this.nrn1Co;
                json['page_' + pageIndex]['nrn1Changed'] = this.nrn1Changed;
                json['nrn1Changed'] = this.nrn1Changed;
                json['page_' + pageIndex]['nrn1Accuracy'] = this.nrn1Accuracy;
                try {
                  json['nrn1_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.nrn1 = [];
                this.nrn1Co = [];
                this.nrn1Changed = [];
                this.nrn1Accuracy = [];
              break;
              case 'NSEF':
                this.total_fields++;
                this.nseffound = true;
                this.nsef.push(this.rawData[itrI].result[itrJ][0][0]);
                this.nsefAccuracy.push(this.rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.nsefCo.push(this.rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.nsefCo.push([]);
                }
                pageIndex = this.rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.nsefChanged.push(this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['nsef'] = this.nsef;
                json['nseffound'] = this.nseffound;
                json['page_' + pageIndex]['nsef'] = this.nsef;
                json['nsefPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['nsefCo'] = this.nsefCo;
                json['nsefCo'] = this.nsefCo;
                json['page_' + pageIndex]['nsefChanged'] = this.nsefChanged;
                json['nsefChanged'] = this.nsefChanged;
                json['page_' + pageIndex]['nsefAccuracy'] = this.nsefAccuracy;
                try {
                  json['nsef_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.nsef = [];
                this.nsefCo = [];
                this.nsefChanged = [];
                this.nsefAccuracy = [];
              break;
              case 'NBNEF':
                this.total_fields++;
                this.nbneffound = true;
                this.nbnef.push(this.rawData[itrI].result[itrJ][0][0]);
                this.nbnefAccuracy.push(this.rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.nbnefCo.push(this.rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.nbnefCo.push([]);
                }
                pageIndex = this.rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.nbnefChanged.push(this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (this.rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['nbnef'] = this.nbnef;
                json['nbneffound'] = this.nbneffound;
                json['page_' + pageIndex]['nbnef'] = this.nbnef;
                json['nbnefPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['nbnefCo'] = this.nbnefCo;
                json['nbnefCo'] = this.nbnefCo;
                json['page_' + pageIndex]['nbnefChanged'] = this.nbnefChanged;
                json['nbnefChanged'] = this.nbnefChanged;
                json['page_' + pageIndex]['nbnefAccuracy'] = this.nbnefAccuracy;
                try {
                  json['nbnef_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.nbnef = [];
                this.nbnefCo = [];
                this.nbnefChanged = [];
                this.nbnefAccuracy = [];
              break;
              case 'PNV':
                this.total_fields++;
                this.pnvfound = true;
                this.pnv.push(rawData[itrI].result[itrJ][0][0]);
                this.pnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.pnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.pnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.pnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['pnv'] = this.pnv;
                json['pnvfound'] = this.pnvfound;
                json['page_' + pageIndex]['pnv'] = this.pnv;
                json['pnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['pnvCo'] = this.pnvCo;
                json['pnvCo'] = this.pnvCo;
                json['page_' + pageIndex]['pnvChanged'] = this.pnvChanged;
                json['pnvChanged'] = this.pnvChanged;
                json['page_' + pageIndex]['pnvAccuracy'] = this.pnvAccuracy;
                try {
                  json['pnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.pnv = [];
                this.pnvCo = [];
                this.pnvChanged = [];
                this.pnvAccuracy = [];
              break;
              case 'SIDV':
                this.total_fields++;
                this.sidvfound = true;
                this.sidv.push(rawData[itrI].result[itrJ][0][0]);
                this.sidvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.sidvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.sidvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.sidvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['sidv'] = this.sidv;
                json['sidvfound'] = this.sidvfound;
                json['page_' + pageIndex]['sidv'] = this.sidv;
                json['sidvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['sidvCo'] = this.sidvCo;
                json['sidvCo'] = this.sidvCo;
                json['page_' + pageIndex]['sidvChanged'] = this.sidvChanged;
                json['sidvChanged'] = this.sidvChanged;
                json['page_' + pageIndex]['sidvAccuracy'] = this.sidvAccuracy;
                try {
                  json['sidv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.sidv = [];
                this.sidvCo = [];
                this.sidvChanged = [];
                this.sidvAccuracy = [];
              break;
              case 'EIDV':
                this.total_fields++;
                this.eidvfound = true;
                this.eidv.push(rawData[itrI].result[itrJ][0][0]);
                this.eidvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.eidvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.eidvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.eidvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['eidv'] = this.eidv;
                json['eidvfound'] = this.eidvfound;
                json['page_' + pageIndex]['eidv'] = this.eidv;
                json['eidvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['eidvCo'] = this.eidvCo;
                json['eidvCo'] = this.eidvCo;
                json['page_' + pageIndex]['eidvChanged'] = this.eidvChanged;
                json['eidvChanged'] = this.eidvChanged;
                json['page_' + pageIndex]['eidvAccuracy'] = this.eidvAccuracy;
                try {
                  json['eidv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.eidv = [];
                this.eidvCo = [];
                this.eidvChanged = [];
                this.eidvAccuracy = [];
              break;
              case 'PDV':
                this.total_fields++;
                this.pdvfound = true;
                this.pdv.push(rawData[itrI].result[itrJ][0][0]);
                this.pdvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.pdvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.pdvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.pdvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['pdv'] = this.pdv;
                json['pdvfound'] = this.pdvfound;
                json['page_' + pageIndex]['pdv'] = this.pdv;
                json['pdvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['pdvCo'] = this.pdvCo;
                json['pdvCo'] = this.pdvCo;
                json['page_' + pageIndex]['pdvChanged'] = this.pdvChanged;
                json['pdvChanged'] = this.pdvChanged;
                json['page_' + pageIndex]['pdvAccuracy'] = this.pdvAccuracy;
                try {
                  json['pdv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.pdv = [];
                this.pdvCo = [];
                this.pdvChanged = [];
                this.pdvAccuracy = [];
              break;
              case 'PMV':
                this.total_fields++;
                this.pmvfound = true;
                this.pmv.push(rawData[itrI].result[itrJ][0][0]);
                this.pmvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.pmvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.pmvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.pmvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['pmv'] = this.pmv;
                json['pmvfound'] = this.pmvfound;
                json['page_' + pageIndex]['pmv'] = this.pmv;
                json['pmvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['pmvCo'] = this.pmvCo;
                json['pmvCo'] = this.pmvCo;
                json['page_' + pageIndex]['pmvChanged'] = this.pmvChanged;
                json['pmvChanged'] = this.pmvChanged;
                json['page_' + pageIndex]['pmvAccuracy'] = this.pmvAccuracy;
                this.pmv = [];
                this.pmvCo = [];
                this.pmvChanged = [];
                this.pmvAccuracy = [];
              break;
              case 'CTV':
                this.total_fields++;
                this.ctvfound = true;
                this.ctv.push(rawData[itrI].result[itrJ][0][0]);
                this.ctvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ctvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ctvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ctvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ctv'] = this.ctv;
                json['ctvfound'] = this.ctvfound;
                json['page_' + pageIndex]['ctv'] = this.ctv;
                json['ctvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ctvCo'] = this.ctvCo;
                json['ctvCo'] = this.ctvCo;
                json['page_' + pageIndex]['ctvChanged'] = this.ctvChanged;
                json['ctvChanged'] = this.ctvChanged;
                json['page_' + pageIndex]['ctvAccuracy'] = this.ctvAccuracy;
                try {
                  json['ctv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ctv = [];
                this.ctvCo = [];
                this.ctvChanged = [];
                this.ctvAccuracy = [];
              break;
              case 'VTV':
                this.total_fields++;
                this.vtvfound = true;
                this.vtv.push(rawData[itrI].result[itrJ][0][0]);
                this.vtvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.vtvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.vtvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.vtvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['vtv'] = this.vtv;
                json['vtvfound'] = this.vtvfound;
                json['page_' + pageIndex]['vtv'] = this.vtv;
                json['vtvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['vtvCo'] = this.vtvCo;
                json['vtvCo'] = this.vtvCo;
                json['page_' + pageIndex]['vtvChanged'] = this.vtvChanged;
                json['vtvChanged'] = this.vtvChanged;
                json['page_' + pageIndex]['vtvAccuracy'] = this.vtvAccuracy;
                try {
                  json['vtv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.vtv = [];
                this.vtvCo = [];
                this.vtvChanged = [];
                this.vtvAccuracy = [];
              break;
              case 'MAV':
                this.total_fields++;
                this.mavfound = true;
                this.mav.push(rawData[itrI].result[itrJ][0][0]);
                this.mavAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.mavCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.mavCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.mavChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['mav'] = this.mav;
                json['mavfound'] = this.mavfound;
                json['page_' + pageIndex]['mav'] = this.mav;
                json['mavPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['mavCo'] = this.mavCo;
                json['mavCo'] = this.mavCo;
                json['page_' + pageIndex]['mavChanged'] = this.mavChanged;
                json['mavChanged'] = this.mavChanged;
                json['page_' + pageIndex]['mavAccuracy'] = this.mavAccuracy;
                try {
                  json['mav_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.mav = [];
                this.mavCo = [];
                this.mavChanged = [];
                this.mavAccuracy = [];
              break;
              case 'PFV':
                this.total_fields++;
                this.pfvfound = true;
                this.pfv.push(rawData[itrI].result[itrJ][0][0]);
                this.pfvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.pfvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.pfvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.pfvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['pfv'] = this.pfv;
                json['pfvfound'] = this.pfvfound;
                json['page_' + pageIndex]['pfv'] = this.pfv;
                json['pfvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['pfvCo'] = this.pfvCo;
                json['pfvCo'] = this.pfvCo;
                json['page_' + pageIndex]['pfvChanged'] = this.pfvChanged;
                json['pfvChanged'] = this.pfvChanged;
                json['page_' + pageIndex]['pfvAccuracy'] = this.pfvAccuracy;
                try {
                  json['pfv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.pfv = [];
                this.pfvCo = [];
                this.pfvChanged = [];
                this.pfvAccuracy = [];
              break;
              case 'STAV':
                this.total_fields++;
                this.stavfound = true;
                this.stav.push(rawData[itrI].result[itrJ][0][0]);
                this.stavAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.stavCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.stavCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.stavChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['stav'] = this.stav;
                json['stavfound'] = this.stavfound;
                json['page_' + pageIndex]['stav'] = this.stav;
                json['stavPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['stavCo'] = this.stavCo;
                json['stavCo'] = this.stavCo;
                json['page_' + pageIndex]['stavChanged'] = this.stavChanged;
                json['stavChanged'] = this.stavChanged;
                json['page_' + pageIndex]['stavAccuracy'] = this.stavAccuracy;
                try {
                  json['stav_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.stav = [];
                this.stavCo = [];
                this.stavChanged = [];
                this.stavAccuracy = [];
              break;
              case 'CRV':
                this.total_fields++;
                this.crvfound = true;
                this.crv.push(rawData[itrI].result[itrJ][0][0]);
                this.crvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.crvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.crvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.crvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['crv'] = this.crv;
                json['crvfound'] = this.crvfound;
                json['page_' + pageIndex]['crv'] = this.crv;
                json['crvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['crvCo'] = this.crvCo;
                json['crvCo'] = this.crvCo;
                json['page_' + pageIndex]['crvChanged'] = this.crvChanged;
                json['crvChanged'] = this.crvChanged;
                json['page_' + pageIndex]['crvAccuracy'] = this.crvAccuracy;
                try {
                  json['crv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.crv = [];
                this.crvCo = [];
                this.crvChanged = [];
                this.crvAccuracy = [];
              break;
              case 'TECV':
                this.total_fields++;
                this.tecvfound = true;
                this.tecv.push(rawData[itrI].result[itrJ][0][0]);
                this.tecvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.tecvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.tecvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.tecvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['tecv'] = this.tecv;
                json['tecvfound'] = this.tecvfound;
                json['page_' + pageIndex]['tecv'] = this.tecv;
                json['tecvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['tecvCo'] = this.tecvCo;
                json['tecvCo'] = this.tecvCo;
                json['page_' + pageIndex]['tecvChanged'] = this.tecvChanged;
                json['tecvChanged'] = this.tecvChanged;
                json['page_' + pageIndex]['tecvAccuracy'] = this.tecvAccuracy;
                try {
                  json['tecv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.tecv = [];
                this.tecvCo = [];
                this.tecvChanged = [];
                this.tecvAccuracy = [];
              break;
              case 'BMV':
                this.total_fields++;
                this.bmvfound = true;
                this.bmv.push(rawData[itrI].result[itrJ][0][0]);
                this.bmvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.bmvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.bmvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.bmvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['bmv'] = this.bmv;
                json['bmvfound'] = this.bmvfound;
                json['page_' + pageIndex]['bmv'] = this.bmv;
                json['bmvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['bmvCo'] = this.bmvCo;
                json['bmvCo'] = this.bmvCo;
                json['page_' + pageIndex]['bmvChanged'] = this.bmvChanged;
                json['bmvChanged'] = this.bmvChanged;
                json['page_' + pageIndex]['bmvAccuracy'] = this.bmvAccuracy;
                try {
                  json['bmv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.bmv = [];
                this.bmvCo = [];
                this.bmvChanged = [];
                this.bmvAccuracy = [];
              break;
              case 'VATP':
                this.total_fields++;
                this.vatpfound = true;
                this.vatp.push(rawData[itrI].result[itrJ][0][0]);
                this.vatpAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.vatpCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.vatpCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.vatpChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['vatp'] = this.vatp;
                json['vatpfound'] = this.vatpfound;
                json['page_' + pageIndex]['vatp'] = this.vatp;
                json['vatpPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['vatpCo'] = this.vatpCo;
                json['vatpCo'] = this.vatpCo;
                json['page_' + pageIndex]['vatpChanged'] = this.vatpChanged;
                json['vatpChanged'] = this.vatpChanged;
                json['page_' + pageIndex]['vatpAccuracy'] = this.vatpAccuracy;
                try {
                  json['vatp_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.vatp = [];
                this.vatpCo = [];
                this.vatpChanged = [];
                this.vatpAccuracy = [];
              break;
              case 'ISLV':
                this.total_fields++;
                this.islvfound = true;
                this.islv.push(rawData[itrI].result[itrJ][0][0]);
                this.islvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.islvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.islvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.islvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['islv'] = this.islv;
                json['islvfound'] = this.islvfound;
                json['page_' + pageIndex]['islv'] = this.islv;
                json['islvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['islvCo'] = this.islvCo;
                json['islvCo'] = this.islvCo;
                json['page_' + pageIndex]['islvChanged'] = this.islvChanged;
                json['islvChanged'] = this.islvChanged;
                json['page_' + pageIndex]['islvAccuracy'] = this.islvAccuracy;
                try {
                  json['islv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.islv = [];
                this.islvCo = [];
                this.islvChanged = [];
                this.islvAccuracy = [];
              break;
              case 'ICLV':
                this.total_fields++;
                this.iclvfound = true;
                this.iclv.push(rawData[itrI].result[itrJ][0][0]);
                this.iclvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.iclvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.iclvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.iclvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['iclv'] = this.iclv;
                json['iclvfound'] = this.iclvfound;
                json['page_' + pageIndex]['iclv'] = this.iclv;
                json['iclvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['iclvCo'] = this.iclvCo;
                json['iclvCo'] = this.iclvCo;
                json['page_' + pageIndex]['iclvChanged'] = this.iclvChanged;
                json['iclvChanged'] = this.iclvChanged;
                json['page_' + pageIndex]['iclvAccuracy'] = this.iclvAccuracy;
                try {
                  json['iclv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.iclv = [];
                this.iclvCo = [];
                this.iclvChanged = [];
                this.iclvAccuracy = [];
              break;
              case 'SCRN':
                this.total_fields++;
                this.scrnfound = true;
                this.scrn.push(rawData[itrI].result[itrJ][0][0]);
                this.scrnAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.scrnCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.scrnCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.scrnChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['scrn'] = this.scrn;
                json['scrnfound'] = this.scrnfound;
                json['page_' + pageIndex]['scrn'] = this.scrn;
                json['scrnPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['scrnCo'] = this.scrnCo;
                json['scrnCo'] = this.scrnCo;
                json['page_' + pageIndex]['scrnChanged'] = this.scrnChanged;
                json['scrnChanged'] = this.scrnChanged;
                json['page_' + pageIndex]['scrnAccuracy'] = this.scrnAccuracy;
                try {
                  json['scrn_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.scrn = [];
                this.scrnCo = [];
                this.scrnChanged = [];
                this.scrnAccuracy = [];
              break;
              case 'LECRN':
                this.total_fields++;
                this.lecrnfound = true;
                this.lecrn.push(rawData[itrI].result[itrJ][0][0]);
                this.lecrnAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.lecrnCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.lecrnCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.lecrnChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['lecrn'] = this.lecrn;
                json['lecrnfound'] = this.lecrnfound;
                json['page_' + pageIndex]['lecrn'] = this.lecrn;
                json['lecrnPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['lecrnCo'] = this.lecrnCo;
                json['lecrnCo'] = this.lecrnCo;
                json['page_' + pageIndex]['lecrnChanged'] = this.lecrnChanged;
                json['lecrnChanged'] = this.lecrnChanged;
                json['page_' + pageIndex]['lecrnAccuracy'] = this.lecrnAccuracy;
                try {
                  json['lecrn_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.lecrn = [];
                this.lecrnCo = [];
                this.lecrnChanged = [];
                this.lecrnAccuracy = [];
              break;
              case 'LRCRN':
                this.total_fields++;
                this.lrcrnfound = true;
                this.lrcrn.push(rawData[itrI].result[itrJ][0][0]);
                this.lrcrnAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.lrcrnCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.lrcrnCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.lrcrnChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['lrcrn'] = this.lrcrn;
                json['lrcrnfound'] = this.lrcrnfound;
                json['page_' + pageIndex]['lrcrn'] = this.lrcrn;
                json['lrcrnPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['lrcrnCo'] = this.lrcrnCo;
                json['lrcrnCo'] = this.lrcrnCo;
                json['page_' + pageIndex]['lrcrnChanged'] = this.lrcrnChanged;
                json['lrcrnChanged'] = this.lrcrnChanged;
                json['page_' + pageIndex]['lrcrnAccuracy'] = this.lrcrnAccuracy;
                try {
                  json['lrcrn_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.lrcrn = [];
                this.lrcrnCo = [];
                this.lrcrnChanged = [];
                this.lrcrnAccuracy = [];
              break;
              case 'ISLAV':
                this.total_fields++;
                this.islavfound = true;
                this.islav.push(rawData[itrI].result[itrJ][0][0]);
                this.islavAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.islavCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.islavCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.islavChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['islav'] = this.islav;
                json['islavfound'] = this.islavfound;
                json['page_' + pageIndex]['islav'] = this.islav;
                json['islavPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['islavCo'] = this.islavCo;
                json['islavCo'] = this.islavCo;
                json['page_' + pageIndex]['islavChanged'] = this.islavChanged;
                json['islavChanged'] = this.islavChanged;
                json['page_' + pageIndex]['islavAccuracy'] = this.islavAccuracy;
                try {
                  json['islav_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.islav = [];
                this.islavCo = [];
                this.islavChanged = [];
                this.islavAccuracy = [];
              break;
              case 'ICLAV':
                this.total_fields++;
                this.iclavfound = true;
                this.iclav.push(rawData[itrI].result[itrJ][0][0]);
                this.iclavAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.iclavCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.iclavCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.iclavChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['iclav'] = this.iclav;
                json['iclavfound'] = this.iclavfound;
                json['page_' + pageIndex]['iclav'] = this.iclav;
                json['iclavPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['iclavCo'] = this.iclavCo;
                json['iclavCo'] = this.iclavCo;
                json['page_' + pageIndex]['iclavChanged'] = this.iclavChanged;
                json['iclavChanged'] = this.iclavChanged;
                json['page_' + pageIndex]['iclavAccuracy'] = this.iclavAccuracy;
                try {
                  json['iclav_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.iclav = [];
                this.iclavCo = [];
                this.iclavChanged = [];
                this.iclavAccuracy = [];
              break;
              case 'CESSV':
                this.total_fields++;
                this.cessvfound = true;
                this.cessv.push(rawData[itrI].result[itrJ][0][0]);
                this.cessvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cessvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cessvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cessvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cessv'] = this.cessv;
                json['cessvfound'] = this.cessvfound;
                json['page_' + pageIndex]['cessv'] = this.cessv;
                json['cessvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cessvCo'] = this.cessvCo;
                json['cessvCo'] = this.cessvCo;
                json['page_' + pageIndex]['cessvChanged'] = this.cessvChanged;
                json['cessvChanged'] = this.cessvChanged;
                json['page_' + pageIndex]['cessvAccuracy'] = this.cessvAccuracy;
                try {
                  json['cessv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cessv = [];
                this.cessvCo = [];
                this.cessvChanged = [];
                this.cessvAccuracy = [];
              break;
              case 'TCSV':
                this.total_fields++;
                this.tcsvfound = true;
                this.tcsv.push(rawData[itrI].result[itrJ][0][0]);
                this.tcsvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.tcsvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.tcsvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.tcsvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['tcsv'] = this.tcsv;
                json['tcsvfound'] = this.tcsvfound;
                json['page_' + pageIndex]['tcsv'] = this.tcsv;
                json['tcsvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['tcsvCo'] = this.tcsvCo;
                json['tcsvCo'] = this.tcsvCo;
                json['page_' + pageIndex]['tcsvChanged'] = this.tcsvChanged;
                json['tcsvChanged'] = this.tcsvChanged;
                json['page_' + pageIndex]['tcsvAccuracy'] = this.tcsvAccuracy;
                try {
                  json['tcsv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.tcsv = [];
                this.tcsvCo = [];
                this.tcsvChanged = [];
                this.tcsvAccuracy = [];
              break;
              case 'DISCV':
                this.total_fields++;
                this.discvfound = true;
                this.discv.push(rawData[itrI].result[itrJ][0][0]);
                this.discvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.discvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.discvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.discvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['discv'] = this.discv;
                json['discvfound'] = this.discvfound;
                json['page_' + pageIndex]['discv'] = this.discv;
                json['discvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['discvCo'] = this.discvCo;
                json['discvCo'] = this.discvCo;
                json['page_' + pageIndex]['discvChanged'] = this.discvChanged;
                json['discvChanged'] = this.discvChanged;
                json['page_' + pageIndex]['discvAccuracy'] = this.discvAccuracy;
                try {
                  json['discv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.discv = [];
                this.discvCo = [];
                this.discvChanged = [];
                this.discvAccuracy = [];
              break;
              case 'VRNV':
                this.total_fields++;
                this.vrnvfound = true;
                this.vrnv.push(rawData[itrI].result[itrJ][0][0]);
                this.vrnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.vrnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.vrnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.vrnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['vrnv'] = this.vrnv;
                json['vrnvfound'] = this.vrnvfound;
                json['page_' + pageIndex]['vrnv'] = this.vrnv;
                json['vrnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['vrnvCo'] = this.vrnvCo;
                json['vrnvCo'] = this.vrnvCo;
                json['page_' + pageIndex]['vrnvChanged'] = this.vrnvChanged;
                json['vrnvChanged'] = this.vrnvChanged;
                json['page_' + pageIndex]['vrnvAccuracy'] = this.vrnvAccuracy;
                try {
                  json['vrnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.vrnv = [];
                this.vrnvCo = [];
                this.vrnvChanged = [];
                this.vrnvAccuracy = [];
              break;
              case 'HSNV':
                this.total_fields++;
                this.hsnvfound = true;
                this.hsnv.push(rawData[itrI].result[itrJ][0][0]);
                this.hsnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.hsnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.hsnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.hsnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['hsnv'] = this.hsnv;
                json['hsnvfound'] = this.hsnvfound;
                json['page_' + pageIndex]['hsnv'] = this.hsnv;
                json['hsnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['hsnvCo'] = this.hsnvCo;
                json['hsnvCo'] = this.hsnvCo;
                json['page_' + pageIndex]['hsnvChanged'] = this.hsnvChanged;
                json['hsnvChanged'] = this.hsnvChanged;
                json['page_' + pageIndex]['hsnvAccuracy'] = this.hsnvAccuracy;
                try {
                  json['hsnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.hsnv = [];
                this.hsnvCo = [];
                this.hsnvChanged = [];
                this.hsnvAccuracy = [];
              break;
              case 'LRGV':
                this.total_fields++;
                this.lrgvfound = true;
                this.lrgv.push(rawData[itrI].result[itrJ][0][0]);
                this.lrgvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.lrgvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.lrgvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.lrgvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['lrgv'] = this.lrgv;
                json['lrgvfound'] = this.lrgvfound;
                json['page_' + pageIndex]['lrgv'] = this.lrgv;
                json['lrgvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['lrgvCo'] = this.lrgvCo;
                json['lrgvCo'] = this.lrgvCo;
                json['page_' + pageIndex]['lrgvChanged'] = this.lrgvChanged;
                json['lrgvChanged'] = this.lrgvChanged;
                json['page_' + pageIndex]['lrgvAccuracy'] = this.lrgvAccuracy;
                try {
                  json['lrgv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.lrgv = [];
                this.lrgvCo = [];
                this.lrgvChanged = [];
                this.lrgvAccuracy = [];
              break;
              case 'LEGV':
                this.total_fields++;
                this.legvfound = true;
                this.legv.push(rawData[itrI].result[itrJ][0][0]);
                this.legvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.legvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.legvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.legvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['legv'] = this.legv;
                json['legvfound'] = this.legvfound;
                json['page_' + pageIndex]['legv'] = this.legv;
                json['legvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['legvCo'] = this.legvCo;
                json['legvCo'] = this.legvCo;
                json['page_' + pageIndex]['legvChanged'] = this.legvChanged;
                json['legvChanged'] = this.legvChanged;
                json['page_' + pageIndex]['legvAccuracy'] = this.legvAccuracy;
                this.legv = [];
                this.legvCo = [];
                this.legvChanged = [];
                this.legvAccuracy = [];
              break;
              case 'SMNV':
                this.total_fields++;
                this.smnvfound = true;
                this.smnv.push(rawData[itrI].result[itrJ][0][0]);
                this.smnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.smnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.smnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.smnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['smnv'] = this.smnv;
                json['smnvfound'] = this.smnvfound;
                json['page_' + pageIndex]['smnv'] = this.smnv;
                json['smnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['smnvCo'] = this.smnvCo;
                json['smnvCo'] = this.smnvCo;
                json['page_' + pageIndex]['smnvChanged'] = this.smnvChanged;
                json['smnvChanged'] = this.smnvChanged;
                json['page_' + pageIndex]['smnvAccuracy'] = this.smnvAccuracy;
                try {
                  json['smnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.smnv = [];
                this.smnvCo = [];
                this.smnvChanged = [];
                this.smnvAccuracy = [];
              break;
              case 'ICLMNV':
                this.total_fields++;
                this.iclmnvfound = true;
                this.iclmnv.push(rawData[itrI].result[itrJ][0][0]);
                this.iclmnvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.iclmnvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.iclmnvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.iclmnvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['iclmnv'] = this.iclmnv;
                json['iclmnvfound'] = this.iclmnvfound;
                json['page_' + pageIndex]['iclmnv'] = this.iclmnv;
                json['iclmnvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['iclmnvCo'] = this.iclmnvCo;
                json['iclmnvCo'] = this.iclmnvCo;
                json['page_' + pageIndex]['iclmnvChanged'] = this.iclmnvChanged;
                json['iclmnvChanged'] = this.iclmnvChanged;
                json['page_' + pageIndex]['iclmnvAccuracy'] = this.iclmnvAccuracy;
                try {
                  json['iclmnv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.iclmnv = [];
                this.iclmnvCo = [];
                this.iclmnvChanged = [];
                this.iclmnvAccuracy = [];
              break;
              case 'ICLEIV':
                this.total_fields++;
                this.icleivfound = true;
                this.icleiv.push(rawData[itrI].result[itrJ][0][0]);
                this.icleivAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.icleivCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.icleivCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.icleivChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['icleiv'] = this.icleiv;
                json['icleivfound'] = this.icleivfound;
                json['page_' + pageIndex]['icleiv'] = this.icleiv;
                json['icleivPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['icleivCo'] = this.icleivCo;
                json['icleivCo'] = this.icleivCo;
                json['page_' + pageIndex]['icleivChanged'] = this.icleivChanged;
                json['icleivChanged'] = this.icleivChanged;
                json['page_' + pageIndex]['icleivAccuracy'] = this.icleivAccuracy;
                try {
                  json['icleiv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.icleiv = [];
                this.icleivCo = [];
                this.icleivChanged = [];
                this.icleivAccuracy = [];
              break;
              case 'INSV':
                this.total_fields++;
                this.insvfound = true;
                this.insv.push(rawData[itrI].result[itrJ][0][0]);
                this.insvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.insvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.insvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.insvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['insv'] = this.insv;
                json['insvfound'] = this.insvfound;
                json['page_' + pageIndex]['insv'] = this.insv;
                json['insvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['insvCo'] = this.insvCo;
                json['insvCo'] = this.insvCo;
                json['page_' + pageIndex]['insvChanged'] = this.insvChanged;
                json['insvChanged'] = this.insvChanged;
                json['page_' + pageIndex]['insvAccuracy'] = this.insvAccuracy;
                try {
                  json['insv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.insv = [];
                this.insvCo = [];
                this.insvChanged = [];
                this.insvAccuracy = [];
              break;
              case 'REGV':
                this.total_fields++;
                this.regvfound = true;
                this.regv.push(rawData[itrI].result[itrJ][0][0]);
                this.regvAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.regvCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.regvCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.regvChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['regv'] = this.regv;
                json['regvfound'] = this.regvfound;
                json['page_' + pageIndex]['regv'] = this.regv;
                json['regvPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['regvCo'] = this.regvCo;
                json['regvCo'] = this.regvCo;
                json['page_' + pageIndex]['regvChanged'] = this.regvChanged;
                json['regvChanged'] = this.regvChanged;
                json['page_' + pageIndex]['regvAccuracy'] = this.regvAccuracy;
                try {
                  json['regv_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.regv = [];
                this.regvCo = [];
                this.regvChanged = [];
                this.regvAccuracy = [];
              break;
              case 'INL':
                this.invlfound = true;
                this.invl.push(rawData[itrI].result[itrJ][0][0]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.invlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.invlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.invlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['invl'] = this.invl;
                json['invlfound'] = this.invlfound;
                json['page_' + pageIndex]['invl'] = this.invl;
                json['invlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['invlCo'] = this.invlCo;
                json['page_' + pageIndex]['invlChanged'] = this.invlChanged;
                json['invlChanged'] = this.invlChanged;
                json['page_' + pageIndex]['invlAccuracy'] = this.invlAccuracy;
                try {
                  json['invl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.invl = [];
                this.invlCo = [];
                this.invlChanged = [];
                this.invlAccuracy = [];
              break;
              case 'CHNL':
                this.chnvlfound = true;
                this.chnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.chnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.chnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.chnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.chnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['chnvl'] = this.chnvl;
                json['chnvlfound'] = this.chnvlfound;
                json['page_' + pageIndex]['chnvl'] = this.chnvl;
                json['chnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['chnvlCo'] = this.chnvlCo;
                json['page_' + pageIndex]['chnvlChanged'] = this.chnvlChanged;
                json['chnvlChanged'] = this.chnvlChanged;
                json['page_' + pageIndex]['chnvlAccuracy'] = this.chnvlAccuracy;
                try {
                  json['chnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.chnvl = [];
                this.chnvlCo = [];
                this.chnvlChanged = [];
                this.chnvlAccuracy = [];
              break;
              case 'ENL':
                this.envllfound = true;
                this.envl.push(rawData[itrI].result[itrJ][0][0]);
                this.envlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.envlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.envlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.envlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['envl'] = this.envl;
                json['envlfound'] = this.envlfound;
                json['page_' + pageIndex]['envl'] = this.envl;
                json['envlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['envlCo'] = this.envlCo;
                json['page_' + pageIndex]['envlChanged'] = this.envlChanged;
                json['envlChanged'] = this.envlChanged;
                json['page_' + pageIndex]['envlAccuracy'] = this.envlAccuracy;
                try {
                  json['envl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.envl = [];
                this.envlCo = [];
                this.envlChanged = [];
                this.envlAccuracy = [];
              break;
              case 'MNL':
                this.mnvlfound = true;
                this.mnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.mnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.mnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.mnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.mnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['mnvl'] = this.mnvl;
                json['mnvlfound'] = this.mnvlfound;
                json['page_' + pageIndex]['mnvl'] = this.mnvl;
                json['mnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['mnvlCo'] = this.mnvlCo;
                json['page_' + pageIndex]['mnvlChanged'] = this.mnvlChanged;
                json['mnvlChanged'] = this.mnvlChanged;
                json['page_' + pageIndex]['mnvlAccuracy'] = this.mnvlAccuracy;
                try {
                  json['mnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.mnvl = [];
                this.mnvlCo = [];
                this.mnvlChanged = [];
                this.mnvlAccuracy = [];
              break;
              case 'EML':
                this.emvlfound = true;
                this.emvl.push(rawData[itrI].result[itrJ][0][0]);
                this.emvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.emvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.emvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.emvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['emvl'] = this.emvl;
                json['emvlfound'] = this.emvlfound;
                json['page_' + pageIndex]['emvl'] = this.emvl;
                json['emvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['emvlCo'] = this.emvlCo;
                json['page_' + pageIndex]['emvlChanged'] = this.emvlChanged;
                json['emvlChanged'] = this.emvlChanged;
                json['page_' + pageIndex]['emvlAccuracy'] = this.emvlAccuracy;
                try {
                  json['emvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.emvl = [];
                this.emvlCo = [];
                this.emvlChanged = [];
                this.emvlAccuracy = [];
              break;
              case 'ISL':
                this.isvlfound = true;
                this.isvl.push(rawData[itrI].result[itrJ][0][0]);
                this.isvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  if (rawData[itrI].result[itrJ][1][0] &&
                    rawData[itrI].result[itrJ][1][1] &&
                    rawData[itrI].result[itrJ][1][2] &&
                    rawData[itrI].result[itrJ][1][3]) {
                    this.isvlCo.push(rawData[itrI].result[itrJ][1]);
                    if (rawData[itrI].result[itrJ][1][4] === -1) {
                      json['isvlTrained'] = true;
                    } else {
                      json['isvlTrained'] = false;
                    }
                  } else {
                    this.isvlCo.push(rawData[itrI].result[itrJ][1]);
                    json['isvlTrained'] = false;
                  }
                } else {
                  itemIndex = 0;
                  this.isvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.isvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['isvl'] = this.isvl;
                json['isvlfound'] = this.isvlfound;
                json['page_' + pageIndex]['isvl'] = this.isvl;
                json['isvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['isvlCo'] = this.isvlCo;
                json['isvlCo'] = this.isvlCo;
                json['page_' + pageIndex]['isvlChanged'] = this.isvlChanged;
                json['isvlChanged'] = this.isvlChanged;
                json['page_' + pageIndex]['isvlAccuracy'] = this.isvlAccuracy;
                try {
                  json['isvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.isvl = [];
                this.isvlCo = [];
                this.isvlChanged = [];
                this.isvlAccuracy = [];
              break;
              case 'CNL':
                try {
                  this.cnvlfound = true;
                  this.cnvl.push(rawData[itrI].result[itrJ][0][0]);
                  this.cnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                    if (rawData[itrI].result[itrJ][1][0] &&
                      rawData[itrI].result[itrJ][1][1] &&
                      rawData[itrI].result[itrJ][1][2] &&
                      rawData[itrI].result[itrJ][1][3]) {
                      this.cnvlCo.push(rawData[itrI].result[itrJ][1]);
                    } else {
                      this.cnvlCo.push(rawData[itrI].result[itrJ][1]);
                    }
                  } else {
                    itemIndex = 0;
                    this.cnvlCo.push([]);
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  this.cnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['cnvl'] = this.cnvl;
                  json['cnvlfound'] = this.cnvlfound;
                  json['page_' + pageIndex]['cnvl'] = this.cnvl;
                  json['cnvlPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['cnvlCo'] = this.cnvlCo;
                  json['cnvlCo'] = this.cnvlCo;
                  json['page_' + pageIndex]['cnvlChanged'] = this.cnvlChanged;
                  json['cnvlChanged'] = this.cnvlChanged;
                  json['page_' + pageIndex]['cnvlAccuracy'] = this.cnvlAccuracy;
                  try {
                    json['cnvl_old_log'] = {
                      old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                      modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                      modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                    }
                  } catch (e) {}
                  this.cnvl = [];
                  this.cnvlCo = [];
                  this.cnvlChanged = [];
                  this.cnvlAccuracy = [];
                } catch (e) {
                  console.log(e);
                }
              break;
              case 'CAL':
                this.cavlfound = true;
                this.cavl.push(rawData[itrI].result[itrJ][0][0]);
                this.cavlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  if (rawData[itrI].result[itrJ][1][0] &&
                    rawData[itrI].result[itrJ][1][1] &&
                    rawData[itrI].result[itrJ][1][2] &&
                    rawData[itrI].result[itrJ][1][3]) {
                      this.cavlCo.push(rawData[itrI].result[itrJ][1]);
                  } else {
                    this.cavlCo.push(rawData[itrI].result[itrJ][1]);
                  }
                } else {
                  itemIndex = 0;
                  this.cavlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cavlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cavl'] = this.cavl;
                json['cavlfound'] = this.cavlfound;
                json['page_' + pageIndex]['cavl'] = this.cavl;
                json['cavlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cavlCo'] = this.cavlCo;
                json['cavlCo'] = this.cavlCo;
                json['page_' + pageIndex]['cavlChanged'] = this.cavlChanged;
                json['cavlChanged'] = this.cavlChanged;
                json['page_' + pageIndex]['cavlAccuracy'] = this.cavlAccuracy;
                try {
                  json['cavl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cavl = [];
                this.cavlCo = [];
                this.cavlChanged = [];
                this.cavlAccuracy = [];
              break;
              case 'SAL':
                this.savlfound = true;
                this.savl.push(rawData[itrI].result[itrJ][0][0]);
                this.savlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  if (rawData[itrI].result[itrJ][1][0] &&
                    rawData[itrI].result[itrJ][1][1] &&
                    rawData[itrI].result[itrJ][1][2] &&
                    rawData[itrI].result[itrJ][1][3]) {
                      this.savlCo.push(rawData[itrI].result[itrJ][1]);
                  } else {
                    this.savlCo.push(rawData[itrI].result[itrJ][1]);
                  }
                } else {
                  itemIndex = 0;
                  this.savlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.savlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['savl'] = this.savl;
                json['savlfound'] = this.savlfound;
                json['page_' + pageIndex]['savl'] = this.savl;
                json['savlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['savlCo'] = this.savlCo;
                json['savlCo'] = this.savlCo;
                json['page_' + pageIndex]['savlChanged'] = this.savlChanged;
                json['savlChanged'] = this.savlChanged;
                json['page_' + pageIndex]['savlAccuracy'] = this.savlAccuracy;
                try {
                  json['savl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.savl = [];
                this.savlCo = [];
                this.savlChanged = [];
                this.savlAccuracy = [];
              break;
              case 'IDL':
                this.idvlfound = true;
                this.idvl.push(rawData[itrI].result[itrJ][0][0]);
                this.idvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.idvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.idvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.idvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['idvl'] = this.idvl;
                json['idvlfound'] = this.idvlfound;
                json['page_' + pageIndex]['idvl'] = this.idvl;
                json['idvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['idvlCo'] = this.idvlCo;
                json['page_' + pageIndex]['idvlChanged'] = this.idvlChanged;
                json['idvlChanged'] = this.idvlChanged;
                json['page_' + pageIndex]['idvlAccuracy'] = this.idvlAccuracy;
                try {
                  json['idvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.idvl = [];
                this.idvlCo = [];
                this.idvlChanged = [];
                this.idvlAccuracy = [];
              break;
              case 'IGL':
                if (rawData[itrI].is_approved && igvCount > 1) {
                  rawData[itrI].result.splice(itrJ--, 1);
                  continue;
                } else {
                  igvlCount++;
                  this.igvlfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (igvlPageIndex !== pageIndex) {
                    igvlCount = 2;
                    this.igvl = [];
                    this.igvlCo = [];
                    this.igvlChanged = [];
                    this.igvlAccuracy = [];
                    igvlPageIndex = pageIndex;
                  } else if (igvlCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.igvlCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.igvlCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.igvl.push(rawData[itrI].result[itrJ][0][0]);
                  this.igvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.igvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['igvl'] = this.igvl;
                  json['igvlfound'] = this.igvlfound;
                  json['page_' + pageIndex]['igvl'] = this.igvl;
                  json['igvlPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['igvlCo'] = this.igvlCo;
                  json['page_' + pageIndex]['igvlChanged'] = this.igvlChanged;
                  json['igvlChanged'] = this.igvlChanged;
                  json['page_' + pageIndex]['igvlAccuracy'] = this.igvlAccuracy;
                  try {
                    json['igvl_old_log'] = {
                      old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                      modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                      modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                    }
                  } catch (e) {}
                }
              break;
              case 'CGL':
                if (rawData[itrI].is_approved && cgvCount > 1) {
                  rawData[itrI].result.splice(itrJ--, 1);
                  continue;
                } else {
                  cgvlCount++;
                  this.cgvlfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (cgvlPageIndex !== pageIndex) {
                    cgvlCount = 2;
                    this.cgvl = [];
                    this.cgvlCo = [];
                    this.cgvlChanged = [];
                    this.cgvlAccuracy = [];
                    cgvlPageIndex = pageIndex;
                  } else if (cgvlCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.cgvlCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.cgvlCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.cgvl.push(rawData[itrI].result[itrJ][0][0]);
                  this.cgvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.cgvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['cgvl'] = this.cgvl;
                  json['cgvlfound'] = this.cgvlfound;
                  json['page_' + pageIndex]['cgvl'] = this.cgvl;
                  json['cgvlPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['cgvlCo'] = this.cgvlCo;
                  json['page_' + pageIndex]['cgvlChanged'] = this.cgvlChanged;
                  json['cgvlChanged'] = this.cgvlChanged;
                  json['page_' + pageIndex]['cgvlAccuracy'] = this.cgvlAccuracy;
                }
              break;
              case 'IVL':
                if (rawData[itrI].is_approved && ivvCount > 1) {
                  delete rawData[itrI].result[itrJ];
                } else {
                  ivvlCount++;
                  this.ivvlfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (ivvlPageIndex !== pageIndex) {
                    ivvlCount = 2;
                    this.ivvl = [];
                    this.ivvlCo = [];
                    this.ivvlChanged = [];
                    this.ivvlAccuracy = [];
                    ivvlPageIndex = pageIndex;
                  } else if (ivvlCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.ivvlCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.ivvlCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.ivvl.push(rawData[itrI].result[itrJ][0][0]);
                  this.ivvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.ivvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['ivvl'] = this.ivvl;
                  json['ivvlfound'] = this.ivvlfound;
                  json['page_' + pageIndex]['ivvl'] = this.ivvl;
                  json['ivvlPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['ivvlCo'] = this.ivvlCo;
                  json['page_' + pageIndex]['ivvlChanged'] = this.ivvlChanged;
                  json['ivvlChanged'] = this.ivvlChanged;
                  json['page_' + pageIndex]['ivvlAccuracy'] = this.ivvlAccuracy;
                  try {
                    json['ivvl_old_log'] = {
                      old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                      modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                      modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                    }
                  } catch (e) {}
                }
              break;
              case 'CVL':
                if (rawData[itrI].is_approved && cvvCount > 1) {
                  delete rawData[itrI].result[itrJ];
                } else {
                  cvvlCount++;
                  this.cvvlfound = true;

                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (cvvlPageIndex !== pageIndex) {
                    cvvlCount = 2;
                    this.cvvl = [];
                    this.cvvlCo = [];
                    this.cvvlChanged = [];
                    this.cvvlAccuracy = [];
                    cvvlPageIndex = pageIndex;
                  } else if (cvvlCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.cvvlCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.cvvlCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.cvvl.push(rawData[itrI].result[itrJ][0][0]);
                  this.cvvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.cvvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['cvvl'] = this.cvvl;
                  json['cvvlfound'] = this.cvvlfound;
                  json['page_' + pageIndex]['cvvl'] = this.cvvl;
                  json['cvvlPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['cvvlCo'] = this.cvvlCo;
                  json['page_' + pageIndex]['cvvlChanged'] = this.cvvlChanged;
                  json['cvvlChanged'] = this.cvvlChanged;
                  json['page_' + pageIndex]['cvvlAccuracy'] = this.cvvlAccuracy;
                  try {
                    json['cvvl_old_log'] = {
                      old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                      modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                      modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                    }
                  } catch (e) {}
                }
              break;
              case 'ICL':
                this.icvlfound = true;
                this.icvl.push(rawData[itrI].result[itrJ][0][0]);
                this.icvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.icvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.icvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                try {
                  this.icvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                } catch (e) {}
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['icvl'] = this.icvl;
                json['icvlfound'] = this.icvlfound;
                json['page_' + pageIndex]['icvl'] = this.icvl;
                json['icvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['icvlCo'] = this.icvlCo;
                json['page_' + pageIndex]['icvlChanged'] = this.icvlChanged;
                json['icvlChanged'] = this.icvlChanged;
                json['page_' + pageIndex]['icvlAccuracy'] = this.icvlAccuracy;
                try {
                  json['icvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.icvl = [];
                this.icvlCo = [];
                this.icvlChanged = [];
                this.icvlAccuracy = [];
              break;
              case 'FCL':
                this.fcvlfound = true;
                this.fcvl.push(rawData[itrI].result[itrJ][0][0]);
                this.fcvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.fcvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.fcvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                try {
                  this.fcvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                } catch (e) {}
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['fcvl'] = this.fcvl;
                json['fcvlfound'] = this.fcvlfound;
                json['page_' + pageIndex]['fcvl'] = this.fcvl;
                json['fcvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['fcvlCo'] = this.fcvlCo;
                json['page_' + pageIndex]['fcvlChanged'] = this.fcvlChanged;
                json['fcvlChanged'] = this.fcvlChanged;
                json['page_' + pageIndex]['fcvlAccuracy'] = this.fcvlAccuracy;
                try {
                  json['fcvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.fcvl = [];
                this.fcvlCo = [];
                this.fcvlChanged = [];
                this.fcvlAccuracy = [];
              break;
              case 'MDL':
                this.mdvlfound = true;
                this.mdvl.push(rawData[itrI].result[itrJ][0][0]);
                this.mdvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.mdvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.mdvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.mdvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['mdvl'] = this.mdvl;
                json['mdvlfound'] = this.mdvlfound;
                json['page_' + pageIndex]['mdvl'] = this.mdvl;
                json['mdvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['mdvlCo'] = this.mdvlCo;
                json['page_' + pageIndex]['mdvlChanged'] = this.mdvlChanged;
                json['mdvlChanged'] = this.mdvlChanged;
                json['page_' + pageIndex]['mdvlAccuracy'] = this.mdvlAccuracy;
                try {
                  json['mdvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.mdvl = [];
                this.mdvlCo = [];
                this.mdvlChanged = [];
                this.mdvlAccuracy = [];
              break;
              case 'IPL':
                this.ipvlfound = true;
                this.ipvl.push(rawData[itrI].result[itrJ][0][0]);
                this.ipvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ipvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ipvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ipvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ipvl'] = this.ipvl;
                json['ipvlfound'] = this.ipvlfound;
                json['page_' + pageIndex]['ipvl'] = this.ipvl;
                json['ipvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ipvlCo'] = this.ipvlCo;
                json['page_' + pageIndex]['ipvlChanged'] = this.ipvlChanged;
                json['ipvlChanged'] = this.ipvlChanged;
                json['page_' + pageIndex]['ipvlAccuracy'] = this.ipvlAccuracy;
                try {
                  json['ipvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ipvl = [];
                this.ipvlCo = [];
                this.ipvlChanged = [];
                this.ipvlAccuracy = [];
              break;
              case 'TAL':
                this.itvlfound = true;
                this.itvl.push(rawData[itrI].result[itrJ][0][0]);
                this.itvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.itvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.itvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.itvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['itvl'] = this.itvl;
                json['itvlfound'] = this.itvlfound;
                json['page_' + pageIndex]['itvl'] = this.itvl;
                json['itvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['itvlCo'] = this.itvlCo;
                json['page_' + pageIndex]['itvlChanged'] = this.itvlChanged;
                json['itvlChanged'] = this.itvlChanged;
                json['page_' + pageIndex]['itvlAccuracy'] = this.itvlAccuracy;
                try {
                  json['itvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.itvl = [];
                this.itvlCo = [];
                this.itvlChanged = [];
                this.itvlAccuracy = [];
              break;
              case 'CGSTVL':
                this.cgstvlfound = true;
                this.cgstvl.push(rawData[itrI].result[itrJ][0][0]);
                this.cgstvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cgstvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cgstvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cgstvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cgstvl'] = this.cgstvl;
                json['cgstvlfound'] = this.cgstvlfound;
                json['page_' + pageIndex]['cgstvl'] = this.cgstvl;
                json['cgstvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cgstvlCo'] = this.cgstvlCo;
                json['cgstvlCo'] = this.cgstvlCo;
                json['page_' + pageIndex]['cgstvlChanged'] = this.cgstvlChanged;
                json['cgstvlChanged'] = this.cgstvlChanged;
                json['page_' + pageIndex]['cgstvlAccuracy'] = this.cgstvlAccuracy;
                try {
                  json['cgstvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cgstvl = [];
                this.cgstvlCo = [];
                this.cgstvlChanged = [];
                this.cgstvlAccuracy = [];
              break;
              case 'SGSTVL':
                this.sgstvlfound = true;
                this.sgstvl.push(rawData[itrI].result[itrJ][0][0]);
                this.sgstvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.sgstvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.sgstvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.sgstvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['sgstvl'] = this.sgstvl;
                json['sgstvlfound'] = this.sgstvlfound;
                json['page_' + pageIndex]['sgstvl'] = this.sgstvl;
                json['sgstvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['sgstvlCo'] = this.sgstvlCo;
                json['sgstvlCo'] = this.sgstvlCo;
                json['page_' + pageIndex]['sgstvlChanged'] = this.sgstvlChanged;
                json['sgstvlChanged'] = this.sgstvlChanged;
                json['page_' + pageIndex]['sgstvlAccuracy'] = this.sgstvlAccuracy;
                try {
                  json['sgstvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.sgstvl = [];
                this.sgstvlCo = [];
                this.sgstvlChanged = [];
                this.sgstvlAccuracy = [];
              break;
              case 'IGSTVL':
                this.igstvlfound = true;
                this.igstvl.push(rawData[itrI].result[itrJ][0][0]);
                this.igstvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.igstvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.igstvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.igstvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['igstvl'] = this.igstvl;
                json['igstvlfound'] = this.igstvlfound;
                json['page_' + pageIndex]['igstvl'] = this.igstvl;
                json['igstvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['igstvlCo'] = this.igstvlCo;
                json['igstvlCo'] = this.igstvlCo;
                json['page_' + pageIndex]['igstvlChanged'] = this.igstvlChanged;
                json['igstvlChanged'] = this.igstvlChanged;
                json['page_' + pageIndex]['igstvlAccuracy'] = this.igstvlAccuracy;
                try {
                  json['igstvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.igstvl = [];
                this.igstvlCo = [];
                this.igstvlChanged = [];
                this.igstvlAccuracy = [];
              break;
              case 'CGSTPL':
                this.cgstplfound = true;
                this.cgstpl.push(rawData[itrI].result[itrJ][0][0]);
                this.cgstplAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cgstplCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cgstplCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cgstplChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cgstpl'] = this.cgstpl;
                json['cgstplfound'] = this.cgstplfound;
                json['page_' + pageIndex]['cgstpl'] = this.cgstpl;
                json['cgstplPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cgstplCo'] = this.cgstplCo;
                json['cgstplCo'] = this.cgstplCo;
                json['page_' + pageIndex]['cgstplChanged'] = this.cgstplChanged;
                json['cgstplChanged'] = this.cgstplChanged;
                json['page_' + pageIndex]['cgstplAccuracy'] = this.cgstplAccuracy;
                try {
                  json['cgstpl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cgstpl = [];
                this.cgstplCo = [];
                this.cgstplChanged = [];
                this.cgstplAccuracy = [];
              break;
              case 'SGSTPL':
                this.sgstplfound = true;
                this.sgstpl.push(rawData[itrI].result[itrJ][0][0]);
                this.sgstplAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.sgstplCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.sgstplCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.sgstplChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['sgstpl'] = this.sgstpl;
                json['sgstplfound'] = this.sgstplfound;
                json['page_' + pageIndex]['sgstpl'] = this.sgstpl;
                json['sgstplPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['sgstplCo'] = this.sgstplCo;
                json['sgstplCo'] = this.sgstplCo;
                json['page_' + pageIndex]['sgstplChanged'] = this.sgstplChanged;
                json['sgstplChanged'] = this.sgstplChanged;
                json['page_' + pageIndex]['sgstplAccuracy'] = this.sgstplAccuracy;
                try {
                  json['sgstpl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.sgstpl = [];
                this.sgstplCo = [];
                this.sgstplChanged = [];
                this.sgstplAccuracy = [];
              break;
              case 'IGSTPL':
                this.igstplfound = true;
                this.igstpl.push(rawData[itrI].result[itrJ][0][0]);
                this.igstplAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.igstplCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.igstplCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.igstplChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['igstpl'] = this.igstpl;
                json['igstplfound'] = this.igstplfound;
                json['page_' + pageIndex]['igstpl'] = this.igstpl;
                json['igstplPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['igstplCo'] = this.igstplCo;
                json['igstplCo'] = this.igstplCo;
                json['page_' + pageIndex]['igstplChanged'] = this.igstplChanged;
                json['igstplChanged'] = this.igstplChanged;
                json['page_' + pageIndex]['igstplAccuracy'] = this.igstplAccuracy;
                try {
                  json['igstpl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.igstpl = [];
                this.igstplCo = [];
                this.igstplChanged = [];
                this.igstplAccuracy = [];
              break;
              case 'TXAL':
                this.txavlfound = true;
                this.txavl.push(rawData[itrI].result[itrJ][0][0]);
                this.txavlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.txavlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.txavlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.txavlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['txavl'] = this.txavl;
                json['txavlfound'] = this.txavlfound;
                json['page_' + pageIndex]['txavl'] = this.txavl;
                json['txavlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['txavlCo'] = this.txavlCo;
                json['txavlCo'] = this.txavlCo;
                json['page_' + pageIndex]['txavlChanged'] = this.txavlChanged;
                json['txavlChanged'] = this.txavlChanged;
                json['page_' + pageIndex]['txavlAccuracy'] = this.txavlAccuracy;
                try {
                  json['txavl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.txavl = [];
                this.txavlCo = [];
                this.txavlChanged = [];
                this.txavlAccuracy = [];
              break;
              case 'GNL':
                this.gnvlfound = true;
                this.gnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.gnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.gnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.gnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.gnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['gnvl'] = this.gnvl;
                json['gnvlfound'] = this.gnvlfound;
                json['page_' + pageIndex]['gnvl'] = this.gnvl;
                json['gnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['gnvlCo'] = this.gnvlCo;
                json['gnvlCo'] = this.gnvlCo;
                json['page_' + pageIndex]['gnvlChanged'] = this.gnvlChanged;
                json['gnvlChanged'] = this.gnvlChanged;
                json['page_' + pageIndex]['gnvlAccuracy'] = this.gnvlAccuracy;
                try {
                  json['gnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.gnvl = [];
                this.gnvlCo = [];
                this.gnvlChanged = [];
                this.gnvlAccuracy = [];
              break;
              case 'IHL':
                this.ihvlfound = true;
                this.ihvl.push(rawData[itrI].result[itrJ][0][0]);
                this.ihvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ihvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ihvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ihvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ihvl'] = this.ihvl;
                json['ihvlfound'] = this.ihvlfound;
                json['page_' + pageIndex]['ihvl'] = this.ihvl;
                json['ihvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ihvlCo'] = this.ihvlCo;
                json['ihvlCo'] = this.ihvlCo;
                json['page_' + pageIndex]['ihvlChanged'] = this.ihvlChanged;
                json['ihvlChanged'] = this.ihvlChanged;
                json['page_' + pageIndex]['ihvlAccuracy'] = this.ihvlAccuracy;
                try {
                  json['ihvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ihvl = [];
                this.ihvlCo = [];
                this.ihvlChanged = [];
                this.ihvlAccuracy = [];
              break;
              case 'BDL':
                this.bdvlfound = true;
                this.bdvl.push(rawData[itrI].result[itrJ][0][0]);
                this.bdvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.bdvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.bdvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.bdvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['bdvl'] = this.bdvl;
                json['bdvlfound'] = this.bdvlfound;
                json['page_' + pageIndex]['bdvl'] = this.bdvl;
                json['bdvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['bdvlCo'] = this.bdvlCo;
                json['bdvlCo'] = this.bdvlCo;
                json['page_' + pageIndex]['bdvlChanged'] = this.bdvlChanged;
                json['bdvlChanged'] = this.bdvlChanged;
                json['page_' + pageIndex]['bdvlAccuracy'] = this.bdvlAccuracy;
                try {
                  json['bdvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.bdvl = [];
                this.bdvlCo = [];
                this.bdvlChanged = [];
                this.bdvlAccuracy = [];
              break;
              case 'CSNL':
                this.csnvlfound = true;
                this.csnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.csnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.csnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.csnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.csnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['csnvl'] = this.csnvl;
                json['csnvlfound'] = this.csnvlfound;
                json['page_' + pageIndex]['csnvl'] = this.csnvl;
                json['csnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['csnvlCo'] = this.csnvlCo;
                json['csnvlCo'] = this.csnvlCo;
                json['page_' + pageIndex]['csnvlChanged'] = this.csnvlChanged;
                json['csnvlChanged'] = this.csnvlChanged;
                json['page_' + pageIndex]['csnvlAccuracy'] = this.csnvlAccuracy;
                try {
                  json['csnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.csnvl = [];
                this.csnvlCo = [];
                this.csnvlChanged = [];
                this.csnvlAccuracy = [];
              break;
              case 'SSNL':
                this.ssnvlfound = true;
                this.ssnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.ssnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ssnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ssnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ssnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ssnvl'] = this.ssnvl;
                json['ssnvlfound'] = this.ssnvlfound;
                json['page_' + pageIndex]['ssnvl'] = this.ssnvl;
                json['ssnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ssnvlCo'] = this.ssnvlCo;
                json['ssnvlCo'] = this.ssnvlCo;
                json['page_' + pageIndex]['ssnvlChanged'] = this.ssnvlChanged;
                json['ssnvlChanged'] = this.ssnvlChanged;
                json['page_' + pageIndex]['ssnvlAccuracy'] = this.ssnvlAccuracy;
                try {
                  json['ssnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ssnvl = [];
                this.ssnvlCo = [];
                this.ssnvlChanged = [];
                this.ssnvlAccuracy = [];
              break;
              case 'SCNL':
                this.scnvlfound = true;
                this.scnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.scnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.scnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.scnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.scnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['scnvl'] = this.scnvl;
                json['scnvlfound'] = this.scnvlfound;
                json['page_' + pageIndex]['scnvl'] = this.scnvl;
                json['scnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['scnvlCo'] = this.scnvlCo;
                json['scnvlCo'] = this.scnvlCo;
                json['page_' + pageIndex]['scnvlChanged'] = this.scnvlChanged;
                json['scnvlChanged'] = this.scnvlChanged;
                json['page_' + pageIndex]['scnvlAccuracy'] = this.scnvlAccuracy;
                try {
                  json['scnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.scnvl = [];
                this.scnvlCo = [];
                this.scnvlChanged = [];
                this.scnvlAccuracy = [];
              break;
              case 'CCNL':
                this.ccnvlfound = true;
                this.ccnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.ccnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ccnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ccnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ccnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ccnvl'] = this.ccnvl;
                json['ccnvlfound'] = this.ccnvlfound;
                json['page_' + pageIndex]['ccnvl'] = this.ccnvl;
                json['ccnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ccnvlCo'] = this.ccnvlCo;
                json['ccnvlCo'] = this.ccnvlCo;
                json['page_' + pageIndex]['ccnvlChanged'] = this.ccnvlChanged;
                json['ccnvlChanged'] = this.ccnvlChanged;
                json['page_' + pageIndex]['ccnvlAccuracy'] = this.ccnvlAccuracy;
                try {
                  json['ccnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ccnvl = [];
                this.ccnvlCo = [];
                this.ccnvlChanged = [];
                this.ccnvlAccuracy = [];
              break;
              case 'ILL':
                this.ilvlfound = true;
                this.ilvl.push(rawData[itrI].result[itrJ][0][0]);
                this.ilvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ilvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ilvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ilvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ilvl'] = this.ilvl;
                json['ilvlfound'] = this.ilvlfound;
                json['page_' + pageIndex]['ilvl'] = this.ilvl;
                json['ilvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ilvlCo'] = this.ilvlCo;
                json['ilvlCo'] = this.ilvlCo;
                json['page_' + pageIndex]['ilvlChanged'] = this.ilvlChanged;
                json['ilvlChanged'] = this.ilvlChanged;
                json['page_' + pageIndex]['ilvlAccuracy'] = this.ilvlAccuracy;
                try {
                  json['ilvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ilvl = [];
                this.ilvlCo = [];
                this.ilvlChanged = [];
                this.ilvlAccuracy = [];
              break;
              case 'PTL':
                this.ptvlfound = true;
                this.ptvl.push(rawData[itrI].result[itrJ][0][0]);
                this.ptvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ptvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ptvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ptvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ptvl'] = this.ptvl;
                json['ptvlfound'] = this.ptvlfound;
                json['page_' + pageIndex]['ptvl'] = this.ptvl;
                json['ptvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ptvlCo'] = this.ptvlCo;
                json['ptvlCo'] = this.ptvlCo;
                json['page_' + pageIndex]['ptvlChanged'] = this.ptvlChanged;
                json['ptvlChanged'] = this.ptvlChanged;
                json['page_' + pageIndex]['ptvlAccuracy'] = this.ptvlAccuracy;
                try {
                  json['ptvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ptvl = [];
                this.ptvlCo = [];
                this.ptvlChanged = [];
                this.ptvlAccuracy = [];
              break;
              case 'IITL':
                this.iitvlfound = true;
                this.iitvl.push(rawData[itrI].result[itrJ][0][0]);
                this.iitvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.iitvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.iitvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.iitvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['iitvl'] = this.iitvl;
                json['iitvlfound'] = this.iitvlfound;
                json['page_' + pageIndex]['iitvl'] = this.iitvl;
                json['iitvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['iitvlCo'] = this.iitvlCo;
                json['iitvlCo'] = this.iitvlCo;
                json['page_' + pageIndex]['iitvlChanged'] = this.iitvlChanged;
                json['iitvlChanged'] = this.iitvlChanged;
                json['page_' + pageIndex]['iitvlAccuracy'] = this.iitvlAccuracy;
                try {
                  json['iitvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.iitvl = [];
                this.iitvlCo = [];
                this.iitvlChanged = [];
                this.iitvlAccuracy = [];
              break;
              case 'SPNL':
                this.spnvlfound = true;
                this.spnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.spnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.spnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.spnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.spnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['spnvl'] = this.spnvl;
                json['spnvlfound'] = this.spnvlfound;
                json['page_' + pageIndex]['spnvl'] = this.spnvl;
                json['spnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['spnvlCo'] = this.spnvlCo;
                json['spnvlCo'] = this.spnvlCo;
                json['page_' + pageIndex]['spnvlChanged'] = this.spnvlChanged;
                json['spnvlChanged'] = this.spnvlChanged;
                json['page_' + pageIndex]['spnvlAccuracy'] = this.spnvlAccuracy;
                try {
                  json['spnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.spnvl = [];
                this.spnvlCo = [];
                this.spnvlChanged = [];
                this.spnvlAccuracy = [];
              break;
              case 'CPNL':
                this.cpnvlfound = true;
                this.cpnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.cpnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cpnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cpnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cpnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cpnvl'] = this.cpnvl;
                json['cpnvlfound'] = this.cpnvlfound;
                json['page_' + pageIndex]['cpnvl'] = this.cpnvl;
                json['cpnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cpnvlCo'] = this.cpnvlCo;
                json['cpnvlCo'] = this.cpnvlCo;
                json['page_' + pageIndex]['cpnvlChanged'] = this.cpnvlChanged;
                json['cpnvlChanged'] = this.cpnvlChanged;
                json['page_' + pageIndex]['cpnvlAccuracy'] = this.cpnvlAccuracy;
                try {
                  json['cpnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cpnvl = [];
                this.cpnvlCo = [];
                this.cpnvlChanged = [];
                this.cpnvlAccuracy = [];
              break;
              case 'ICNL':
                this.icnvlfound = true;
                this.icnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.icnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.icnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.icnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.icnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['icnvl'] = this.icnvl;
                json['icnvlfound'] = this.icnvlfound;
                json['page_' + pageIndex]['icnvl'] = this.icnvl;
                json['icnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['icnvlCo'] = this.icnvlCo;
                json['icnvlCo'] = this.icnvlCo;
                json['page_' + pageIndex]['icnvlChanged'] = this.icnvlChanged;
                json['icnvlChanged'] = this.icnvlChanged;
                json['page_' + pageIndex]['icnvlAccuracy'] = this.icnvlAccuracy;
                try {
                  json['icnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.icnvl = [];
                this.icnvlCo = [];
                this.icnvlChanged = [];
                this.icnvlAccuracy = [];
              break;
              case 'DNL':
                this.dnvlfound = true;
                this.dnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.dnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.dnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.dnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.dnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['dnvl'] = this.dnvl;
                json['dnvlfound'] = this.dnvlfound;
                json['page_' + pageIndex]['dnvl'] = this.dnvl;
                json['dnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['dnvlCo'] = this.dnvlCo;
                json['dnvlCo'] = this.dnvlCo;
                json['page_' + pageIndex]['dnvlChanged'] = this.dnvlChanged;
                json['dnvlChanged'] = this.dnvlChanged;
                json['page_' + pageIndex]['dnvlAccuracy'] = this.dnvlAccuracy;
                try {
                  json['dnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.dnvl = [];
                this.dnvlCo = [];
                this.dnvlChanged = [];
                this.dnvlAccuracy = [];
              break;
              case 'QIL':
                this.qivlfound = true;
                this.qivl.push(rawData[itrI].result[itrJ][0][0]);
                this.qivlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.qivlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.qivlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.qivlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['qivl'] = this.qivl;
                json['qivlfound'] = this.qivlfound;
                json['page_' + pageIndex]['qivl'] = this.qivl;
                json['qivlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['qivlCo'] = this.qivlCo;
                json['qivlCo'] = this.qivlCo;
                json['page_' + pageIndex]['qivlChanged'] = this.qivlChanged;
                json['qivlChanged'] = this.qivlChanged;
                json['page_' + pageIndex]['qivlAccuracy'] = this.qivlAccuracy;
                try {
                  json['qivl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.qivl = [];
                this.qivlCo = [];
                this.qivlChanged = [];
                this.qivlAccuracy = [];
              break;
              case 'PNL':
                this.pnvlfound = true;
                this.pnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.pnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.pnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.pnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.pnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['pnvl'] = this.pnvl;
                json['pnvlfound'] = this.pnvlfound;
                json['page_' + pageIndex]['pnvl'] = this.pnvl;
                json['pnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['pnvlCo'] = this.pnvlCo;
                json['pnvlCo'] = this.pnvlCo;
                json['page_' + pageIndex]['pnvlChanged'] = this.pnvlChanged;
                json['pnvlChanged'] = this.pnvlChanged;
                json['page_' + pageIndex]['pnvlAccuracy'] = this.pnvlAccuracy;
                try {
                  json['pnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.pnvl = [];
                this.pnvlCo = [];
                this.pnvlChanged = [];
                this.pnvlAccuracy = [];
              break;
              case 'SIDL':
                this.sidvlfound = true;
                this.sidvl.push(rawData[itrI].result[itrJ][0][0]);
                this.sidvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.sidvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.sidvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.sidvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['sidvl'] = this.sidvl;
                json['sidvlfound'] = this.sidvlfound;
                json['page_' + pageIndex]['sidvl'] = this.sidvl;
                json['sidvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['sidvlCo'] = this.sidvlCo;
                json['sidvlCo'] = this.sidvlCo;
                json['page_' + pageIndex]['sidvlChanged'] = this.sidvlChanged;
                json['sidvlChanged'] = this.sidvlChanged;
                json['page_' + pageIndex]['sidvlAccuracy'] = this.sidvlAccuracy;
                try {
                  json['sidvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.sidvl = [];
                this.sidvlCo = [];
                this.sidvlChanged = [];
                this.sidvlAccuracy = [];
              break;
              case 'EIDL':
                this.eidvlfound = true;
                this.eidvl.push(rawData[itrI].result[itrJ][0][0]);
                this.eidvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.eidvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.eidvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.eidvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['eidvl'] = this.eidvl;
                json['eidvlfound'] = this.eidvlfound;
                json['page_' + pageIndex]['eidvl'] = this.eidvl;
                json['eidvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['eidvlCo'] = this.eidvlCo;
                json['eidvlCo'] = this.eidvlCo;
                json['page_' + pageIndex]['eidvlChanged'] = this.eidvlChanged;
                json['eidvlChanged'] = this.eidvlChanged;
                json['page_' + pageIndex]['eidvlAccuracy'] = this.eidvlAccuracy;
                try {
                  json['eidvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.eidvl = [];
                this.eidvlCo = [];
                this.eidvlChanged = [];
                this.eidvlAccuracy = [];
              break;
              case 'PDL':
                this.pdvlfound = true;
                this.pdvl.push(rawData[itrI].result[itrJ][0][0]);
                this.pdvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.pdvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.pdvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.pdvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['pdvl'] = this.pdvl;
                json['pdvlfound'] = this.pdvlfound;
                json['page_' + pageIndex]['pdvl'] = this.pdvl;
                json['pdvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['pdvlCo'] = this.pdvlCo;
                json['pdvlCo'] = this.pdvlCo;
                json['page_' + pageIndex]['pdvlChanged'] = this.pdvlChanged;
                json['pdvlChanged'] = this.pdvlChanged;
                json['page_' + pageIndex]['pdvlAccuracy'] = this.pdvlAccuracy;
                try {
                  json['pdvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.pdvl = [];
                this.pdvlCo = [];
                this.pdvlChanged = [];
                this.pdvlAccuracy = [];
              break;
              case 'PML':
                this.pmvlfound = true;
                this.pmvl.push(rawData[itrI].result[itrJ][0][0]);
                this.pmvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.pmvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.pmvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.pmvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['pmvl'] = this.pmvl;
                json['pmvlfound'] = this.pmvlfound;
                json['page_' + pageIndex]['pmvl'] = this.pmvl;
                json['pmvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['pmvlCo'] = this.pmvlCo;
                json['pmvlCo'] = this.pmvlCo;
                json['page_' + pageIndex]['pmvlChanged'] = this.pmvlChanged;
                json['pmvlChanged'] = this.pmvlChanged;
                json['page_' + pageIndex]['pmvlAccuracy'] = this.pmvlAccuracy;
                try {
                  json['pmvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.pmvl = [];
                this.pmvlCo = [];
                this.pmvlChanged = [];
                this.pmvlAccuracy = [];
              break;
              case 'CTL':
                this.ctvlfound = true;
                this.ctvl.push(rawData[itrI].result[itrJ][0][0]);
                this.ctvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.ctvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.ctvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.ctvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['ctvl'] = this.ctvl;
                json['ctvlfound'] = this.ctvlfound;
                json['page_' + pageIndex]['ctvl'] = this.ctvl;
                json['ctvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['ctvlCo'] = this.ctvlCo;
                json['ctvlCo'] = this.ctvlCo;
                json['page_' + pageIndex]['ctvlChanged'] = this.ctvlChanged;
                json['ctvlChanged'] = this.ctvlChanged;
                json['page_' + pageIndex]['ctvlAccuracy'] = this.ctvlAccuracy;
                try {
                  json['ctvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.ctvl = [];
                this.ctvlCo = [];
                this.ctvlChanged = [];
                this.ctvlAccuracy = [];
              break;
              case 'VTL':
                this.vtvlfound = true;
                this.vtvl.push(rawData[itrI].result[itrJ][0][0]);
                this.vtvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.vtvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.vtvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.vtvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['vtvl'] = this.vtvl;
                json['vtvlfound'] = this.vtvlfound;
                json['page_' + pageIndex]['vtvl'] = this.vtvl;
                json['vtvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['vtvlCo'] = this.vtvlCo;
                json['vtvlCo'] = this.vtvlCo;
                json['page_' + pageIndex]['vtvlChanged'] = this.vtvlChanged;
                json['vtvlChanged'] = this.vtvlChanged;
                json['page_' + pageIndex]['vtvlAccuracy'] = this.vtvlAccuracy;
                try {
                  json['vtvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.vtvl = [];
                this.vtvlCo = [];
                this.vtvlChanged = [];
                this.vtvlAccuracy = [];
              break;
              case 'MAL':
                this.mavlfound = true;
                this.mavl.push(rawData[itrI].result[itrJ][0][0]);
                this.mavlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.mavlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.mavlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.mavlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['mavl'] = this.mavl;
                json['mavlfound'] = this.mavlfound;
                json['page_' + pageIndex]['mavl'] = this.mavl;
                json['mavlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['mavlCo'] = this.mavlCo;
                json['mavlCo'] = this.mavlCo;
                json['page_' + pageIndex]['mavlChanged'] = this.mavlChanged;
                json['mavlChanged'] = this.mavlChanged;
                json['page_' + pageIndex]['mavlAccuracy'] = this.mavlAccuracy;
                try {
                  json['mavl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.mavl = [];
                this.mavlCo = [];
                this.mavlChanged = [];
                this.mavlAccuracy = [];
              break;
              case 'PFL':
                this.pfvlfound = true;
                this.pfvl.push(rawData[itrI].result[itrJ][0][0]);
                this.pfvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.pfvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.pfvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.pfvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['pfvl'] = this.pfvl;
                json['pfvlfound'] = this.pfvlfound;
                json['page_' + pageIndex]['pfvl'] = this.pfvl;
                json['pfvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['pfvlCo'] = this.pfvlCo;
                json['pfvlCo'] = this.pfvlCo;
                json['page_' + pageIndex]['pfvlChanged'] = this.pfvlChanged;
                json['pfvlChanged'] = this.pfvlChanged;
                json['page_' + pageIndex]['pfvlAccuracy'] = this.pfvlAccuracy;
                try {
                  json['pfvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.pfvl = [];
                this.pfvlCo = [];
                this.pfvlChanged = [];
                this.pfvlAccuracy = [];
              break;
              case 'STAL':
                this.stavlfound = true;
                this.stavl.push(rawData[itrI].result[itrJ][0][0]);
                this.stavlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.stavlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.stavlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.stavlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['stavl'] = this.stavl;
                json['stavlfound'] = this.stavlfound;
                json['page_' + pageIndex]['stavl'] = this.stavl;
                json['stavlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['stavlCo'] = this.stavlCo;
                json['stavlCo'] = this.stavlCo;
                json['page_' + pageIndex]['stavlChanged'] = this.stavlChanged;
                json['stavlChanged'] = this.stavlChanged;
                json['page_' + pageIndex]['stavlAccuracy'] = this.stavlAccuracy;
                try {
                  json['stavl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.stavl = [];
                this.stavlCo = [];
                this.stavlChanged = [];
                this.stavlAccuracy = [];
              break;
              case 'CRL':
                this.crvlfound = true;
                this.crvl.push(rawData[itrI].result[itrJ][0][0]);
                this.crvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.crvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.crvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.crvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['crvl'] = this.crvl;
                json['crvlfound'] = this.crvlfound;
                json['page_' + pageIndex]['crvl'] = this.crvl;
                json['crvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['crvlCo'] = this.crvlCo;
                json['crvlCo'] = this.crvlCo;
                json['page_' + pageIndex]['crvlChanged'] = this.crvlChanged;
                json['crvlChanged'] = this.crvlChanged;
                json['page_' + pageIndex]['crvlAccuracy'] = this.crvlAccuracy;
                try {
                  json['crvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.crvl = [];
                this.crvlCo = [];
                this.crvlChanged = [];
                this.crvlAccuracy = [];
              break;
              case 'TECL':
                this.tecvlfound = true;
                this.tecvl.push(rawData[itrI].result[itrJ][0][0]);
                this.tecvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.tecvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.tecvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.tecvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['tecvl'] = this.tecvl;
                json['tecvlfound'] = this.tecvlfound;
                json['page_' + pageIndex]['tecvl'] = this.tecvl;
                json['tecvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['tecvlCo'] = this.tecvlCo;
                json['tecvlCo'] = this.tecvlCo;
                json['page_' + pageIndex]['tecvlChanged'] = this.tecvlChanged;
                json['tecvlChanged'] = this.tecvlChanged;
                json['page_' + pageIndex]['tecvlAccuracy'] = this.tecvlAccuracy;
                try {
                  json['tecvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.tecvl = [];
                this.tecvlCo = [];
                this.tecvlChanged = [];
                this.tecvlAccuracy = [];
              break;
              case 'BML':
                this.bmvlfound = true;
                this.bmvl.push(rawData[itrI].result[itrJ][0][0]);
                this.bmvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.bmvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.bmvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.bmvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['bmvl'] = this.bmvl;
                json['bmvlfound'] = this.bmvlfound;
                json['page_' + pageIndex]['bmvl'] = this.bmvl;
                json['bmvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['bmvlCo'] = this.bmvlCo;
                json['bmvlCo'] = this.bmvlCo;
                json['page_' + pageIndex]['bmvlChanged'] = this.bmvlChanged;
                json['bmvlChanged'] = this.bmvlChanged;
                json['page_' + pageIndex]['bmvlAccuracy'] = this.bmvlAccuracy;
                try {
                  json['bmvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.bmvl = [];
                this.bmvlCo = [];
                this.bmvlChanged = [];
                this.bmvlAccuracy = [];
              break;
              case 'VATPL':
                this.vatplfound = true;
                this.vatpl.push(rawData[itrI].result[itrJ][0][0]);
                this.vatplAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.vatplCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.vatplCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.vatplChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['vatpl'] = this.vatpl;
                json['vatplfound'] = this.vatplfound;
                json['page_' + pageIndex]['vatpl'] = this.vatpl;
                json['vatplPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['vatplCo'] = this.vatplCo;
                json['vatplCo'] = this.vatplCo;
                json['page_' + pageIndex]['vatplChanged'] = this.vatplChanged;
                json['vatplChanged'] = this.vatplChanged;
                json['page_' + pageIndex]['vatplAccuracy'] = this.vatplAccuracy;
                try {
                  json['vatpl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.vatpl = [];
                this.vatplCo = [];
                this.vatplChanged = [];
                this.vatplAccuracy = [];
              break;
              case 'ISLL':
                this.islvlfound = true;
                this.islvl.push(rawData[itrI].result[itrJ][0][0]);
                this.islvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.islvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.islvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.islvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['islvl'] = this.islvl;
                json['islvlfound'] = this.islvlfound;
                json['page_' + pageIndex]['islvl'] = this.islvl;
                json['islvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['islvlCo'] = this.islvlCo;
                json['islvlCo'] = this.islvlCo;
                json['page_' + pageIndex]['islvlChanged'] = this.islvlChanged;
                json['islvlChanged'] = this.islvlChanged;
                json['page_' + pageIndex]['islvlAccuracy'] = this.islvlAccuracy;
                try {
                  json['islvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.islvl = [];
                this.islvlCo = [];
                this.islvlChanged = [];
                this.islvlAccuracy = [];
              break;
              case 'ICLL':
                this.iclvlfound = true;
                this.iclvl.push(rawData[itrI].result[itrJ][0][0]);
                this.iclvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.iclvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.iclvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.iclvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['iclvl'] = this.iclvl;
                json['iclvlfound'] = this.iclvlfound;
                json['page_' + pageIndex]['iclvl'] = this.iclvl;
                json['iclvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['iclvlCo'] = this.iclvlCo;
                json['iclvlCo'] = this.iclvlCo;
                json['page_' + pageIndex]['iclvlChanged'] = this.iclvlChanged;
                json['iclvlChanged'] = this.iclvlChanged;
                json['page_' + pageIndex]['iclvlAccuracy'] = this.iclvlAccuracy;
                try {
                  json['iclvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.iclvl = [];
                this.iclvlCo = [];
                this.iclvlChanged = [];
                this.iclvlAccuracy = [];
              break;
              case 'SCRNL':
                this.scrnlfound = true;
                this.scrnl.push(rawData[itrI].result[itrJ][0][0]);
                this.scrnlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.scrnlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.scrnlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.scrnlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['scrnl'] = this.scrnl;
                json['scrnlfound'] = this.scrnlfound;
                json['page_' + pageIndex]['scrnl'] = this.scrnl;
                json['scrnlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['scrnlCo'] = this.scrnlCo;
                json['scrnlCo'] = this.scrnlCo;
                json['page_' + pageIndex]['scrnlChanged'] = this.scrnlChanged;
                json['scrnlChanged'] = this.scrnlChanged;
                json['page_' + pageIndex]['scrnlAccuracy'] = this.scrnlAccuracy;
                try {
                  json['scrnl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.scrnl = [];
                this.scrnlCo = [];
                this.scrnlChanged = [];
                this.scrnlAccuracy = [];
              break;
              case 'LECRNL':
                this.lecrnlfound = true;
                this.lecrnl.push(rawData[itrI].result[itrJ][0][0]);
                this.lecrnlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.lecrnlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.lecrnlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.lecrnlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['lecrnl'] = this.lecrnl;
                json['lecrnlfound'] = this.lecrnlfound;
                json['page_' + pageIndex]['lecrnl'] = this.lecrnl;
                json['lecrnlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['lecrnlCo'] = this.lecrnlCo;
                json['lecrnlCo'] = this.lecrnlCo;
                json['page_' + pageIndex]['lecrnlChanged'] = this.lecrnlChanged;
                json['lecrnlChanged'] = this.lecrnlChanged;
                json['page_' + pageIndex]['lecrnlAccuracy'] = this.lecrnlAccuracy;
                try {
                  json['lecrnl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.lecrnl = [];
                this.lecrnlCo = [];
                this.lecrnlChanged = [];
                this.lecrnlAccuracy = [];
              break;
              case 'LRCRNL':
                this.lrcrnlfound = true;
                this.lrcrnl.push(rawData[itrI].result[itrJ][0][0]);
                this.lrcrnlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.lrcrnlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.lrcrnlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.lrcrnlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['lrcrnl'] = this.lrcrnl;
                json['lrcrnlfound'] = this.lrcrnlfound;
                json['page_' + pageIndex]['lrcrnl'] = this.lrcrnl;
                json['lrcrnlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['lrcrnlCo'] = this.lrcrnlCo;
                json['lrcrnlCo'] = this.lrcrnlCo;
                json['page_' + pageIndex]['lrcrnlChanged'] = this.lrcrnlChanged;
                json['lrcrnlChanged'] = this.lrcrnlChanged;
                json['page_' + pageIndex]['lrcrnlAccuracy'] = this.lrcrnlAccuracy;
                try {
                  json['lrcrnl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.lrcrnl = [];
                this.lrcrnlCo = [];
                this.lrcrnlChanged = [];
                this.lrcrnlAccuracy = [];
              break;
              case 'ISLAL':
                this.islavlfound = true;
                this.islavl.push(rawData[itrI].result[itrJ][0][0]);
                this.islavlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.islavlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.islavlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.islavlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['islavl'] = this.islavl;
                json['islavlfound'] = this.islavlfound;
                json['page_' + pageIndex]['islavl'] = this.islavl;
                json['islavlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['islavlCo'] = this.islavlCo;
                json['islavlCo'] = this.islavlCo;
                json['page_' + pageIndex]['islavlChanged'] = this.islavlChanged;
                json['islavlChanged'] = this.islavlChanged;
                json['page_' + pageIndex]['islavlAccuracy'] = this.islavlAccuracy;
                try {
                  json['islavl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.islavl = [];
                this.islavlCo = [];
                this.islavlChanged = [];
                this.islavlAccuracy = [];
              break;
              case 'ICLAL':
                this.iclavlfound = true;
                this.iclavl.push(rawData[itrI].result[itrJ][0][0]);
                this.iclavlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.iclavlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.iclavlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.iclavlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['iclavl'] = this.iclavl;
                json['iclavlfound'] = this.iclavlfound;
                json['page_' + pageIndex]['iclavl'] = this.iclavl;
                json['iclavlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['iclavlCo'] = this.iclavlCo;
                json['iclavlCo'] = this.iclavlCo;
                json['page_' + pageIndex]['iclavlChanged'] = this.iclavlChanged;
                json['iclavlChanged'] = this.iclavlChanged;
                json['page_' + pageIndex]['iclavlAccuracy'] = this.iclavlAccuracy;
                try {
                  json['iclavl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.iclavl = [];
                this.iclavlCo = [];
                this.iclavlChanged = [];
                this.iclavlAccuracy = [];
              break;
              case 'CESSL':
                this.cessvlfound = true;
                this.cessvl.push(rawData[itrI].result[itrJ][0][0]);
                this.cessvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.cessvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.cessvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.cessvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['cessvl'] = this.cessvl;
                json['cessvlfound'] = this.cessvlfound;
                json['page_' + pageIndex]['cessvl'] = this.cessvl;
                json['cessvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['cessvlCo'] = this.cessvlCo;
                json['cessvlCo'] = this.cessvlCo;
                json['page_' + pageIndex]['cessvlChanged'] = this.cessvlChanged;
                json['cessvlChanged'] = this.cessvlChanged;
                json['page_' + pageIndex]['cessvlAccuracy'] = this.cessvlAccuracy;
                try {
                  json['cessvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.cessvl = [];
                this.cessvlCo = [];
                this.cessvlChanged = [];
                this.cessvlAccuracy = [];
              break;
              case 'TCSL':
                this.tcsvlfound = true;
                this.tcsvl.push(rawData[itrI].result[itrJ][0][0]);
                this.tcsvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.tcsvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.tcsvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.tcsvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['tcsvl'] = this.tcsvl;
                json['tcsvlfound'] = this.tcsvlfound;
                json['page_' + pageIndex]['tcsvl'] = this.tcsvl;
                json['tcsvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['tcsvlCo'] = this.tcsvlCo;
                json['tcsvlCo'] = this.tcsvlCo;
                json['page_' + pageIndex]['tcsvlChanged'] = this.tcsvlChanged;
                json['tcsvlChanged'] = this.tcsvlChanged;
                json['page_' + pageIndex]['tcsvlAccuracy'] = this.tcsvlAccuracy;
                try {
                  json['tcsvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.tcsvl = [];
                this.tcsvlCo = [];
                this.tcsvlChanged = [];
                this.tcsvlAccuracy = [];
              break;
              case 'DISCL':
                this.discvlfound = true;
                this.discvl.push(rawData[itrI].result[itrJ][0][0]);
                this.discvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.discvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.discvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.discvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['discvl'] = this.discvl;
                json['discvlfound'] = this.discvlfound;
                json['page_' + pageIndex]['discvl'] = this.discvl;
                json['discvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['discvlCo'] = this.discvlCo;
                json['discvlCo'] = this.discvlCo;
                json['page_' + pageIndex]['discvlChanged'] = this.discvlChanged;
                json['discvlChanged'] = this.discvlChanged;
                json['page_' + pageIndex]['discvlAccuracy'] = this.discvlAccuracy;
                try {
                  json['discvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.discvl = [];
                this.discvlCo = [];
                this.discvlChanged = [];
                this.discvlAccuracy = [];
              break;
              case 'VRNL':
                this.vrnvlfound = true;
                this.vrnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.vrnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.vrnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.vrnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.vrnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['vrnvl'] = this.vrnvl;
                json['vrnvlfound'] = this.vrnvlfound;
                json['page_' + pageIndex]['vrnvl'] = this.vrnvl;
                json['vrnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['vrnvlCo'] = this.vrnvlCo;
                json['vrnvlCo'] = this.vrnvlCo;
                json['page_' + pageIndex]['vrnvlChanged'] = this.vrnvlChanged;
                json['vrnvlChanged'] = this.vrnvlChanged;
                json['page_' + pageIndex]['vrnvlAccuracy'] = this.vrnvlAccuracy;
                try {
                  json['vrnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.vrnvl = [];
                this.vrnvlCo = [];
                this.vrnvlChanged = [];
                this.vrnvlAccuracy = [];
              break;
              case 'HSNL':
                this.hsnvlfound = true;
                this.hsnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.hsnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.hsnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.hsnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.hsnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['hsnvl'] = this.hsnvl;
                json['hsnvlfound'] = this.hsnvlfound;
                json['page_' + pageIndex]['hsnvl'] = this.hsnvl;
                json['hsnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['hsnvlCo'] = this.hsnvlCo;
                json['hsnvlCo'] = this.hsnvlCo;
                json['page_' + pageIndex]['hsnvlChanged'] = this.hsnvlChanged;
                json['hsnvlChanged'] = this.hsnvlChanged;
                json['page_' + pageIndex]['hsnvlAccuracy'] = this.hsnvlAccuracy;
                try {
                  json['hsnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.hsnvl = [];
                this.hsnvlCo = [];
                this.hsnvlChanged = [];
                this.hsnvlAccuracy = [];
              break;
              case 'LRGL':
                this.lrgvlfound = true;
                this.lrgvl.push(rawData[itrI].result[itrJ][0][0]);
                this.lrgvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.lrgvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.lrgvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.lrgvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['lrgvl'] = this.lrgvl;
                json['lrgvlfound'] = this.lrgvlfound;
                json['page_' + pageIndex]['lrgvl'] = this.lrgvl;
                json['lrgvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['lrgvlCo'] = this.lrgvlCo;
                json['lrgvlCo'] = this.lrgvlCo;
                json['page_' + pageIndex]['lrgvlChanged'] = this.lrgvlChanged;
                json['lrgvlChanged'] = this.lrgvlChanged;
                json['page_' + pageIndex]['lrgvlAccuracy'] = this.lrgvlAccuracy;
                try {
                  json['lrgvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.lrgvl = [];
                this.lrgvlCo = [];
                this.lrgvlChanged = [];
                this.lrgvlAccuracy = [];
              break;
              case 'LEGL':
                this.legvlfound = true;
                this.legvl.push(rawData[itrI].result[itrJ][0][0]);
                this.legvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.legvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.legvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.legvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['legvl'] = this.legvl;
                json['legvlfound'] = this.legvlfound;
                json['page_' + pageIndex]['legvl'] = this.legvl;
                json['legvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['legvlCo'] = this.legvlCo;
                json['legvlCo'] = this.legvlCo;
                json['page_' + pageIndex]['legvlChanged'] = this.legvlChanged;
                json['legvlChanged'] = this.legvlChanged;
                json['page_' + pageIndex]['legvlAccuracy'] = this.legvlAccuracy;
                this.legvl = [];
                this.legvlCo = [];
                this.legvlChanged = [];
                this.legvlAccuracy = [];
              break;
              case 'SMNL':
                this.smnvlfound = true;
                this.smnvl.push(rawData[itrI].result[itrJ][0][0]);
                this.smnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.smnvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.smnvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.smnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['smnvl'] = this.smnvl;
                json['smnvlfound'] = this.smnvlfound;
                json['page_' + pageIndex]['smnvl'] = this.smnvl;
                json['smnvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['smnvlCo'] = this.smnvlCo;
                json['smnvlCo'] = this.smnvlCo;
                json['page_' + pageIndex]['smnvlChanged'] = this.smnvlChanged;
                json['smnvlChanged'] = this.smnvlChanged;
                json['page_' + pageIndex]['smnvlAccuracy'] = this.smnvlAccuracy;
                try {
                  json['smnvl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.smnvl = [];
                this.smnvlCo = [];
                this.smnvlChanged = [];
                this.smnvlAccuracy = [];
              break;
              case 'ICLMNL':
                this.iclmnlfound = true;
                this.iclmnl.push(rawData[itrI].result[itrJ][0][0]);
                this.iclmnlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.iclmnlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.iclmnlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.iclmnlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['iclmnl'] = this.iclmnl;
                json['iclmnlfound'] = this.iclmnlfound;
                json['page_' + pageIndex]['iclmnl'] = this.iclmnl;
                json['iclmnlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['iclmnlCo'] = this.iclmnlCo;
                json['iclmnlCo'] = this.iclmnlCo;
                json['page_' + pageIndex]['iclmnlChanged'] = this.iclmnlChanged;
                json['iclmnlChanged'] = this.iclmnlChanged;
                json['page_' + pageIndex]['iclmnlAccuracy'] = this.iclmnlAccuracy;
                try {
                  json['iclmnl_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.iclmnl = [];
                this.iclmnlCo = [];
                this.iclmnlChanged = [];
                this.iclmnlAccuracy = [];
              break;
              case 'ICLEIL':
                this.icleilfound = true;
                this.icleil.push(rawData[itrI].result[itrJ][0][0]);
                this.icleilAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.icleilCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.icleilCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.icleilChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['icleil'] = this.icleil;
                json['icleilfound'] = this.icleilfound;
                json['page_' + pageIndex]['icleil'] = this.icleil;
                json['icleilPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['icleilCo'] = this.icleilCo;
                json['icleilCo'] = this.icleilCo;
                json['page_' + pageIndex]['icleilChanged'] = this.icleilChanged;
                json['icleilChanged'] = this.icleilChanged;
                json['page_' + pageIndex]['icleilAccuracy'] = this.icleilAccuracy;
                try {
                  json['icleil_old_log'] = {
                    old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                    modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                    modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                  }
                } catch (e) {}
                this.icleil = [];
                this.icleilCo = [];
                this.icleilChanged = [];
                this.icleilAccuracy = [];
              break;
              case 'INSL':
                this.insvlfound = true;
                this.insvl.push(rawData[itrI].result[itrJ][0][0]);
                this.insvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.insvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.insvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.insvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['insvl'] = this.insvl;
                json['insvlfound'] = this.insvlfound;
                json['page_' + pageIndex]['insvl'] = this.insvl;
                json['insvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['insvlCo'] = this.insvlCo;
                json['insvlCo'] = this.insvlCo;
                json['page_' + pageIndex]['insvlChanged'] = this.insvlChanged;
                json['insvlChanged'] = this.insvlChanged;
                json['page_' + pageIndex]['insvlAccuracy'] = this.insvlAccuracy;
                this.insvl = [];
                this.insvlCo = [];
                this.insvlChanged = [];
                this.insvlAccuracy = [];
              break;
              case 'REGL':
                this.regvlfound = true;
                this.regvl.push(rawData[itrI].result[itrJ][0][0]);
                this.regvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);

                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                  this.regvlCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  itemIndex = 0;
                  this.regvlCo.push([]);
                }
                pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                this.regvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);

                if (!json['page_' + pageIndex]) {
                  json['page_' + pageIndex] = {};
                  json['page_' + pageIndex]['coordinates'] = {};
                }
                json['regvl'] = this.regvl;
                json['regvlfound'] = this.regvlfound;
                json['page_' + pageIndex]['regvl'] = this.regvl;
                json['regvlPage'] = pageIndex;
                json['page_' + pageIndex]['coordinates']['regvlCo'] = this.regvlCo;
                json['regvlCo'] = this.regvlCo;
                json['page_' + pageIndex]['regvlChanged'] = this.regvlChanged;
                json['regvlChanged'] = this.regvlChanged;
                json['page_' + pageIndex]['regvlAccuracy'] = this.regvlAccuracy;
                this.regvl = [];
                this.regvlCo = [];
                this.regvlChanged = [];
                this.regvlAccuracy = [];
              break ;case 'CMNVL':
                if (rawData[itrI].is_approved && cmnvlCount > 1) {
                  delete rawData[itrI].result[itrJ];
                } else {
                  if (cmnvlCount === 1) {
                    this.total_fields++;
                  }
                  cmnvlCount++;
                  this.cmnvlfound = true;
                  let itemIndex;
                  if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                    itemIndex = 1;
                  } else if (rawData[itrI].result[itrJ].length === 5) {
                    itemIndex = 2;
                  } else {
                    itemIndex = 0;
                  }
                  const pageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                  if (cmnvlPageIndex !== pageIndex) {
                    cmnvlCount = 2;
                    this.cmnvl = [];
                    this.cmnvlCo = [];
                    this.cmnvlChanged = [];
                    this.cmnvlAccuracy = [];
                    cmnvlPageIndex = pageIndex;
                  } else if (cmnvlCount >= 4) {
                    continue;
                  }
                  try {
                    if (itemIndex) {
                      this.cmnvlCo.push(rawData[itrI].result[itrJ][itemIndex]);
                    } else {
                      this.cmnvlCo.push([]);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.cmnvl.push(rawData[itrI].result[itrJ][0][0]);
                  this.cmnvlAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                  this.cmnvlChanged.push(rawData[itrI].result[itrJ][2 + itemIndex].is_modified);
                  if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                    this.modified_fields++;
                  }
                  if (!json['page_' + pageIndex]) {
                    json['page_' + pageIndex] = {};
                    json['page_' + pageIndex]['coordinates'] = {};
                  }
                  json['cmnvl'] = this.cmnvl;
                  json['cmnvlfound'] = this.cmnvlfound;
                  json['page_' + pageIndex]['cmnvl'] = this.cmnvl;
                  json['cmnvlPage'] = pageIndex;
                  json['page_' + pageIndex]['coordinates']['cmnvlCo'] = this.cmnvlCo;
                  json['page_' + pageIndex]['cmnvlChanged'] = this.cmnvlChanged;
                  json['cmnvlChanged'] = this.cmnvlChanged;
                  json['page_' + pageIndex]['cmnvlAccuracy'] = this.cmnvlAccuracy;
                  try {
                    json['cmnvl_old_log'] = {
                      old_value: rawData[itrI].result[itrJ][3 + itemIndex].old_value,
                      modified_by: rawData[itrI].result[itrJ][4 + itemIndex].modified_by,
                      modified_time: rawData[itrI].result[itrJ][5 + itemIndex].modified_time
                    }
                  } catch (e) {}
                }
              break;
              }
              if (rawData[itrI].result[itrJ][0][1].includes('line_item')){
                itemIndex;
                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                } else {
                  itemIndex = 0;
                }
                if (
                  currentPageIndex !==
                  rawData[itrI].result[itrJ][1 + itemIndex]['page_index']
                ) {
                  if (currentPageIndex < rawData[itrI].result[itrJ][1 + itemIndex]['page_index']) {
                    this.lineItems = [];
                    this.lineItemChanged = [];
                    this.lineCo = [];
                  } else {
                    // this.lineItems =
                    //   json['page_' + rawData[itrI].result[itrJ][1 + itemIndex]['page_index']]['lineItems'];
                    // this.lineCo =
                    //   json['page_' + rawData[itrI].result[itrJ][1 + itemIndex]['page_index']]['coordinates']['lineCo'];
                  }
                  currentPageIndex = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                }
                this.total_fields++;
                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  this.lineCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  this.lineCo.push([]);
                }
                this.colNames = Object.keys(
                  rawData[itrI].result[itrJ][0][0]
                );
                this.oldColnames = Object.keys(
                  rawData[itrI].result[itrJ][0][0]
                );
                try {
                  for (let i = 0; i < this.colNames.length; i++) {
                    if (this.colNames[i] === 'y_boundary') {
                      this.colNames.splice(i--, 1);
                    }
                  }
                } catch (e) {}
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  json['page_' + currentPageIndex]['lineTableModified'] = true;
                }
                this.lineItems.push(rawData[itrI].result[itrJ][0][0]);
                this.lineItemChanged.push(
                  rawData[itrI].result[itrJ][2 + itemIndex].is_modified
                );
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + currentPageIndex]
                ) {
                  json['page_' + currentPageIndex] = {};
                  json['page_' + currentPageIndex]['coordinates'] = {};
                }
                if (!json['lineTableTitle']['page_' + currentPageIndex]) {
                  json['lineTableTitle']['page_' + currentPageIndex] = 'Line Table'
                }
                json['page_' + currentPageIndex]['colNames'] = this.colNames;
                json['page_' + currentPageIndex]['oldColnames'] = this.oldColnames;
                json['page_' + currentPageIndex]['lineItems'] = this.lineItems;
                json['page_' + currentPageIndex]['coordinates']['lineCo'] = this.lineCo;
                json['page_' + currentPageIndex]['lineItemChanged'] = this.lineItemChanged;
              } if (rawData[itrI].result[itrJ][0][1].includes('data_item')){
                itemIndex;
                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  itemIndex = 1;
                } else {
                  itemIndex = 0;
                }
                if (
                  currentPageIndex2 !==
                  rawData[itrI].result[itrJ][1 + itemIndex]['page_index']
                ) {
                  if (currentPageIndex2 < rawData[itrI].result[itrJ][1 + itemIndex]['page_index']) {
                    this.dataItems = [];
                    this.dataItemChanged = [];
                    this.dataItemAccuracy = [];
                    this.dataCo = [];
                  } else {
                    // this.lineItems =
                    //   json['page_' + rawData[itrI].result[itrJ][1 + itemIndex]['page_index']]['lineItems'];
                    // this.lineCo =
                    //   json['page_' + rawData[itrI].result[itrJ][1 + itemIndex]['page_index']]['coordinates']['lineCo'];
                  }
                  currentPageIndex2 = rawData[itrI].result[itrJ][1 + itemIndex]['page_index'];
                }
                this.total_fields++;
                if ([4,7].includes(rawData[itrI].result[itrJ].length)) {
                  this.dataCo.push(rawData[itrI].result[itrJ][1]);
                } else {
                  this.dataCo.push([]);
                }
                this.dataColNames = Object.keys(
                  rawData[itrI].result[itrJ][0][0]
                );
                this.dataOldColnames = Object.keys(
                  rawData[itrI].result[itrJ][0][0]
                );
                try {
                  for (let i = 0; i < this.dataColNames.length; i++) {
                    if (this.dataColNames[i] === 'y_boundary') {
                      this.dataColNames.splice(i--, 1);
                    }
                  }
                } catch (e) {}

                this.dataItems.push(rawData[itrI].result[itrJ][0][0]);
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  json['page_' + currentPageIndex]['dataTableModified'] = true;
                }
                this.dataItemChanged.push(
                  rawData[itrI].result[itrJ][2 + itemIndex].is_modified
                );
                try {
                  this.dataItemAccuracy.push(rawData[itrI].result[itrJ][0][2]);
                } catch (e) {}
                if (rawData[itrI].result[itrJ][2 + itemIndex].is_modified) {
                  this.modified_fields++;
                }
                if (!json['page_' + currentPageIndex2]
                ) {
                  json['page_' + currentPageIndex2] = {};
                  json['page_' + currentPageIndex2]['coordinates'] = {};
                }
                if (!json['dataTableTitle']['page_' + currentPageIndex2]) {
                  json['dataTableTitle']['page_' + currentPageIndex2] = 'Data Table'
                }
                json['page_' + currentPageIndex2]['dataColNames'] = this.dataColNames;
                json['page_' + currentPageIndex2]['dataOldColnames'] = this.dataOldColnames;
                json['page_' + currentPageIndex2]['dataItems'] = this.dataItems;
                json['page_' + currentPageIndex2]['coordinates']['dataCo'] = this.dataCo;
                json['page_' + currentPageIndex2]['dataItemChanged'] = this.dataItemChanged;
                json['page_' + currentPageIndex2]['dataItemAccuracy'] = this.dataItemAccuracy;
              }
            } catch (e) {
              console.log(itrI, itrJ, rawData[itrI].result[itrJ], e);
              // rawData[itrI].result.splice(itrJ--, 1);
              continue;
            }
          }

          // lineitem
          try {
            let c = 0;
            const jsonKeys = Object.keys(json);
            for (let i = 0; i < jsonKeys.length; i++) {
              if (jsonKeys[i].includes('page_')) {
                const pageNum = parseInt(jsonKeys[i].split('_')[1], 10);
                if (pageNum > c) {
                  c = pageNum;
                }
              }
            }
            for (let i = 0; i < c; i++) {
              try {
                for (
                  let k = 0;
                  k < json['page_' + (i + 1)].colNames.length;
                  k++
                ) {
                  this.cols.push([]);
                }
              } catch (e) {
                if (!json['page_' + (i + 1)]) {
                  json['page_' + (i + 1)] = {};
                }
                continue;
              }
              try {
                for (let j = 0; j < json['page_' + (i + 1)].lineItems.length; j++) {
                  for (let k = 0; k < json['page_' + (i + 1)].colNames.length; k++) {
                    try {
                      if (json['page_' + (i + 1)].colNames[k] !== 'y_boundary') {
                        this.cols[k].push(json['page_' + (i + 1)].lineItems[j][json['page_' + (i + 1)].colNames[k]]);
                      }
                    } catch (e) {
                      console.log(e);
                      continue;
                    }
                  }
                }
              } catch (e) {
                console.log(e);
                continue;
              }
              json['page_' + (i + 1)]['cols'] = this.cols;
              this.cols = [];
            }
          } catch (e) {
            console.log(e);
          }

          // data item
          try {
            let c = 0;
            const jsonKeys = Object.keys(json);
            for (let i = 0; i < jsonKeys.length; i++) {
              if (jsonKeys[i].includes('page_')) {
                const pageNum = parseInt(jsonKeys[i].split('_')[1], 10);
                if (pageNum > c) {
                  c = pageNum;
                }
              }
            }
            for (let i = 0; i < c; i++) {
              let x;
              try {
                x = json['page_' + (i + 1)];
                for (
                  let k = 0;
                  k < json['page_' + (i + 1)].dataColNames.length;
                  k++
                ) {
                  this.dataCols.push([]);
                }
              } catch (e) {
                if (!json['page_' + (i + 1)]) {
                  json['page_' + (i + 1)] = {};
                }
                continue;
              }
              try {
                for (let j = 0; j < json['page_' + (i + 1)].dataItems.length; j++) {
                  for (let k = 0; k < json['page_' + (i + 1)].dataColNames.length; k++) {
                    try {
                      if (json['page_' + (i + 1)].dataColNames[k] !== 'y_boundary') {
                        this.dataCols[k].push(json['page_' + (i + 1)].dataItems[j][json['page_' + (i + 1)].dataColNames[k]]);
                      }
                    } catch (e) {
                      continue;
                    }
                  }
                }
              } catch (e) {
                console.log(e);
                continue;
              }
              json['page_' + (i + 1)]['dataCols'] = this.dataCols;
              this.dataCols = [];
            }
          } catch (e) {}

          if (rawData[itrI].total_fields > this.total_fields) {
            this.total_fields = rawData[itrI].total_fields;
          }
          this.fieldsCount += this.total_fields;
          let username;
          try {
            username = rawData[itrI].user_data.username;
          } catch (e) {
            username = 'Not available';
          }
          json['invfound'] = this.invfound;
          json['chnvfound'] = this.chnvfound;
          json['envfound'] = this.envfound;
          json['mnvfound'] = this.mnvfound;
          json['cmnvfound'] = this.cmnvfound;
          json['cmnlfound'] = this.cmnlfound;
          json['sesvfound'] = this.sesvfound;
          json['emvfound'] = this.emvfound;
          json['isvfound'] = this.isvfound;
          json['cnvfound'] = this.cnvfound;
          json['cavfound'] = this.cavfound;
          json['savfound'] = this.savfound;
          json['idvfound'] = this.idvfound;
          json['igvfound'] = this.igvfound;
          json['cgvfound'] = this.cgvfound;
          json['ivvfound'] = this.ivvfound;
          json['cvvfound'] = this.cvvfound;
          json['ipvfound'] = this.ipvfound;
          json['itvfound'] = this.itvfound;
          json['icvfound'] = this.icvfound;
          json['mdvfound'] = this.mdvfound;
          json['fcvfound'] = this.fcvfound;

          json['invlfound'] = this.invlfound;
          json['chnvlfound'] = this.chnvlfound;
          json['envlfound'] = this.envlfound;
          json['mnvlfound'] = this.mnvlfound;
          json['cmnvlfound'] = this.cmnvlfound;
          json['cmnllfound'] = this.cmnllfound;
          json['sesvlfound'] = this.sesvlfound;
          json['emvlfound'] = this.emvlfound;
          json['isvlfound'] = this.isvlfound;
          json['cnvlfound'] = this.cnvlfound;
          json['cavlfound'] = this.cavlfound;
          json['savlfound'] = this.savlfound;
          json['idvlfound'] = this.idvlfound;
          json['igvlfound'] = this.igvlfound;
          json['cgvlfound'] = this.cgvlfound;
          json['ivvlfound'] = this.ivvlfound;
          json['cvvlfound'] = this.cvvlfound;
          json['ipvlfound'] = this.ipvlfound;
          json['itvlfound'] = this.itvlfound;
          json['icvlfound'] = this.icvlfound;
          json['mdvlfound'] = this.mdvlfound;
          json['fcvlfound'] = this.fcvlfound;

          if (!this.isvfound) {
            json['isvTrained'] = false;
          }

          json['cgstvfound'] = this.cgstvfound;
          json['sgstvfound'] = this.sgstvfound;
          json['igstvfound'] = this.igstvfound;
          json['cgstpfound'] = this.cgstpfound;
          json['sgstpfound'] = this.sgstpfound;
          json['igstpfound'] = this.igstpfound;
          json['txavfound'] = this.txavfound;
          json['gnvfound'] = this.gnvfound;
          json['ihvfound'] = this.ihvfound;
          json['bdvfound'] = this.bdvfound;
          json['csnvfound'] = this.csnvfound;
          json['ssnvfound'] = this.ssnvfound;
          json['scnvfound'] = this.scnvfound;
          json['ccnvfound'] = this.ccnvfound;
          json['ilvfound'] = this.ilvfound;
          json['ptvfound'] = this.ptvfound;
          json['iitvfound'] = this.iitvfound;
          json['spnvfound'] = this.spnvfound;
          json['cpnvfound'] = this.cpnvfound;
          json['icnvfound'] = this.icnvfound;
          json['dnvfound'] = this.dnvfound;
          json['qivfound'] = this.qivfound;
          json['niqfound'] = this.niqfound;
          json['nanfound'] = this.nanfound;
          json['ntypefound'] = this.ntypefound;
          json['nrn1found'] = this.nrn1found;
          json['nseffound'] = this.nseffound;
          json['nbneffound'] = this.nbneffound;
          json['pnvfound'] = this.pnvfound;
          json['sidvfound'] = this.sidvfound;
          json['eidvfound'] = this.eidvfound;
          json['pdvfound'] = this.pdvfound;
          json['pmvfound'] = this.pmvfound;
          json['ctvfound'] = this.ctvfound;
          json['vtvfound'] = this.vtvfound;
          json['crvfound'] = this.crvfound;
          json['tecvfound'] = this.tecvfound;
          json['bmvfound'] = this.bmvfound;
          json['mavfound'] = this.mavfound;
          json['pfvfound'] = this.pfvfound;
          json['stavfound'] = this.stavfound;
          json['vatpfound'] = this.vatpfound;
          json['islvfound'] = this.islvfound;
          json['iclvfound'] = this.iclvfound;
          json['scrnfound'] = this.scrnfound;
          json['lecrnfound'] = this.lecrnfound;
          json['lrcrnfound'] = this.lrcrnfound;
          json['islavfound'] = this.islavfound;
          json['iclavfound'] = this.iclavfound;
          json['cessvfound'] = this.cessvfound;
          json['tcsvfound'] = this.tcsvfound;
          json['discvfound'] = this.discvfound;
          json['vrnvfound'] = this.vrnvfound;
          json['hsnvfound'] = this.hsnvfound;
          json['lrgvfound'] = this.lrgvfound;
          json['lrgvfound'] = this.lrgvfound;
          json['smnvfound'] = this.smnvfound;
          json['iclmnvfound'] = this.iclmnvfound;
          json['icleivfound'] = this.icleivfound;
          json['insvfound'] = this.insvfound;
          json['regvfound'] = this.regvfound;

          json['isvlfound'] = this.isvlfound;
          json['cgstvlfound'] = this.cgstvlfound;
          json['sgstvlfound'] = this.sgstvlfound;
          json['igstvlfound'] = this.igstvlfound;
          json['cgstplfound'] = this.cgstplfound;
          json['sgstplfound'] = this.sgstplfound;
          json['igstplfound'] = this.igstplfound;
          json['txavlfound'] = this.txavlfound;
          json['gnvlfound'] = this.gnvlfound;
          json['ihvlfound'] = this.ihvlfound;
          json['bdvlfound'] = this.bdvlfound;
          json['csnvlfound'] = this.csnvlfound;
          json['ssnvlfound'] = this.ssnvlfound;
          json['scnvlfound'] = this.scnvlfound;
          json['ccnvlfound'] = this.ccnvlfound;
          json['ilvlfound'] = this.ilvlfound;
          json['ptvlfound'] = this.ptvlfound;
          json['iitvlfound'] = this.iitvlfound;
          json['spnvlfound'] = this.spnvlfound;
          json['cpnvlfound'] = this.cpnvlfound;
          json['icnvlfound'] = this.icnvlfound;
          json['dnvlfound'] = this.dnvlfound;
          json['qivlfound'] = this.qivlfound;
          json['pnvlfound'] = this.pnvlfound;
          json['sidvlfound'] = this.sidvlfound;
          json['eidvlfound'] = this.eidvlfound;
          json['pdvlfound'] = this.pdvlfound;
          json['pmvlfound'] = this.pmvlfound;
          json['ctvlfound'] = this.ctvlfound;
          json['vtvlfound'] = this.vtvlfound;
          json['crvlfound'] = this.crvlfound;
          json['tecvlfound'] = this.tecvlfound;
          json['bmvlfound'] = this.bmvlfound;
          json['mavlfound'] = this.mavlfound;
          json['pfvlfound'] = this.pfvlfound;
          json['stavlfound'] = this.stavlfound;
          json['vatplfound'] = this.vatplfound;
          json['islvlfound'] = this.islvlfound;
          json['iclvlfound'] = this.iclvlfound;
          json['scrnlfound'] = this.scrnlfound;
          json['lecrnlfound'] = this.lecrnlfound;
          json['lrcrnlfound'] = this.lrcrnlfound;
          json['islavlfound'] = this.islavlfound;
          json['iclavlfound'] = this.iclavlfound;
          json['cessvlfound'] = this.cessvlfound;
          json['tcsvlfound'] = this.tcsvlfound;
          json['discvlfound'] = this.discvlfound;
          json['vrnvlfound'] = this.vrnvlfound;
          json['hsnvlfound'] = this.hsnvlfound;
          json['lrgvlfound'] = this.lrgvlfound;
          json['legvlfound'] = this.legvlfound;
          json['smnvlfound'] = this.smnvlfound;
          json['iclmnlfound'] = this.iclmnlfound;
          json['icleilfound'] = this.icleilfound;
          json['insvlfound'] = this.insvlfound;
          json['regvlfound'] = this.regvlfound;

          json['total_fields'] = this.total_fields;
          json['modified_fields'] = this.modified_fields;
          this.resetValues();
          this.finalResult.push(json);
          json = {};
        } catch (e) {
          this.resetValues();
          continue;
        }
      }
    } catch (e) {
      // console.log(e);
      return this.finalResult;
    }
    return this.finalResult;
  }

  processOldData(rawData) {
    let oldValuesJson = [];
    for(let itrI = 0; itrI < rawData.length; itrI++) {
      oldValuesJson.push({});
      oldValuesJson[itrI]['file_name'] = rawData[itrI].file_name;
      for (let itrJ = 0; itrJ < rawData[itrI].result.length; itrJ++) {
        try {
          if (rawData[itrI].result[itrJ] === null || rawData[itrI].result[itrJ].length === 0) {
            const dt = rawData[itrI].result.splice(itrJ--, 1);
            continue;
          }
          if (rawData[itrI].result[itrJ][0][1] === 'INV_OLD') {
            oldValuesJson[itrI]['inv'] = {};
            oldValuesJson[itrI]['inv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['inv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['inv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CHNV_OLD') {
            oldValuesJson[itrI]['chnv'] = {};
            oldValuesJson[itrI]['chnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['chnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['chnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ENV_OLD') {
            oldValuesJson[itrI]['env'] = {};
            oldValuesJson[itrI]['env']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['env']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['env']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'MNV_OLD') {
            oldValuesJson[itrI]['mnv'] = {};
            oldValuesJson[itrI]['mnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['mnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['mnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CMNV_OLD') {
            oldValuesJson[itrI]['cmnv'] = {};
            oldValuesJson[itrI]['cmnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cmnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cmnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CMNL_OLD') {
            oldValuesJson[itrI]['cmnl'] = {};
            oldValuesJson[itrI]['cmnl']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cmnl']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cmnl']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SESV_OLD') {
            oldValuesJson[itrI]['sesv'] = {};
            oldValuesJson[itrI]['sesv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['sesv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['sesv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'EMV_OLD') {
            oldValuesJson[itrI]['emv'] = {};
            oldValuesJson[itrI]['emv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['emv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['emv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ISV_OLD') {
            oldValuesJson[itrI]['isv'] = {};
            oldValuesJson[itrI]['isv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['isv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['isv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CNV_OLD') {
            oldValuesJson[itrI]['cnv'] = {};
            oldValuesJson[itrI]['cnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CAV_OLD') {
            oldValuesJson[itrI]['cav'] = {};
            oldValuesJson[itrI]['cav']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cav']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cav']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SAV_OLD') {
            oldValuesJson[itrI]['sav'] = {};
            oldValuesJson[itrI]['sav']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['sav']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['sav']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'IDV_OLD') {
            oldValuesJson[itrI]['idv'] = {};
            oldValuesJson[itrI]['idv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['idv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['idv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'IGV_OLD') {
            oldValuesJson[itrI]['igv'] = {};
            oldValuesJson[itrI]['igv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['igv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['igv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CGV_OLD') {
            oldValuesJson[itrI]['cgv'] = {};
            oldValuesJson[itrI]['cgv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cgv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cgv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'IVV_OLD') {
            oldValuesJson[itrI]['ivv'] = {};
            oldValuesJson[itrI]['ivv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ivv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ivv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CVV_OLD') {
            oldValuesJson[itrI]['cvv'] = {};
            oldValuesJson[itrI]['cvv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cvv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cvv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ICV_OLD') {
            oldValuesJson[itrI]['icv'] = {};
            oldValuesJson[itrI]['icv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['icv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['icv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'FCV_OLD') {
            oldValuesJson[itrI]['fcv'] = {};
            oldValuesJson[itrI]['fcv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['fcv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['fcv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'MDV_OLD') {
            oldValuesJson[itrI]['mdv'] = {};
            oldValuesJson[itrI]['mdv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['mdv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['mdv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'IPV_OLD') {
            oldValuesJson[itrI]['ipv'] = {};
            oldValuesJson[itrI]['ipv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ipv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ipv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'TAV_OLD') {
            oldValuesJson[itrI]['itv'] = {};
            oldValuesJson[itrI]['itv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['itv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['itv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CGSTV_OLD') {
            oldValuesJson[itrI]['cgstv'] = {};
            oldValuesJson[itrI]['cgstv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cgstv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cgstv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SGSTV_OLD') {
            oldValuesJson[itrI]['sgstv'] = {};
            oldValuesJson[itrI]['sgstv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['sgstv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['sgstv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'IGSTV_OLD') {
            oldValuesJson[itrI]['igstv'] = {};
            oldValuesJson[itrI]['igstv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['igstv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['igstv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CGSTP_OLD') {
            oldValuesJson[itrI]['cgstp'] = {};
            oldValuesJson[itrI]['cgstp']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cgstp']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cgstp']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SGSTP_OLD') {
            oldValuesJson[itrI]['sgstp'] = {};
            oldValuesJson[itrI]['sgstp']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['sgstp']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['sgstp']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'IGSTP_OLD') {
            oldValuesJson[itrI]['igstp'] = {};
            oldValuesJson[itrI]['igstp']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['igstp']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['igstp']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'TXAV_OLD') {
            oldValuesJson[itrI]['txav'] = {};
            oldValuesJson[itrI]['txav']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['txav']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['txav']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'GNV_OLD') {
            oldValuesJson[itrI]['gnv'] = {};
            oldValuesJson[itrI]['gnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['gnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['gnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'IHV_OLD') {
            oldValuesJson[itrI]['ihv'] = {};
            oldValuesJson[itrI]['ihv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ihv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ihv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'BDV_OLD') {
            oldValuesJson[itrI]['bdv'] = {};
            oldValuesJson[itrI]['bdv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['bdv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['bdv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CSNV_OLD') {
            oldValuesJson[itrI]['csnv'] = {};
            oldValuesJson[itrI]['csnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['csnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['csnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SSNV_OLD') {
            oldValuesJson[itrI]['ssnv'] = {};
            oldValuesJson[itrI]['ssnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ssnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ssnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SCNV_OLD') {
            oldValuesJson[itrI]['scnv'] = {};
            oldValuesJson[itrI]['scnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['scnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['scnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CCNV_OLD') {
            oldValuesJson[itrI]['ccnv'] = {};
            oldValuesJson[itrI]['ccnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ccnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ccnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ILV_OLD') {
            oldValuesJson[itrI]['ilv'] = {};
            oldValuesJson[itrI]['ilv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ilv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ilv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'PTV_OLD') {
            oldValuesJson[itrI]['ptv'] = {};
            oldValuesJson[itrI]['ptv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ptv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ptv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'IITV_OLD') {
            oldValuesJson[itrI]['iitv'] = {};
            oldValuesJson[itrI]['iitv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['iitv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['iitv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SPNV_OLD') {
            oldValuesJson[itrI]['spnv'] = {};
            oldValuesJson[itrI]['spnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['spnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['spnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CPNV_OLD') {
            oldValuesJson[itrI]['cpnv'] = {};
            oldValuesJson[itrI]['cpnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cpnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cpnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ICNV_OLD') {
            oldValuesJson[itrI]['icnv'] = {};
            oldValuesJson[itrI]['icnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['icnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['icnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'DNV_OLD') {
            oldValuesJson[itrI]['dnv'] = {};
            oldValuesJson[itrI]['dnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['dnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['dnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'QIV_OLD') {
            oldValuesJson[itrI]['qiv'] = {};
            oldValuesJson[itrI]['qiv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['qiv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['qiv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'NIQ_OLD') {
            oldValuesJson[itrI]['niq'] = {};
            oldValuesJson[itrI]['niq']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['niq']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['niq']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'NAN_OLD') {
            oldValuesJson[itrI]['nan'] = {};
            oldValuesJson[itrI]['nan']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['nan']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['nan']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'DOCTYPE_OLD') {
            oldValuesJson[itrI]['ntype'] = {};
            oldValuesJson[itrI]['ntype']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ntype']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ntype']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'NRN1_OLD') {
            oldValuesJson[itrI]['nrn1'] = {};
            oldValuesJson[itrI]['nrn1']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['nrn1']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['nrn1']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'NSEF_OLD') {
            oldValuesJson[itrI]['nsef'] = {};
            oldValuesJson[itrI]['nsef']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['nsef']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['nsef']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'NBNEF_OLD') {
            oldValuesJson[itrI]['nvnef'] = {};
            oldValuesJson[itrI]['nvnef']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['nvnef']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['nvnef']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'PNV_OLD') {
            oldValuesJson[itrI]['pnv'] = {};
            oldValuesJson[itrI]['pnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['pnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['pnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SIDV_OLD') {
            oldValuesJson[itrI]['sidv'] = {};
            oldValuesJson[itrI]['sidv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['sidv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['sidv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'EIDV_OLD') {
            oldValuesJson[itrI]['eidv'] = {};
            oldValuesJson[itrI]['eidv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['eidv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['eidv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'PDV_OLD') {
            oldValuesJson[itrI]['pdv'] = {};
            oldValuesJson[itrI]['pdv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['pdv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['pdv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'PMV_OLD') {
            oldValuesJson[itrI]['pmv'] = {};
            oldValuesJson[itrI]['pmv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['pmv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['pmv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CTV_OLD') {
            oldValuesJson[itrI]['ctv'] = {};
            oldValuesJson[itrI]['ctv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['ctv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['ctv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'VTV_OLD') {
            oldValuesJson[itrI]['vtv'] = {};
            oldValuesJson[itrI]['vtv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['vtv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['vtv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'MAV_OLD') {
            oldValuesJson[itrI]['mav'] = {};
            oldValuesJson[itrI]['mav']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['mav']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['mav']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'PFV_OLD') {
            oldValuesJson[itrI]['pfv'] = {};
            oldValuesJson[itrI]['pfv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['pfv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['pfv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'STAV_OLD') {
            oldValuesJson[itrI]['stav'] = {};
            oldValuesJson[itrI]['stav']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['stav']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['stav']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CRV_OLD') {
            oldValuesJson[itrI]['crv'] = {};
            oldValuesJson[itrI]['crv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['crv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['crv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'TECV_OLD') {
            oldValuesJson[itrI]['tecv'] = {};
            oldValuesJson[itrI]['tecv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['tecv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['tecv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'BMV_OLD') {
            oldValuesJson[itrI]['bmv'] = {};
            oldValuesJson[itrI]['bmv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['bmv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['bmv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'VATP_OLD') {
            oldValuesJson[itrI]['vatp'] = {};
            oldValuesJson[itrI]['vatp']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['vatp']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['vatp']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ISLV_OLD') {
            oldValuesJson[itrI]['islv'] = {};
            oldValuesJson[itrI]['islv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['islv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['islv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ICLV_OLD') {
            oldValuesJson[itrI]['iclv'] = {};
            oldValuesJson[itrI]['iclv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['iclv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['iclv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SCRN_OLD') {
            oldValuesJson[itrI]['scrn'] = {};
            oldValuesJson[itrI]['scrn']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['scrn']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['scrn']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'LECRN_OLD') {
            oldValuesJson[itrI]['lecrn'] = {};
            oldValuesJson[itrI]['lecrn']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['lecrn']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['lecrn']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'LRCRN_OLD') {
            oldValuesJson[itrI]['lrcrn'] = {};
            oldValuesJson[itrI]['lrcrn']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['lrcrn']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['lrcrn']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ISLAV_OLD') {
            oldValuesJson[itrI]['islav'] = {};
            oldValuesJson[itrI]['islav']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['islav']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['islav']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ICLAV_OLD') {
            oldValuesJson[itrI]['iclav'] = {};
            oldValuesJson[itrI]['iclav']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['iclav']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['iclav']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'CESSV_OLD') {
            oldValuesJson[itrI]['cessv'] = {};
            oldValuesJson[itrI]['cessv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['cessv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['cessv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'TCSV_OLD') {
            oldValuesJson[itrI]['tcsv'] = {};
            oldValuesJson[itrI]['tcsv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['tcsv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['tcsv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'DISCV_OLD') {
            oldValuesJson[itrI]['discv'] = {};
            oldValuesJson[itrI]['discv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['discv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['discv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'VRNV_OLD') {
            oldValuesJson[itrI]['vrnv'] = {};
            oldValuesJson[itrI]['vrnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['vrnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['vrnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'HSNV_OLD') {
            oldValuesJson[itrI]['hsnv'] = {};
            oldValuesJson[itrI]['hsnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['hsnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['hsnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'LRGV_OLD') {
            oldValuesJson[itrI]['lrgv'] = {};
            oldValuesJson[itrI]['lrgv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['lrgv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['lrgv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'LEGV_OLD') {
            oldValuesJson[itrI]['legv'] = {};
            oldValuesJson[itrI]['legv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['legv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['legv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'SMNV_OLD') {
            oldValuesJson[itrI]['smnv'] = {};
            oldValuesJson[itrI]['smnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['smnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['smnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ICLMNV_OLD') {
            oldValuesJson[itrI]['iclmnv'] = {};
            oldValuesJson[itrI]['iclmnv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['iclmnv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['iclmnv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'ICLEIV_OLD') {
            oldValuesJson[itrI]['icleiv'] = {};
            oldValuesJson[itrI]['icleiv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['icleiv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['icleiv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'INSV_OLD') {
            oldValuesJson[itrI]['insv'] = {};
            oldValuesJson[itrI]['insv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['insv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['insv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          } else if (rawData[itrI].result[itrJ][0][1] === 'REGV_OLD') {
            oldValuesJson[itrI]['regv'] = {};
            oldValuesJson[itrI]['regv']['value'] = rawData[itrI].result[itrJ][0][0];
            oldValuesJson[itrI]['regv']['modified_time'] = rawData[itrI].result[itrJ][3].modified_time;
            oldValuesJson[itrI]['regv']['modified_by'] = rawData[itrI].result[itrJ][4].modified_by;

          }
        } catch (e) {
          // rawData[itrI].result.splice(itrJ--, 1);
          console.log(e)
          continue;
        }
      }
    }
    return oldValuesJson;
  }

  extractLineItems (lineItems) {
    const json = {};
    const pages = Object.keys(lineItems);
    for (let page = 0; page < pages.length; page++) {
      this.cols = [];
      this.dataCols = [];
      this.lineCo = [];
      this.dataCo = [];
      this.lineItems = [];
      this.dataItems = [];
      this.lineItemChanged = [];
      this.dataItemChanged = [];
      this.dataItemAccuracy = [];
      json[pages[page]] = {};
      json[pages[page]]['coordinates'] = {};
      for (let item = 0; item < lineItems[pages[page]]['result'].length; item++) {
        this.lineCo.push(lineItems[pages[page]]['result'][item][1]);
        this.colNames = Object.keys(lineItems[pages[page]]['result'][item][0][0]);
        this.lineItems.push(lineItems[pages[page]]['result'][item][0][0]);
        this.oldColnames = Object.keys(lineItems[pages[page]]['result'][item][0][0]);
        this.lineItemChanged.push(1);
      }
      json[pages[page]]['colNames'] = this.colNames;
      json[pages[page]]['oldColnames'] = this.oldColnames;
      json[pages[page]]['lineItems'] = this.lineItems;
      json[pages[page]]['coordinates']['lineCo'] = this.lineCo;
      json[pages[page]]['lineItemChanged'] = this.lineItemChanged;
      try {
        for (let col = 0; col < this.colNames.length; col++) {
          this.cols.push([]);
          for (let lin = 0; lin < this.lineItems.length; lin++) {
            this.cols[col].push(this.lineItems[lin][this.colNames[col]]);
          }
        }
      } catch (e) {
        console.log(e, this.cols, pages);
      }
      json[pages[page]]['cols'] = this.cols;
    }
    this.lineCo = [];
    this.lineItemChanged = [];
    this.lineItems = [];
    this.cols = [];
    return json;
  }

  dateFormat(date: string) {
    const dateMilliSec: number = Date.parse(date);
    return dateMilliSec;
  }

  resetValues() {
    this.is_modified = 0;
    this.invfound = false;
    this.chnvfound = false;
    this.envfound = false;
    this.mnvfound = false;
    this.cmnvfound = false;
    this.cmnlfound = false;
    this.sesvfound = false;
    this.emvfound = false;
    this.isvfound = false;
    this.idvfound = false;
    this.igvfound = false;
    this.cgvfound = false;
    this.ivvfound = false;
    this.cvvfound = false;
    this.itvfound = false;
    this.ipvfound = false;
    this.fcvfound = false;
    this.mdvfound = false;
    this.icvfound = false;
    this.cavfound = false;
    this.cnvfound = false;
    this.savfound = false;
    this.cgstvfound = false;
    this.sgstvfound = false;
    this.igstvfound = false;
    this.cgstpfound = false;
    this.sgstpfound = false;
    this.igstpfound = false;
    this.txavfound = false;
    this.gnvfound = false;
    this.ihvfound = false;
    this.bdvfound = false;
    this.csnvfound = false;
    this.ssnvfound = false;
    this.scnvfound = false;
    this.ccnvfound = false;
    this.ilvfound = false;
    this.ptvfound = false;
    this.iitvfound = false;
    this.spnvfound = false;
    this.cpnvfound = false;
    this.icnvfound = false;
    this.dnvfound = false;
    this.qivfound = false;
    this.niqfound = false;
    this.nanfound = false;
    this.ntypefound = false;
    this.nrn1found = false;
    this.nseffound = false;
    this.nbneffound = false;
    this.pnvfound = false;
    this.sidvfound = false;
    this.eidvfound = false;
    this.pdvfound = false;
    this.pmvfound = false;
    this.ctvfound = false;
    this.vtvfound = false;
    this.crvfound = false;
    this.tecvfound = false;
    this.bmvfound = false;
    this.mavfound = false;
    this.pfvfound = false;
    this.stavfound = false;
    this.vatpfound = false;
    this.islvfound = false;
    this.iclvfound = false;
    this.scrnfound = false;
    this.lecrnfound = false;
    this.lrcrnfound = false;
    this.islavfound = false;
    this.iclavfound = false;
    this.cessvfound = false;
    this.tcsvfound = false;
    this.discvfound = false;
    this.vrnvfound = false;
    this.hsnvfound = false;
    this.lrgvfound = false;
    this.legvfound = false;
    this.smnvfound = false;
    this.iclmnvfound = false;
    this.icleivfound = false;
    this.insvfound = false;
    this.regvfound = false;
    this.invChanged = [];
    this.chnvChanged = [];
    this.envChanged = [];
    this.mnvChanged = [];
    this.cmnvChanged = [];
    this.cmnlChanged = [];
    this.sesvChanged = [];
    this.emvChanged = [];
    this.isvChanged = [];
    this.cavChanged = [];
    this.cnvChanged = [];
    this.savChanged = [];
    this.idvChanged = [];
    this.igvChanged = [];
    this.cgvChanged = [];
    this.ivvChanged = [];
    this.cvvChanged = [];
    this.ipvChanged = [];
    this.itvChanged = [];
    this.fcvChanged = [];
    this.icvChanged = [];
    this.mdvChanged = [];
    this.cgstvChanged = [];
    this.sgstvChanged = [];
    this.igstvChanged = [];
    this.cgstpChanged = [];
    this.sgstpChanged = [];
    this.igstpChanged = [];
    this.txavChanged = [];
    this.gnvChanged = [];
    this.ihvChanged = [];
    this.bdvChanged = [];
    this.csnvChanged = [];
    this.ssnvChanged = [];
    this.scnvChanged = [];
    this.ccnvChanged = [];
    this.ilvChanged = [];
    this.ptvChanged = [];
    this.iitvChanged = [];
    this.spnvChanged = [];
    this.cpnvChanged = [];
    this.icnvChanged = [];
    this.dnvChanged = [];
    this.qivChanged = [];
    this.niqChanged = [];
    this.nanChanged = [];
    this.ntypeChanged = [];
    this.nrn1Changed = [];
    this.nsefChanged = [];
    this.nbnefChanged = [];
    this.pnvChanged = [];
    this.sidvChanged = [];
    this.eidvChanged = [];
    this.pdvChanged = [];
    this.pmvChanged = [];
    this.ctvChanged = [];
    this.vtvChanged = [];
    this.crvChanged = [];
    this.tecvChanged = [];
    this.bmvChanged = [];
    this.mavChanged = [];
    this.pfvChanged = [];
    this.stavChanged = [];
    this.vatpChanged = [];
    this.islvChanged = [];
    this.scrnChanged = [];
    this.lecrnChanged = [];
    this.lrcrnChanged = [];
    this.iclvChanged = [];
    this.islavChanged = [];
    this.iclavChanged = [];
    this.cessvChanged = [];
    this.tcsvChanged = [];
    this.discvChanged = [];
    this.vrnvChanged = [];
    this.hsnvChanged = [];
    this.lrgvChanged = [];
    this.legvChanged = [];
    this.smnvChanged = [];
    this.iclmnvChanged = [];
    this.icleivChanged = [];
    this.insvChanged = [];
    this.regvChanged = [];
    this.invAccuracy = [];
    this.chnvAccuracy = [];
    this.envAccuracy = [];
    this.mnvAccuracy = [];
    this.cmnvAccuracy = [];
    this.cmnlAccuracy = [];
    this.sesvAccuracy = [];
    this.emvAccuracy = [];
    this.isvAccuracy = [];
    this.cnvAccuracy = [];
    this.cavAccuracy = [];
    this.savAccuracy = [];
    this.idvAccuracy = [];
    this.igvAccuracy = [];
    this.cgvAccuracy = [];
    this.ivvAccuracy = [];
    this.cvvAccuracy = [];
    this.ipvAccuracy = [];
    this.itvAccuracy = [];
    this.icvAccuracy = [];
    this.fcvAccuracy = [];
    this.mdvAccuracy = [];
    this.cgstvAccuracy = [];
    this.sgstvAccuracy = [];
    this.igstvAccuracy = [];
    this.cgstpAccuracy = [];
    this.sgstpAccuracy = [];
    this.igstpAccuracy = [];
    this.txavAccuracy = [];
    this.gnvAccuracy = [];
    this.ihvAccuracy = [];
    this.bdvAccuracy = [];
    this.csnvAccuracy = [];
    this.ssnvAccuracy = [];
    this.scnvAccuracy = [];
    this.ccnvAccuracy = [];
    this.ilvAccuracy = [];
    this.ptvAccuracy = [];
    this.iitvAccuracy = [];
    this.spnvAccuracy = [];
    this.lineItemChanged = [];
    this.dataItemChanged = [];
    this.dataItemAccuracy = [];
    this.inv = [];
    this.chnv = [];
    this.env = [];
    this.mnv = [];
    this.cmnv = [];
    this.cmnl = [];
    this.sesv = [];
    this.emv = [];
    this.idv = [];
    this.isv = [];
    this.igv = [];
    this.cgv = [];
    this.ivv = [];
    this.cvv = [];
    this.ipv = [];
    this.itv = [];
    this.cav = [];
    this.cnv = [];
    this.sav = [];
    this.cgstp = [];
    this.sgstp = [];
    this.igstp = [];
    this.txav = [];
    this.gnv = [];
    this.ihv = [];
    this.bdv = [];
    this.csnv = [];
    this.ssnv = [];
    this.scnv = [];
    this.ccnv = [];
    this.ilv = [];
    this.ptv = [];
    this.iitv = [];
    this.spnv = [];
    this.cpnv = [];
    this.icnv = [];
    this.dnv = [];
    this.qiv = [];
    this.niq = [];
    this.nan = [];
    this.ntype = [];
    this.nrn1 = [];
    this.nsef = [];
    this.nbnef = [];
    this.pnv = [];
    this.sidv = [];
    this.eidv = [];
    this.pdv = [];
    this.pmv = [];
    this.ctv = [];
    this.vtv = [];
    this.crv = [];
    this.tecv = [];
    this.bmv = [];
    this.mav = [];
    this.pfv = [];
    this.stav = [];
    this.vatp = [];
    this.islv = [];
    this.iclv = [];
    this.scrn = [];
    this.lecrn = [];
    this.lrcrn = [];
    this.islav = [];
    this.iclav = [];
    this.cessv = [];
    this.tcsv = [];
    this.discv = [];
    this.vrnv = [];
    this.hsnv = [];
    this.lrgv = [];
    this.legv = [];
    this.smnv = [];
    this.iclmnv = [];
    this.icleiv = [];
    this.insv = [];
    this.regv = [];
    this.cgstv = [];
    this.sgstv = [];
    this.igstv = [];
    this.lineItems = [];
    this.dataItems = [];
    this.colNames = [];
    this.dataColNames = [];
    this.oldColnames = [];
    this.dataOldColnames = [];
    this.cols = [];
    this.dataCols = [];
    this.invCo = [];
    this.chnvCo = [];
    this.envCo = [];
    this.mnvCo = [];
    this.cmnvCo = [];
    this.cmnlCo = [];
    this.sesvCo = [];
    this.emvCo = [];
    this.isvCo = [];
    this.cavCo = [];
    this.cnvCo = [];
    this.savCo = [];
    this.idvCo = [];
    this.igvCo = [];
    this.cgvCo = [];
    this.ivvCo = [];
    this.cvvCo = [];
    this.ipvCo = [];
    this.itvCo = [];
    this.cgstpCo = [];
    this.sgstpCo = [];
    this.igstpCo = [];
    this.txavCo = [];
    this.gnvCo = [];
    this.ihvCo = [];
    this.bdvCo = [];
    this.csnvCo = [];
    this.ssnvCo = [];
    this.scnvCo = [];
    this.ccnvCo = [];
    this.ilvCo = [];
    this.ptvCo = [];
    this.iitvCo = [];
    this.cgstvCo = [];
    this.sgstvCo = [];
    this.igstvCo = [];
    this.lineCo = [];
    this.dataCo = [];

    this.invlfound = false;
    this.chnvlfound = false;
    this.envlfound = false;
    this.mnvlfound = false;
    this.cmnvlfound = false;
    this.cmnllfound = false;
    this.sesvlfound = false;
    this.emvlfound = false;
    this.isvlfound = false;
    this.idvlfound = false;
    this.igvlfound = false;
    this.cgvlfound = false;
    this.ivvlfound = false;
    this.cvvlfound = false;
    this.itvlfound = false;
    this.ipvlfound = false;
    this.fcvlfound = false;
    this.mdvlfound = false;
    this.icvlfound = false;
    this.cavlfound = false;
    this.cnvlfound = false;
    this.savlfound = false;
    this.cgstvlfound = false;
    this.sgstvlfound = false;
    this.igstvlfound = false;
    this.cgstplfound = false;
    this.sgstplfound = false;
    this.igstplfound = false;
    this.txavlfound = false;
    this.gnvlfound = false;
    this.ihvlfound = false;
    this.bdvlfound = false;
    this.csnvlfound = false;
    this.ssnvlfound = false;
    this.scnvlfound = false;
    this.ccnvlfound = false;
    this.ilvlfound = false;
    this.ptvlfound = false;
    this.iitvlfound = false;
    this.spnvlfound = false;
    this.cpnvlfound = false;
    this.icnvlfound = false;
    this.dnvlfound = false;
    this.qivlfound = false;
    this.pnvlfound = false;
    this.sidvlfound = false;
    this.eidvlfound = false;
    this.pdvlfound = false;
    this.pmvlfound = false;
    this.ctvlfound = false;
    this.vtvlfound = false;
    this.crvlfound = false;
    this.tecvlfound = false;
    this.bmvlfound = false;
    this.mavlfound = false;
    this.pfvlfound = false;
    this.stavlfound = false;
    this.vatplfound = false;
    this.islvlfound = false;
    this.iclvlfound = false;
    this.scrnlfound = false;
    this.lecrnlfound = false;
    this.lrcrnlfound = false;
    this.islavlfound = false;
    this.iclavlfound = false;
    this.cessvlfound = false;
    this.tcsvlfound = false;
    this.discvlfound = false;
    this.vrnvlfound = false;
    this.hsnvlfound = false;
    this.lrgvlfound = false;
    this.legvlfound = false;
    this.smnvlfound = false;
    this.iclmnlfound = false;
    this.icleilfound = false;
    this.insvlfound = false;
    this.regvlfound = false;
    this.invlChanged = [];
    this.chnvlChanged = [];
    this.envlChanged = [];
    this.mnvlChanged = [];
    this.cmnvlChanged = [];
    this.cmnllChanged = [];
    this.sesvlChanged = [];
    this.emvlChanged = [];
    this.isvlChanged = [];
    this.cavlChanged = [];
    this.cnvlChanged = [];
    this.savlChanged = [];
    this.idvlChanged = [];
    this.igvlChanged = [];
    this.cgvlChanged = [];
    this.ivvlChanged = [];
    this.cvvlChanged = [];
    this.ipvlChanged = [];
    this.itvlChanged = [];
    this.fcvlChanged = [];
    this.icvlChanged = [];
    this.icnvlAccuracy = [];
    this.dnvlAccuracy = [];
    this.qivlAccuracy = [];
    this.pnvlAccuracy = [];
    this.sidvlAccuracy = [];
    this.eidvlAccuracy = [];
    this.pdvlAccuracy = [];
    this.pmvlAccuracy = [];
    this.ctvlAccuracy = [];
    this.vtvlAccuracy = [];
    this.crvlAccuracy = [];
    this.tecvlAccuracy = [];
    this.bmvlAccuracy = [];
    this.mavlAccuracy = [];
    this.pfvlAccuracy = [];
    this.stavlAccuracy = [];
    this.vatplAccuracy = [];
    this.islvlAccuracy = [];
    this.iclvlAccuracy = [];
    this.scrnlAccuracy = [];
    this.lecrnlAccuracy = [];
    this.lrcrnlAccuracy = [];
    this.islavlAccuracy = [];
    this.iclavlAccuracy = [];
    this.cessvlAccuracy = [];
    this.tcsvlAccuracy = [];
    this.discvlAccuracy = [];
    this.vrnvlAccuracy = [];
    this.hsnvlAccuracy = [];
    this.lrgvlAccuracy = [];
    this.legvlAccuracy = [];
    this.smnvlAccuracy = [];
    this.iclmnlAccuracy = [];
    this.icleilAccuracy = [];
    this.insvlAccuracy = [];
    this.regvlAccuracy = [];
    this.mdvlChanged = [];
    this.cgstvlChanged = [];
    this.sgstvlChanged = [];
    this.igstvlChanged = [];
    this.cgstplChanged = [];
    this.sgstplChanged = [];
    this.igstplChanged = [];
    this.txavlChanged = [];
    this.gnvlChanged = [];
    this.ihvlChanged = [];
    this.bdvlChanged = [];
    this.csnvlChanged = [];
    this.ssnvlChanged = [];
    this.scnvlChanged = [];
    this.ccnvlChanged = [];
    this.ilvlChanged = [];
    this.ptvlChanged = [];
    this.iitvlChanged = [];
    this.spnvlChanged = [];
    this.cpnvlChanged = [];
    this.icnvlChanged = [];
    this.dnvlChanged = [];
    this.qivlChanged = [];
    this.pnvlChanged = [];
    this.sidvlChanged = [];
    this.eidvlChanged = [];
    this.pdvlChanged = [];
    this.pmvlChanged = [];
    this.ctvlChanged = [];
    this.vtvlChanged = [];
    this.crvlChanged = [];
    this.tecvlChanged = [];
    this.bmvlChanged = [];
    this.mavlChanged = [];
    this.pfvlChanged = [];
    this.stavlChanged = [];
    this.vatplChanged = [];
    this.islvlChanged = [];
    this.iclvlChanged = [];
    this.scrnlChanged = [];
    this.lecrnlChanged = [];
    this.lrcrnlChanged = [];
    this.islavlChanged = [];
    this.iclavlChanged = [];
    this.cessvlChanged = [];
    this.tcsvlChanged = [];
    this.discvlChanged = [];
    this.vrnvlChanged = [];
    this.hsnvlChanged = [];
    this.lrgvlChanged = [];
    this.legvlChanged = [];
    this.smnvlChanged = [];
    this.iclmnlChanged = [];
    this.icleilChanged = [];
    this.insvlChanged = [];
    this.regvlChanged = [];
    this.invlAccuracy = [];
    this.chnvlAccuracy = [];
    this.envlAccuracy = [];
    this.mnvlAccuracy = [];
    this.cmnvlAccuracy = [];
    this.cmnllAccuracy = [];
    this.sesvlAccuracy = [];
    this.emvlAccuracy = [];
    this.isvlAccuracy = [];
    this.cnvlAccuracy = [];
    this.cavlAccuracy = [];
    this.savlAccuracy = [];
    this.idvlAccuracy = [];
    this.igvlAccuracy = [];
    this.cgvlAccuracy = [];
    this.ivvlAccuracy = [];
    this.cvvlAccuracy = [];
    this.ipvlAccuracy = [];
    this.itvlAccuracy = [];
    this.icvlAccuracy = [];
    this.fcvlAccuracy = [];
    this.mdvlAccuracy = [];
    this.cgstvlAccuracy = [];
    this.sgstvlAccuracy = [];
    this.igstvlAccuracy = [];
    this.cgstplAccuracy = [];
    this.sgstplAccuracy = [];
    this.igstplAccuracy = [];
    this.txavlAccuracy = [];
    this.gnvlAccuracy = [];
    this.ihvlAccuracy = [];
    this.bdvlAccuracy = [];
    this.csnvlAccuracy = [];
    this.ssnvlAccuracy = [];
    this.scnvlAccuracy = [];
    this.ccnvlAccuracy = [];
    this.ilvlAccuracy = [];
    this.ptvlAccuracy = [];
    this.iitvlAccuracy = [];
    this.spnvlAccuracy = [];
    this.cpnvlAccuracy = [];
    this.invl = [];
    this.chnvl = [];
    this.envl = [];
    this.mnvl = [];
    this.cmnvl = [];
    this.cmnll = [];
    this.sesvl = [];
    this.emvl = [];
    this.idvl = [];
    this.fcvl = [];
    this.icvl = [];
    this.isvl = [];
    this.igvl = [];
    this.cgvl = [];
    this.ivvl = [];
    this.cvvl = [];
    this.ipvl = [];
    this.mdvl = [];
    this.itvl = [];
    this.cavl = [];
    this.cnvl = [];
    this.savl = [];
    this.cgstpl = [];
    this.sgstpl = [];
    this.igstpl = [];
    this.txavl = [];
    this.gnvl = [];
    this.ihvl = [];
    this.bdvl = [];
    this.csnvl = [];
    this.ssnvl = [];
    this.scnvl = [];
    this.ccnvl = [];
    this.ilvl = [];
    this.ptvl = [];
    this.iitvl = [];
    this.spnvl = [];
    this.cpnvl = [];
    this.icnvl = [];
    this.dnvl = [];
    this.qivl = [];
    this.pnvl = [];
    this.sidvl = [];
    this.eidvl = [];
    this.pdvl = [];
    this.pmvl = [];
    this.ctvl = [];
    this.vtvl = [];
    this.crvl = [];
    this.tecvl = [];
    this.bmvl = [];
    this.mavl = [];
    this.pfvl = [];
    this.stavl = [];
    this.vatpl = [];
    this.islvl = [];
    this.iclvl = [];
    this.scrnl = [];
    this.lecrnl = [];
    this.lrcrnl = [];
    this.islavl = [];
    this.iclavl = [];
    this.cessvl = [];
    this.tcsvl = [];
    this.discvl = [];
    this.vrnvl = [];
    this.hsnvl = [];
    this.lrgvl = [];
    this.legvl = [];
    this.smnvl = [];
    this.iclmnl = [];
    this.icleil = [];
    this.insvl = [];
    this.regvl = [];
    this.cgstvl = [];
    this.sgstvl = [];
    this.igstvl = [];
    this.invlCo = [];
    this.chnvlCo = [];
    this.envlCo = [];
    this.mnvlCo = [];
    this.cmnvlCo = [];
    this.cmnllCo = [];
    this.sesvlCo = [];
    this.fcvlCo = [];
    this.icvlCo = [];
    this.emvlCo = [];
    this.isvlCo = [];
    this.cavlCo = [];
    this.cnvlCo = [];
    this.savlCo = [];
    this.idvlCo = [];
    this.igvlCo = [];
    this.cgvlCo = [];
    this.ivvlCo = [];
    this.cvvlCo = [];
    this.ipvlCo = [];
    this.mdvlCo = [];
    this.itvlCo = [];
    this.cgstplCo = [];
    this.sgstplCo = [];
    this.igstplCo = [];
    this.txavlCo = [];
    this.gnvlCo = [];
    this.ihvlCo = [];
    this.bdvlCo = [];
    this.csnvlCo = [];
    this.ssnvlCo = [];
    this.scnvlCo = [];
    this.ccnvlCo = [];
    this.ilvlCo = [];
    this.ptvlCo = [];
    this.iitvlCo = [];
    this.spnvlCo = [];
    this.cpnvlCo = [];
    this.icnvlCo = [];
    this.dnvlCo = [];
    this.qivlCo = [];
    this.pnvlCo = [];
    this.sidvlCo = [];
    this.eidvlCo = [];
    this.pdvlCo = [];
    this.pmvlCo = [];
    this.ctvlCo = [];
    this.vtvlCo = [];
    this.crvlCo = [];
    this.tecvlCo = [];
    this.bmvlCo = [];
    this.mavlCo = [];
    this.pfvlCo = [];
    this.stavlCo = [];
    this.vatplCo = [];
    this.islvlCo = [];
    this.iclvlCo = [];
    this.scrnlCo = [];
    this.lecrnlCo = [];
    this.lrcrnlCo = [];
    this.islavlCo = [];
    this.iclavlCo = [];
    this.cessvlCo = [];
    this.tcsvlCo = [];
    this.discvlCo = [];
    this.vrnvlCo = [];
    this.hsnvlCo = [];
    this.lrgvlCo = [];
    this.legvlCo = [];
    this.smnvlCo = [];
    this.iclmnlCo = [];
    this.icleilCo = [];
    this.insvlCo = [];
    this.regvlCo = [];
    this.cgstvlCo = [];
    this.sgstvlCo = [];
    this.igstvlCo = [];
  }

  processOutputData(modified, approved) {
    // console.log('input:', this.data);
    this.itemnumber = 1;
    let cmnvSaved = false;
    let igvlSaved = false;
    let cgvlSaved = false;
    let ivvlSaved = false;
    let cvvlSaved = false;

    try {
      for (let itrI = 0; itrI < this.rawData.length; itrI++) {
        delete this.rawData[itrI]['user_data'];
        this.image_id = this.rawData[itrI].image_id;
        try {
          this.ui_image_list = this.rawData[itrI].ui_image_list;
        } catch (e) {}
        if (this.data.obj_id === this.rawData[itrI]._id) {
          this.rawData[itrI].is_modified = modified;
          this.rawData[itrI].is_opened = 1;
          this.rawData[itrI].is_approved = approved;

          // debug line to be removed
          // if (approved) {
          //   this.rawData[itrI]['QBE_response'] = {'QBE_status': 'failed'};
          // }

          this.rawData[itrI].splitBillByValue = this.data.splitBillByValue;
          this.rawData[itrI].image_quality_error = this.data.ipef;
          this.rawData[itrI].error_fields = this.data.error_fields;
          this.rawData[itrI].exception_type = this.data.exceptionType;
          this.rawData[itrI].exception_image_details = this.data.subExceptionType;

          if (approved) {
            this.rawData[itrI]['approver'] = sessionStorage.getItem('username');
            let date = new Date(Date.now()).toDateString().split(' ');
            let formatedDate = [date[2], date[1], date[3]].join('-');
            this.rawData[itrI]['approved_date'] = formatedDate;
            // this.rawData[itrI]['approved_time'] = new Date(Date.now()).toUTCString();
          } else {
            this.rawData[itrI]['modifier'] = sessionStorage.getItem('username');
            let date = new Date(Date.now()).toDateString().split(' ');
            let formatedDate = [date[2], date[1], date[3]].join('-');
            this.rawData[itrI]['modified_date'] = formatedDate;
            // this.rawData[itrI]['modified_time'] = new Date(Date.now()).toUTCString();
          }
          this.rawData[itrI]['AccountsData'] = this.data.AccountsData;
          this.rawData[itrI]['SplitAccountsJson'] = this.data.SplitAccountsJson;
          this.rawData[itrI]['Tax Amount'] = this.data['Tax Amount'];
          this.rawData[itrI]['CGST'] = this.data['CGST'];
          this.rawData[itrI]['SGST'] = this.data['SGST'];
          this.rawData[itrI]['IGST'] = this.data['IGST'];
          this.rawData[itrI]['PurchaseAc'] = this.data['PurchaseAc'];
          this.rawData[itrI]['LineItemAccountsJson'] = this.data.LineItemAccountsJson;
          this.rawData[itrI]['isvTrained'] = this.data.isvTrained;
          if (this.data.lineTableTitle) {
            this.rawData[itrI]['lineTableTitle'] = this.data.lineTableTitle;
          }
          if (this.data.dataTableTitle) {
            this.rawData[itrI]['dataTableTitle'] = this.data.dataTableTitle;
          }
          const recordId = itrI;
          this.total_fields = 0;
          if (modified === 1) {
            for (let itrk = 0; itrk < this.rawData[itrI].result.length; itrk++) {
              try {
                // INV
                if (this.rawData[itrI].result[itrk][0][1] === 'INV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['invPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['invCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['inv'][0], 'INV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['invCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['invChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['inv'][0], 'INV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['invChanged'][0] });
                  }
                  this.invfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CHNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['chnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['chnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['chnv'][0], 'CHNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['chnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['chnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['chnv'][0], 'CHNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['chnvChanged'][0] });
                  }
                  this.chnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ENV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['envPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['envCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['env'][0], 'ENV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['envCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['envChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['env'][0], 'ENV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['envChanged'][0] });
                  }
                  this.envfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'MNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['mnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['mnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mnv'][0], 'MNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['mnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mnv'][0], 'MNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mnvChanged'][0] });
                  }
                  this.mnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CMNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cmnlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cmnlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cmnl'], 'CMNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cmnlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cmnlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cmnl'][0], 'CMNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cmnlChanged'][0] });
                  }
                  this.cmnlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SESV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['sesvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['sesvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sesv'], 'SESV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['sesvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sesvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sesv'], 'SESV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sesvChanged'][0] });
                  }
                  this.sesvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'EMV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['emvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['emvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['emv'][0], 'EMV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['emvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['emvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['emv'][0], 'EMV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['emvChanged'][0] });
                  }
                  this.emvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ISV') {

                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['isvPage'];
                  this.rawData[itrI].supplier_name = this.data['page_' + imgIdx]['isv'][0];

                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['isvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['isv'][0], 'ISV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['isvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['isvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['isv'][0], 'ISV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['isvChanged'][0] });
                  }
                  this.isvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cnv'][0], 'CNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cnv'][0], 'CNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cnvChanged'][0] });
                  }
                  this.cnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CAV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cavPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cavCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cav'][0], 'CAV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cavCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cavChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cav'][0], 'CAV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cavChanged'][0] });
                  }
                  this.cavfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SAV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['savPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['savCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sav'][0], 'SAV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['savCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['savChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sav'][0], 'SAV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['savChanged'][0] });
                  }
                  this.savfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IDV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['idvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['idvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['idv'][0], 'IDV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['idvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['idvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['idv'][0], 'IDV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['idvChanged'][0] });
                  }
                  this.idvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IGV') {
                  if (this.data['igvChanged'][0] == 1) {
                    this.igvfound = true;
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CGV') {
                  if (this.data['cgvChanged'][0] == 1) {
                    this.cgvfound = true;
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IVV') {
                  if (this.data['ivvChanged'][0] == 1) {
                    this.ivvfound = true;
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CVV') {
                  if (this.data['cvvChanged'][0] == 1) {
                    this.cvvfound = true;
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CMNV') {
                  if (cmnvSaved) {
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                  cmnvSaved = true;
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cmnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cmnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cmnv'][0], 'CMNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cmnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cmnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cmnv'][0], 'CMNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cmnvChanged'][0] });
                  }
                  this.cmnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IPV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ipvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ipvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ipv'][0], 'IPV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ipvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ipvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ipv'][0], 'IPV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ipvChanged'][0] });
                  }
                  this.ipvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'MDV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['mdvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['mdvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mdv'][0], 'MDV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['mdvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mdvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mdv'][0], 'MDV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mdvChanged'][0] });
                  }
                  this.mdvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['icvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['icvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icv'][0], 'ICV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['icvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icv'][0], 'ICV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icvChanged'][0] });
                  }
                  this.icvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'FCV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['fcvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['fcvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['fcv'][0], 'FCV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['fcvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['fcvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['fcv'][0], 'FCV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['fcvChanged'][0] });
                  }
                  this.fcvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'TAV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['itvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['itvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['itv'][0], 'TAV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['itvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['itvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['itv'][0], 'TAV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['itvChanged'][0] });
                  }
                  this.itvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CGSTV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cgstvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cgstvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cgstv'][0], 'CGSTV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cgstvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cgstvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cgstv'][0], 'CGSTV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cgstvChanged'][0] });
                  }
                  this.cgstvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SGSTV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['sgstvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['sgstvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sgstv'][0], 'SGSTV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['sgstvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sgstvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sgstv'][0], 'SGSTV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sgstvChanged'][0] });
                  }
                  this.sgstvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IGSTV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['igstvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['igstvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['igstv'][0], 'IGSTV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['igstvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['igstvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['igstv'][0], 'IGSTV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['igstvChanged'][0] });
                  }
                  this.igstvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CGSTP') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cgstpPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cgstpCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cgstp'][0], 'CGSTP', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cgstpCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cgstpChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cgstp'][0], 'CGSTP', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cgstpChanged'][0] });
                  }
                  this.cgstpfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SGSTP') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['sgstpPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['sgstpCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sgstp'][0], 'SGSTP', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['sgstpCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sgstpChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sgstp'][0], 'SGSTP', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sgstpChanged'][0] });
                  }
                  this.sgstpfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IGSTP') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['igstpPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['igstpCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['igstp'][0], 'IGSTP', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['igstpCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['igstpChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['igstp'][0], 'IGSTP', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['igstpChanged'][0] });
                  }
                  this.igstpfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'TXAV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['txavPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['txavCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['txav'][0], 'TXAV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['txavCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['txavChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['txav'][0], 'TXAV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['txavChanged'][0] });
                  }
                  this.txavfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'GNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['gnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['gnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['gnv'][0], 'GNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['gnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['gnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['gnv'][0], 'GNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['gnvChanged'][0] });
                  }
                  this.gnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IHV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ihvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ihvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ihv'][0], 'IHV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ihvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ihvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ihv'][0], 'IHV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ihvChanged'][0] });
                  }
                  this.ihvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'BDV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['bdvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['bdvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['bdv'][0], 'BDV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['bdvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['bdvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['bdv'][0], 'BDV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['bdvChanged'][0] });
                  }
                  this.bdvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CSNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['csnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['csnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['csnv'][0], 'CSNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['csnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['csnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['csnv'][0], 'CSNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['csnvChanged'][0] });
                  }
                  this.csnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SSNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ssnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ssnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ssnv'][0], 'SSNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ssnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ssnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ssnv'][0], 'SSNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ssnvChanged'][0] });
                  }
                  this.ssnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SCNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['scnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  try {
                    if (this.data['page_' + imgIdx]['coordinates']['scnvCo'][0]) {
                      this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['scnv'][0], 'SCNV', 1]);
                      this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['scnvCo'][0]);
                      this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                      this.rawData[itrI].result[itrk].push({ is_modified: this.data['scnvChanged'][0] });
                    } else {
                      this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['scnv'][0], 'SCNV', 1]);
                      this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                      this.rawData[itrI].result[itrk].push({ is_modified: this.data['scnvChanged'][0] });
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.scnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CCNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ccnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ccnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ccnv'][0], 'CCNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ccnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ccnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ccnv'][0], 'CCNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ccnvChanged'][0] });
                  }
                  this.ccnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ILV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ilvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ilvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ilv'][0], 'ILV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ilvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ilvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ilv'][0], 'ILV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ilvChanged'][0] });
                  }
                  this.ilvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PTV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ptvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ptvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ptv'][0], 'PTV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ptvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ptvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ptv'][0], 'PTV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ptvChanged'][0] });
                  }
                  this.ptvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IITV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['iitvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['iitvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iitv'][0], 'IITV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['iitvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iitvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iitv'][0], 'IITV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iitvChanged'][0] });
                  }
                  this.iitvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SPNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['spnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['spnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['spnv'][0], 'SPNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['spnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['spnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['spnv'][0], 'SPNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['spnvChanged'][0] });
                  }
                  this.spnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CPNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cpnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cpnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cpnv'][0], 'CPNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cpnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cpnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cpnv'][0], 'CPNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cpnvChanged'][0] });
                  }
                  this.cpnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['icnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['icnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icnv'][0], 'ICNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['icnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icnv'][0], 'ICNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icnvChanged'][0] });
                  }
                  this.icnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'DNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['dnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['dnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['dnv'][0], 'DNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['dnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['dnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['dnv'][0], 'DNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['dnvChanged'][0] });
                  }
                  this.dnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'QIV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['qivPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['qivCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['qiv'][0], 'QIV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['qivCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['qivChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['qiv'][0], 'QIV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['qivChanged'][0] });
                  }
                  this.qivfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'NIQ') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['niqPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['niqCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['niq'][0], 'NIQ', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['niqCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['niqChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['niq'][0], 'NIQ', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['niqChanged'][0] });
                  }
                  this.niqfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'NAN') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['nanPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['nanCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['nan'][0], 'NAN', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['nanCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['nanChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['nan'][0], 'NAN', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['nanChanged'][0] });
                  }
                  this.nanfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'DOCTYPE') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ntypePage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ntypeCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ntype'][0], 'DOCTYPE', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ntypeCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ntypeChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ntype'][0], 'DOCTYPE', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ntypeChanged'][0] });
                  }
                  this.ntypefound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'NRN1') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['nrn1Page'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['nrn1Co'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['nrn1'][0], 'NRN1', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['nrn1Co'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['nrn1Changed'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['nrn1'][0], 'NRN1', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['nrn1Changed'][0] });
                  }
                  this.nrn1found = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'NSEF') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['nsefPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['nsefCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['nsef'][0], 'NSEF', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['nsefCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['nsefChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['nsef'][0], 'NSEF', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['nsefChanged'][0] });
                  }
                  this.nseffound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'NBNEF') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['nbnefPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['nbnefCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['nbnef'][0], 'NBNEF', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['nbnefCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['nbnefChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['nbnef'][0], 'NBNEF', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['nbnefChanged'][0] });
                  }
                  this.nbneffound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['pnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['pnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pnv'][0], 'PNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['pnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pnv'][0], 'PNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pnvChanged'][0] });
                  }
                  this.pnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SIDV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['sidvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['sidvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sidv'][0], 'SIDV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['sidvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sidvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sidv'][0], 'SIDV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sidvChanged'][0] });
                  }
                  this.sidvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'EIDV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['eidvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['eidvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['eidv'][0], 'EIDV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['eidvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['eidvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['eidv'][0], 'EIDV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['eidvChanged'][0] });
                  }
                  this.eidvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PDV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['pdvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['pdvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pdv'][0], 'PDV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['pdvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pdvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pdv'][0], 'PDV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pdvChanged'][0] });
                  }
                  this.pdvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PMV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['pmvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['pmvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pmv'][0], 'PMV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['pmvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pmvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pmv'][0], 'PMV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pmvChanged'][0] });
                  }
                  this.pmvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CTV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ctvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ctvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ctv'][0], 'CTV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ctvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ctvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ctv'][0], 'CTV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ctvChanged'][0] });
                  }
                  this.ctvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'VTV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['vtvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['vtvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vtv'][0], 'VTV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['vtvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vtvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vtv'][0], 'VTV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vtvChanged'][0] });
                  }
                  this.vtvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'MAV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['mavPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['mavCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mav'][0], 'MAV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['mavCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mavChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mav'][0], 'MAV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mavChanged'][0] });
                  }
                  this.mavfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PFV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['pfvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['pfvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pfv'][0], 'PFV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['pfvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pfvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pfv'][0], 'PFV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pfvChanged'][0] });
                  }
                  this.pfvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'STAV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['stavPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['stavCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['stav'][0], 'STAV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['stavCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['stavChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['stav'][0], 'STAV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['stavChanged'][0] });
                  }
                  this.stavfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CRV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['crvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['crvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['crv'][0], 'CRV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['crvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['crvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['crv'][0], 'CRV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['crvChanged'][0] });
                  }
                  this.crvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'TECV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['tecvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['tecvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['tecv'][0], 'TECV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['tecvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['tecvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['tecv'][0], 'TECV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['tecvChanged'][0] });
                  }
                  this.tecvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'BMV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['bmvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['bmvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['bmv'][0], 'BMV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['bmvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['bmvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['bmv'][0], 'BMV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['bmvChanged'][0] });
                  }
                  this.bmvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'VATP') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['vatpPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['vatpCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vatp'][0], 'VATP', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['vatpCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vatpChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vatp'][0], 'VATP', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vatpChanged'][0] });
                  }
                  this.vatpfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ISLV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['islvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['islvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['islv'][0], 'ISLV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['islvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['islvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['islv'][0], 'ISLV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['islvChanged'][0] });
                  }
                  this.islvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICLV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['iclvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['iclvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclv'][0], 'ICLV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['iclvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclv'][0], 'ICLV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclvChanged'][0] });
                  }
                  this.iclvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SCRN') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['scrnPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['scrnCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['scrn'][0], 'SCRN', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['scrnCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['scrnChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['scrn'][0], 'SCRN', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['scrnChanged'][0] });
                  }
                  this.scrnfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'LECRN') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['lecrnPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['lecrnCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lecrn'][0], 'LECRN', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['lecrnCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lecrnChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lecrn'][0], 'LECRN', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lecrnChanged'][0] });
                  }
                  this.lecrnfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'LRCRN') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['lrcrnPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['lrcrnCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lrcrn'][0], 'LRCRN', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['lrcrnCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lrcrnChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lrcrn'][0], 'LRCRN', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lrcrnChanged'][0] });
                  }
                  this.lrcrnfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ISLAV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['islavPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['islavCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['islav'][0], 'ISLAV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['islavCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['islavChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['islav'][0], 'ISLAV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['islavChanged'][0] });
                  }
                  this.islavfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICLAV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['iclavPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['iclavCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclav'][0], 'ICLAV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['iclavCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclavChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclav'][0], 'ICLAV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclavChanged'][0] });
                  }
                  this.iclavfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CESSV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cessvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cessvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cessv'][0], 'CESSV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cessvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cessvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cessv'][0], 'CESSV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cessvChanged'][0] });
                  }
                  this.cessvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'TCSV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['tcsvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['tcsvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['tcsv'][0], 'TCSV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['tcsvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['tcsvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['tcsv'][0], 'TCSV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['tcsvChanged'][0] });
                  }
                  this.tcsvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'DISCV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['discvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['discvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['discv'][0], 'DISCV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['discvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['discvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['discv'][0], 'DISCV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['discvChanged'][0] });
                  }
                  this.discvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'VRNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['vrnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['vrnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vrnv'][0], 'VRNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['vrnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vrnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vrnv'][0], 'VRNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vrnvChanged'][0] });
                  }
                  this.vrnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'HSNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['hsnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['hsnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['hsnv'][0], 'HSNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['hsnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['hsnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['hsnv'][0], 'HSNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['hsnvChanged'][0] });
                  }
                  this.hsnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'LRGV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['lrgvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['lrgvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lrgv'][0], 'LRGV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['lrgvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lrgvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lrgv'][0], 'LRGV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lrgvChanged'][0] });
                  }
                  this.lrgvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'LEGV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['legvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['legvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['legv'][0], 'LEGV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['legvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['legvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['legv'][0], 'LEGV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['legvChanged'][0] });
                  }
                  this.legvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SMNV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['smnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['smnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['smnv'][0], 'SMNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['smnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['smnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['smnv'][0], 'SMNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['smnvChanged'][0] });
                  }
                  this.smnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICLMNV') {
                  console.log('found at:', itrI)
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['iclmnvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['iclmnvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclmnv'][0], 'ICLMNV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['iclmnvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclmnvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclmnv'][0], 'ICLMNV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclmnvChanged'][0] });
                  }
                  this.iclmnvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICLEIV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['icleivPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['icleivCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icleiv'][0], 'ICLEIV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['icleivCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icleivChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icleiv'][0], 'ICLEIV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icleivChanged'][0] });
                  }
                  this.icleivfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'INSV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['insvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['insvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['insv'][0], 'INSV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['insvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['insvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['insv'][0], 'INSV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['insvChanged'][0] });
                  }
                  this.insvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'REGV') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['regvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['regvCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['regv'][0], 'REGV', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['regvCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['regvChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['regv'][0], 'REGV', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['regvChanged'][0] });
                  }
                  this.regvfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'INL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['invlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  if (this.data['page_' + imgIdx]['coordinates']['invlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['invl'][0], 'INL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['invlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['invlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['invl'][0], 'INL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['invlChanged'][0] });
                  }
                  this.invlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CHNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['chnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  if (this.data['page_' + imgIdx]['coordinates']['chnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['chnvl'][0], 'CHNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['chnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['chnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['chnvl'][0], 'CHNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['chnvlChanged'][0] });
                  }
                  this.chnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ENL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['envlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['envlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['envl'][0], 'ENL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['envlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['envlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['envl'][0], 'ENL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['envlChanged'][0] });
                  }
                  this.envlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'MNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['mnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['mnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mnvl'][0], 'MNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['mnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mnvl'][0], 'MNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mnvlChanged'][0] });
                  }
                  this.mnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'EML') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['emvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['emvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['emvl'][0], 'EML', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['emvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['emvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['emvl'][0], 'EML', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['emvlChanged'][0] });
                  }
                  this.emvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ISL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['islvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['isvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['isvl'][0], 'ISL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['isvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['isvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['isvl'][0], 'ISL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['isvlChanged'][0] });
                  }
                  this.isvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cnvl'][0], 'CNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cnvl'][0], 'CNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cnvlChanged'][0] });
                  }
                  this.cnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CAL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cavlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cavlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cavl'][0], 'CAL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cavlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cavlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cavl'][0], 'CAL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cavlChanged'][0] });
                  }
                  this.cavlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SAL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['savlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['savlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['savl'][0], 'SAL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['savlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['savlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['savl'][0], 'SAL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['savlChanged'][0] });
                  }
                  this.savlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IDL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['idvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['idvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['idvl'][0], 'IDL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['idvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['idvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['idvl'][0], 'IDL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['idvlChanged'][0] });
                  }
                  this.idvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IGL') {
                  if (igvlSaved) {
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                  igvlSaved = true;
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['igvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  try {
                    if (this.data['page_' + imgIdx]['coordinates']['igvlCo'][0]) {
                      this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['igvl'][0], 'IGL', 1]);
                      this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['igvlCo'][0]);
                      this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                      this.rawData[itrI].result[itrk].push({ is_modified: this.data['igvlChanged'][0] });
                    } else {
                      this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['igvl'][0], 'IGL', 1]);
                      this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                      this.rawData[itrI].result[itrk].push({ is_modified: this.data['igvlChanged'][0] });
                    }
                  } catch (e) {}
                  this.igvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CGL') {
                  if (cgvlSaved) {
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                  cgvlSaved = true;
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cgvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  try {
                    if (this.data['page_' + imgIdx]['coordinates']['cgvlCo'][0]) {
                      this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cgvl'][0], 'CGL', 1]);
                      this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cgvlCo'][0]);
                      this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                      this.rawData[itrI].result[itrk].push({ is_modified: this.data['cgvlChanged'][0] });
                    } else {
                      this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cgvl'][0], 'CGL', 1]);
                      this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                      this.rawData[itrI].result[itrk].push({ is_modified: this.data['cgvlChanged'][0] });
                    }
                  } catch (e) {

                  }
                  this.cgvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IVL') {
                  if (ivvlSaved) {
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                  ivvlSaved = true;
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ivvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ivvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ivvl'][0], 'IVL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ivvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ivvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ivvl'][0], 'IVL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ivvlChanged'][0] });
                  }
                  this.ivvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CVL') {
                  if (cvvlSaved) {
                    this.rawData[itrI].result.splice(itrk--, 1);
                    continue;
                  }
                  cvvlSaved = true;
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cvvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cvvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cvvl'][0], 'CVL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cvvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cvvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cvvl'][0], 'CVL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cvvlChanged'][0] });
                  }
                  this.cvvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IPL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ipvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ipvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ipvl'][0], 'IPL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ipvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ipvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ipvl'][0], 'IPL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ipvlChanged'][0] });
                  }
                  this.ipvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'MDL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['mdvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['mdvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mdvl'][0], 'MDL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['mdvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mdvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mdvl'][0], 'MDL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mdvlChanged'][0] });
                  }
                  this.mdvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['icvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['icvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icvl'][0], 'ICL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['icvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icvl'][0], 'ICL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icvlChanged'][0] });
                  }
                  this.icvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'FCL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['fcvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['fcvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['fcvl'][0], 'FCL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['fcvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['fcvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['fcvl'][0], 'FCL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['fcvlChanged'][0] });
                  }
                  this.fcvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'TAL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['itvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['itvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['itvl'][0], 'TAL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['itvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['itvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['itvl'][0], 'TAL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['itvlChanged'][0] });
                  }
                  this.itvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'TXAL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['txavlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['txavlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['txavl'][0], 'TXAL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['txavlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['txavlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['txavl'][0], 'TXAL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['txavlChanged'][0] });
                  }
                  this.txavlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'GNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['gnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['gnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['gnvl'][0], 'GNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['gnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['gnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['gnvl'][0], 'GNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['gnvlChanged'][0] });
                  }
                  this.gnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IHL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ihvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ihvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ihvl'][0], 'IHL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ihvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ihvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ihvl'][0], 'IHL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ihvlChanged'][0] });
                  }
                  this.ihvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'BDL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['bdvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['bdvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['bdvl'][0], 'BDL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['bdvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['bdvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['bdvl'][0], 'BDL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['bdvlChanged'][0] });
                  }
                  this.bdvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CSNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['csnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['csnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['csnvl'][0], 'CSNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['csnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['csnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['csnvl'][0], 'CSNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['csnvlChanged'][0] });
                  }
                  this.csnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SSNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ssnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ssnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ssnvl'][0], 'SSNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ssnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ssnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ssnvl'][0], 'SSNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ssnvlChanged'][0] });
                  }
                  this.ssnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SCNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['scnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  try {
                    if (this.data['page_' + imgIdx]['coordinates']['scnvlCo'][0]) {
                      this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['scnvl'][0], 'SCNL', 1]);
                      this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['scnvlCo'][0]);
                      this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                      this.rawData[itrI].result[itrk].push({ is_modified: this.data['scnvlChanged'][0] });
                    } else {
                      this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['scnvl'][0], 'SCNL', 1]);
                      this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                      this.rawData[itrI].result[itrk].push({ is_modified: this.data['scnvlChanged'][0] });
                    }
                  } catch (e) {
                    console.log(e);
                  }
                  this.scnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CCNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ccnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ccnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ccnvl'][0], 'CCNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ccnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ccnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ccnvl'][0], 'CCNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ccnvlChanged'][0] });
                  }
                  this.ccnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ILL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ilvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ilvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ilvl'][0], 'ILL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ilvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ilvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ilvl'][0], 'ILL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ilvlChanged'][0] });
                  }
                  this.ilvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PTL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ptvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ptvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ptvl'][0], 'PTL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ptvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ptvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ptvl'][0], 'PTL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ptvlChanged'][0] });
                  }
                  this.ptvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'IITL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['iitvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['iitvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iitvl'][0], 'IITL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['iitvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iitvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iitvl'][0], 'IITL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iitvlChanged'][0] });
                  }
                  this.iitvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SPNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['spnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['spnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['spnvl'][0], 'SPNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['spnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['spnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['spnvl'][0], 'SPNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['spnvlChanged'][0] });
                  }
                  this.spnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CPNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cpnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cpnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cpnvl'][0], 'CPNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cpnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cpnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cpnvl'][0], 'CPNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cpnvlChanged'][0] });
                  }
                  this.cpnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['icnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['icnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icnvl'][0], 'ICNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['icnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icnvl'][0], 'ICNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icnvlChanged'][0] });
                  }
                  this.icnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'DNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['dnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['dnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['dnvl'][0], 'DNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['dnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['dnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['dnvl'][0], 'DNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['dnvlChanged'][0] });
                  }
                  this.dnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'QIL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['qivlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['qivlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['qivl'][0], 'QIL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['qivlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['qivlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['qivl'][0], 'QIL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['qivlChanged'][0] });
                  }
                  this.qivlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['pnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['pnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pnvl'][0], 'PNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['pnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pnvl'][0], 'PNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pnvlChanged'][0] });
                  }
                  this.pnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SIDL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['sidvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['sidvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sidvl'][0], 'SIDL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['sidvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sidvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['sidvl'][0], 'SIDL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['sidvlChanged'][0] });
                  }
                  this.sidvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'EIDL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['eidvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['eidvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['eidvl'][0], 'EIDL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['eidvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['eidvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['eidvl'][0], 'EIDL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['eidvlChanged'][0] });
                  }
                  this.eidvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PDL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['pdvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['pdvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pdvl'][0], 'PDL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['pdvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pdvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pdvl'][0], 'PDL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pdvlChanged'][0] });
                  }
                  this.pdvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PML') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['pmvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['pmvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pmvl'][0], 'PML', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['pmvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pmvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pmvl'][0], 'PML', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pmvlChanged'][0] });
                  }
                  this.pmvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CTL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['ctvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['ctvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ctvl'][0], 'CTL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['ctvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ctvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['ctvl'][0], 'CTL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['ctvlChanged'][0] });
                  }
                  this.ctvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'VTL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['vtvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['vtvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vtvl'][0], 'VTL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['vtvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vtvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vtvl'][0], 'VTL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vtvlChanged'][0] });
                  }
                  this.vtvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'MAL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['mavlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['mavlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mavl'][0], 'MAL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['mavlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mavlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['mavl'][0], 'MAL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['mavlChanged'][0] });
                  }
                  this.mavlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'PFL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['pfvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['pfvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pfvl'][0], 'PFL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['pfvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pfvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['pfvl'][0], 'PFL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['pfvlChanged'][0] });
                  }
                  this.pfvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'STAL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['stavlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['stavlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['stavl'][0], 'STAL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['stavlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['stavlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['stavl'][0], 'STAL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['stavlChanged'][0] });
                  }
                  this.stavlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CRL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['crvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['crvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['crvl'][0], 'CRL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['crvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['crvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['crvl'][0], 'CRL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['crvlChanged'][0] });
                  }
                  this.crvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'TECL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['tecvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['tecvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['tecvl'][0], 'TECL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['tecvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['tecvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['tecvl'][0], 'TECL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['tecvlChanged'][0] });
                  }
                  this.tecvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'BML') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['bmvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['bmvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['bmvl'][0], 'BML', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['bmvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['bmvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['bmvl'][0], 'BML', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['bmvlChanged'][0] });
                  }
                  this.bmvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'VATPL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['vatplPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['vatplCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vatpl'][0], 'VATPL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['vatplCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vatplChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vatpl'][0], 'VATPL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vatplChanged'][0] });
                  }
                  this.vatplfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ISLL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['islvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['islvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['islvl'][0], 'ISLL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['islvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['islvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['islvl'][0], 'ISLL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['islvlChanged'][0] });
                  }
                  this.islvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICLL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['iclvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['iclvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclvl'][0], 'ICLL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['iclvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclvl'][0], 'ICLL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclvlChanged'][0] });
                  }
                  this.iclvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SCRNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['scrnlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['scrnlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['scrnl'][0], 'SCRNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['scrnlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['scrnlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['scrnl'][0], 'SCRNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['scrnlChanged'][0] });
                  }
                  this.scrnlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'LECRNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['lecrnlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['lecrnlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lecrnl'][0], 'LECRNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['lecrnlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lecrnlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lecrnl'][0], 'LECRNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lecrnlChanged'][0] });
                  }
                  this.lecrnlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'LRCRNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['lrcrnlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['lrcrnlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lrcrnl'][0], 'LRCRNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['lrcrnlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lrcrnlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lrcrnl'][0], 'LRCRNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lrcrnlChanged'][0] });
                  }
                  this.lrcrnlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ISLAL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['islavlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['islavlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['islavl'][0], 'ISLAL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['islavlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['islavlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['islavl'][0], 'ISLAL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['islavlChanged'][0] });
                  }
                  this.islavlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICLAL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['iclavlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['iclavlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclavl'][0], 'ICLAL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['iclavlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclavlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclavl'][0], 'ICLAL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclavlChanged'][0] });
                  }
                  this.iclavlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'CESSL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['cessvPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['cessvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cessvl'][0], 'CESSL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['cessvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cessvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['cessvl'][0], 'CESSL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['cessvlChanged'][0] });
                  }
                  this.cessvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'TCSL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['tcsvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['tcsvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['tcsvl'][0], 'TCSL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['tcsvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['tcsvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['tcsvl'][0], 'TCSL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['tcsvlChanged'][0] });
                  }
                  this.tcsvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'DISCL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['discvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['discvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['discvl'][0], 'DISCL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['discvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['discvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['discvl'][0], 'DISCL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['discvlChanged'][0] });
                  }
                  this.discvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'VRNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['vrnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['vrnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vrnvl'][0], 'VRNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['vrnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vrnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['vrnvl'][0], 'VRNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['vrnvlChanged'][0] });
                  }
                  this.vrnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'HSNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['hsnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['hsnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['hsnvl'][0], 'HSNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['hsnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['hsnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['hsnvl'][0], 'HSNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['hsnvlChanged'][0] });
                  }
                  this.hsnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'LRGL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['lrgvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['lrgvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lrgvl'][0], 'LRGL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['lrgvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lrgvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['lrgvl'][0], 'LRGL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['lrgvlChanged'][0] });
                  }
                  this.lrgvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'LEGL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['legvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['legvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['legvl'][0], 'LEGL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['legvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['legvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['legvl'][0], 'LEGL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['legvlChanged'][0] });
                  }
                  this.legvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'SMNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['smnvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['smnvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['smnvl'][0], 'SMNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['smnvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['smnvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['smnvl'][0], 'SMNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['smnvlChanged'][0] });
                  }
                  this.smnvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICLMNL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['iclmnlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['iclmnlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclmnl'][0], 'ICLMNL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['iclmnlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclmnlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['iclmnl'][0], 'ICLMNL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['iclmnlChanged'][0] });
                  }
                  this.iclmnlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'ICLEIL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['icleilPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['icleilCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icleil'][0], 'ICLEIL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['icleilCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icleilChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['icleil'][0], 'ICLEIL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['icleilChanged'][0] });
                  }
                  this.icleilfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'INSL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['insvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['insvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['insvl'][0], 'INSL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['insvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['insvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['insvl'][0], 'INSL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['insvlChanged'][0] });
                  }
                  this.insvlfound = true;
                } else if (this.rawData[itrI].result[itrk][0][1] === 'REGL') {
                  let itemIndex;
                  if ([4,7].includes(this.rawData[itrI].result[itrk].length)) {
                    itemIndex = 1;
                  } else {
                    itemIndex = 0;
                  }
                  const imgIdx = this.data['regvlPage'];
                  this.rawData[itrI].result[itrk] = [];
                  this.total_fields++;
                  if (this.data['page_' + imgIdx]['coordinates']['regvlCo'][0]) {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['regvl'][0], 'REGL', 1]);
                    this.rawData[itrI].result[itrk].push(this.data['page_' + imgIdx]['coordinates']['regvlCo'][0]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['regvlChanged'][0] });
                  } else {
                    this.rawData[itrI].result[itrk].push([this.data['page_' + imgIdx]['regvl'][0], 'REGL', 1]);
                    this.rawData[itrI].result[itrk].push({ page_index: imgIdx });
                    this.rawData[itrI].result[itrk].push({ is_modified: this.data['regvlChanged'][0] });
                  }
                  this.regvlfound = true;
                } else if (
                  this.rawData[itrI].result[itrk][0][1].includes('line_item')
                ) {
                  this.rawData[itrI].result.splice(itrk--, 1);
                } else if (
                  this.rawData[itrI].result[itrk][0][1].includes('data_item')
                ) {
                  this.rawData[itrI].result.splice(itrk--, 1);
                }
              } catch (e) {
                // this.rawData[itrI].result.splice(itrI--, 1);
                console.log('error', e);
                continue;
              }
            }

            if (this.igvfound && this.data['igvChanged'][0]) {
              this.rawData[itrI].result.push([]);
              const igvIndex = this.rawData[itrI].result.length - 1;
              const imgIdx = this.data['igvPage'];
              this.total_fields++;
              if (this.data['page_' + imgIdx]['coordinates']['igvCo'][0]) {
                this.rawData[itrI].result[igvIndex].push([this.data['page_' + imgIdx]['igv'][0], 'IGV', 1]);
                this.rawData[itrI].result[igvIndex].push(this.data['page_' + imgIdx]['coordinates']['igvCo'][0]);
                this.rawData[itrI].result[igvIndex].push({ page_index: imgIdx });
                this.rawData[itrI].result[igvIndex].push({ is_modified: this.data['igvChanged'][0] });
              } else {
                this.rawData[itrI].result[igvIndex].push([this.data['page_' + imgIdx]['igv'][0], 'IGV', 1]);
                this.rawData[itrI].result[igvIndex].push({ page_index: imgIdx });
                this.rawData[itrI].result[igvIndex].push({ is_modified: this.data['igvChanged'][0] });
              }
            }

            if (this.cgvfound && this.data['cgvChanged'][0]) {
              this.rawData[itrI].result.push([]);
              const cgvIndex = this.rawData[itrI].result.length - 1;
              const imgIdx = this.data['cgvPage'];
              this.total_fields++;
              if (this.data['page_' + imgIdx]['coordinates']['cgvCo'][0]) {
                this.rawData[itrI].result[cgvIndex].push([this.data['page_' + imgIdx]['cgv'][0], 'CGV', 1]);
                this.rawData[itrI].result[cgvIndex].push(this.data['page_' + imgIdx]['coordinates']['cgvCo'][0]);
                this.rawData[itrI].result[cgvIndex].push({ page_index: imgIdx });
                this.rawData[itrI].result[cgvIndex].push({ is_modified: this.data['cgvChanged'][0] });
              } else {
                this.rawData[itrI].result[cgvIndex].push([this.data['page_' + imgIdx]['cgv'][0], 'CGV', 1]);
                this.rawData[itrI].result[cgvIndex].push({ page_index: imgIdx });
                this.rawData[itrI].result[cgvIndex].push({ is_modified: this.data['cgvChanged'][0] });
              }
            }

            if (this.ivvfound && this.data['ivvChanged'][0]) {
              this.rawData[itrI].result.push([]);
              const ivvIndex = this.rawData[itrI].result.length - 1;
              const imgIdx = this.data['ivvPage'];
              this.total_fields++;
              if (this.data['page_' + imgIdx]['coordinates']['ivvCo'][0]) {
                this.rawData[itrI].result[ivvIndex].push([this.data['page_' + imgIdx]['ivv'][0], 'IVV', 1]);
                this.rawData[itrI].result[ivvIndex].push(this.data['page_' + imgIdx]['coordinates']['ivvCo'][0]);
                this.rawData[itrI].result[ivvIndex].push({ page_index: imgIdx });
                this.rawData[itrI].result[ivvIndex].push({ is_modified: this.data['ivvChanged'][0] });
              } else {
                this.rawData[itrI].result[ivvIndex].push([this.data['page_' + imgIdx]['ivv'][0], 'IVV', 1]);
                this.rawData[itrI].result[ivvIndex].push({ page_index: imgIdx });
                this.rawData[itrI].result[ivvIndex].push({ is_modified: this.data['ivvChanged'][0] });
              }
            }

            if (this.cvvfound && this.data['cvvChanged'][0]) {
              this.rawData[itrI].result.push([]);
              const cvvIndex = this.rawData[itrI].result.length - 1;
              const imgIdx = this.data['cvvPage'];
              this.total_fields++;
              if (this.data['page_' + imgIdx]['coordinates']['cvvCo'][0]) {
                this.rawData[itrI].result[cvvIndex].push([this.data['page_' + imgIdx]['cvv'][0], 'CVV', 1]);
                this.rawData[itrI].result[cvvIndex].push(this.data['page_' + imgIdx]['coordinates']['cvvCo'][0]);
                this.rawData[itrI].result[cvvIndex].push({ page_index: imgIdx });
                this.rawData[itrI].result[cvvIndex].push({ is_modified: this.data['cvvChanged'][0] });
              } else {
                this.rawData[itrI].result[cvvIndex].push([this.data['page_' + imgIdx]['cvv'][0], 'CVV', 1]);
                this.rawData[itrI].result[cvvIndex].push({ page_index: imgIdx });
                this.rawData[itrI].result[cvvIndex].push({ is_modified: this.data['cvvChanged'][0] });
              }
            }

            if (this.data['inv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'INV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['inv_old'][0], 'INV_OLD', 1],
                { page_index: 0 },
                { is_modified: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]);
            }
            if (this.data['chnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CHNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['chnv_old'][0], 'CHNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]);
            }
            if (this.data['env_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ENV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['env_old'][0], 'ENV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['mnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'MNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['mnv_old'][0], 'MNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cmnl_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CMNL_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cmnl_old'][0], 'CMNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sesv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SESV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['sesv_old'][0], 'SESV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['emv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'EMV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['emv_old'][0], 'EMV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['isv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                try {
                  if (this.rawData[recordId].result[itr][0][1] == 'ISV_OLD') {
                    this.rawData[recordId].result.splice(itr--, 1);
                    break;
                  }
                } catch(e) {
                  console.log(this.rawData[recordId].result[itr])
                }
              }
              this.rawData[recordId].result.push([
                [this.data['isv_old'][0], 'ISV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cnv_old'][0], 'CNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cav_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CAV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cav_old'][0], 'CAV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sav_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SAV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['sav_old'][0], 'SAV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['idv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'IDV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['idv_old'][0], 'IDV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['igv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                try {
                  if (this.rawData[recordId].result[itr][0][1] == 'IGV_OLD') {
                    this.rawData[recordId].result.splice(itr--, 1);
                    break;
                  }
                }catch (e) {}
              }
              this.rawData[recordId].result.push([
                [this.data['igv_old'][0], 'IGV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cgv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                try {
                  if (this.rawData[recordId].result[itr][0][1] == 'CGV_OLD') {
                    this.rawData[recordId].result.splice(itr--, 1);
                    break;
                  }
                } catch (e) {
                  console.log(this.rawData[recordId].result, itr)
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cgv_old'][0], 'CGV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ivv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'IVV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ivv_old'][0], 'IVV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cvv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CVV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cvv_old'][0], 'CVV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cmnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CMNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cmnv_old'][0], 'CMNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ipv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'IPV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ipv_old'][0], 'IPV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['itv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'TAV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['itv_old'][0], 'TAV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['mdv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'MDV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['mdv_old'][0], 'MDV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['fcv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'FCV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['fcv_old'][0], 'FCV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['icv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ICV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['icv_old'][0], 'ICV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cgstp_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CGSTP_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cgstp_old'][0], 'CGSTP_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sgstp_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SGSTP_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['sgstp_old'][0], 'SGSTP_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['igstp_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'IGSTP_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['igstp_old'][0], 'IGSTP_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cgstv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CGSTV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cgstv_old'][0], 'CGSTV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sgstv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SGSTV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['sgstv_old'][0], 'SGSTV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['igstv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'IGSTV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['igstv_old'][0], 'IGSTV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['txav_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'TXAV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['txav_old'][0], 'TXAV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['gnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'GNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['gnv_old'][0], 'GNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ihv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'IHV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ihv_old'][0], 'IHV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['bdv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'BDV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['bdv_old'][0], 'BDV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['csnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CSNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['csnv_old'][0], 'CSNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ssnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SSNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ssnv_old'][0], 'SSNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['scnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SCNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['scnv_old'][0], 'SCNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ccnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CCNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ccnv_old'][0], 'CCNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ilv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ILV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ilv_old'][0], 'ILV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ptv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'PTV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ptv_old'][0], 'PTV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['iitv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'IITV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['iitv_old'][0], 'IITV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['spnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SPNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['spnv_old'][0], 'SPNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cpnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CPNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cpnv_old'][0], 'CPNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['icnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ICNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['icnv_old'][0], 'ICNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['dnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'DNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['dnv_old'][0], 'DNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['qiv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'QIV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['qiv_old'][0], 'QIV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['niq_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'NIQ_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['niq_old'][0], 'NIQ_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['nan_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'NAN_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['nan_old'][0], 'NAN_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ntype_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'DOCTYPE_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ntype_old'][0], 'DOCTYPE_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['nrn1_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'NRN1_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['nrn1_old'][0], 'NRN1_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['nsef_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'NSEF_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['nsef_old'][0], 'NSEF_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['nbnef_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'NBNEF_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['nbnef_old'][0], 'NBNEF_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['pnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'PNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['pnv_old'][0], 'PNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sidv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SIDV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['sidv_old'][0], 'SIDV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['eidv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'EIDV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['eidv_old'][0], 'EIDV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['pdv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'PDV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['pdv_old'][0], 'PDV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['pmv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'PMV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['pmv_old'][0], 'PMV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ctv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CTV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['ctv_old'][0], 'CTV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['vtv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'VTV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['vtv_old'][0], 'VTV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['mav_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'MAV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['mav_old'][0], 'MAV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['pfv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'PFV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['pfv_old'][0], 'PFV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['stav_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'STAV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['stav_old'][0], 'STAV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['crv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CRV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['crv_old'][0], 'CRV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['tecv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'TECV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['tecv_old'][0], 'TECV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['bmv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'BMV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['bmv_old'][0], 'BMV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['vatp_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'VATP_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['vatp_old'][0], 'VATP_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['islv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ISLV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['islv_old'][0], 'ISLV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['iclv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ICLV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['iclv_old'][0], 'ICLV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['scrn_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SCRN_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['scrn_old'][0], 'SCRN_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['lecrn_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'LECRN_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['lecrn_old'][0], 'LECRN_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['lrcrn_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'LRCRN_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['lrcrn_old'][0], 'LRCRN_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['islav_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ISLAV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['islav_old'][0], 'ISLAV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['iclav_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ICLAV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['iclav_old'][0], 'ICLAV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cessv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'CESSV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['cessv_old'][0], 'CESSV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['tcsv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'TCSV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['tcsv_old'][0], 'TCSV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['discv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'DISCV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['discv_old'][0], 'DISCV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['vrnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'VRNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['vrnv_old'][0], 'VRNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['hsnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'HSNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['hsnv_old'][0], 'HSNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['lrgv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'LRGV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['lrgv_old'][0], 'LRGV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['legv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'LEGV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['legv_old'][0], 'LEGV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['smnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'SMNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['smnv_old'][0], 'SMNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['iclmnv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ICLMNV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['iclmnv_old'][0], 'ICLMNV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['icleiv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'ICLEIV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['icleiv_old'][0], 'ICLEIV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['insv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'INSV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['insv_old'][0], 'INSV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['regv_old']) {
              for (let itr = 0; itr < this.rawData[recordId].result.length; itr++) {
                if (this.rawData[recordId].result[itr][0][1] == 'REGV_OLD') {
                  this.rawData[recordId].result.splice(itr--, 1);
                  break;
                }
              }
              this.rawData[recordId].result.push([
                [this.data['regv_old'][0], 'REGV_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }

            if (this.data['invl_old']) {
              this.rawData[recordId].result.push([
                [this.data['invl_old'][0], 'INL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]);
            }
            if (this.data['chnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['chnvl_old'][0], 'CHNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]);
            }
            if (this.data['envl_old']) {
              this.rawData[recordId].result.push([
                [this.data['envl_old'][0], 'ENL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['mnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['mnvl_old'][0], 'MNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cmnll_old']) {
              this.rawData[recordId].result.push([
                [this.data['cmnll_old'][0], 'CMNLL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sesvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['sesvl_old'][0], 'SESL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['emvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['emvl_old'][0], 'EML_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['isvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['isvl_old'][0], 'ISL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cnvl_old'][0], 'CNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cavl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cavl_old'][0], 'CAL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['savl_old']) {
              this.rawData[recordId].result.push([
                [this.data['savl_old'][0], 'SAL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['idvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['idvl_old'][0], 'IDL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['igvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['igvl_old'][0], 'IGL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cgvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cgvl_old'][0], 'CGL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ivvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['ivvl_old'][0], 'IVL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cvvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cvvl_old'][0], 'CVL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cmnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cmnvl_old'][0], 'CMNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ipvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['ipvl_old'][0], 'IPL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['itvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['itvl_old'][0], 'TAL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['mdvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['mdvl_old'][0], 'MDL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['fcvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['fcvl_old'][0], 'FCL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['icvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['icvl_old'][0], 'ICL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cgstpl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cgstpl_old'][0], 'CGSTPL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sgstpl_old']) {
              this.rawData[recordId].result.push([
                [this.data['sgstpl_old'][0], 'SGSTPL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['igstpl_old']) {
              this.rawData[recordId].result.push([
                [this.data['igstpl_old'][0], 'IGSTPL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cgstvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cgstvl_old'][0], 'CGSTVL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sgstvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['sgstvl_old'][0], 'SGSTVL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['igstvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['igstvl_old'][0], 'IGSTVL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['txavl_old']) {
              this.rawData[recordId].result.push([
                [this.data['txavl_old'][0], 'TXAL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['gnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['gnvl_old'][0], 'GNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ihvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['ihvl_old'][0], 'IHL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['bdvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['bdvl_old'][0], 'BDL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['csnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['csnvl_old'][0], 'CSNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ssnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['ssnvl_old'][0], 'SSNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['scnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['scnvl_old'][0], 'SCNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ccnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['ccnvl_old'][0], 'CCNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ilvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['ilvl_old'][0], 'ILL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ptvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['ptvl_old'][0], 'PTL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['iitvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['iitvl_old'][0], 'IITL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['spnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['spnvl_old'][0], 'SPNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cpnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cpnvl_old'][0], 'CPNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['icnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['icnvl_old'][0], 'ICNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['dnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['dnvl_old'][0], 'DNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['qivl_old']) {
              this.rawData[recordId].result.push([
                [this.data['qivl_old'][0], 'QIL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['pnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['pnvl_old'][0], 'PNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['sidvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['sidvl_old'][0], 'SIDL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['eidvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['eidvl_old'][0], 'EIDL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['pdvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['pdvl_old'][0], 'PDL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['pmvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['pmvl_old'][0], 'PML_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['ctvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['ctvl_old'][0], 'CTL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['vtvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['vtvl_old'][0], 'VTL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['mavl_old']) {
              this.rawData[recordId].result.push([
                [this.data['mavl_old'][0], 'MAL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['pfvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['pfvl_old'][0], 'PFL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['stavl_old']) {
              this.rawData[recordId].result.push([
                [this.data['stavl_old'][0], 'STAL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['crvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['crvl_old'][0], 'CRL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['tecvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['tecvl_old'][0], 'TECL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['bmvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['bmvl_old'][0], 'BML_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['vatpl_old']) {
              this.rawData[recordId].result.push([
                [this.data['vatpl_old'][0], 'VATPL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['islvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['islvl_old'][0], 'ISLL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['iclvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['iclvl_old'][0], 'ICLL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['scrnl_old']) {
              this.rawData[recordId].result.push([
                [this.data['scrnl_old'][0], 'SCRNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['lecrnl_old']) {
              this.rawData[recordId].result.push([
                [this.data['lecrnl_old'][0], 'LECRNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['lrcrnl_old']) {
              this.rawData[recordId].result.push([
                [this.data['lrcrnl_old'][0], 'LRCRNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['islavl_old']) {
              this.rawData[recordId].result.push([
                [this.data['islavl_old'][0], 'ISLAL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['iclavl_old']) {
              this.rawData[recordId].result.push([
                [this.data['iclavl_old'][0], 'ICLAL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['cessvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['cessvl_old'][0], 'CESSL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['tcsvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['tcsvl_old'][0], 'TCSL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['discvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['discvl_old'][0], 'DISCL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['vrnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['vrnvl_old'][0], 'VRNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['hsnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['hsnvl_old'][0], 'HSNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['lrgvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['lrgvl_old'][0], 'LRGL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['legvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['legvl_old'][0], 'LEGL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['smnvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['smnvl_old'][0], 'SMNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['iclmnl_old']) {
              this.rawData[recordId].result.push([
                [this.data['iclmnl_old'][0], 'ICLMNL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['icleil_old']) {
              this.rawData[recordId].result.push([
                [this.data['icleil_old'][0], 'ICLEIL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['insvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['insvl_old'][0], 'INSL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }
            if (this.data['regvl_old']) {
              this.rawData[recordId].result.push([
                [this.data['regvl_old'][0], 'REGL_OLD', 1],
                { is_modified: 0 },
                { page_index: 0 },
                { modified_time: new Date(Date.now()).toUTCString() },
                { modified_by: sessionStorage.getItem('username')}
              ]
              );
            }

            // if values are not predicted originally but inserted by user

            if (!this.invfound && this.data['inv']) {
              this.rawData[recordId].result.push([
                [this.data['inv'][0], 'INV', 1],
                this.data['page_' + this.data['invPage']]['coordinates']['invCo'][0],
                { page_index: this.data['invPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
            }
            if (!this.chnvfound && this.data['chnv']) {
              this.rawData[recordId].result.push([
                [this.data['chnv'][0], 'CHNV', 1],
                this.data['page_' + this.data['chnvPage']]['coordinates']['chnvCo'][0],
                { page_index: this.data['chnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
            }
            if (!this.envfound && this.data['env']) {
              this.rawData[recordId].result.push([
                [this.data['env'][0], 'ENV', 1],
                this.data['page_' + this.data['envPage']]['coordinates']['envCo'][0],
                { page_index: this.data['envPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
            }
            if (!this.mnvfound && this.data['mnv']) {
              this.rawData[recordId].result.push([
                [this.data['mnv'][0], 'MNV', 1],
                this.data['page_' + this.data['mnvPage']]['coordinates']['mnvCo'][0],
                { page_index: this.data['mnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
            }
            if (!this.cmnlfound && this.data['cmnl']) {
              this.rawData[recordId].result.push([
                [this.data['cmnl'][0], 'CMNL', 1],
                this.data['page_' + this.data['cmnlPage']]['coordinates']['cmnlCo'][0],
                { page_index: this.data['cmnlPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
            }
            if (!this.sesvfound && this.data['sesv']) {
              this.rawData[recordId].result.push([
                [this.data['sesv'], 'SESV', 1],
                { page_index: this.data['sesvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
            }
            if (!this.emvfound && this.data['emv']) {
              this.rawData[recordId].result.push([
                [this.data['emv'][0], 'EMV', 1],
                this.data['page_' + this.data['emvPage']]['coordinates']['emvCo'][0],
                { page_index: this.data['emvPage'] },
                { is_modified: 1 }
              ]);
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
            }
            if (!this.isvfound && this.data['isv']) {
              this.rawData[recordId].result.push([
                [this.data['isv'][0], 'ISV', 1],
                this.data['page_' + this.data['isvPage']]['coordinates']['isvCo'][0],
                { page_index: this.data['isvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cnvfound && this.data['cnv']) {
              this.rawData[recordId].result.push([
                [this.data['cnv'][0], 'CNV', 1],
                this.data['page_' + this.data['cnvPage']]['coordinates']['cnvCo'][0],
                { page_index: this.data['cnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cavfound && this.data['cav']) {
              this.rawData[recordId].result.push([
                [this.data['cav'][0], 'CAV', 1],
                this.data['page_' + this.data['cavPage']]['coordinates']['cavCo'][0],
                { page_index: this.data['cavPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.savfound && this.data['sav']) {
              this.rawData[recordId].result.push([
                [this.data['sav'][0], 'SAV', 1],
                this.data['page_' + this.data['savPage']]['coordinates']['savCo'][0],
                { page_index: this.data['savPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.idvfound && this.data['idv']) {
              this.rawData[recordId].result.push([
                [this.data['idv'][0], 'IDV', 1],
                this.data['page_' + this.data['idvPage']]['coordinates']['idvCo'][0],
                { page_index: this.data['idvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.igvfound && this.data['igv']) {
              this.rawData[recordId].result.push([
                [this.data['igv'][0], 'IGV', 1],
                this.data['page_' + this.data['igvPage']]['coordinates']['igvCo'][0],
                { page_index: this.data['igvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cgvfound && this.data['cgv']) {
              this.rawData[recordId].result.push([
                [this.data['cgv'][0], 'CGV', 1],
                this.data['page_' + this.data['cgvPage']]['coordinates']['cgvCo'][0],
                { page_index: this.data['cgvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ivvfound && this.data['ivv']) {
              this.rawData[recordId].result.push([
                [this.data['ivv'][0], 'IVV', 1],
                this.data['page_' + this.data['ivvPage']]['coordinates']['ivvCo'][0],
                { page_index: this.data['ivvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cvvfound && this.data['cvv']) {
              this.rawData[recordId].result.push([
                [this.data['cvv'][0], 'CVV', 1],
                this.data['page_' + this.data['cvvPage']]['coordinates']['cvvCo'][0],
                { page_index: this.data['cvvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cmnvfound && this.data['cmnv']) {
              this.rawData[recordId].result.push([
                [this.data['cmnv'][0], 'CMNV', 1],
                this.data['page_' + this.data['cmnvPage']]['coordinates']['cmnvCo'][0],
                { page_index: this.data['cmnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ipvfound && this.data['ipv']) {
              this.rawData[recordId].result.push([
                [this.data['ipv'][0], 'IPV', 1],
                this.data['page_' + this.data['ipvPage']]['coordinates']['ipvCo'][0],
                { page_index: this.data['ipvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.itvfound && this.data['itv']) {
              this.rawData[recordId].result.push([
                [this.data['itv'][0], 'TAV', 1],
                this.data['page_' + this.data['itvPage']]['coordinates']['itvCo'][0],
                { page_index: this.data['itvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.mdvfound && this.data['mdv']) {
              this.rawData[recordId].result.push([
                [this.data['mdv'][0], 'MDV', 1],
                this.data['page_' + this.data['mdvPage']]['coordinates']['mdvCo'][0],
                { page_index: this.data['mdvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.fcvfound && this.data['fcv']) {
              this.rawData[recordId].result.push([
                [this.data['fcv'][0], 'FCV', 1],
                this.data['page_' + this.data['fcvPage']]['coordinates']['fcvCo'][0],
                { page_index: this.data['fcvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.icvfound && this.data['icv']) {
              this.rawData[recordId].result.push([
                [this.data['icv'][0], 'ICV', 1],
                this.data['page_' + this.data['icvPage']]['coordinates']['icvCo'][0],
                { page_index: this.data['icvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cgstpfound && this.data['cgstp']) {
              this.rawData[recordId].result.push([
                [this.data['cgstp'][0], 'CGSTP', 1],
                this.data['page_' + this.data['cgstpPage']]['coordinates']['cgstpCo'][0],
                { page_index: this.data['cgstpPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.sgstpfound && this.data['sgstp']) {
              this.rawData[recordId].result.push([
                [this.data['sgstp'][0], 'SGSTP', 1],
                this.data['page_' + this.data['sgstpPage']]['coordinates']['sgstpCo'][0],
                { page_index: this.data['sgstpPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.igstpfound && this.data['igstp']) {
              this.rawData[recordId].result.push([
                [this.data['igstp'][0], 'IGSTP', 1],
                this.data['page_' + this.data['igstpPage']]['coordinates']['igstpCo'][0],
                { page_index: this.data['igstpPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cgstvfound && this.data['cgstv']) {
              this.rawData[recordId].result.push([
                [this.data['cgstv'][0], 'CGSTV', 1],
                this.data['page_' + this.data['cgstvPage']]['coordinates']['cgstvCo'][0],
                { page_index: this.data['cgstvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.sgstvfound && this.data['sgstv']) {
              this.rawData[recordId].result.push([
                [this.data['sgstv'][0], 'SGSTV', 1],
                this.data['page_' + this.data['sgstvPage']]['coordinates']['sgstvCo'][0],
                { page_index: this.data['sgstvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.igstvfound && this.data['igstv']) {
              this.rawData[recordId].result.push([
                [this.data['igstv'][0], 'IGSTV', 1],
                this.data['page_' + this.data['igstvPage']]['coordinates']['igstvCo'][0],
                { page_index: this.data['igstvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.txavfound && this.data['txav']) {
              this.rawData[recordId].result.push([
                [this.data['txav'][0], 'TXAV', 1],
                this.data['page_' + this.data['txavPage']]['coordinates']['txavCo'][0],
                { page_index: this.data['txavPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.gnvfound && this.data['gnv']) {
              this.rawData[recordId].result.push([
                [this.data['gnv'][0], 'GNV', 1],
                this.data['page_' + this.data['gnvPage']]['coordinates']['gnvCo'][0],
                { page_index: this.data['gnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ihvfound && this.data['ihv']) {
              this.rawData[recordId].result.push([
                [this.data['ihv'][0], 'IHV', 1],
                this.data['page_' + this.data['ihvPage']]['coordinates']['ihvCo'][0],
                { page_index: this.data['ihvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.bdvfound && this.data['bdv']) {
              this.rawData[recordId].result.push([
                [this.data['bdv'][0], 'BDV', 1],
                this.data['page_' + this.data['bdvPage']]['coordinates']['bdvCo'][0],
                { page_index: this.data['bdvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.csnvfound && this.data['csnv']) {
              this.rawData[recordId].result.push([
                [this.data['csnv'][0], 'CSNV', 1],
                this.data['page_' + this.data['csnvPage']]['coordinates']['csnvCo'][0],
                { page_index: this.data['csnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ssnvfound && this.data['ssnv']) {
              this.rawData[recordId].result.push([
                [this.data['ssnv'][0], 'SSNV', 1],
                this.data['page_' + this.data['ssnvPage']]['coordinates']['ssnvCo'][0],
                { page_index: this.data['ssnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.scnvfound && this.data['scnv']) {
              this.rawData[recordId].result.push([
                [this.data['scnv'][0], 'SCNV', 1],
                this.data['page_' + this.data['scnvPage']]['coordinates']['scnvCo'][0],
                { page_index: this.data['scnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ccnvfound && this.data['ccnv']) {
              this.rawData[recordId].result.push([
                [this.data['ccnv'][0], 'CCNV', 1],
                this.data['page_' + this.data['ccnvPage']]['coordinates']['ccnvCo'][0],
                { page_index: this.data['ccnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ilvfound && this.data['ilv']) {
              this.rawData[recordId].result.push([
                [this.data['ilv'][0], 'ILV', 1],
                this.data['page_' + this.data['ilvPage']]['coordinates']['ilvCo'][0],
                { page_index: this.data['ilvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ptvfound && this.data['ptv']) {
              this.rawData[recordId].result.push([
                [this.data['ptv'][0], 'PTV', 1],
                this.data['page_' + this.data['ptvPage']]['coordinates']['ptvCo'][0],
                { page_index: this.data['ptvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.iitvfound && this.data['iitv']) {
              console.log("iitv")
              this.rawData[recordId].result.push([
                [this.data['iitv'][0], 'IITV', 1],
                this.data['page_' + this.data['iitvPage']]['coordinates']['iitvCo'][0],
                { page_index: this.data['iitvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.spnvfound && this.data['spnv']) {
              this.rawData[recordId].result.push([
                [this.data['spnv'][0], 'SPNV', 1],
                this.data['page_' + this.data['spnvPage']]['coordinates']['spnvCo'][0],
                { page_index: this.data['spnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cpnvfound && this.data['cpnv']) {
              this.rawData[recordId].result.push([
                [this.data['cpnv'][0], 'CPNV', 1],
                this.data['page_' + this.data['cpnvPage']]['coordinates']['cpnvCo'][0],
                { page_index: this.data['cpnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.icnvfound && this.data['icnv']) {
              this.rawData[recordId].result.push([
                [this.data['icnv'][0], 'ICNV', 1],
                this.data['page_' + this.data['icnvPage']]['coordinates']['icnvCo'][0],
                { page_index: this.data['icnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.dnvfound && this.data['dnv']) {
              this.rawData[recordId].result.push([
                [this.data['dnv'][0], 'DNV', 1],
                this.data['page_' + this.data['dnvPage']]['coordinates']['dnvCo'][0],
                { page_index: this.data['dnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.qivfound && this.data['qiv']) {
              this.rawData[recordId].result.push([
                [this.data['qiv'][0], 'QIV', 1],
                this.data['page_' + this.data['qivPage']]['coordinates']['qivCo'][0],
                { page_index: this.data['qivPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.niqfound && this.data['niq']) {
              this.rawData[recordId].result.push([
                [this.data['niq'][0], 'NIQ', 1],
                this.data['page_' + this.data['niqPage']]['coordinates']['niqCo'][0],
                { page_index: this.data['niqPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.nanfound && this.data['nan']) {
              this.rawData[recordId].result.push([
                [this.data['nan'][0], 'NAN', 1],
                this.data['page_' + this.data['nanPage']]['coordinates']['nanCo'][0],
                { page_index: this.data['nanPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ntypefound && this.data['ntype']) {
              this.rawData[recordId].result.push([
                [this.data['ntype'][0], 'DOCTYPE', 1],
                this.data['page_' + this.data['ntypePage']]['coordinates']['ntypeCo'][0],
                { page_index: this.data['ntypePage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.nrn1found && this.data['nrn1']) {
              this.rawData[recordId].result.push([
                [this.data['nrn1'][0], 'NRN1', 1],
                this.data['page_' + this.data['nrn1Page']]['coordinates']['nrn1Co'][0],
                { page_index: this.data['nrn1Page'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.nseffound && this.data['nsef']) {
              this.rawData[recordId].result.push([
                [this.data['nsef'][0], 'NSEF', 1],
                this.data['page_' + this.data['nsefPage']]['coordinates']['nsefCo'][0],
                { page_index: this.data['nsefPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.nbneffound && this.data['nbnef']) {
              this.rawData[recordId].result.push([
                [this.data['nbnef'][0], 'NBNEF', 1],
                this.data['page_' + this.data['nbnefPage']]['coordinates']['nbnefCo'][0],
                { page_index: this.data['nbnefPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.pnvfound && this.data['pnv']) {
              this.rawData[recordId].result.push([
                [this.data['pnv'][0], 'PNV', 1],
                this.data['page_' + this.data['pnvPage']]['coordinates']['pnvCo'][0],
                { page_index: this.data['pnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.sidvfound && this.data['sidv']) {
              this.rawData[recordId].result.push([
                [this.data['sidv'][0], 'SIDV', 1],
                this.data['page_' + this.data['sidvPage']]['coordinates']['sidvCo'][0],
                { page_index: this.data['sidvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.eidvfound && this.data['eidv']) {
              this.rawData[recordId].result.push([
                [this.data['eidv'][0], 'EIDV', 1],
                this.data['page_' + this.data['eidvPage']]['coordinates']['eidvCo'][0],
                { page_index: this.data['eidvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.pdvfound && this.data['pdv']) {
              this.rawData[recordId].result.push([
                [this.data['pdv'][0], 'PDV', 1],
                this.data['page_' + this.data['pdvPage']]['coordinates']['pdvCo'][0],
                { page_index: this.data['pdvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.pmvfound && this.data['pmv']) {
              this.rawData[recordId].result.push([
                [this.data['pmv'][0], 'PMV', 1],
                this.data['page_' + this.data['pmvPage']]['coordinates']['pmvCo'][0],
                { page_index: this.data['pmvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.ctvfound && this.data['ctv']) {
              this.rawData[recordId].result.push([
                [this.data['ctv'][0], 'CTV', 1],
                this.data['page_' + this.data['ctvPage']]['coordinates']['ctvCo'][0],
                { page_index: this.data['ctvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.vtvfound && this.data['vtv']) {
              this.rawData[recordId].result.push([
                [this.data['vtv'][0], 'VTV', 1],
                this.data['page_' + this.data['vtvPage']]['coordinates']['vtvCo'][0],
                { page_index: this.data['vtvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.mavfound && this.data['mav']) {
              this.rawData[recordId].result.push([
                [this.data['mav'][0], 'MAV', 1],
                this.data['page_' + this.data['mavPage']]['coordinates']['mavCo'][0],
                { page_index: this.data['mavPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.pfvfound && this.data['pfv']) {
              this.rawData[recordId].result.push([
                [this.data['pfv'][0], 'PFV', 1],
                this.data['page_' + this.data['pfvPage']]['coordinates']['pfvCo'][0],
                { page_index: this.data['pfvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.stavfound && this.data['stav']) {
              this.rawData[recordId].result.push([
                [this.data['stav'][0], 'STAV', 1],
                this.data['page_' + this.data['stavPage']]['coordinates']['stavCo'][0],
                { page_index: this.data['stavPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.crvfound && this.data['crv']) {
              this.rawData[recordId].result.push([
                [this.data['crv'][0], 'CRV', 1],
                this.data['page_' + this.data['crvPage']]['coordinates']['crvCo'][0],
                { page_index: this.data['crvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.tecvfound && this.data['tecv']) {
              this.rawData[recordId].result.push([
                [this.data['tecv'][0], 'TECV', 1],
                this.data['page_' + this.data['tecvPage']]['coordinates']['tecvCo'][0],
                { page_index: this.data['tecvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.bmvfound && this.data['bmv']) {
              this.rawData[recordId].result.push([
                [this.data['bmv'][0], 'BMV', 1],
                this.data['page_' + this.data['bmvPage']]['coordinates']['bmvCo'][0],
                { page_index: this.data['bmvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.vatpfound && this.data['vatp']) {
              this.rawData[recordId].result.push([
                [this.data['vatp'][0], 'VATP', 1],
                this.data['page_' + this.data['vatpPage']]['coordinates']['vatpCo'][0],
                { page_index: this.data['vatpPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.islvfound && this.data['islv']) {
              this.rawData[recordId].result.push([
                [this.data['islv'][0], 'ISLV', 1],
                this.data['page_' + this.data['islvPage']]['coordinates']['islvCo'][0],
                { page_index: this.data['islvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.iclvfound && this.data['iclv']) {
              this.rawData[recordId].result.push([
                [this.data['iclv'][0], 'ICLV', 1],
                this.data['page_' + this.data['iclvPage']]['coordinates']['iclvCo'][0],
                { page_index: this.data['iclvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.scrnfound && this.data['scrn']) {
              this.rawData[recordId].result.push([
                [this.data['scrn'][0], 'SCRN', 1],
                this.data['page_' + this.data['scrnPage']]['coordinates']['scrnCo'][0],
                { page_index: this.data['scrnPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.lecrnfound && this.data['lecrn']) {
              this.rawData[recordId].result.push([
                [this.data['lecrn'][0], 'LECRN', 1],
                this.data['page_' + this.data['lecrnPage']]['coordinates']['lecrnCo'][0],
                { page_index: this.data['lecrnPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.lrcrnfound && this.data['lrcrn']) {
              this.rawData[recordId].result.push([
                [this.data['lrcrn'][0], 'LRCRN', 1],
                this.data['page_' + this.data['lrcrnPage']]['coordinates']['lrcrnCo'][0],
                { page_index: this.data['lrcrnPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.islavfound && this.data['islav']) {
              this.rawData[recordId].result.push([
                [this.data['islav'][0], 'ISLAV', 1],
                this.data['page_' + this.data['islavPage']]['coordinates']['islavCo'][0],
                { page_index: this.data['islavPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.iclavfound && this.data['iclav']) {
              this.rawData[recordId].result.push([
                [this.data['iclav'][0], 'ICLAV', 1],
                this.data['page_' + this.data['iclavPage']]['coordinates']['iclavCo'][0],
                { page_index: this.data['iclavPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.cessvfound && this.data['cessv']) {
              this.rawData[recordId].result.push([
                [this.data['cessv'][0], 'CESSV', 1],
                this.data['page_' + this.data['cessvPage']]['coordinates']['cessvCo'][0],
                { page_index: this.data['cessvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.tcsvfound && this.data['tcsv']) {
              this.rawData[recordId].result.push([
                [this.data['tcsv'][0], 'TCSV', 1],
                this.data['page_' + this.data['tcsvPage']]['coordinates']['tcsvCo'][0],
                { page_index: this.data['tcsvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.discvfound && this.data['discv']) {
              this.rawData[recordId].result.push([
                [this.data['discv'][0], 'DISCV', 1],
                this.data['page_' + this.data['discvPage']]['coordinates']['discvCo'][0],
                { page_index: this.data['discvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.vrnvfound && this.data['vrnv']) {
              this.rawData[recordId].result.push([
                [this.data['vrnv'][0], 'VRNV', 1],
                this.data['page_' + this.data['vrnvPage']]['coordinates']['vrnvCo'][0],
                { page_index: this.data['vrnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.hsnvfound && this.data['hsnv']) {
              this.rawData[recordId].result.push([
                [this.data['hsnv'][0], 'HSNV', 1],
                this.data['page_' + this.data['hsnvPage']]['coordinates']['hsnvCo'][0],
                { page_index: this.data['hsnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.lrgvfound && this.data['lrgv']) {
              this.rawData[recordId].result.push([
                [this.data['lrgv'][0], 'LRGV', 1],
                this.data['page_' + this.data['lrgvPage']]['coordinates']['lrgvCo'][0],
                { page_index: this.data['lrgvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.legvfound && this.data['legv']) {
              this.rawData[recordId].result.push([
                [this.data['legv'][0], 'LEGV', 1],
                this.data['page_' + this.data['legvPage']]['coordinates']['legvCo'][0],
                { page_index: this.data['legvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.smnvfound && this.data['smnv']) {
              this.rawData[recordId].result.push([
                [this.data['smnv'][0], 'SMNV', 1],
                this.data['page_' + this.data['smnvPage']]['coordinates']['smnvCo'][0],
                { page_index: this.data['smnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.iclmnvfound && this.data['iclmnv']) {
              this.rawData[recordId].result.push([
                [this.data['iclmnv'][0], 'ICLMNV', 1],
                this.data['page_' + this.data['iclmnvPage']]['coordinates']['iclmnvCo'][0],
                { page_index: this.data['iclmnvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.icleivfound && this.data['icleiv']) {
              this.rawData[recordId].result.push([
                [this.data['icleiv'][0], 'ICLEIV', 1],
                this.data['page_' + this.data['icleivPage']]['coordinates']['icleivCo'][0],
                { page_index: this.data['icleivPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.insvfound && this.data['insv']) {
              this.rawData[recordId].result.push([
                [this.data['insv'][0], 'INSV', 1],
                this.data['page_' + this.data['insvPage']]['coordinates']['insvCo'][0],
                { page_index: this.data['insvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }
            if (!this.regvfound && this.data['regv']) {
              this.rawData[recordId].result.push([
                [this.data['regv'][0], 'REGV', 1],
                this.data['page_' + this.data['regvPage']]['coordinates']['regvCo'][0],
                { page_index: this.data['regvPage'] },
                { is_modified: 1 }
              ]
              );
              this.rawData[recordId].total_fields =
                this.rawData[recordId].total_fields + 1;
              this.fieldsCount++;
            }

            if (!this.invlfound && this.data['invl']) {
              this.rawData[recordId].result.push([
                [this.data['invl'][0], 'INL', 1],
                this.data['page_' + this.data['invlPage']]['coordinates']['invlCo'][0],
                { page_index: this.data['invlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.chnvlfound && this.data['chnvl']) {
              this.rawData[recordId].result.push([
                [this.data['chnvl'][0], 'CHNL', 1],
                this.data['page_' + this.data['chnvlPage']]['coordinates']['chnvlCo'][0],
                { page_index: this.data['chnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.envlfound && this.data['envl']) {
              this.rawData[recordId].result.push([
                [this.data['envl'][0], 'ENL', 1],
                this.data['page_' + this.data['envlPage']]['coordinates']['envlCo'][0],
                { page_index: this.data['envlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.mnvlfound && this.data['mnvl']) {
              this.rawData[recordId].result.push([
                [this.data['mnvl'][0], 'MNL', 1],
                this.data['page_' + this.data['mnvlPage']]['coordinates']['mnvlCo'][0],
                { page_index: this.data['mnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cmnllfound && this.data['cmnll']) {
              this.rawData[recordId].result.push([
                [this.data['cmnll'][0], 'CMNLL', 1],
                this.data['page_' + this.data['cmnllPage']]['coordinates']['cmnllCo'][0],
                { page_index: this.data['cmnllPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.sesvlfound && this.data['sesvl']) {
              this.rawData[recordId].result.push([
                [this.data['sesvl'][0], 'SESVL', 1],
                this.data['page_' + this.data['sesvlPage']]['coordinates']['sesvlCo'][0],
                { page_index: this.data['sesvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.emvlfound && this.data['emvl']) {
              this.rawData[recordId].result.push([
                [this.data['emvl'][0], 'EML', 1],
                this.data['page_' + this.data['emvlPage']]['coordinates']['emvlCo'][0],
                { page_index: this.data['emvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.isvlfound && this.data['isvl']) {
              this.rawData[recordId].result.push([
                [this.data['isvl'][0], 'ISL', 1],
                this.data['page_' + this.data['isvlPage']]['coordinates']['isvlCo'][0],
                { page_index: this.data['isvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cnvlfound && this.data['cnvl']) {
              this.rawData[recordId].result.push([
                [this.data['cnvl'][0], 'CNL', 1],
                this.data['page_' + this.data['cnvlPage']]['coordinates']['cnvlCo'][0],
                { page_index: this.data['cnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cavlfound && this.data['cavl']) {
              this.rawData[recordId].result.push([
                [this.data['cavl'][0], 'CAL', 1],
                this.data['page_' + this.data['cavlPage']]['coordinates']['cavlCo'][0],
                { page_index: this.data['cavlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.savlfound && this.data['savl']) {
              this.rawData[recordId].result.push([
                [this.data['savl'][0], 'SAL', 1],
                this.data['page_' + this.data['savlPage']]['coordinates']['savlCo'][0],
                { page_index: this.data['savlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.idvlfound && this.data['idvl']) {
              this.rawData[recordId].result.push([
                [this.data['idvl'][0], 'IDL', 1],
                this.data['page_' + this.data['idvlPage']]['coordinates']['idvlCo'][0],
                { page_index: this.data['idvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.igvlfound && this.data['igvl']) {
              this.rawData[recordId].result.push([
                [this.data['igvl'][0], 'IGL', 1],
                this.data['page_' + this.data['igvlPage']]['coordinates']['igvlCo'][0],
                { page_index: this.data['igvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cgvlfound && this.data['cgvl']) {
              this.rawData[recordId].result.push([
                [this.data['cgvl'][0], 'CGL', 1],
                this.data['page_' + this.data['cgvlPage']]['coordinates']['cgvlCo'][0],
                { page_index: this.data['cgvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.ivvlfound && this.data['ivvl']) {
              this.rawData[recordId].result.push([
                [this.data['ivvl'][0], 'IVL', 1],
                this.data['page_' + this.data['ivvlPage']]['coordinates']['ivvlCo'][0],
                { page_index: this.data['ivvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cvvlfound && this.data['cvvl']) {
              this.rawData[recordId].result.push([
                [this.data['cvvl'][0], 'CVL', 1],
                this.data['page_' + this.data['cvvlPage']]['coordinates']['cvvlCo'][0],
                { page_index: this.data['cvvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cmnvlfound && this.data['cmnvl']) {
              this.rawData[recordId].result.push([
                [this.data['cmnvl'][0], 'CMNVL', 1],
                this.data['page_' + this.data['cmnvlPage']]['coordinates']['cmnvlCo'][0],
                { page_index: this.data['cmnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.ipvlfound && this.data['ipvl']) {
              this.rawData[recordId].result.push([
                [this.data['ipvl'][0], 'IPL', 1],
                this.data['page_' + this.data['ipvlPage']]['coordinates']['ipvlCo'][0],
                { page_index: this.data['ipvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.itvlfound && this.data['itvl']) {
              this.rawData[recordId].result.push([
                [this.data['itvl'][0], 'TAL', 1],
                this.data['page_' + this.data['itvlPage']]['coordinates']['itvlCo'][0],
                { page_index: this.data['itvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.mdvlfound && this.data['mdvl']) {
              this.rawData[recordId].result.push([
                [this.data['mdvl'][0], 'MDL', 1],
                this.data['page_' + this.data['mdvlPage']]['coordinates']['mdvlCo'][0],
                { page_index: this.data['mdvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.fcvlfound && this.data['fcvl']) {
              this.rawData[recordId].result.push([
                [this.data['fcvl'][0], 'FCL', 1],
                this.data['page_' + this.data['fcvlPage']]['coordinates']['fcvlCo'][0],
                { page_index: this.data['fcvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.icvlfound && this.data['icvl']) {
              this.rawData[recordId].result.push([
                [this.data['icvl'][0], 'ICL', 1],
                this.data['page_' + this.data['icvlPage']]['coordinates']['icvlCo'][0],
                { page_index: this.data['icvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cgstplfound && this.data['cgstpl']) {
              this.rawData[recordId].result.push([
                [this.data['cgstpl'][0], 'CGSTPL', 1],
                this.data['page_' + this.data['cgstplPage']]['coordinates']['cgstplCo'][0],
                { page_index: this.data['cgstplPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.sgstplfound && this.data['sgstpl']) {
              this.rawData[recordId].result.push([
                [this.data['sgstpl'][0], 'SGSTPL', 1],
                this.data['page_' + this.data['sgstplPage']]['coordinates']['sgstplCo'][0],
                { page_index: this.data['sgstplPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.igstplfound && this.data['igstpl']) {
              this.rawData[recordId].result.push([
                [this.data['igstpl'][0], 'IGSTPL', 1],
                this.data['page_' + this.data['igstplPage']]['coordinates']['igstplCo'][0],
                { page_index: this.data['igstplPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cgstvlfound && this.data['cgstvl']) {
              this.rawData[recordId].result.push([
                [this.data['cgstvl'][0], 'CGSTVL', 1],
                this.data['page_' + this.data['cgstvlPage']]['coordinates']['cgstvlCo'][0],
                { page_index: this.data['cgstvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.sgstvlfound && this.data['sgstvl']) {
              this.rawData[recordId].result.push([
                [this.data['sgstvl'][0], 'SGSTVL', 1],
                this.data['page_' + this.data['sgstvlPage']]['coordinates']['sgstvlCo'][0],
                { page_index: this.data['sgstvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.igstvlfound && this.data['igstvl']) {
              this.rawData[recordId].result.push([
                [this.data['igstvl'][0], 'IGSTVL', 1],
                this.data['page_' + this.data['igstvlPage']]['coordinates']['igstvlCo'][0],
                { page_index: this.data['igstvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.txavlfound && this.data['txavl']) {
              this.rawData[recordId].result.push([
                [this.data['txavl'][0], 'TXAL', 1],
                this.data['page_' + this.data['txavlPage']]['coordinates']['txavlCo'][0],
                { page_index: this.data['txavlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.gnvlfound && this.data['gnvl']) {
              this.rawData[recordId].result.push([
                [this.data['gnvl'][0], 'GNL', 1],
                this.data['page_' + this.data['gnvlPage']]['coordinates']['gnvlCo'][0],
                { page_index: this.data['gnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.ihvlfound && this.data['ihvl']) {
              this.rawData[recordId].result.push([
                [this.data['ihvl'][0], 'IHL', 1],
                this.data['page_' + this.data['ihvlPage']]['coordinates']['ihvlCo'][0],
                { page_index: this.data['ihvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.bdvlfound && this.data['bdvl']) {
              this.rawData[recordId].result.push([
                [this.data['bdvl'][0], 'BDL', 1],
                this.data['page_' + this.data['bdvlPage']]['coordinates']['bdvlCo'][0],
                { page_index: this.data['bdvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.csnvlfound && this.data['csnvl']) {
              this.rawData[recordId].result.push([
                [this.data['csnvl'][0], 'CSNL', 1],
                this.data['page_' + this.data['csnvlPage']]['coordinates']['csnvlCo'][0],
                { page_index: this.data['csnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.ssnvlfound && this.data['ssnvl']) {
              this.rawData[recordId].result.push([
                [this.data['ssnvl'][0], 'SSNL', 1],
                this.data['page_' + this.data['ssnvlPage']]['coordinates']['ssnvlCo'][0],
                { page_index: this.data['ssnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.scnvlfound && this.data['scnvl']) {
              this.rawData[recordId].result.push([
                [this.data['scnvl'][0], 'SCNL', 1],
                this.data['page_' + this.data['scnvlPage']]['coordinates']['scnvlCo'][0],
                { page_index: this.data['scnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.ccnvlfound && this.data['ccnvl']) {
              this.rawData[recordId].result.push([
                [this.data['ccnvl'][0], 'CCNL', 1],
                this.data['page_' + this.data['ccnvlPage']]['coordinates']['ccnvlCo'][0],
                { page_index: this.data['ccnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.ilvlfound && this.data['ilvl']) {
              this.rawData[recordId].result.push([
                [this.data['ilvl'][0], 'ILL', 1],
                this.data['page_' + this.data['ilvlPage']]['coordinates']['ilvlCo'][0],
                { page_index: this.data['ilvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.ptvlfound && this.data['ptvl']) {
              this.rawData[recordId].result.push([
                [this.data['ptvl'][0], 'PTL', 1],
                this.data['page_' + this.data['ptvlPage']]['coordinates']['ptvlCo'][0],
                { page_index: this.data['ptvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.iitvlfound && this.data['iitvl']) {
              this.rawData[recordId].result.push([
                [this.data['iitvl'][0], 'IITL', 1],
                this.data['page_' + this.data['iitvlPage']]['coordinates']['iitvlCo'][0],
                { page_index: this.data['iitvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.spnvlfound && this.data['spnvl']) {
              this.rawData[recordId].result.push([
                [this.data['spnvl'][0], 'SPNL', 1],
                this.data['page_' + this.data['spnvlPage']]['coordinates']['spnvlCo'][0],
                { page_index: this.data['spnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cpnvlfound && this.data['cpnvl']) {
              this.rawData[recordId].result.push([
                [this.data['cpnvl'][0], 'CPNL', 1],
                this.data['page_' + this.data['cpnvlPage']]['coordinates']['cpnvlCo'][0],
                { page_index: this.data['cpnlvPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.icnvlfound && this.data['icnvl']) {
              this.rawData[recordId].result.push([
                [this.data['icnvl'][0], 'ICNL', 1],
                this.data['page_' + this.data['icnvlPage']]['coordinates']['icnvlCo'][0],
                { page_index: this.data['icnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.dnvlfound && this.data['dnvl']) {
              this.rawData[recordId].result.push([
                [this.data['dnvl'][0], 'DNL', 1],
                this.data['page_' + this.data['dnvlPage']]['coordinates']['dnvlCo'][0],
                { page_index: this.data['dnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.qivlfound && this.data['qivl']) {
              this.rawData[recordId].result.push([
                [this.data['qivl'][0], 'QIL', 1],
                this.data['page_' + this.data['qivlPage']]['coordinates']['qivlCo'][0],
                { page_index: this.data['qivlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.pnvlfound && this.data['pnvl']) {
              this.rawData[recordId].result.push([
                [this.data['pnvl'][0], 'PNL', 1],
                this.data['page_' + this.data['pnvlPage']]['coordinates']['pnvlCo'][0],
                { page_index: this.data['pnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.sidvlfound && this.data['sidvl']) {
              this.rawData[recordId].result.push([
                [this.data['sidvl'][0], 'SIDL', 1],
                this.data['page_' + this.data['sidvlPage']]['coordinates']['sidvlCo'][0],
                { page_index: this.data['sidvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.eidvlfound && this.data['eidvl']) {
              this.rawData[recordId].result.push([
                [this.data['eidvl'][0], 'EIDL', 1],
                this.data['page_' + this.data['eidvlPage']]['coordinates']['eidvlCo'][0],
                { page_index: this.data['eidvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.pdvlfound && this.data['pdvl']) {
              this.rawData[recordId].result.push([
                [this.data['pdvl'][0], 'PDL', 1],
                this.data['page_' + this.data['pdvlPage']]['coordinates']['pdvlCo'][0],
                { page_index: this.data['pdvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.pmvlfound && this.data['pmvl']) {
              this.rawData[recordId].result.push([
                [this.data['pmvl'][0], 'PML', 1],
                this.data['page_' + this.data['pmvlPage']]['coordinates']['pmvlCo'][0],
                { page_index: this.data['pmvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.ctvlfound && this.data['ctvl']) {
              this.rawData[recordId].result.push([
                [this.data['ctvl'][0], 'CTL', 1],
                this.data['page_' + this.data['ctvlPage']]['coordinates']['ctvlCo'][0],
                { page_index: this.data['ctvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.vtvlfound && this.data['vtvl']) {
              this.rawData[recordId].result.push([
                [this.data['vtvl'][0], 'VTL', 1],
                this.data['page_' + this.data['vtvlPage']]['coordinates']['vtvlCo'][0],
                { page_index: this.data['vtvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.crvlfound && this.data['crvl']) {
              this.rawData[recordId].result.push([
                [this.data['crvl'][0], 'CRL', 1],
                this.data['page_' + this.data['crvlPage']]['coordinates']['crvlCo'][0],
                { page_index: this.data['crvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.tecvlfound && this.data['tecvl']) {
              this.rawData[recordId].result.push([
                [this.data['tecvl'][0], 'TECL', 1],
                this.data['page_' + this.data['tecvlPage']]['coordinates']['tecvlCo'][0],
                { page_index: this.data['tecvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.bmvlfound && this.data['bmvl']) {
              this.rawData[recordId].result.push([
                [this.data['bmvl'][0], 'BML', 1],
                this.data['page_' + this.data['bmvlPage']]['coordinates']['bmvlCo'][0],
                { page_index: this.data['bmvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.mavlfound && this.data['mavl']) {
              this.rawData[recordId].result.push([
                [this.data['mavl'][0], 'MAL', 1],
                this.data['page_' + this.data['mavlPage']]['coordinates']['mavlCo'][0],
                { page_index: this.data['mavlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.pfvlfound && this.data['pfvl']) {
              this.rawData[recordId].result.push([
                [this.data['pfvl'][0], 'PFL', 1],
                this.data['page_' + this.data['pfvlPage']]['coordinates']['pfvlCo'][0],
                { page_index: this.data['pfvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.stavlfound && this.data['stavl']) {
              this.rawData[recordId].result.push([
                [this.data['stavl'][0], 'STAL', 1],
                this.data['page_' + this.data['stavlPage']]['coordinates']['stavlCo'][0],
                { page_index: this.data['stavlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.vatplfound && this.data['vatpl']) {
              this.rawData[recordId].result.push([
                [this.data['vatpl'][0], 'VATPL', 1],
                this.data['page_' + this.data['vatplPage']]['coordinates']['vatplCo'][0],
                { page_index: this.data['vatplPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.islvlfound && this.data['islvl']) {
              this.rawData[recordId].result.push([
                [this.data['islvl'][0], 'ISLL', 1],
                this.data['page_' + this.data['islvlPage']]['coordinates']['islvlCo'][0],
                { page_index: this.data['islvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.iclvlfound && this.data['iclvl']) {
              this.rawData[recordId].result.push([
                [this.data['iclvl'][0], 'ICLL', 1],
                this.data['page_' + this.data['iclvlPage']]['coordinates']['iclvlCo'][0],
                { page_index: this.data['iclvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.scrnlfound && this.data['scrnl']) {
              this.rawData[recordId].result.push([
                [this.data['scrnl'][0], 'SCRNL', 1],
                this.data['page_' + this.data['scrnlPage']]['coordinates']['scrnlCo'][0],
                { page_index: this.data['scrnlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.lecrnlfound && this.data['lecrnl']) {
              this.rawData[recordId].result.push([
                [this.data['lecrnl'][0], 'LECRNL', 1],
                this.data['page_' + this.data['lecrnlPage']]['coordinates']['lecrnlCo'][0],
                { page_index: this.data['lecrnlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.lrcrnlfound && this.data['lrcrnl']) {
              this.rawData[recordId].result.push([
                [this.data['lrcrnl'][0], 'LRCRNL', 1],
                this.data['page_' + this.data['lrcrnlPage']]['coordinates']['lrcrnlCo'][0],
                { page_index: this.data['lrcrnlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.islavlfound && this.data['islavl']) {
              this.rawData[recordId].result.push([
                [this.data['islavl'][0], 'ISLAL', 1],
                this.data['page_' + this.data['islavlPage']]['coordinates']['islavlCo'][0],
                { page_index: this.data['islavlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.iclavlfound && this.data['iclavl']) {
              this.rawData[recordId].result.push([
                [this.data['iclavl'][0], 'ICLAL', 1],
                this.data['page_' + this.data['iclavlPage']]['coordinates']['iclavlCo'][0],
                { page_index: this.data['iclavlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.cessvlfound && this.data['cessvl']) {
              this.rawData[recordId].result.push([
                [this.data['cessvl'][0], 'CESSL', 1],
                this.data['page_' + this.data['cessvlPage']]['coordinates']['cessvlCo'][0],
                { page_index: this.data['cessvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.tcsvlfound && this.data['tcsvl']) {
              this.rawData[recordId].result.push([
                [this.data['tcsvl'][0], 'TCSL', 1],
                this.data['page_' + this.data['tcsvlPage']]['coordinates']['tcsvlCo'][0],
                { page_index: this.data['tcsvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.discvlfound && this.data['discvl']) {
              this.rawData[recordId].result.push([
                [this.data['discvl'][0], 'DISCL', 1],
                this.data['page_' + this.data['discvlPage']]['coordinates']['discvlCo'][0],
                { page_index: this.data['discvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.vrnvlfound && this.data['vrnvl']) {
              this.rawData[recordId].result.push([
                [this.data['vrnvl'][0], 'VRNL', 1],
                this.data['page_' + this.data['vrnvlPage']]['coordinates']['vrnvlCo'][0],
                { page_index: this.data['vrnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.hsnvlfound && this.data['hsnvl']) {
              this.rawData[recordId].result.push([
                [this.data['hsnvl'][0], 'HSNL', 1],
                this.data['page_' + this.data['hsnvlPage']]['coordinates']['hsnvlCo'][0],
                { page_index: this.data['hsnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.lrgvlfound && this.data['lrgvl']) {
              this.rawData[recordId].result.push([
                [this.data['lrgvl'][0], 'LRGL', 1],
                this.data['page_' + this.data['lrgvlPage']]['coordinates']['lrgvlCo'][0],
                { page_index: this.data['lrgvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.legvlfound && this.data['legvl']) {
              this.rawData[recordId].result.push([
                [this.data['legvl'][0], 'LEGL', 1],
                this.data['page_' + this.data['legvlPage']]['coordinates']['legvlCo'][0],
                { page_index: this.data['legvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.smnvlfound && this.data['smnvl']) {
              this.rawData[recordId].result.push([
                [this.data['smnvl'][0], 'SMNL', 1],
                this.data['page_' + this.data['smnvlPage']]['coordinates']['smnvlCo'][0],
                { page_index: this.data['smnvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.iclmnlfound && this.data['iclmnl']) {
              this.rawData[recordId].result.push([
                [this.data['iclmnl'][0], 'ICLMNL', 1],
                this.data['page_' + this.data['iclmnlPage']]['coordinates']['iclmnlCo'][0],
                { page_index: this.data['iclmnlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.icleilfound && this.data['icleil']) {
              this.rawData[recordId].result.push([
                [this.data['icleil'][0], 'ICLEIL', 1],
                this.data['page_' + this.data['icleilPage']]['coordinates']['icleilCo'][0],
                { page_index: this.data['icleilPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.insvlfound && this.data['insvl']) {
              this.rawData[recordId].result.push([
                [this.data['insvl'][0], 'INSL', 1],
                this.data['page_' + this.data['insvlPage']]['coordinates']['insvlCo'][0],
                { page_index: this.data['insvlPage'] },
                { is_modified: 1 }
              ]
              );
            }
            if (!this.regvlfound && this.data['regvl']) {
              this.rawData[recordId].result.push([
                [this.data['regvl'][0], 'REGL', 1],
                this.data['page_' + this.data['regvlPage']]['coordinates']['regvlCo'][0],
                { page_index: this.data['regvlPage'] },
                { is_modified: 1 }
              ]
              );
            }

            // line items
            try {
              let page = 1;
              while (this.data['page_' + page]) {
                try {
                  for (let item = 0; item < this.data['page_' + page]['lineItems'].length; item++) {
                    const lineItem = [this.data['page_' + page]['lineItems'][item], 'line_item_' + (item + 1)];
                    let lineItemCo;
                    const lineItemPage = { page_index: page };
                    const lineItemModified = {is_modified: this.data['page_' + page]['lineItemChanged'][item]};
                    if (
                      this.data['page_' + page]['coordinates']['lineCo'][item]
                    ) {
                      lineItemCo = this.data['page_' + page]['coordinates']['lineCo'][item];
                      this.rawData[itrI].result.push([lineItem, lineItemCo, lineItemPage, lineItemModified]);
                    } else {
                      this.rawData[itrI].result.push([lineItem, lineItemPage, lineItemModified]);
                    }
                  }
                } catch (e) {
                  // console.log(e);
                }
                page++;
              }
            } catch (e) {
              console.log(e);
            }

            // console.log('Output:', this.rawData[itrI].result)

            // data items
            try {
              let page = 1;
              while (this.data['page_' + page]) {
                try {
                  for (let item = 0; item < this.data['page_' + page]['dataItems'].length; item++) {
                    const dataItem = [
                      this.data['page_' + page]['dataItems'][item], 'data_item_' + (item + 1),
                      this.data['page_' + page]['dataItemAccuracy'][item]
                    ];
                    let dataItemCo;
                    const dataItemPage = { page_index: page };
                    const dataItemModified = {is_modified: this.data['page_' + page]['dataItemChanged'][item]};
                    if (
                      this.data['page_' + page]['coordinates']['dataCo'][item]
                    ) {
                      dataItemCo = this.data['page_' + page]['coordinates']['dataCo'][item];
                      this.rawData[itrI].result.push([dataItem, dataItemCo, dataItemPage, dataItemModified]);
                    } else {
                      this.rawData[itrI].result.push([dataItem, dataItemPage, dataItemModified]);
                    }
                  }
                } catch (e) {
                  // console.log(e);
                }
                page++;
              }
            } catch (e) {
              console.log(e);
            }
          }
          return recordId;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  openedInvoice(changedData: any, modified, isApproved, collectionName?) {
    this.data = changedData;
    const id = this.processOutputData(modified, isApproved);
    const str = JSON.stringify(this.rawData[id]);
    const formdata = new FormData();
    formdata.append('data', str);
    formdata.append('collection_name', collectionName);
    formdata.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.isOpenedUrl, formdata, {
      responseType: 'text'
    });
  }

  saveChangedData(changedData: any, modified, isApproved, collectionName?) {
    this.data = changedData;

    if (sessionStorage.getItem('rolename') && sessionStorage.getItem('rolename').toLowerCase() == 'annotator') {
      this.sendProcessedDataUrl = '/klearstack/saveresult';
    } else {
      this.sendProcessedDataUrl = '/klearstack/updateresult';
    }
    const id = this.processOutputData(modified, isApproved);
    const str = JSON.stringify(this.rawData[id]);
    // const blob = new Blob([str], {type : 'application/json'});
    // saveAs(blob, 'result.json');
    const formdata = new FormData();
    formdata.append('data', str);
    formdata.append('collection_name', collectionName);
    formdata.append('company_name', sessionStorage.getItem('company_name'));
    return this.http.post(this.sendProcessedDataUrl, formdata, {
      responseType: 'text'
    });
  }

}
