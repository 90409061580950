import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DisconnectComponent } from './disconnect/disconnect.component';

const routes: Routes = [

  { path: 'disconnect', component: DisconnectComponent, pathMatch: 'full' },

  {
    path: 'login',
    loadChildren: () => import('./login-page/login-page.module').then(m => m.LoginPageModule)
  },

  {
    path: 'home',
    loadChildren: () => import('./homepage2/homepage2.module').then(m => m.Homepage2Module)
  },

  {
    path: 'home/:id',
    loadChildren: () => import('./homepage2/homepage2.module').then(m => m.Homepage2Module)
  },

  {
    path: 'url',
    loadChildren: () => import('./invoice-from-urls/invoice-from-urls.module').then(m => m.InvoiceFromUrlsModule)
  },

  {
    path: 'url/:companyName',
    loadChildren: () => import('./invoice-from-urls/invoice-from-urls.module').then(m => m.InvoiceFromUrlsModule)
  },
  {
    path: 'url/:companyName/:collectionName',
    loadChildren: () => import('./invoice-from-urls/invoice-from-urls.module').then(m => m.InvoiceFromUrlsModule)
  },
  {
    path: 'url/:companyName/:collectionName/:userId',
    loadChildren: () => import('./invoice-from-urls/invoice-from-urls.module').then(m => m.InvoiceFromUrlsModule)
  },
  {
    path: 'url/:companyName/:collectionName/:userId/:obj_id',
    loadChildren: () => import('./invoice-from-urls/invoice-from-urls.module').then(m => m.InvoiceFromUrlsModule)
  },

  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '**', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
