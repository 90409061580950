/*************************
 *
 * MAVENICK CONFIDENTIAL
 * ______
 *
 *  [2020] - Mavenick Consulting OPC Pvt Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mavenick Consulting OPC Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Mavenick Consulting OPC Pvt Ltd
 * and its suppliers and may be covered by India, U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mavenick Consulting OPC Pvt Ltd.
 */


 import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GetpostService } from '../getpost.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-disconnect',
  templateUrl: './disconnect.component.html',
  styleUrls: ['./disconnect.component.css']
})
export class DisconnectComponent implements OnInit {
  messageText: string;
  popup: boolean;
  operation: any;
  scrHeight: number;
  @Output() logoutEvent = new EventEmitter();

  constructor(
    private getpost: GetpostService,
    private route: Router
    ) { }

  ngOnInit() {
    this.messageText = 'Are you sure to disconnect ?';
    if (sessionStorage.getItem('loggedin') === 'false') {
      sessionStorage.setItem('disconnect', 'true');
      this.route.navigate(['/login']);
    } else {
      this.displayPopup('disconnect');
    }
  }

  displayPopup(operation) {
    this.popup = true;
    this.operation = operation;
    if (operation === 'disconnect') {
      this.messageText = 'Do you want to disconnect ?';
    } else if (operation === 'cancelDisconnect') {
      this.messageText = '';
    }
    // const offsetHeight = event.pageY - event.clientY;
    // this.scrHeight = document.getElementById('sub-normalScreen').offsetHeight;
    document.getElementById('popUpBackground').style.height = 200 + 'px';
    document.getElementById('downloadPopup').style.display = 'flex';
    document.getElementById('downloadPopup').style.flexDirection = 'column';
    document.getElementById('downloadPopup').style.alignItems = 'center';
    document.getElementById('downloadPopup').style.justifyContent = 'center';
    document.getElementById('downloadPopup').style.top = 0 + 'px';
    document.getElementById('popUpBackground').style.display = 'block';
    document.getElementById('cancel-btn').focus();
  }

  action(event?) {
    if (event === 'disconnect') {
      this.disconnectAndLogout();
    } else {
      sessionStorage.removeItem('disconnect');
      this.route.navigate(['/home']);
    }
    // this.hidePopup();
  }
  cancelDisconnect() {
  }

  disconnectAndLogout() {
    const jsonArray = [];
      jsonArray.push({
        _id: sessionStorage.getItem('_id'),
        username: sessionStorage.getItem('username'),
        role: sessionStorage.getItem('rolename'),
        is_blocked: 1
    });
    this.getpost.updateUserDetails(jsonArray).subscribe(res => {
    },
    () => {},
    () => {
      sessionStorage.removeItem('disconnect');
      this.route.navigate(['/login']);
      // this.logoutEvent.emit(1);
    });
  }

  hidePopup() {
    try {
      this.popup = false;
      document.getElementById('popUpBackground').style.height = 'initial';
      document.getElementById('downloadPopup').style.display = 'none';
      document.getElementById('downloadPopup').style.top = 'initial';
      document.getElementById('popUpBackground').style.display = 'none';
    } catch (e) {}
  }

}
