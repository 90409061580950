import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }
  private invoiceList = [];
  private selectedDocType: any;
  private lableJson: any;
  private totalListCount: number;
  recordsPerPage = 10;

  get _invoiceList() {
    return this.invoiceList;
  }

  set _invoiceList(invoiceList) {
    this.invoiceList = invoiceList;
  }

  get _selectedDocType() {
    return this.selectedDocType;
  }

  set _selectedDocType(selectedDocType) {
    this.selectedDocType = selectedDocType;
  }

  get _totalListCount() {
    return this.totalListCount;
  }

  set _totalListCount(totalListCount) {
    this.totalListCount = totalListCount;
  }

  get _recordsPerPage() {
    return this.recordsPerPage;
  }

  set _recordsPerPage(recordsPerPage) {
    this.recordsPerPage = recordsPerPage;
  }

  get _labelJson() {
    return this.lableJson;
  }

  set _labelJson(lableJson) {
    this.lableJson = lableJson;
  }
}
